import * as actions from './action';

const initialState = {
  loading: false,
  machine: null,
  machineErrors: [],
  machineActivities: [],
  machineTypes: [],
  machines: [],
  machinesCount: 0,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.MACHINES_GET_ALL_REQUEST:
      return {
        ...state,
        machines: initialState.machines,
        loading: true,
      };
    case actions.MACHINES_GET_ALL_SUCCESS:
      return {
        ...state,
        machines: payload,
        loading: initialState.loading,
      };
    case actions.MACHINE_TYPES_GET_ALL_REQUEST:
      return {
        ...state,
        machineTypes: initialState.machineTypes,
        loading: true,
      };
    case actions.MACHINE_TYPES_GET_ALL_SUCCESS:
      return {
        ...state,
        machineTypes: payload,
        loading: false,
      };
    case actions.MACHINES_GET_COUNT_REQUEST_SUCCESS:
      return {
        ...state,
        machinesCount: payload,
      };
    case actions.MACHINE_GET_ONE:
      return {
        ...state,
        machine: initialState.machine,
      };
    case actions.MACHINE_GET_ONE_SUCCESS:
      return {
        ...state,
        machine: payload,
      };
    case actions.MACHINE_GET_ERRORS:
      return {
        ...state,
        machineErrors: initialState.machineErrors,
      };
    case actions.MACHINE_GET_ERRORS_SUCCESS:
      return {
        ...state,
        machineErrors: payload,
      };
    case actions.MACHINE_GET_ACTIVITIES:
      return {
        ...state,
        machineActivities: initialState.machineActivities,
      };
    case actions.MACHINE_GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        machineActivities: payload,
      };
    case actions.MACHINE_UPDATE_PROGRAM_POSITION_SUCCESS:
      let modified = {
        '@id': payload['@id'],
        '@type': payload['@type'],
        position: payload.position,
        machine: payload.machine['@id'],
        program: payload.program,
      };
      let programMachines = payload.machine.programMachines;
      programMachines.map((programMachine, index) => {
        if (typeof programMachine === 'string') {
          programMachines.splice(index, 1);
          programMachines.splice(modified.position - 1, 0, modified);
        }
        return true;
      });
      programMachines.sort(
        (program1, program2) => program1.position - program2.position,
      );
      let machine = payload.machine;
      machine.programMachines = programMachines;

      return {
        ...state,
        machine,
      };
    default:
      return state;
  }
};
