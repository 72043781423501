import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import AccountCreate from './components/AccountCreation';
import AuthGoogle from './components/AuthGoogle/auth';
import Profile from './components/Profile';
import ProfileEdit from './components/Profile/edit';
import Activate from './components/Activate/activate';
import SelectUser from './components/SelectUser';
import {getToken, hasRoles} from './utils/auth';
import GalleryEurofours from './components/ImagesEurofours';
import GalleryMachines from './components/ImagesMachines';
import UpdateMachineImage from './components/ImagesMachines/update';
import ProfileHistory from './components/Profile/history';
import ChangePassword from './components/Passwords/Change';
import ResetPassword from './components/Passwords/Reset';
import GalleryUser from './components/UserGallery';
import NotGranted from './components/NotGranted';
import {CreateTechnician} from './components/CreateTechnician';
import {SOFTWARE_UPDATES_CREATE_PATH, SOFTWARE_UPDATES_PATH} from './constants';
import SoftwareUpdatesList from './components/SoftwareUpdates/list';
import SoftwareUpdatesCreate from './components/SoftwareUpdates/create';
import SoftwareUpdatesEdit from './components/SoftwareUpdates/edit';
import Create from './components/User/create.js';

const routes_denied = ['errors', 'program_machines'];

const token = getToken();

const UserAccess = component => {
  if (hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_EUROFOURS_TECHNICIAN'])) {
    return <Redirect to="/select-user" />;
  }
  return component;
};

const OnlyAdminAccess = component => {
  if (
    hasRoles([
      'ROLE_EUROFOURS_TECHNICIAN',
      'ROLE_SUPERADMIN',
      'ROLE_CLIENT_ADMIN',
    ])
  ) {
    return component;
  }
  return <Redirect to="/" />;
};

const OnlySuperAdminAccess = component => {
  if (hasRoles(['ROLE_SUPERADMIN'])) {
    return component;
  }
  return <Redirect to="/" />;
};

const NoAdminAccess = component => {
  if (!hasRoles(['ROLE_EUROFOURS_TECHNICIAN'])) {
    return component;
  }
  return <Redirect to="/" />;
};

const authenticatedCustomRoutes = token
  ? [
      ...routes_denied.map((route_denied, index) => (
        <Route
          key={index}
          path={`/${route_denied}`}
          render={() => {
            return <Redirect to="/" />;
          }}
        />
      )),
      <Route exact path="/profile" render={() => <Profile />} />,
      <Route
        exact
        path="/profile/edit"
        render={() => NoAdminAccess(<ProfileEdit />)}
      />,
      <Route exact path="/activity" render={() => <ProfileHistory />} />,
      <Route path="/change-password" render={() => <ChangePassword />} />,
      <Route
        exact
        path="/manage-images"
        render={() => UserAccess(<GalleryUser />)}
      />,
      <Route
        exact
        path="/select-user"
        render={() => {
          return <SelectUser />;
        }}
      />,
      <Route
        exact
        path="/images-eurofours"
        render={() => {
          return OnlySuperAdminAccess(<GalleryEurofours />);
        }}
      />,
      <Route
        exact
        path="/images-machines"
        render={() => {
          return OnlySuperAdminAccess(<GalleryMachines />);
        }}
      />,
      <Route
        exact
        path="/images-machines/:machineCode"
        render={() => {
          return OnlySuperAdminAccess(<UpdateMachineImage />);
        }}
      />,
      <Route
        exact
        path="/create-eurofours-tech"
        render={() => {
          return OnlyAdminAccess(<CreateTechnician />);
        }}
      />,
      <Route
        exact
        path="/create-eurofours-admin"
        render={() => {
          return (
            <Create
              basePath="/create-eurofours-admin"
              location="admins"
              resource="users"
            />
          );
        }}
      />,
      <Route
        exact
        path="/eurofours-technicians"
        render={() => {
          return <SelectUser />;
        }}
      />,
      <Route
        exact
        path="/client-admins"
        render={() => {
          return <SelectUser />;
        }}
      />,
      <Route
        exact
        path={SOFTWARE_UPDATES_PATH}
        render={() => {
          return OnlySuperAdminAccess(<SoftwareUpdatesList />);
        }}
      />,
      <Route
        exact
        path={SOFTWARE_UPDATES_CREATE_PATH}
        render={() => {
          return OnlySuperAdminAccess(<SoftwareUpdatesCreate />);
        }}
      />,
      <Route
        exact
        path="/software-updates/:id"
        render={() => {
          return OnlySuperAdminAccess(<SoftwareUpdatesEdit />);
        }}
      />,
    ]
  : [];

export default hasRoles(['ROLE_NEED_2FA'])
  ? [<Route exact path="/authenticate" render={() => <AuthGoogle />} />]
  : [
      ...authenticatedCustomRoutes,
      <Route path="/reset-password/:token" render={() => <ChangePassword />} />,
      <Route
        path="/reset"
        exact
        render={() => {
          return <ResetPassword />;
        }}
      />,
      <Route
        path="/activate/:token"
        render={() => {
          return <Activate />;
        }}
        noLayout
      />,
      <Route
        exact
        path="/create-account"
        render={() => {
          return <AccountCreate />;
        }}
        noLayout
      />,
      <Route
        exact
        path="/not-granted"
        render={() => {
          return <NotGranted />;
        }}
      />,
    ];
