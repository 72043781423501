import React from 'react';
import {
  ApproveButton,
  DisApproveButton,
  AssignButton,
  BooleanButton,
} from '../Buttons';
import CustomActions from './CustomActions';
import {hasRoles} from '../../utils/auth';

export default props => {
  let buttons = [];
  if (hasRoles(['ROLE_MACHINE_INSTALL_PROGRAM', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton
        target="programs"
        assign
        roles={['ROLE_MACHINE_INSTALL_PROGRAM']}
      />,
    );
  }
  if (hasRoles(['ROLE_STRUCTURE_ADD_MACHINE', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton
        target="structures"
        assign
        single
        roles={['ROLE_STRUCTURE_ADD_MACHINE']}
      />,
    );
  }
  if (hasRoles(['ROLE_MACHINE_ACTIVATE_ACTION', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <BooleanButton
        property="active"
        switchOn
        roles={['ROLE_MACHINE_ACTIVATE_ACTION']}
      />,
    );
  }
  if (hasRoles(['ROLE_MACHINE_APPROVE_ACTION', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <ApproveButton
        label="custom.action.machines.approve"
        property="approved"
        switchOn
        roles={['ROLE_MACHINE_APPROVE_ACTION']}
      />,
    );
  }
  if (hasRoles(['ROLE_MACHINE_INSTALL_PROGRAM', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton
        target="programs"
        roles={['ROLE_MACHINE_INSTALL_PROGRAM']}
      />,
    );
  }
  if (hasRoles(['ROLE_STRUCTURE_ADD_MACHINE', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton
        target="structures"
        single
        roles={['ROLE_STRUCTURE_ADD_MACHINE']}
      />,
    );
  }
  if (hasRoles(['ROLE_MACHINE_ACTIVATE_ACTION', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <DisApproveButton
        property="active"
        roles={['ROLE_MACHINE_ACTIVATE_ACTION']}
      />,
    );
  }
  if (hasRoles(['ROLE_MACHINE_APPROVE_ACTION', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <DisApproveButton
        property="approved"
        label="custom.action.machines.disapprove"
        roles={['ROLE_MACHINE_APPROVE_ACTION']}
      />,
    );
  }
  return <CustomActions buttons={buttons} {...props} />;
};
