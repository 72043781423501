import {getToken} from './auth';

const apiURL = process.env.REACT_APP_API_ENTRYPOINT;

async function client(
  endpoint,
  {data, method, headers: customHeaders, ...customConfig} = {},
) {
  const config = {
    method: method ? method : data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Accept: 'application/ld+json',
      Authorization: getToken() ? `Bearer ${getToken()}` : undefined,
      'Content-Type': data ? 'application/ld+json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };
  try {
    return window
      .fetch(`${apiURL}/${endpoint.replace(/^\//, '')}`, config)
      .then(async response => {
        if (response.status === 400 || response.status === 422) {
          const data = await response.json();
          return Promise.reject(data['hydra:description']);
        }

        if (response.status === 404) {
          return Promise.reject('404, not found.');
        }

        if (response.status < 200 || response.status >= 300) {
          return Promise.reject(
            'An unexpected error has rejected your action, we are sorry for the inconvenience.',
          );
        }

        if (response.status === 204) {
          return;
        }

        const data = await response.json();
        if (response.ok) {
          return data;
        } else {
          return Promise.reject(data);
        }
      });
  } catch (e) {
    console.log(e);
    return null;
  }
}

export {client};
