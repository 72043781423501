import React from 'react';
import {Layout} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import SimpleAppBar from '../AppBar/AppBar';
import Footer from '../Footer';
import MyMenu, {MyEurofoursMenu} from '../../menu';
import {getToken, hasRoles} from '../../utils/auth';

const DefaultLayout = props => (
  <>
    <Layout {...props} appBar={SimpleAppBar} sidebar={() => <></>} />
    <Footer />
  </>
);

const styles = () => ({
  layout: {
    backgroundColor: '#303030',
    minHeight: 100,
    paddingTop: '70px',
  },
});

const MyLayout = withStyles(styles)(({classes, ...props}) => {
  return (
    <>
      <Layout
        className={classes.layout}
        {...props}
        appBar={SimpleAppBar}
        menu={
          hasRoles(['ROLE_EUROFOURS_TECHNICIAN', 'ROLE_SUPERADMIN'])
            ? MyEurofoursMenu
            : MyMenu
        }
      />
      <Footer />
    </>
  );
});

export default getToken() && !hasRoles(['ROLE_NEED_2FA'])
  ? MyLayout
  : DefaultLayout;
