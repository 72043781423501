import React from 'react';
import {translate} from 'react-admin';
import './index.css';

export const ErrorGenerator = translate(({items, translate}) =>
  items
    ? items.length
      ? items.map(
          (item, index) =>
            !item.dateEnd && (
              <div key={index}>
                <span className={['not-resolved', 'errors'].join(' ')}>
                  <span>{item.name}</span>
                  <span className={'date'}>
                    <span>
                      {`${new Date(
                        item.dateStart,
                      ).toLocaleString()} - ${translate(
                        `custom.errors.not_resolved`,
                      )}`}
                    </span>
                  </span>
                </span>
              </div>
            ),
        )
      : 'Aucun défaut détecté sur la machine'
    : 'Chargement des défauts de la machine en cours',
);
