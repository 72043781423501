import React, {Fragment} from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  LongTextInput,
  maxLength,
} from 'react-admin';
import {Create} from '../Common/Admin';
import Title from '../Common/Header';
import {hasRoles} from '../../utils/auth';
import {CustomToolbar} from '../Common/Admin/CustomToolbar';

const isRequired = [required()];
const validateDescription = [maxLength(255)];

export default props => {
  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAG_CREATE'])) {
    window.location.replace('/#/not-granted');
  }
  return (
    <Create
      title={
        <Title
          title="custom.components.tags.create.title"
          redirect="list"
          {...props}
        />
      }
      actions={<Fragment />}
      {...props}>
      <SimpleForm
        toolbar={<CustomToolbar showDelete={false} />}
        redirect="list">
        <TextInput source="name" validate={isRequired} />
        <LongTextInput source="description" validate={validateDescription} />
      </SimpleForm>
    </Create>
  );
};
