import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {getEmail, hasRoles} from '../../../utils/auth';

const userEmail = getEmail();
const isUser = hasRoles(['ROLE_USER']);

class NbMachineView extends Component {
  getNbMachineWithChildren = (structure, nb) => {
    if (structure.children) {
      structure.children
        .filter(
          c =>
            !isUser ||
            (Array.isArray(c.relatedUsers) &&
              c.relatedUsers.some(user => userEmail)),
        ) // filter substructures if not concerned by current user
        .forEach(child => {
          nb += child.nbMachine;
          this.getNbMachineWithChildren(child, nb);
        });
    }

    return nb;
  };

  render() {
    const {structure, withSubStructures} = this.props;
    const nbSubStructuresMachine = withSubStructures
      ? this.getNbMachineWithChildren(structure, 0)
      : 0;

    return (
      <>
        <Typography component="span" color="primary">
          {structure.nbMachine}
        </Typography>
        {nbSubStructuresMachine ? (
          <span>&nbsp;{`(+${nbSubStructuresMachine})`}</span>
        ) : (
          ''
        )}
      </>
    );
  }
}

NbMachineView.propTypes = {
  structure: PropTypes.object,
};

export default NbMachineView;
