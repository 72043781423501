import React, {Component, Fragment} from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import {formValueSelector, change} from 'redux-form';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose';
import UserGallery from '../../UserGallery';
import UserUploader from '../../ImageUploader';
import EurofoursGallery from '../../ImagesEurofours';
import {translate, REDUX_FORM_NAME} from 'react-admin';
import {initSendImage, resetSendImage} from '../../ImageUploader/store/action';
import {resetImage} from '../../UserGallery/store/action';
import {connect} from 'react-redux';

const styles = theme => ({
  imageContainer: {
    position: 'relative',
    textAlign: 'center',
    '& img': {
      borderRadius: 100 + '%',
    },
  },
  removeImage: {
    position: 'absolute',
    right: 10 + '%',
    fontSize: 25,
    lineHeight: 'calc(25px/2)',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  blankAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: 150,
    height: 150,
    borderRadius: 100 + '%',
    border: '1px solid #cecece',
    fontSize: 90,
    color: '#666',
  },
  buttons: {
    margin: theme.spacing.unit,
    width: 100 + '%',
    paddingTop: 0.5 + 'em',
    paddingBottom: 0.5 + 'em',
  },
});

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eurofoursGallery: false,
      userGallery: false,
    };
  }

  updateAvatar = value => this.props.change(REDUX_FORM_NAME, 'avatar', value);

  componentDidUpdate = () => {
    const {image, resetImage} = this.props;

    if (image !== null) {
      this.updateAvatar(image);
      resetImage();
    }
  };

  handleClickOpen = name => () => {
    this.setState({[name]: true});
  };

  handleSelect = avatar => {
    this.updateAvatar(avatar);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({
      eurofoursGallery: false,
      userGallery: false,
    });
  };

  render() {
    const {userGallery, eurofoursGallery} = this.state;
    const {
      classes,
      translate,
      initSendImage,
      resetSendImage,
      isUpdated,
      avatar,
    } = this.props;

    return (
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justify="center">
        <Grid item xs={12} sm={6} className={classes.imageContainer}>
          {avatar ? (
            <>
              <div
                onClick={() => {
                  this.updateAvatar(null);
                }}
                className={classes.removeImage}>
                x
              </div>
              <img
                src={`${process.env.REACT_APP_API_ENTRYPOINT}/${avatar}`}
                alt="program-avatar"
                width="150"
              />
            </>
          ) : (
            <div className={classes.blankAvatar}>
              <PhotoCamera fontSize="inherit" color="inherit" />
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container direction="column">
            <Grid item>
              <Button
                variant="contained"
                className={classes.buttons}
                color="primary"
                onClick={this.handleClickOpen('eurofoursGallery')}>
                {translate('custom.components.image_uploader.images_eurofours')}
              </Button>
              <Dialog
                open={eurofoursGallery}
                onClose={this.handleClose}
                aria-labelledby="eurofours">
                <DialogTitle id="eurofours">
                  {translate('custom.components.image_uploader.label')}
                </DialogTitle>
                <DialogContent>
                  <EurofoursGallery
                    handleSelect={this.handleSelect}
                    selectable
                  />
                </DialogContent>
              </Dialog>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.buttons}
                color="primary"
                onClick={this.handleClickOpen('userGallery')}>
                {translate(
                  'custom.components.image_uploader.images_bibliotheque',
                )}
              </Button>
              <Dialog
                open={userGallery}
                onClose={this.handleClose}
                aria-labelledby="bibliotheque">
                <DialogTitle id="bibliotheque">
                  {translate('custom.components.image_uploader.label')}
                </DialogTitle>
                <DialogContent>
                  <UserGallery handleSelect={this.handleSelect} selectable />
                </DialogContent>
              </Dialog>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.buttons}
                color="primary"
                onClick={() => initSendImage()}>
                {translate('custom.components.image_uploader.images_upload')}
              </Button>
              <Dialog
                open={isUpdated !== null}
                onClose={() => resetSendImage()}
                aria-labelledby="upload">
                <DialogContent>
                  <UserUploader />
                </DialogContent>
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const selector = formValueSelector(REDUX_FORM_NAME);

const mapStateToProps = ({imageReducer, userGalleryReducer, ...state}) => ({
  isUpdated: imageReducer.isUpdated,
  image: userGalleryReducer.image,
  avatar: selector(state, 'avatar'),
});

export default compose(
  translate,
  connect(mapStateToProps, {initSendImage, resetSendImage, resetImage, change}),
  withStyles(styles),
)(Image);
