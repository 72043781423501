import * as actions from './action';

const initialState = {
  changePassword: null,
  resetPassword: null,
  resetPasswordChild: null,
  loading: false,
};

export default (state = initialState, {type}) => {
  switch (type) {
    case actions.PASSWORD_CHANGE_REQUEST:
    case actions.PASSWORD_RESET_REQUEST:
    case actions.PASSWORD_RESET_CHILD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        changePassword: true,
        loading: false,
      };
    case actions.PASSWORD_CHANGE_ERROR:
      return {
        ...state,
        changePassword: false,
        loading: false,
      };
    case actions.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        resetPassword: true,
        loading: false,
      };
    case actions.PASSWORD_RESET_ERROR:
      return {
        ...state,
        resetPassword: false,
        loading: false,
      };
    case actions.PASSWORD_RESET_CHILD_SUCCESS:
      return {
        ...state,
        resetPasswordChild: true,
        loading: false,
      };
    case actions.PASSWORD_RESET_CHILD_ERROR:
      return {
        ...state,
        resetPasswordChild: false,
        loading: false,
      };
    default:
      return state;
  }
};
