import React, {Component, Children, cloneElement} from 'react';
import {withRouter} from 'react-router-dom';
import {
  Filter as BaseFilter,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  ReferenceArrayInput,
  translate,
  NullableBooleanInput,
} from 'react-admin';
import SelectArrayInput from '../Input/selectArrayInput';
import StructureInput from '../Input/StructureInput';
import {connect} from 'react-redux';
import {withStyles, Grid, Typography} from '@material-ui/core';
import NavigationRefresh from '@material-ui/icons/Refresh';
import {Link} from 'react-router-dom';
import qs from 'query-string';
import compose from 'recompose/compose';
import classNames from 'classnames';
import lightblue from '@material-ui/core/colors/lightBlue';
import {getNbResourcesToApprove} from '../Common/store/action';
import {getRoles, hasRoles} from '../../utils/auth';

const styles = theme => ({
  container: {
    marginBottom: '1em',
  },
  form: {
    margin: '20px 0',
    padding: '0 16px 8px 16px',
    backgroundColor: '#ededed',
    borderRadius: 2,
  },
  button: {
    textTransform: 'none',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  red: {
    color: theme.palette.primary.main,
  },
  blue: {
    color: lightblue['400'],
  },
  input: {
    width: 250,
    background: 'white',
    borderRadius: 2,
    border: '8px solid white',
    boxShadow: '0 0 0 1px #c6c6c6',
    '& svg': {
      color: 'inherit',
    },
  },
  booleanInput: {
    marginTop: '8px',
  },
});

class FilterController extends Component {
  componentDidMount = () => {
    this.props.getNbResourcesToApprove(this.props.resource);
  };

  sanitizeProps = ({getNbResourcesToApprove, ...rest}) => rest;

  render() {
    const {
      translate,
      classes,
      location,
      children,
      nbResourcesToApprove,
      isLoading,
      ...rest
    } = this.props;
    const {resource} = rest;
    const query = location ? qs.parse(location.search) : {};

    return (
      <Grid
        classes={{
          container: classes.container,
        }}
        container
        direction="row-reverse"
        justify="space-between">
        <Grid item xs={12}>
          <BaseFilter
            classes={{
              form: classes.form,
            }}
            {...this.sanitizeProps(rest)}>
            {Children.map(children, (child, index) =>
              cloneElement(child, {
                key: index,
                className: `${
                  child.type === BooleanInput
                    ? `${classes.booleanInput} ${classes.input}`
                    : classes.input
                }`,
                alwaysOn: true,
                shrink: child.type === ReferenceArrayInput ? true : undefined,
                options:
                  child.type === ReferenceArrayInput
                    ? {
                        disableUnderline: true,
                      }
                    : {
                        InputProps: {
                          disableUnderline: true,
                        },
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
              }),
            )}
          </BaseFilter>
        </Grid>
        {location && (
          <Grid item>
            <Typography>
              <Link
                to={{
                  ...location,
                  search: qs.stringify(Object.assign(query, {filter: ''})),
                }}
                className={classNames(classes.button, classes.red)}>
                <NavigationRefresh />
                {translate('custom.components.filter.reset')}
              </Link>
            </Typography>
          </Grid>
        )}
        {0 < nbResourcesToApprove && !isLoading && (
          <Grid item>
            <Typography>
              <Link
                to={{
                  ...location,
                  search: qs.stringify(
                    Object.assign(query, {
                      filter: JSON.stringify({approved: false}),
                    }),
                  ),
                }}
                className={classNames(classes.button, classes.blue, 'blink')}>
                {translate(`custom.components.filter.to_approve.${resource}`, {
                  smart_count: nbResourcesToApprove,
                  _: '',
                })}
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
  nbResourcesToApprove: state.commonReducer.nbResourcesToApprove,
});

const enhance = compose(
  connect(mapStateToProps, {getNbResourcesToApprove}),
  translate,
  withStyles(styles),
);

const Filter = enhance(FilterController);

export const MachineFilters = withRouter(props => {
  const {location, filterValues, setFilters} = props;

  if (location.search === '' && Object.keys(filterValues).length > 0) {
    setFilters({}); // si aucun paramètre de search dans l'URL : on réinitialise les filtres
  }
  return (
    <Filter {...props}>
      <TextInput source="serialNumber" />
      <TextInput source="name" />
      {hasRoles(['ROLE_CLIENT_ADMIN']) ? (
        <ReferenceInput source="category" reference="categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
      ) : (
        <></>
      )}
      {hasRoles(['ROLE_STRUCTURE_ACCESS', 'ROLE_CLIENT_ADMIN']) ? (
        <ReferenceInput
          source="structure"
          reference="structures"
          perPage={500}
          sort={{field: 'name', order: 'ASC'}}>
          {/*<SelectInput optionText="name" />*/}
          <StructureInput filter />
        </ReferenceInput>
      ) : (
        <></>
      )}
      {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_MACHINE_APPROVE_ACCESS']) ? (
        <NullableBooleanInput source="approved" />
      ) : (
        <></>
      )}
    </Filter>
  );
});

export const StructureFilters = withRouter(props => {
  const {location, filterValues, setFilters} = props;

  if (location.search === '' && Object.keys(filterValues).length > 0) {
    setFilters({}); // si aucun paramètre de search dans l'URL : on réinitialise les filtres
  }
  return (
    <Filter {...props}>
      <TextInput source="name" />
    </Filter>
  );
});

export const TagFilters = withRouter(props => {
  const {location, filterValues, setFilters} = props;

  if (location.search === '' && Object.keys(filterValues).length > 0) {
    setFilters({}); // si aucun paramètre de search dans l'URL : on réinitialise les filtres
  }

  return (
    <Filter {...props}>
      <TextInput source="name" />
    </Filter>
  );
});

export const ProgramFilters = withRouter(props => {
  const {location, filterValues, setFilters} = props;

  if (location.search === '' && Object.keys(filterValues).length > 0) {
    setFilters({}); // si aucun paramètre de search dans l'URL : on réinitialise les filtres
  }
  return (
    <Filter {...props}>
      {hasRoles(['ROLE_TAG_ACCESS', 'ROLE_CLIENT_ADMIN']) ? (
        <ReferenceArrayInput
          source="tags"
          reference="tags"
          perPage={500}
          sort={{field: 'name', order: 'ASC'}}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      ) : (
        <></>
      )}
      {getRoles().includes('ROLE_CLIENT_ADMIN') ? (
        <ReferenceInput
          source="category"
          reference="categories"
          perPage={500}
          sort={{field: 'name', order: 'ASC'}}>
          <SelectInput optionText="name" />
        </ReferenceInput>
      ) : (
        <></>
      )}
      <TextInput source="name" />
      {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_APPROVE_ACCESS']) ? (
        <NullableBooleanInput source="approved" />
      ) : (
        <></>
      )}
    </Filter>
  );
});

export const UserFilters = withRouter(props => {
  const {location, filterValues, setFilters} = props;

  if (location.search === '' && Object.keys(filterValues).length > 0) {
    setFilters({}); // si aucun paramètre de search dans l'URL : on réinitialise les filtres
  }
  return (
    <Filter {...props}>
      {hasRoles(['ROLE_STRUCTURE_ACCESS', 'ROLE_CLIENT_ADMIN']) ? (
        <ReferenceInput
          perPage={500}
          source="structure"
          reference="structures"
          sort={{field: 'name', order: 'ASC'}}>
          <StructureInput filter />
        </ReferenceInput>
      ) : (
        <></>
      )}
      <TextInput source="society" />
      <TextInput source="email" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
    </Filter>
  );
});

export const SoftwareUpdatesFilters = withRouter(props => {
  const {location, filterValues, setFilters} = props;

  if (location.search === '' && Object.keys(filterValues).length > 0) {
    setFilters({}); // si aucun paramètre de search dans l'URL : on réinitialise les filtres
  }
  return (
    <Filter {...props}>
      <BooleanInput source="addRevoked" />
      <BooleanInput source="isAllowedBeta" />
    </Filter>
  );
});
