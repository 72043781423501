import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {Card, Tab, Tabs} from '@material-ui/core';
import ImagesListFactory from '../Images/list';
import {deleteImagesGallery, getImagesGallery} from './store/action';
import ImageUploader from '../ImageUploader';
import {translate, Confirm} from 'react-admin';
import {getId} from '../../utils/auth';
import Loading from '../Loading';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
});

class UserGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 'manage',
      opened: false,
      imageId: null,
    };
  }

  componentDidMount() {
    this.props.getImagesGallery();
  }

  handleSelect = image => {
    // image already contains the user path now (eg: "5/image-2.png") so we dont need to have the prefix "images/${getId()}/${image}"
    this.props.handleSelect(`images/${image}`);
  };

  handleChange = (event, value) => {
    this.setState({value});
  };

  retrieveButton = item => {
    if ('BUTTON' === item.tagName) {
      return item;
    }
    return this.retrieveButton(item.parentNode);
  };

  askConfirmation = event => {
    this.setState({
      imageId: this.retrieveButton(event.target).attributes.id.value,
      opened: true,
    });
  };

  userHandleDelete = () => {
    this.props.deleteImagesGallery({image: this.state.imageId.split('/')[1]});
    this.setState({opened: false});
  };

  handleDelete = event => {
    event.preventDefault();
    this.props.deleteImagesGallery({
      image: this.retrieveButton(event.target).attributes.id.value,
    });
  };

  handleClose = () => {
    this.setState({opened: false, imageId: null});
  };

  render() {
    const {userGallery, theme, isLoaded, translate, selectable} = this.props;
    const {value, opened} = this.state;

    const isUserGallery = true;

    return (
      <Card>
        {isLoaded ? (
          selectable ? (
            userGallery && (
              <>
                <ImagesListFactory
                  handleDelete={this.askConfirmation}
                  images={userGallery}
                  selectable={selectable}
                  handleSelect={this.handleSelect}
                  path={`/images/`}
                  isUserGallery={isUserGallery}
                />
                {opened && (
                  <Confirm
                    isOpen={opened}
                    title={translate('custom.images.delete.title')}
                    content={translate('custom.images.delete.content')}
                    confirm={translate('ra.message.yes')}
                    cancel={translate('ra.message.no')}
                    onConfirm={this.userHandleDelete}
                    onClose={this.handleClose}
                  />
                )}
              </>
            )
          ) : (
            <Fragment>
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                style={{backgroundColor: '#303030'}}
                fullWidth>
                <Tab
                  value={'manage'}
                  label={translate('custom.images.manage')}
                />
                <Tab value={'add'} label={translate('custom.images.add')} />
              </Tabs>
              {value === 'manage' && (
                <div dir={theme.direction}>
                  <ImagesListFactory
                    handleDelete={this.handleDelete}
                    images={userGallery}
                    handleSelect={this.handleSelect}
                    selectable={selectable}
                    path={`/images/${getId()}/`}
                  />
                </div>
              )}
              {value === 'add' && (
                <div dir={theme.direction}>
                  <ImageUploader />
                </div>
              )}
            </Fragment>
          )
        ) : (
          <Loading />
        )}
      </Card>
    );
  }
}

const mapStateToProps = ({userGalleryReducer}) => ({
  userGallery: userGalleryReducer.userGallery,
  isLoaded: userGalleryReducer.isLoaded,
});

export default compose(
  translate,
  connect(mapStateToProps, {getImagesGallery, deleteImagesGallery}),
  withStyles(styles),
)(UserGallery);
