export const PROGRAMS_GET_ONE_RESOURCE = 'PROGRAMS_GET_ONE_RESOURCE';
export const PROGRAMS_GET_ONE_SUCCESS = 'PROGRAMS_GET_ONE_SUCCESS';
export const CREATE_PROGRAM = 'CREATE_PROGRAM';
export const CREATE_PROGRAM_SUCCESS = 'CREATE_PROGRAM_SUCCESS';
export const RESET_PROGRAM = 'RESET_PROGRAM';
export const RESET_CREATED_TAG = 'RESET_CREATED_TAG';

export const getProgram = data => ({
  type: PROGRAMS_GET_ONE_RESOURCE,
  payload: data,
});

export const createProgram = data => ({
  type: CREATE_PROGRAM,
  payload: data,
});

export const resetProgram = () => ({
  type: RESET_PROGRAM,
});

export const resetCreatedTag = () => ({
  type: RESET_CREATED_TAG,
});
