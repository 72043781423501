import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {verifyUserToken} from './store/action';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import Loading from '../Loading';

class Activate extends Component {
  componentDidMount() {
    this.props.verifyUserToken(this.props.match.params.token);
  }

  render() {
    if (!this.props.loaded) {
      return <Loading />;
    }

    return <Redirect to="/login" />;
  }
}

const mapStateToProps = ({tokenVerifyReducer}) => ({
  loaded: tokenVerifyReducer.loaded,
});

const enhance = compose(
  connect(mapStateToProps, {verifyUserToken}),
  withRouter,
);

export default enhance(Activate);
