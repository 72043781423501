export const IMAGES_GET_EUROFOURS = 'IMAGES_GET_EUROFOURS';
export const IMAGES_GET_EUROFOURS_SUCCESS = 'IMAGES_GET_EUROFOURS_SUCCESS';
export const IMAGES_DELETE_EUROFOURS = 'IMAGES_DELETE_EUROFOURS';
export const IMAGES_DELETE_EUROFOURS_SUCCESS =
  'IMAGES_DELETE_EUROFOURS_SUCCESS';
export const IMAGE_SEND_EUROFOURS_SUCCESS = 'IMAGE_SEND_EUROFOURS_SUCCESS';

export const getImagesEurofours = data => ({
  type: IMAGES_GET_EUROFOURS,
  payload: data,
});

export const deleteImagesEurofours = data => ({
  type: IMAGES_DELETE_EUROFOURS,
  payload: data,
});
