import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from 'react-admin';
import {getToken, resetToken, setToken} from './utils/auth';
import {client} from './utils/api-client';

export default (type, params) => {
  switch (type) {
    case AUTH_LOGIN:
      const {username, password} = params;

      return client('login', {data: {username, password}}).then(({token}) => {
        setToken(token);
        window.location.replace('/');
      });

    case AUTH_LOGOUT:
      resetToken();
      window.location.reload();
      break;

    case AUTH_ERROR:
      if (401 === params.status) {
        resetToken();
      }
      if (403 === params.status) {
        if (!getToken()) {
          return Promise.reject();
        }
        window.location.replace('/#/not-granted');
      }
      break;

    case AUTH_CHECK:
      return getToken ? Promise.resolve() : Promise.reject();

    case AUTH_GET_PERMISSIONS:
      const role = localStorage.getItem('role');
      return role ? Promise.resolve(role) : Promise.reject();

    default:
  }

  return Promise.resolve();
};
