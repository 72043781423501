import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Confirm, translate, resolveRedirectTo} from 'react-admin';
import {crudDeleteMany} from 'ra-core';
import {compose} from 'recompose';

class DeleteAction extends Component {
  handleDialogClose = () => {
    this.props.onExit(false);
  };

  handleConfirm = () => {
    const {
      basePath,
      crudDeleteMany,
      resource,
      selectedIds,
      record,
    } = this.props;
    crudDeleteMany(resource, selectedIds, basePath);

    this.props.onExit(
      record ? resolveRedirectTo('list', basePath, record.id, record) : false,
    );
  };

  render() {
    const {resource, translate, selectedIds} = this.props;

    return (
      <Confirm
        isOpen={true}
        title={translate(`custom.message.bulk_delete_title.${resource}`, {
          smart_count: selectedIds.length,
        })}
        content={translate('ra.message.bulk_delete_content', {
          name: translate(`custom.action.${resource}.name`, {
            smart_count: selectedIds.length,
          }),
          smart_count: selectedIds.length,
        })}
        confirm={translate('ra.message.yes')}
        cancel={translate('ra.message.no')}
        onConfirm={this.handleConfirm}
        onClose={this.handleDialogClose}
      />
    );
  }
}

DeleteAction.propTypes = {
  selectedIds: PropTypes.array,
  record: PropTypes.object,
  crudDeleteMany: PropTypes.func.isRequired,
  basePath: PropTypes.string,
  resource: PropTypes.string,
  redirect: PropTypes.bool,
  onExit: PropTypes.func,
  translate: PropTypes.func.isRequired,
};

DeleteAction.defaultProps = {
  selectedIds: [],
};

export default compose(
  translate,
  connect(undefined, {crudDeleteMany}),
)(DeleteAction);
