import React from 'react';
import {TabbedForm} from 'react-admin';

const CustomTabbedForm = props => {
  // on formatte les données de la phase de cuisson pour arrondir les celsius en cas de conversion fahrenheit
  const formatData = data => {
    const heatPhase = data.phaseGroups.find(p => (p.phaseGroupeType = 'heat'));
    heatPhase.phases.map(p =>
      p.values.map(c => (c.value = Math.round(c.value))),
    );
    heatPhase.prePhaseValues.map(c => (c.value = Math.round(c.value)));
    return data;
  };

  const handleSave = data => {
    props.save(formatData(data));
  };

  return <TabbedForm {...props} save={handleSave} />;
};

export default CustomTabbedForm;
