import * as actions from './action';
import {CRUD_UPDATE_SUCCESS} from 'react-admin';

const initialState = {
  history: [],
  historyLoaded: false,
  appbarHistory: null,
  refresh: false,
  historyAccessPlus: [],
  historyAccessPlusLoaded: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.HISTORY_GET_PROFILE_REQUEST:
    case actions.HISTORY_GET_ALL_ONE_REQUEST:
      return {
        ...state,
        history: initialState.history,
        historyLoaded: initialState.historyLoaded,
        refresh: initialState.refresh,
      };
    case actions.HISTORY_GET_ACCESSPLUS_REQUEST:
    case actions.HISTORY_GET_ALL_ONE_ACCESSPLUS_REQUEST:
      return {
        ...state,
        historyAccessPlus: initialState.historyAccessPlus,
        historyAccessPlusLoaded: initialState.historyAccessPlusLoaded,
        refresh: initialState.refresh,
      };
    case actions.HISTORY_GET_PROFILE_SUCCESS:
    case actions.HISTORY_GET_ALL_ONE_SUCCESS:
      return {
        ...state,
        history: payload,
        historyLoaded: true,
      };
    case actions.HISTORY_GET_ACCESSPLUS_SUCCESS:
    case actions.HISTORY_GET_ALL_ONE_ACCESSPLUS_SUCCESS:
      return {
        ...state,
        historyAccessPlus: payload,
        historyAccessPlusLoaded: true,
      };
    case actions.HISTORY_GET_APPBAR_SUCCESS:
      return {
        ...state,
        appbarHistory: payload,
      };
    case CRUD_UPDATE_SUCCESS:
      return {
        ...state,
        refresh: true,
      };
    default:
      return state;
  }
};
