import React from 'react';
import {DeleteWithConfirmButton} from 'react-admin';
import {Show} from '../Common/Admin';
import Title from '../Common/Header';
import StructureShowView from './view/showView';
import StructureActions from '../ActionsCards/StructureActions';
import {hasRoles} from '../../utils/auth';
import {TopBarList, Breadcrumb} from '../TopBar';
import {connect} from 'react-redux';

const StructureShow = props => {
  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_ACCESS'])) {
    window.location.replace('/#/not-granted');
  }
  const {structuresData, ...rest} = props;
  const {id} = rest;
  const structure = structuresData[id];

  return (
    <>
      <TopBarList>
        <Breadcrumb
          categoryName="custom.components.menu.structures"
          label={structure ? structure.name : ' '}
        />
        {structure && (
          <div>
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_DELETE']) && (
              <DeleteWithConfirmButton
                record={{id: structure['@id']}}
                resource="structures"
                basePath="/structures"
              />
            )}
          </div>
        )}
      </TopBarList>
      <Show
        title={<Title redirect="list" {...props} />}
        actions={<StructureActions displayTotal={false} />}
        {...props}>
        <StructureShowView />
      </Show>
    </>
  );
};

const mapStateToProps = ({admin}) => {
  return {
    structuresData: admin.resources.structures.data,
  };
};

export default connect(mapStateToProps)(StructureShow);
