import React from 'react';
import {Grid} from '@material-ui/core';
import {AddButton, BooleanButton, DeleteButton} from '../Buttons';
import {withStyles} from '@material-ui/core/styles';
import {Add} from '@material-ui/icons';
import {hasRoles, getBaseRoles} from '../../utils/auth';

const styles = theme => ({
  buttons: {
    color: '#636363',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      left: 0,
      bottom: 10,
    },
  },
});

export const CommonActions = withStyles(styles)(
  ({classes, record, selectedIds, resource, basePath}) => {
    const displayDelete =
      resource &&
      basePath &&
      ((selectedIds && selectedIds.length > 0) || (record && record.id));

    return (
      <Grid
        className={classes.buttons}
        container
        justify="flex-end"
        spacing={16}>
        {!record ? (
          <Grid item>
            {hasRoles(
              basePath !== '/machines'
                ? [
                    `ROLE_${basePath.slice(1, -1).toUpperCase()}_CREATE`,
                    ...getBaseRoles,
                  ]
                : [],
            ) && (
              <AddButton
                roles={[`ROLE_${basePath.slice(1, -1).toUpperCase()}_CREATE`]}
                basePath={basePath}
              />
            )}
          </Grid>
        ) : (
          'machines' === resource &&
          record &&
          !record.approved && (
            <Grid item>
              <BooleanButton
                label="custom.action.machines.connect"
                icon={<Add />}
                property="approved"
                switchOn
                roles={['ROLE_MACHINE_APPROVE_ACTION']}
                resource={resource}
                selectedIds={[record.id]}
              />
            </Grid>
          )
        )}
        {displayDelete && (
          <Grid item>
            <DeleteButton
              resource={resource}
              basePath={basePath}
              selectedIds={selectedIds || [record.id]}
              record={record}
            />
          </Grid>
        )}
      </Grid>
    );
  },
);
