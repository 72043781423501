import React from 'react';
import {linkToRecord} from 'ra-core';
import {FunctionField, ReferenceField} from 'react-admin';
import {Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
  link: {
    display: 'inline-block',
    padding: '1em 0em 1em 0em',
  },
  button: {
    padding: '1em 0em 1em 0em',
  },
});

const ResourceListItem = ({
  classes,
  item,
  itemIndex,
  source,
  reference,
  fields,
  ...rest
}) => {
  return (
    <ReferenceField
      source={`${source}[${itemIndex}]`}
      reference={reference || source}
      linkType={false}
      {...rest}>
      <FunctionField
        render={record => (
          <Typography variant="body2" component="p">
            <Link
              className={classes.link}
              to={linkToRecord(`/${reference || source}`, item, 'show')}>
              {fields
                ? fields.map(field => record[field]).join(' ')
                : record.name}
            </Link>
          </Typography>
        )}
      />
    </ReferenceField>
  );
};

export default withStyles(styles)(ResourceListItem);
