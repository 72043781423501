import React, {Fragment} from 'react';
import {Card} from '@material-ui/core';
import {Steam, Heat, Damper, Duration, Ventilation} from '../Buttons/SVG';
import '../../assets/program-graph.css';
import {getPointsOfInterest} from './ParametersFactory';
import isEmpty from 'lodash/isEmpty';
import {toFahrenheit} from '../Program/PhaseGroups/utils';

const loopTimer = count => {
  let divToReturn = [];

  for (let i = 0; i < count; i++) {
    divToReturn.push(i + 1);
  }

  return divToReturn;
};

export default ({heatMin, heatMax, totalTime, items, isFahrenheit}) => {
  const typeEnum = ['heat', 'steam', 'damper', 'fan'];
  const poi = getPointsOfInterest(items);
  // we need totalTime in minutes for the graph display
  const totalForGraph = totalTime / 60;
  if (totalTime === 0) return null;
  return (
    <Card>
      <div className="graph-head">
        <span className="graph-head-timer">
          <Duration isBlack />
          &nbsp;&nbsp;&nbsp;
          {totalForGraph} min
        </span>
      </div>
      <div className="graph-poi">
        {poi
          ? poi.map((pointOfInterest, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: `calc(${
                      poi[index + 1]
                        ? ((poi[index + 1] - pointOfInterest) / totalForGraph) *
                          100
                        : ((totalForGraph - pointOfInterest) / totalForGraph) *
                          100
                    }%`,
                    height: 20 + 'px',
                  }}>
                  <span>{pointOfInterest}</span>
                </div>
              );
            })
          : null}
      </div>
      <div className="graph-container">
        {items &&
          typeEnum.map(
            (type, index) =>
              items[type] && (
                <Fragment key={index}>
                  <div
                    className="graph-counter"
                    style={{display: 'inline-block', width: 100 + '%'}}>
                    {loopTimer(totalForGraph).map(
                      (i, index) =>
                        i !== totalForGraph && (
                          <div
                            key={index}
                            style={{
                              width: `calc(${100 / totalForGraph}% - 2px)`,
                            }}
                          />
                        ),
                    )}
                  </div>
                  <ParametrageItemFactory
                    total={totalTime}
                    heatMin={heatMin}
                    heatMax={heatMax}
                    type={type}
                    items={items[type]}
                    isFahrenheit={isFahrenheit}
                  />
                </Fragment>
              ),
          )}
      </div>
    </Card>
  );
};

const style = {
  container: {
    width: 100 + '%',
    height: 40 + 'px',
    display: 'flex',
    border: 1 + 'px solid #cecece',
    borderRadius: 5 + 'px',
    backgroundColor: 'white',
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 100 + '%',
  },
  textShow: {
    alignSelf: 'center',
    position: 'absolute',
  },
  icon: {
    position: 'absolute',
    marginLeft: '-60px',
  },
  graphBarContainer: {
    display: 'flex',
    'align-items': 'center',
  },
};

const ParametrageTime = ({
  heatMin,
  heatMax,
  item,
  color,
  backgroundColor,
  total,
  hasText,
  next,
  type,
  previous,
  index,
  isFahrenheit,
}) => {
  switch (type) {
    case 'heat':
      const percent = (item.temperature * 100) / heatMax;
      return (
        item.duration > 0 && (
          <div
            style={{
              ...style.subContainer,
              ...{
                width: (item.duration / total) * 100 + '%',
                background: `linear-gradient(0, ${backgroundColor} ${percent}%, #ffffff00 ${percent}%)`,
              },
            }}>
            {hasText && (
              <span style={{...style.textShow, ...{color: color}}}>
                {isFahrenheit
                  ? toFahrenheit(item.temperature)
                  : item.temperature}
                {isFahrenheit ? '°F' : '°C'}
              </span>
            )}
          </div>
        )
      );
    case 'fan':
      const widthFanPercentage = next
        ? ((next.start - item.start) / total) * 100
        : ((total - item.start) / total) * 100;
      return (
        <div
          style={{
            ...style.subContainer,
            ...{
              width: `${widthFanPercentage}%`,
              background: `linear-gradient(0, ${backgroundColor} ${item.speed}%, #ffffff00 ${item.speed}%)`,
              borderRight: '1px dotted #cecece',
            },
          }}>
          {hasText && (
            <span style={{...style.textShow, ...{color: color}}}>
              {item.speed} %
            </span>
          )}
        </div>
      );
    case 'steam':
      const itemStartInMinutes = item.start / 60;
      const totalInMinutes = total / 60;
      const steam = (itemStartInMinutes / totalInMinutes) * 100;
      return (
        <div
          style={{
            position: 'relative',
            ...style.subContainer,
            ...{
              width: '3%',
              background: backgroundColor,
              left: Math.round(+steam - index * 3) + '%',
            },
          }}>
          {hasText && (
            <span
              style={{
                ...style.textShow,
                ...{color: color, position: 'absolute'},
              }}>
              {item.volume} cl
            </span>
          )}
        </div>
      );
    default:
      return null;
  }
};

const ParametrageStarting = ({
  item: {start, duration, temperature},
  color,
  backgroundColor,
  total,
  hasText,
  isFahrenheit,
  previous,
}) => (
  <div
    style={{
      ...style.subContainer,
      ...{
        width: (duration / total) * 100 + '%',
        marginLeft:
          ((start - (previous.start + previous.duration)) / total) * 100 + '%',
      },
    }}>
    {hasText && (
      <span style={{...style.textShow, ...{color: color}}}>
        {isFahrenheit ? toFahrenheit(temperature) : temperature}
        {isFahrenheit ? '°F' : '°C'}
      </span>
    )}
    <div
      style={{
        backgroundColor: backgroundColor,
        height: 100 + '%',
        width: 100 + '%',
        alignSelf: 'flex-end',
      }}
    />
  </div>
);

const ParametrageItemFactory = ({
  total,
  type,
  items,
  heatMin,
  heatMax,
  isFahrenheit,
}) => {
  if (isEmpty(items) || total === 0) {
    return null;
  }

  switch (type) {
    case 'heat':
      return (
        <div style={style.graphBarContainer}>
          <Heat style={style.icon} isColored />
          <div className="graph-parameter-container" style={style.container}>
            {items.map((item, index) => (
              <ParametrageTime
                key={`parametrage-heat-${index}`}
                item={item}
                hasText
                color="#ff0500"
                isFahrenheit={isFahrenheit}
                backgroundColor="#ffcdcd"
                {...{heatMax, heatMin, total, type}}
              />
            ))}
          </div>
        </div>
      );
    case 'steam':
      return (
        <div style={style.graphBarContainer}>
          <Steam style={style.icon} isColored />
          <div className="graph-parameter-container" style={style.container}>
            {items.map((item, index) => {
              return (
                <ParametrageTime
                  key={`parametrage-steam-${index}`}
                  backgroundColor="#d5f4ff"
                  color="#2cc8ff"
                  index={index}
                  previous={items[index - 1] || {start: 0, duration: 0}}
                  hasText
                  {...{item, total, type}}
                />
              );
            })}
          </div>
        </div>
      );
    case 'damper':
      return (
        <div style={style.graphBarContainer}>
          <Damper style={style.icon} isColored />
          <div className="graph-parameter-container" style={style.container}>
            {items.map((item, index) => (
              <ParametrageStarting
                key={`parametrage-rotate-${index}`}
                backgroundColor="#fce9cd"
                previous={items[index - 1] || {start: 0, duration: 0}}
                {...{item, total}}
              />
            ))}
          </div>
        </div>
      );
    case 'fan':
      return (
        <div style={style.graphBarContainer}>
          <Ventilation style={style.icon} isColored />
          <div className="graph-parameter-container" style={style.container}>
            {items.map((item, index) => (
              <ParametrageTime
                key={`parametrage-ventilation-${index}`}
                hasText
                index={index}
                next={items[index + 1]}
                color="#98bd0c"
                backgroundColor="#eaf2cf"
                {...{item, total, type}}
              />
            ))}
          </div>
        </div>
      );
    default:
      return null;
  }
};
