import React, {Component, cloneElement} from 'react';
import {Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {translate} from 'react-admin';
import {compose} from 'recompose';

const styles = {
  tabsText: {
    textAlign: 'center',
    borderTopLeftRadius: 5 + 'px',
    borderTopRightRadius: 5 + 'px',
    cursor: 'pointer',
  },
  tabsNotSelected: {
    borderBottom: 1 + 'px solid gray',
  },
  tabsSelected: {
    borderTop: 1 + 'px solid gray',
    borderLeft: 1 + 'px solid gray',
    borderRight: 1 + 'px solid gray',
    color: '#f14343',
  },
};

class TabsCommon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
    };
  }

  handleChange = value => () => {
    this.setState({selected: value});
  };

  render() {
    const {classes, translate, items = []} = this.props;
    const {selected} = this.state;

    return (
      <Grid container style={{paddingTop: 3 + 'em'}}>
        {items.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12 / items.length}
            className={[
              classes.tabsText,
              selected === index
                ? classes.tabsSelected
                : classes.tabsNotSelected,
            ].join(' ')}
            onClick={this.handleChange(index)}>
            <h3>
              {cloneElement(item.icon, {style: {verticalAlign: 'top'}})}
              {translate(item.label)}
            </h3>
          </Grid>
        ))}
        <Grid item style={{paddingTop: 1 + 'em', width: 100 + '%'}}>
          {items[selected] && items[selected].content}
        </Grid>
      </Grid>
    );
  }
}

export default compose(translate, withStyles(styles))(TabsCommon);
