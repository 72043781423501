import React from 'react';
import {withStyles} from '@material-ui/core';
import {Edit, translate} from 'react-admin';
import {styles} from './styles';
import {TopBarList, Breadcrumb} from '../../TopBar';

const EditWithStyles = withStyles(styles)(({translate, classes, ...rest}) => {
  const {resource} = rest;

  return (
    <>
      <TopBarList>
        <Breadcrumb label={translate(`resources.${resource}.edit`)} />
      </TopBarList>
      <Edit className={classes.root} {...rest} />
    </>
  );
});

export default translate(EditWithStyles);
