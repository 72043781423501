import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {
  CREATE_PROGRAM,
  CREATE_PROGRAM_SUCCESS,
  PROGRAMS_GET_ONE_RESOURCE,
  PROGRAMS_GET_ONE_SUCCESS,
} from '../components/Program/store/action';
import {errorMessageHandler} from './helpers';
import {client} from '../utils/api-client';

function sortPhaseGroups(unsortedPhaseGroups) {
  return unsortedPhaseGroups.slice().sort((a, b) => {
    if (a && b && a.phaseGroupType && b.phaseGroupType) {
      return a.phaseGroupType.localeCompare(b.phaseGroupType);
    } else {
      return 0;
    }
  });
}

function* handleProgram(action) {
  const {type, payload} = action;
  switch (type) {
    case CREATE_PROGRAM: {
      try {
        const response = yield client(`programs`, {data: payload});
        yield put({
          type: CREATE_PROGRAM_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case PROGRAMS_GET_ONE_RESOURCE: {
      try {
        const response = yield client(`programs/${payload}`);
        // We need to sort phaseGroups by phaseGroupsType id
        const prog = {
          ...response,
          phaseGroups: sortPhaseGroups(response.phaseGroups),
        };
        yield put({
          type: PROGRAMS_GET_ONE_SUCCESS,
          payload: prog,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    default:
      break;
  }
}

export default function* watchMachinesActions() {
  yield all([takeEvery(CREATE_PROGRAM, handleProgram)]);
  yield all([takeEvery(PROGRAMS_GET_ONE_RESOURCE, handleProgram)]);
}
