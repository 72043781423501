import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import logoEurofours from '../../assets/logo_eurofours.png';

const styles = () => ({
  footer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: 100,
    backgroundColor: '#303030',
    padding: '0 24px 24px 24px',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Footer = ({classes}) => {
  return (
    <footer className={classes.footer}>
      <Link to="/">
        <img
          width={140}
          className={classes.logo}
          alt="eurofours"
          src={logoEurofours}
        />
      </Link>
    </footer>
  );
};

Footer.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Footer);
