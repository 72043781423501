import React from 'react';
import {DeleteButton, SaveButton, Toolbar} from 'react-admin';

export const CustomToolbar = props => {
  const {showDelete} = props;

  return (
    <Toolbar
      style={{
        padding: '0px 16px',
        display: 'flex',
        'justify-content': 'space-between',
      }}
      {...props}>
      {(showDelete === undefined || showDelete) && <DeleteButton />}
      <SaveButton type="button" />
    </Toolbar>
  );
};
