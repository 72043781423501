import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ExportButton} from '../../Buttons';
import {exportOneProgram} from './store/action';

class Exporter extends Component {
  handleClick = () => {
    const {selectedIds} = this.props;
    selectedIds.length &&
      selectedIds.map(id => this.props.exportOneProgram(id));
  };

  sanitizeProps = ({exportOneProgram, ...rest}) => rest;

  render() {
    return (
      <ExportButton
        handleClick={this.handleClick}
        {...this.sanitizeProps(this.props)}
      />
    );
  }
}

export default connect(null, {exportOneProgram})(Exporter);
