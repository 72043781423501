import {useMemo, useCallback, useEffect} from 'react';
import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import CriteriaInput from './CriteriaInput';
import {Duration, Ventilation} from '../../Buttons/SVG';
import CriteriaLine from './CriteriaLine';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {formatFanPhase} from './utils';

export default function PhaseGroupFan({phase, onChange, totalDuration}) {
  const phases = useMemo(() => formatFanPhase(phase), [phase]);

  const parse = phases => ({
    phaseGroupType: 'fan',
    phases: phases.map((p, index) => ({
      position: index,
      values: [
        {
          criteria: 'speed',
          value: p.speed,
        },
        {
          criteria: 'start',
          value: p.start * 60,
        },
      ],
    })),
  });

  useEffect(() => {
    const newPhases = phases.filter(
      p => p.start === 0 || p.start <= totalDuration - 1,
    );
    if (newPhases.length === phases.length) return;
    const result = parse(newPhases);
    onChange(result);
  }, [phase, totalDuration, onChange]);

  const getPhaseSize = useCallback(
    index => {
      if (phases.length === 1) return totalDuration;
      else if (index === phases.length - 1)
        return totalDuration - phases[index].start;
      else if (index < phases.length - 1)
        return phases[index + 1].start - phases[index].start;
    },
    [phases, totalDuration],
  );

  const handleChange = useCallback(
    (index, attribute) => value => {
      const newPhases = [...phases];
      let newValue = parseInt(value, 10);
      newPhases[index][attribute] = newValue;

      const result = parse(newPhases);

      onChange(result);
    },
    [phases, onChange],
  );

  const addPhase = useCallback(
    index => event => {
      event.preventDefault();
      const newPhases = [...phases];
      const phaseSize = getPhaseSize(index);
      if (phaseSize <= 1) return;
      const newPhase = {
        start: phases[index].start + Math.ceil(phaseSize / 2),
        speed: 100,
      };
      newPhases.splice(index + 1, 0, newPhase);
      const result = parse(newPhases);
      onChange(result);
    },
    [phases, onChange, getPhaseSize],
  );

  const removePhase = useCallback(
    index => event => {
      event.preventDefault();
      const newPhases = [...phases];
      newPhases.splice(index, 1);
      newPhases[0].start = 0;
      const result = parse(newPhases);
      onChange(result);
    },
    [phases, onChange],
  );

  return (
    <Grid container spacing={24} style={{marginBottom: 3 + 'em'}}>
      <Grid item>
        <Typography>
          <Ventilation isColored />
          <sup>{phases.length}</sup>
        </Typography>
      </Grid>
      <Grid item>
        {phases.length === 0 && totalDuration > 0 ? (
          <Button
            onClick={addPhase(0)}
            color="primary"
            variant={'contained'}
            aria-label="Add">
            <AddIcon />
          </Button>
        ) : null}
        {phases.map((p, index) => {
          let maxStart;
          if (index === 0) maxStart = 0;
          else if (index === phases.length - 1)
            maxStart = Math.max(0, totalDuration - 1);
          else maxStart = phases[index + 1].start - 1;
          return (
            <CriteriaLine
              isLast={index === phases.length - 1}
              onAdd={addPhase(index)}
              onRemove={removePhase(index)}
              notification={p.notification}
              withNotification={false}
              canAdd={getPhaseSize(index) > 1 && phases.length < 50}
              canDelete={phases.length > 1}>
              <CriteriaInput
                unit="min"
                min={
                  index === 0 || phases.length === 1
                    ? 0
                    : phases[index - 1].start + 1
                }
                max={maxStart}
                Icon={Duration}
                value={p.start}
                onChange={index > 0 && handleChange(index, 'start')}
              />
              <CriteriaInput
                unit="%"
                min={0}
                max={100}
                error={p.speed > 0 && p.speed < 30}
                helperText={
                  p.speed > 0 &&
                  p.speed < 30 &&
                  'La valeur ne peut être que nulle ou supérieure à 30'
                }
                Icon={Ventilation}
                value={p.speed}
                onChange={handleChange(index, 'speed')}
              />
            </CriteriaLine>
          );
        })}
      </Grid>
    </Grid>
  );
}
