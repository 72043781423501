import React, {Component, Fragment} from 'react';
import {BooleanField, Datagrid, TextField} from 'react-admin';
import {withRouter} from 'react-router-dom';
import {TopBarList} from '../TopBar';
import {compose} from 'recompose';
import {List} from '../Common/Admin';
import {NameField} from '../Common/Field';
import {SoftwareUpdatesFilters} from '../ActionsCards/CustomFilter';
import Title from '../Common/Header';
import {hasRoles} from '../../utils/auth';
import {AddButton} from '../Buttons';

class SoftwareUpdatesList extends Component {
  render() {
    const {classes, staticContext, ...rest} = this.props;
    const {location} = rest;

    return (
      <>
        <TopBarList>
          <div>
            {hasRoles(['ROLE_SUPERADMIN', 'ROLE_EUROFOURS_TECHNICIAN']) && (
              <AddButton
                basePath="/software-updates"
                roles={['ROLE_SUPERADMIN', 'ROLE_EUROFOURS_TECHNICIAN']}
              />
            )}
          </div>
        </TopBarList>
        <List
          actions={<Fragment />}
          bulkActions={false}
          basePath="/software-updates"
          hasCreate
          hasEdit
          hasList
          hasShow={false}
          resource="software_updates"
          title={
            <Title
              basePath="/software-updates"
              title="custom.components.menu.softwareUpdates"
              {...rest}
            />
          }
          filters={<SoftwareUpdatesFilters location={location} />}
          filterDefaultValues={{isAllowedBeta: true, addRevoked: true}}
          perPage={30}
          {...rest}>
          <Datagrid>
            <NameField source="version" pageTo="edit" />
            <BooleanField source="beta" />
            <BooleanField source="revoked" />
            <TextField source="description" />
            <TextField source="countDownloaded" />
            <TextField source="checksum" />
          </Datagrid>
        </List>
      </>
    );
  }
}

export default compose(withRouter)(SoftwareUpdatesList);
