import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
  BooleanInput,
  FileField,
  FileInput,
  LongTextInput,
  maxLength,
  SimpleForm,
  TextInput,
  regex,
  required,
  translate,
} from 'react-admin';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import {Create} from '../Common/Admin';
import Title from '../Common/Header';
import {SEMVER_REGEX, SOFTWARE_UPDATES_PATH} from '../../constants';

const validateVersion = [
  required(),
  maxLength(10),
  regex(
    SEMVER_REGEX,
    'custom.components.software_updates.create.semverRegexValidation',
  ),
];

const validateFile = [required()];

class SoftwareUpdatesCreate extends Component {
  render() {
    const {dispatch, location, staticContext, translate, ...rest} = this.props;
    const transform = data => {
      if (data.file && data.file.rawFile instanceof File) {
        data.file = data.file.rawFile.name;
      }
      return data;
    };
    return (
      <Create
        actions={<Fragment />}
        basePath="/software-updates"
        resource="software_updates"
        location={location}
        title={
          <Title
            location={location}
            redirect={SOFTWARE_UPDATES_PATH}
            title="custom.components.software_updates.create.title"
          />
        }
        {...rest}>
        <SimpleForm {...rest} transform={transform}>
          <TextInput source="version" validate={validateVersion} />
          <BooleanInput source="beta" />
          <LongTextInput source="description" />
          <FileInput
            source="file"
            label="resources.software_updates.fields.file"
            placeholder={translate(
              'custom.components.software_updates.create.filePlaceholder',
            )}
            validate={validateFile}>
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>
    );
  }
}

export default compose(connect(), withRouter, translate)(SoftwareUpdatesCreate);
