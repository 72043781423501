import React, {useMemo} from 'react';
import {compose} from 'recompose';
import {permissions} from '../ManageRights';
import {CheckboxGroupInput, translate} from 'react-admin';
import {Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
  root: {
    marginBottom: '3em',
  },
  roles: {
    '& > fieldset': {
      marginBottom: '0',
    },
    '& > fieldset > legend > span': {
      display: 'none',
    },
    '& > fieldset > div > label > span': {
      fontSize: '1em',
      fontWeight: 800,
    },
  },
  subRoles: {
    paddingLeft: '2em',
    '& > fieldset': {
      marginBottom: '0',
    },
    '& > fieldset > legend > span': {
      display: 'none',
    },
    '& > fieldset > div > label > span': {
      fontSize: '0.9em',
      fontWeight: 500,
    },
  },
  description: {
    fontSize: '0.7em',
    fontStyle: 'oblique',
    color: 'gray',
    paddingLeft: '3em',
  },
});

const DelegateRightsSetup = ({classes, translate}) => {
  const defaultValues = [];
  for (let p of permissions) {
    defaultValues.push(p.role);
    if (!p.subs) continue;
    for (let sub of p.subs) {
      defaultValues.push(sub.role);
      if (!sub.subs) continue;
      for (let sub2 of sub.subs) {
        defaultValues.push(sub2.role);
      }
    }
  }
  const allCheckbox = useMemo(() => {
    return permissions.map(role => {
      return (
        <Grid item xs={6} key={role.role}>
          <div className={classes.roles}>
            <CheckboxGroupInput
              id={role.name}
              source="roles"
              choices={[role]}
              optionText="name"
              optionValue="role"
              defaultValue={defaultValues}
            />
            <span className={classes.description}>
              {translate(role.description)}
            </span>
          </div>
          {role.subs.map(sub => {
            return (
              <div className={classes.subRoles} key={sub.role}>
                <CheckboxGroupInput
                  source="roles"
                  choices={[sub]}
                  optionText="name"
                  optionValue="role"
                  defaultValue={defaultValues}
                />
                {sub.description && (
                  <span className={classes.description}>
                    {translate(sub.description)}
                  </span>
                )}
                {sub.subs &&
                  sub.subs.map(sub2 => {
                    return (
                      <div className={classes.subRoles} key={sub2.role}>
                        <CheckboxGroupInput
                          source="roles"
                          choices={[sub2]}
                          optionText="name"
                          optionValue="role"
                          defaultValue={defaultValues}
                        />
                        {sub2.description && (
                          <span className={classes.description}>
                            {translate(sub2.description)}
                          </span>
                        )}
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </Grid>
      );
    });
  }, []);
  return (
    <Grid container spacing={40} className={classes.root}>
      {allCheckbox}
    </Grid>
  );
};

export default compose(translate, withStyles(styles))(DelegateRightsSetup);
