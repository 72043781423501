import * as actions from './action';

const initialState = {
  user: null,
  users: [],
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.USER_GET_ONE_RESOURCE:
      return {
        ...state,
        user: initialState.user,
      };
    case actions.USER_GET_ONE_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case actions.USER_GET_ALL_SUCCESS:
      return {
        ...state,
        users: payload,
      };
    default:
      return state;
  }
};
