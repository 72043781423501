import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {
  STRUCTURE_GET_ALL_REQUEST,
  STRUCTURE_GET_ALL_SUCCESS,
  STRUCTURE_GET_ONE_RESOURCE,
  STRUCTURE_GET_ONE_SUCCESS,
} from '../components/Structure/store/action';
import {errorMessageHandler} from './helpers';
import {client} from '../utils/api-client';

function* handleStructure(action) {
  const {type, payload} = action;
  switch (type) {
    case STRUCTURE_GET_ONE_RESOURCE: {
      try {
        const response = yield client(`structures/${payload}`);
        yield put({
          type: STRUCTURE_GET_ONE_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case STRUCTURE_GET_ALL_REQUEST: {
      try {
        const response = yield client(`structures?lvl=0`);
        /*const response = yield client(
          'structures?properties[]=name&properties[]=children&properties[]=nbMachine&properties[]=userInCharge&properties[]=depth&properties[]=nbRelatedUsers&lvl=0',
        );*/
        yield put({
          type: STRUCTURE_GET_ALL_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    default:
      break;
  }
}

export default function* watchStructuresActions() {
  // yield all([takeEvery(STRUCTURE_GET_ALL_REQUEST, handleStructure)]);
  yield all([takeEvery(STRUCTURE_GET_ONE_RESOURCE, handleStructure)]);
}
