import React, {useEffect, useState} from 'react';
import {client} from '../../utils/api-client';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from '@material-ui/core';
import {compose} from 'recompose';
import {translate, linkToRecord} from 'react-admin';
import {Link} from 'react-router-dom';

const TableStructure = props => {
  const {translate, user} = props;
  const [isLoading, setIsLoading] = useState(true);
  const [rootStructures, setRootStructures] = useState([]);
  const [toDisplay, setToDisplay] = useState([]);

  useEffect(() => {
    const rootParams = {lvl: 0};
    const rootQueryString = new URLSearchParams(rootParams).toString();
    const rootResponse = client(`/structures?${rootQueryString}`);
    rootResponse.then(response => {
      setRootStructures(response['hydra:member']);
    });
  }, []);

  const shouldDisplay = structure => {
    if (strictContains(structure)) {
      return true;
    }

    for (const childKey in structure.children) {
      if (shouldDisplay(structure.children[childKey])) {
        return true;
      }
    }

    return false;
  };

  const strictContains = structure => {
    for (const sUserKey in structure.relatedUsers) {
      if (structure.relatedUsers[sUserKey]['@id'] == user['@id']) {
        return true;
      }
    }

    return false;
  };

  const displayStructure = (structure, offset = 0) => {
    if (shouldDisplay(structure)) {
      setToDisplay(old => [
        ...old,
        {
          id: structure['@id'],
          name: structure.name,
          offset,
          relatedUsers: structure.relatedUsers,
        },
      ]);
    }

    structure.children.forEach(child =>
      displayStructure(child, offset == 0 ? 32 : offset * 2),
    );
  };

  useEffect(() => {
    rootStructures.forEach(structure => displayStructure(structure));
    setIsLoading(false);
  }, [rootStructures]);

  return isLoading ? (
    <CircularProgress size={24} />
  ) : toDisplay.length > 0 ? (
    <Table>
      <TableHead>
        <TableRow>
          {/* <TableCell style={{width: '50px'}} padding="checkbox"></TableCell> */}
          <TableCell>{translate('custom.commons.name')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {toDisplay.map(structure => (
          <TableRow>
            {/* <TableCell padding="checkbox">
              {strictContains(structure) ? <Checkbox /> : <Checkbox disabled />}
            </TableCell> */}
            <TableCell>
              <Link
                to={linkToRecord('/structures', structure.id, 'show')}
                style={{'margin-left': structure.offset}}>
                {structure.name}
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <>{translate('custom.components.profile.structures.noResults')}</>
  );
};

export default compose(translate)(TableStructure);
