import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, withStyles} from '@material-ui/core';
import {translate} from 'react-admin';
import {compose} from 'recompose';
import classNames from 'classnames';
import {ButtonsList} from './ButtonsList';
import {client} from '../../utils/api-client';

const styles = () => ({
  moreInfo: {
    padding: '2em',
    fontStyle: 'italic',
    fontWeight: 600,
    fontSize: '0.7em',
  },
  blackDiv: {
    background: '#363636',
    color: 'white',
    fontWeight: 600,
    borderRadius: '5px 0 0 5px',
  },
  rightPanel: {
    position: 'relative',
    right: '-44px',
  },
  paddingCounter: {
    paddingBottom: '1em',
  },
  counter: {
    fontSize: '4em',
  },
  caption: {
    fontSize: '1em',
  },
});

const CustomActions = props => {
  const {
    total,
    translate,
    classes,
    location,
    data,
    selectedIds,
    resource,
    buttons,
    filterValues,
    isLoading,
    displayTotal = true,
    customCount = -1,
    customCountLabel = 'resources.programs.installed_on',
  } = props;
  const nbItemsSelected = selectedIds && selectedIds.length;
  const state = location && location.state;

  const [totalRows, setTotalRows] = useState(total);

  useEffect(() => {
    const fetchStructures = async () => {
      const result = await client('/structures?itemsPerPage=1');
      setTotalRows(result['hydra:totalItems']);
    };
    if (
      resource === 'structures' &&
      filterValues &&
      Object.keys(filterValues).length === 0 &&
      !isLoading
    ) {
      fetchStructures();
    } else {
      setTotalRows(total);
    }
  }, [isLoading]);

  if (!selectedIds && !data) {
    return null;
  }
  return (
    <Grid container alignItems="center">
      <Grid item xs={8}>
        <ButtonsList
          /** when displaying detail for 1 program, we need to replace the
           * content of selectedIds by the id of the program we are viewing.
           * If there is a prop data.id it means we are in the viewing details
           * for 1 program, so selectedIds must contain data.id. If there is
           * no data.id, we are in the list view so selectedIds can contain the
           * values of the selectedIds prop passed from parent
           */
          selectedIds={data.id ? [data.id] : selectedIds}
          resource={resource}
          buttons={(state && state.buttonsList) || buttons}
        />
      </Grid>
      <Grid className={classes.rightPanel} item xs={4}>
        <Grid
          container
          alignItems="center"
          className={classNames({[classes.blackDiv]: nbItemsSelected > 0})}>
          <Grid item xs>
            {nbItemsSelected > 0 ? (
              <Grid
                container
                direction="column"
                className={classes.paddingCounter}>
                <Grid item>
                  <Typography className={classes.counter} align="center">
                    {nbItemsSelected}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.caption}
                    align="center"
                    variant="body2"
                    component="p">
                    {translate(`custom.action.${resource}.name`, {
                      smart_count: nbItemsSelected,
                    })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.caption}
                    align="center"
                    variant="body2"
                    component="p">
                    {translate(`custom.action.${resource}.selected`, {
                      smart_count: nbItemsSelected,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              0 === nbItemsSelected && (
                <Typography className={classes.moreInfo} align="right">
                  {translate(`custom.action.${resource}.more_actions`, {
                    _: translate(`custom.action.${resource}.selected`, {
                      name: translate(`custom.action.${resource}.name`, {
                        smart_count: 0,
                      }),
                      smart_count: 0,
                    }),
                  })}
                </Typography>
              )
            )}
          </Grid>
          {displayTotal && (
            <Grid item xs>
              <Grid
                container
                direction="column"
                className={classNames(classes.paddingCounter, {
                  [classes.blackDiv]: !nbItemsSelected,
                })}>
                <Grid item>
                  <Typography className={classes.counter} align="center">
                    {isLoading ? '-' : totalRows}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.caption}
                    align="center"
                    variant="body2"
                    component="p">
                    {translate(`custom.action.${resource}.name`, {
                      smart_count: totalRows,
                    })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.caption}
                    align="center"
                    variant="body2"
                    component="p">
                    {translate(`custom.action.inTotal`)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {customCount >= 0 && (
            <Grid item xs>
              <Grid
                container
                direction="column"
                className={classNames(classes.paddingCounter, {
                  [classes.blackDiv]: !nbItemsSelected,
                })}>
                <Grid item>
                  <Typography
                    className={classes.counter}
                    align="center"
                    variant="body2"
                    component="p">
                    {customCount}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.caption}
                    align="center"
                    variant="body2"
                    component="p">
                    {translate(customCountLabel, {
                      smart_count: customCount,
                      _: '',
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

CustomActions.propTypes = {
  nbItemsSelected: PropTypes.number,
  resource: PropTypes.string,
};

export default compose(translate, withStyles(styles))(CustomActions);
