import React, {useCallback, useState, useMemo} from 'react';
import {TextField, InputAdornment} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Confirm, translate} from 'react-admin';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  unit: {
    minWidth: '36px',
  },
  bootstrapRoot: {
    borderRadius: 4,
    border: '1px solid #ced4da',
    paddingRight: 0.5 + 'em',
    paddingLeft: 0.5 + 'em',
    marginRight: 5,
    'label + &': {
      marginTop: 0,
      marginBottom: 0,
    },
    marginBottom: 0.4 + 'em',
    '& + p': {
      position: 'absolute',
      bottom: -15,
    },
    alignItems: 'center',
    '&:focus-within': {
      borderColor: '#18c7ff',
    },
    '&.MuiInput-error': {
      color: 'red',
      '& > *': {
        color: 'red',
      },
    },
  },
  bootstrapInput: {
    padding: '10px 12px',
    width: 7 + 'em',
    textAlign: 'center',
    '& disabledInput': {
      opacity: '0.4',
    },
  },
  disabledInput: {
    opacity: '0.4',
  },
  errorText: {
    color: 'red',
    fontSize: '10px',
    marginTop: '-2px',
    marginBottom: '4px',
    display: 'block',
  },
});

const CriteriaInput = ({
  min,
  max,
  onChange,
  value,
  unit,
  Icon,
  classes,
  onFocus,
  translate,
  onBlur,
  delayed,
  confirmCondition,
  confirmTitle,
  confirmContent,
  ...rest
}) => {
  const [tempValue, setTempValue] = useState(null);
  const [timer, setTimer] = useState();
  const [confirm, setConfirm] = useState(false);

  const onChangeHandler = useCallback(
    event => {
      event.preventDefault();
      if (event.target.value === '') {
        // si le champ est vide : on ne fait pas le onChange, on affiche juste la valeur vide, elle sera réinitialisée au blur
        setTempValue('');
        return;
      }

      if (!onChange) return;
      if (event.target.value > max || event.target.value < min) {
        if (timer) clearTimeout(timer);
        setTempValue(event.target.value);
        return;
      }
      if (!delayed) {
        onChange(event.target.value);
        setTempValue(null);
        return;
      }
      const newValue = event.target.value;
      setTempValue(newValue);
      if (timer) clearTimeout(timer);
      const newTimer = setTimeout(() => {
        if (confirmCondition(newValue)) setConfirm(true);
        else {
          setConfirm(false);
          onChange(newValue);
          setTempValue(null);
        }
      }, 1000);
      setTimer(newTimer);
    },
    [timer, onChange, delayed, tempValue, setTempValue],
  );

  const confirmHandler = useCallback(() => {
    onChange(tempValue);
    setTempValue(null);
    setConfirm(false);
  }, [tempValue, onChange]);

  const cancelHandler = useCallback(() => {
    setConfirm(false);
    setTempValue(null);
  }, [setConfirm, setTempValue]);

  const onBlurHandler = useCallback(
    event => {
      if (timer) clearTimeout(timer);
      // case of empty field
      if (tempValue === '') {
        setTempValue(null);
        return;
      }
      if (tempValue && (tempValue > max || tempValue < min)) {
        setTempValue(null);
        return;
      }
      if (delayed && tempValue && tempValue !== value && !confirm) {
        if (confirmCondition(tempValue)) setConfirm(true);
        else {
          setConfirm(false);
          onChange(tempValue);
          setTempValue(null);
        }
      }
      if (onBlur) onBlur(event);
    },
    [onBlur, timer, onChange, delayed, tempValue, confirm],
  );

  const error = useMemo(() => {
    if (tempValue === '')
      return translate('resources.programs.errors.required');
    else if (tempValue && (tempValue < min || tempValue > max))
      return translate('resources.programs.errors.limits', {min, max});
    else if (value < min || value > max)
      return translate('resources.programs.errors.limits', {min, max});
    return null;
  }, [value, tempValue]);

  return (
    <div className={classes.container}>
      <TextField
        InputProps={{
          type: 'number',
          inputProps: {
            max: max,
            min: min,
          },
          classes: {
            root: classes.bootstrapRoot,
            input: classes.bootstrapInput,
            disabled: classes.disabledInput,
          },
          disabled: value === min && value === max,
          onFocus,
          onBlur: onBlurHandler,
          startAdornment: (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              disableTypography
              position="end"
              className={classes.unit}>
              {unit}
            </InputAdornment>
          ),
        }}
        value={tempValue === '' ? '' : tempValue || value}
        onChange={onChangeHandler}
        error={!!error}
        {...rest}
      />
      {error && <span className={classes.errorText}>{error}</span>}
      {confirm ? (
        <Confirm
          isOpen={confirm}
          title={confirmTitle}
          content={confirmContent}
          confirm={translate('ra.message.yes')}
          cancel={translate('ra.message.no')}
          onConfirm={confirmHandler}
          onClose={cancelHandler}
        />
      ) : null}
    </div>
  );
};

export default translate(withStyles(styles)(CriteriaInput));
