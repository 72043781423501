import {UPDATE} from 'react-admin';

export const IMAGE_SENDING = 'IMAGE_SENDING';
export const IMAGE_INIT = 'IMAGE_INIT';
export const IMAGE_RESET = 'IMAGE_RESET';
export const IMAGE_SEND_SUCCESS = 'IMAGE_SEND_SUCCESS';
export const IMAGE_SEND_FAILURE = 'IMAGE_SEND_FAILURE';

export const IMAGE_EUROFOURS_SEND_REQUEST = 'IMAGE_EUROFOURS_SEND_REQUEST';
export const IMAGE_MACHINES_SEND_REQUEST = 'IMAGE_MACHINES_SEND_REQUEST';
export const IMAGE_MACHINES_UPDATE_REQUEST = 'IMAGE_MACHINES_UPDATE_REQUEST';
export const IMAGE_ME_SEND_REQUEST = 'IMAGE_ME_SEND_REQUEST';
export const IMAGE_PROFILE_SEND_REQUEST = 'IMAGE_PROFILE_SEND_REQUEST';
export const IMAGE_USER_SEND = 'IMAGE_USER_SEND';
export const IMAGE_USER_SEND_SUCCESS = 'IMAGE_USER_SEND_SUCCESS';
export const IMAGE_USER_SEND_FAILURE = 'IMAGE_USER_SEND_FAILURE';

export const sendGalleryImage = data => ({
  type: IMAGE_ME_SEND_REQUEST,
  payload: data,
});

export const sendProfileImage = data => ({
  type: IMAGE_PROFILE_SEND_REQUEST,
  payload: data,
});

export const sendUserImage = (id, imageBase64) => ({
  type: IMAGE_USER_SEND,
  payload: {
    id,
    data: {
      imageBase64: imageBase64,
    },
  },
  meta: {
    resource: 'users',
    fetch: UPDATE,
    onSuccess: {
      notification: {
        body: 'custom.components.image_uploader.success',
        level: 'info',
        messageArgs: {
          smart_count: 1,
        },
      },
    },
  },
});

export const sendImageEurofours = data => ({
  type: IMAGE_EUROFOURS_SEND_REQUEST,
  payload: data,
});

export const sendImageMachines = data => ({
  type: IMAGE_MACHINES_SEND_REQUEST,
  payload: data,
});

export const updateImageMachines = data => ({
  type: IMAGE_MACHINES_UPDATE_REQUEST,
  payload: data,
});

export const initSendImage = () => ({
  type: IMAGE_INIT,
});

export const resetSendImage = () => ({
  type: IMAGE_RESET,
});
