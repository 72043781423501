import React, {Component, Fragment} from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  BulkDeleteWithConfirmButton,
} from 'react-admin';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {NameField} from '../Common/Field';
import {List} from '../Common/Admin';
import TagActions from '../ActionsCards/TagActions';
import {TagFilters} from '../ActionsCards/CustomFilter';
import {withStyles} from '@material-ui/core';
import Title from '../Common/Header';
import {hasRoles} from '../../utils/auth';
import {AddButton} from '../Buttons';
import {TopBarList, Breadcrumb} from '../TopBar';

const styles = {
  cellAlignCenter: {
    textAlign: 'center',
  },
};

const PostBulkActionButtons = ({selectedIds}) => (
  <>
    {/* default bulk delete action */}
    {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAG_DELETE']) && (
      <BulkDeleteWithConfirmButton resource="tags" selectedIds={selectedIds} />
    )}
  </>
);

class TagList extends Component {
  render() {
    const {classes, ...rest} = this.props;
    const {location, selectedIds} = rest;

    if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAG_ACCESS'])) {
      window.location.replace('/#/not-granted');
    }

    return (
      <>
        <TopBarList>
          <Breadcrumb categoryName="custom.components.menu.tags" />
          <div>
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAGS_DELETE']) && (
              <>
                {selectedIds.length ? (
                  <BulkDeleteWithConfirmButton
                    resource="tags"
                    selectedIds={selectedIds}
                  />
                ) : null}
              </>
            )}
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAG_CREATE']) && (
              <AddButton
                basePath="/tags"
                roles={['ROLE_CLIENT_ADMIN', 'ROLE_TAG_CREATE']}
              />
            )}
          </div>
        </TopBarList>
        <List
          title={<Title title="custom.components.menu.tags" {...rest} />}
          actions={<TagActions location={location} />}
          filters={<TagFilters location={location} />}
          sort={{field: 'name', order: 'ASC'}}
          bulkActionButtons={
            <PostBulkActionButtons resource="tags" selectedIds={selectedIds} />
          }
          perPage={30}
          {...rest}>
          <Datagrid
            rowStyle={record => ({
              background: selectedIds.includes(record.id) && '#fcd0d0',
            })}>
            <NameField source="name" />
            <DateField source="createdAt" />
            <FunctionField
              source="programs"
              cellClassName={classes.cellAlignCenter}
              headerClassName={classes.cellAlignCenter}
              sortable={false}
              render={tag =>
                tag.programs && tag.programs.length ? tag.programs.length : '-'
              }
            />
          </Datagrid>
        </List>
      </>
    );
  }
}

const mapStateToProps = state => ({
  selectedIds: state.admin.resources.tags.list.selectedIds,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {}),
)(TagList);
