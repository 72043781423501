import React from 'react';
import {Link} from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  withStyles,
  SvgIcon,
} from '@material-ui/core';
import {Forward} from '@material-ui/icons';
import {translate} from 'react-admin';
import PartialHistory from '../History/Partial';
import PartialAccessPlus from '../History/PartialAccessPlus';
import {compose} from 'recompose';
import {hasRoles} from '../../utils/auth';
import {Button} from '../Buttons';
import {connect} from 'react-redux';
import {exportActivities} from './store/action';

const styles = () => ({
  card: {
    padding: '1em',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  forward: {
    transform: 'rotate(180deg)',
  },
});

const ExportActivityButton = ({exporter}) => {
  return (
    <Button
      isActive={true}
      onClick={() => exporter()}
      color="red"
      labelProps={{
        label: 'custom.buttons.exportbutton',
        icon: (
          <SvgIcon viewBox="0 -3 24 24">
            <polygon points="10.79 0 7.14 3.65 9.72 3.65 9.72 10.15 11.75 10.15 11.75 3.65 14.44 3.65 10.79 0" />
            <path d="M16.05,13.62H5.54A5.55,5.55,0,0,1,0,8.07v-3H1.85v3a3.7,3.7,0,0,0,3.69,3.7H16.05a3.7,3.7,0,0,0,3.69-3.7v-3h1.85v3A5.55,5.55,0,0,1,16.05,13.62Z" />
          </SvgIcon>
        ),
      }}
    />
  );
};

const History = ({translate, classes, exportActivities}) => {
  return (
    <Card className={classes.card}>
      <Link className={classes.link} to={`/profile`}>
        <Forward className={classes.forward} />
        {translate('custom.components.profile.history.backProfile')}
      </Link>
      <CardHeader
        title={translate('custom.components.profile.history.title')}
      />
      {hasRoles(['ROLE_CLIENT_ADMIN']) && (
        <CardContent style={{display: 'none'}}>
          <ExportActivityButton exporter={exportActivities} />
        </CardContent>
      )}
      <CardContent>
        <PartialHistory />
      </CardContent>
      {hasRoles(['ROLE_SUPERADMIN']) && (
        <>
          <CardHeader
            title={translate(
              'custom.components.profile.history.accessPlus.title',
            )}
          />
          <CardContent>
            <PartialAccessPlus />
          </CardContent>
        </>
      )}
    </Card>
  );
};

const mapStateToProps = () => ({});

export default compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps, {exportActivities}),
)(History);
