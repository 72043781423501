import React from 'react';
import {DeleteWithConfirmButton, Show} from 'react-admin';
import Title from '../Common/Header';
import TagShowView from './view/showView';
import TagActions from '../ActionsCards/TagActions';
import {hasRoles} from '../../utils/auth';
import {TopBarList, Breadcrumb} from '../TopBar';
import {connect} from 'react-redux';

const TagShow = props => {
  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAG_ACCESS'])) {
    window.location.replace('/#/not-granted');
  }
  const {tagsData, ...rest} = props;
  const {id} = rest;
  const tag = tagsData[id];

  return (
    <>
      <TopBarList>
        <Breadcrumb
          categoryName="custom.components.menu.tags"
          label={tag ? tag.name : ' '}
        />
        {tag && (
          <div>
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAG_DELETE']) && (
              <DeleteWithConfirmButton
                record={{id: tag['@id']}}
                resource="tags"
                basePath="/tags"
              />
            )}
          </div>
        )}
      </TopBarList>
      <Show
        title={<Title redirect="list" {...props} />}
        actions={<TagActions displayTotal={false} />}
        {...rest}>
        <TagShowView />
      </Show>
    </>
  );
};

const mapStateToProps = ({admin}) => {
  return {
    tagsData: admin.resources.tags.data,
  };
};

export default connect(mapStateToProps)(TagShow);
