import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  Link,
  ReferenceArrayField,
  ReferenceField,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  translate,
} from 'react-admin';
import {linkToRecord} from 'ra-core';
import {Button, CircularProgress, Grid, Typography} from '@material-ui/core';
import PartialHistory from '../../History/Partial';
import '../../../assets/show.css';
import compose from 'recompose/compose';
import {getProgram} from '../store/action';
import Avatar from '../../Common/Avatar';
import ParametersDisplay from '../../Parametrage';
import {formatParameters} from '../../Parametrage/ParametersFactory';
import {hasRoles} from '../../../utils/auth';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  labelCommon: {
    display: 'inline-block',
    lineHeight: '24px',
    position: 'relative',
    margin: '0 16px 8px 0',
    padding: '0 10px 0 20px',
    boxShadow: 'inset 0 0 0 1px ' + theme.palette.primary.main,
    borderRadius: '12px 4px 4px 12px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    backgroundColor: '#fff',
    '&:after': {
      content: "''",
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      left: 8,
      float: 'left',
      width: 5,
      height: 5,
      borderRadius: '50%',
      background: '#fff',
      border: '1px solid' + theme.palette.primary.main,
    },
  },
});

class ProgramShowView extends Component {
  componentDidMount = () => {
    const {
      getProgram,
      record: {id},
    } = this.props;

    getProgram(id.split('/')[2]);
  };

  componentDidUpdate = prevProps => {
    const {
      getProgram,
      record: {id},
      refresh,
    } = this.props;

    if (id !== prevProps.record.id || refresh) {
      getProgram(id.split('/')[2]);
    }
  };

  sanitizeProps = ({getProgram, refresh, ...rest}) => rest;

  render() {
    const {
      isLoading,
      translate,
      phaseGroups,
      classes,
      isFahrenheit,
      ...rest
    } = this.props;

    const {
      record: {avatar, id, tags},
      basePath,
      resource,
    } = rest;

    return (
      <Grid container direction="column" wrap="nowrap">
        <div>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item xs={9} className="show-info">
                <Grid container className="show-info-content">
                  <Grid item xs={4}>
                    {tags && tags.length > 0 ? (
                      <SimpleShowLayout {...this.sanitizeProps(rest)}>
                        <ReferenceArrayField reference="tags" source="tags">
                          <SingleFieldList linkType="show">
                            <TextField
                              source="name"
                              className={classes.labelCommon}
                            />
                          </SingleFieldList>
                        </ReferenceArrayField>
                      </SimpleShowLayout>
                    ) : (
                      <Typography align="center" style={{fontStyle: 'italic'}}>
                        {translate('custom.components.tags.none')}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs>
                    <SimpleShowLayout {...this.sanitizeProps(rest)}>
                      <TextField source="name" />
                      <ReferenceField
                        source="category"
                        reference="categories"
                        linkType={false}>
                        <TextField source="name" />
                      </ReferenceField>
                      <TextField source="description" />
                    </SimpleShowLayout>
                    {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_EDITOR']) && (
                      <div className="edit-info">
                        <Button
                          component={Link}
                          to={linkToRecord(basePath, id)}>
                          {translate('custom.commons.edit')}
                        </Button>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Avatar text="Image" image={avatar} type={resource} />
                {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_EDITOR']) && (
                  <div className="edit-avatar">
                    <Button component={Link} to={linkToRecord(basePath, id)}>
                      {translate('custom.commons.edit')}
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              className="tag-content"
              wrap="nowrap">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Grid item>
                  <Grid container>
                    <Grid item xs={12}>
                      <ParametersDisplay
                        isFahrenheit={isFahrenheit}
                        {...formatParameters(phaseGroups, isFahrenheit)}
                      />
                    </Grid>
                  </Grid>
                  {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_EDITOR']) && (
                    <div className="edit-button">
                      <Button
                        component={Link}
                        to={`${linkToRecord(basePath, id)}/1`}>
                        {translate('custom.commons.edit')}
                      </Button>
                    </div>
                  )}
                </Grid>
              )}
              <Grid item>
                <PartialHistory title id={id} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  }
}

ProgramShowView.propTypes = {
  translate: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  getProgram: PropTypes.func.isRequired,
  phaseGroups: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({programReducer, profileReducer}) => ({
  phaseGroups: programReducer.program.phaseGroups || [],
  isLoading: programReducer.loading,
  refresh: programReducer.refresh,
  isFahrenheit:
    profileReducer.profile &&
    profileReducer.profile.temperatureUnitPreference === 'F',
});

export default compose(
  translate,
  connect(mapStateToProps, {getProgram}),
  withStyles(styles),
)(ProgramShowView);
