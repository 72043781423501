import React, {Component} from 'react';
import {showNotification as showNotificationAction} from 'react-admin';
import {connect} from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  withStyles,
  ExpansionPanelSummary,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Input,
} from '@material-ui/core';
import {
  getAcceptedControl,
  getClientAdmins,
  getEurofoursTechnicians,
  updateClientAdmin,
  deleteClientAdmin,
  connectAsUser,
  resetLoginError,
} from './store/action';
import {
  AssignmentInd,
  Delete,
  PowerSettingsNew,
  ExpandMore,
} from '@material-ui/icons';
import {translate, Confirm} from 'react-admin';
import {compose} from 'recompose';
import {AddButton} from '../Buttons';
import {hasRoles} from '../../utils/auth';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

const styles = theme => ({
  containerTitle: {
    display: 'flex',
  },
  cardTitle: {
    flex: 1,
  },
  dialogContent: {
    textAlign: 'start',
  },
  errorColor: {
    color: theme.palette.primary.main,
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  subUser: {
    padding: '0 0 0 4em',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
});

const listFields = ['firstName', 'lastName', 'email', 'countAllocatedMachine'];

class SelectUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      action: null,
      users: this.props.usersAccept,
      isOpened: false,
      value: '',
      selectedUserId: null,
    };
  }

  componentDidMount() {
    const {
      isUserSelect,
      isClientAdminSelect,
      isEurofoursTechnicianSelect,
    } = this.props;

    if (!!isUserSelect) {
      this.props.getAcceptedControl();
    }

    if (!!isClientAdminSelect) {
      this.props.getClientAdmins();
    }
    if (!!isEurofoursTechnicianSelect) {
      this.props.getEurofoursTechnicians();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.usersAccept !== prevProps.usersAccept) {
      this.setState({users: this.props.usersAccept});
    }
  }

  handleClick = user => {
    this.setState({isOpened: true, selectedUserId: user['@id']});
  };

  handleClose = () => {
    this.setState({isOpened: false});
    this.props.resetLoginError();
  };

  handleChange = event => {
    this.setState({value: event.target.value});
    this.props.resetLoginError();
  };

  handleConnect = (id, value) => () => {
    this.props.connectAsUser({
      id: this.state.selectedUserId.split('/')[2],
      activationCode: value,
    });
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    localStorage.setItem('masterToken', token);
    localStorage.setItem('masterRole', role);
  };

  handleDelete = () => {
    this.props.deleteClientAdmin(this.state.userId);
    this.setState({userId: null});
  };

  toggleActive = user => () => {
    this.props.updateClientAdmin(this.state.userId.replace('/users/', ''), {
      active: this.state.action === 'enable',
    });
  };

  toggleExpand = userIndex => {
    this.setState(prevState => ({
      users: prevState.users.map((user, i) =>
        i === userIndex ? {...user, expanded: !user.expanded} : user,
      ),
    }));
  };

  render() {
    const {
      classes,
      isUserSelect,
      isClientAdminSelect,
      translate,
      isLoading,
      isEurofoursTechnicianSelect,
      isCodeError,
    } = this.props;

    const enhancedArray = this.state.users.map(user => ({
      expanded: false,
      ...user,
    }));

    if (
      (hasRoles(['ROLE_EUROFOURS_TECHNICIAN']) &&
        isEurofoursTechnicianSelect) ||
      (hasRoles(['ROLE_CLIENT_ADMIN']) &&
        (isClientAdminSelect || isEurofoursTechnicianSelect))
    ) {
      window.location.replace('/#/not-granted');
    }
    const addUser = isEurofoursTechnicianSelect
      ? '/create-eurofours-admin'
      : '/create-eurofours-tech';

    const fields =
      hasRoles(['ROLE_SUPERADMIN']) && isEurofoursTechnicianSelect
        ? listFields.filter(e => e !== 'countAllocatedMachine')
        : listFields;

    const SuperAdminOnEurofoursTechnicians =
      hasRoles(['ROLE_SUPERADMIN']) && !!isEurofoursTechnicianSelect;

    const EurofoursUsersOnClientAdminOrUsers =
      hasRoles(['ROLE_SUPERADMIN', 'ROLE_EUROFOURS_TECHNICIAN']) &&
      (!!isClientAdminSelect || !!isUserSelect);

    const impersonalizeClients =
      hasRoles(['ROLE_SUPERADMIN', 'ROLE_EUROFOURS_TECHNICIAN']) &&
      (isClientAdminSelect || isUserSelect);

    const activateUser = hasRoles(['ROLE_CLIENT_ADMIN']) && isUserSelect;

    const ClientAdminOnUsers =
      hasRoles(['ROLE_CLIENT_ADMIN']) && !!isUserSelect;

    return (
      <Card key="select-user-access">
        <div className={classes.containerTitle}>
          <CardHeader
            className={classes.cardTitle}
            title={translate(
              `custom.components.${
                !!isClientAdminSelect
                  ? isClientAdminSelect
                  : !!isUserSelect
                  ? isUserSelect
                  : isEurofoursTechnicianSelect
              }.title`,
            )}
          />
          {hasRoles(['ROLE_SUPERADMIN', 'ROLE_EUROFOURS_TECHNICIAN']) &&
            !!isEurofoursTechnicianSelect && (
              <AddButton
                roles={['ROLE_EUROFOURS_TECHNICIAN', 'ROLE_SUPERADMIN']}
                to={{pathname: addUser}}
              />
            )}
        </div>
        <Table>
          <TableHead>
            <TableRow>
              {fields.map(field => (
                <TableCell key={`field-${field}`}>
                  {translate(`custom.creation.${field}`)}
                </TableCell>
              ))}
              {impersonalizeClients && (
                <TableCell className={classes.tableCellCenterCenter}>
                  <AssignmentInd />
                </TableCell>
              )}
              {!isUserSelect && (
                <>
                  {activateUser && (
                    <TableCell className={classes.tableCellCenterCenter}>
                      <PowerSettingsNew />
                    </TableCell>
                  )}
                  {(SuperAdminOnEurofoursTechnicians ||
                    !EurofoursUsersOnClientAdminOrUsers) && (
                    <TableCell className={classes.tableCellCenterCenter}>
                      <Delete />
                    </TableCell>
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {enhancedArray ? (
              enhancedArray.map((user, index) => {
                return (
                  <>
                    <TableRow
                      key={`${user.id}-accept-${index}`}
                      onClick={() => this.toggleExpand(index)}>
                      {fields.map(field => {
                        return (
                          <TableCell
                            key={`${user.id}-accept-${index}-${user[field]}`}>
                            {field === 'firstName' &&
                            (ClientAdminOnUsers ||
                              SuperAdminOnEurofoursTechnicians) ? (
                              <Link
                                to={{
                                  pathname:
                                    '/users/%2Fusers%2F' +
                                    user['@id'].split('/')[2] +
                                    '/edit',
                                  state: {
                                    from: !!isClientAdminSelect
                                      ? '/client-admins'
                                      : !!isEurofoursTechnicianSelect
                                      ? '/eurofours-technicians'
                                      : 'list',
                                  },
                                }}>
                                {user[field]}
                              </Link>
                            ) : (
                              <>
                                {field === 'firstName' ? (
                                  <ExpansionPanelSummary
                                    expandIcon={
                                      user.clients.length ? (
                                        <ExpandMore
                                          className={classNames(
                                            user.expanded && classes.rotated,
                                          )}
                                        />
                                      ) : null
                                    }>
                                    {user[field]}
                                  </ExpansionPanelSummary>
                                ) : (
                                  user[field]
                                )}
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                      {impersonalizeClients && (
                        <>
                          <TableCell className={classes.tableCellCenter}>
                            <Button
                              onClick={() =>
                                this.setState({
                                  selectedUserId: user['@id'],
                                  isOpened: true,
                                })
                              }
                              color="secondary">
                              {translate('custom.auth.sign_in')}
                            </Button>
                          </TableCell>
                          {this.state.isOpened && (
                            <Dialog
                              open={this.state.isOpened}
                              onClose={this.handleClose}>
                              <DialogTitle>
                                {translate('custom.auth.sign_in')}
                              </DialogTitle>
                              <DialogContent className={classes.dialogContent}>
                                {translate('custom.auth.enter_security_code')}
                                <br />
                                {isCodeError && (
                                  <DialogContentText
                                    className={classes.errorColor}>
                                    {translate('custom.auth.errorCode')}
                                  </DialogContentText>
                                )}
                                <Input
                                  type="text"
                                  onChange={this.handleChange}
                                />
                                <Button
                                  onClick={this.handleConnect(
                                    this.state.selectedUserId,
                                    this.state.value,
                                  )}>
                                  {translate('custom.auth.validate')}
                                </Button>
                              </DialogContent>
                            </Dialog>
                          )}
                        </>
                      )}
                      {activateUser && (
                        <TableCell className={classes.tableCellCenter}>
                          <Button
                            onClick={() =>
                              this.setState({
                                userId: user['@id'],
                                action: user.active ? 'disable' : 'enable',
                              })
                            }
                            color="secondary"
                            disabled={isLoading}>
                            {translate(
                              `custom.buttons.${
                                user.active ? 'disable' : 'enable'
                              }`,
                            )}
                          </Button>
                        </TableCell>
                      )}
                      {(SuperAdminOnEurofoursTechnicians ||
                        !EurofoursUsersOnClientAdminOrUsers) && (
                        <TableCell className={classes.tableCellCenterCenter}>
                          <Button
                            onClick={() =>
                              this.setState({
                                userId: user['@id'],
                                action: 'delete',
                              })
                            }
                            color="secondary"
                            disabled={isLoading}>
                            {translate('custom.buttons.deletebutton')}
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                    {user.expanded && (
                      <>
                        {hasRoles([
                          'ROLE_SUPERADMIN',
                          'ROLE_EUROFOURS_TECHNICIAN',
                        ]) &&
                          isClientAdminSelect && (
                            <>
                              {user.clients.map((client, index) => {
                                const {
                                  firstName,
                                  lastName,
                                  email,
                                  active,
                                  countAllocatedMachine,
                                } = client;
                                return (
                                  <TableRow key={index}>
                                    <TableCell className={classes.subUser}>
                                      {firstName}
                                    </TableCell>
                                    <TableCell>{lastName}</TableCell>
                                    <TableCell>{email}</TableCell>
                                    <TableCell>
                                      {countAllocatedMachine
                                        ? countAllocatedMachine
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          )}
                      </>
                    )}
                  </>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={listFields.length + 3}>
                  {isLoading ? (
                    <LinearProgress />
                  ) : (
                    translate('custom.components.users.none')
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {this.state.action === 'delete' ? (
          <Confirm
            isOpen={this.state.userId !== null}
            title={translate(`custom.message.bulk_delete_title.users`, {
              smart_count: 1,
            })}
            content={translate('ra.message.bulk_delete_content', {
              name: translate(`custom.action.users.name`, {
                smart_count: 1,
              }),
              smart_count: 1,
            })}
            confirm={translate('ra.message.yes')}
            cancel={translate('ra.message.no')}
            onConfirm={this.handleDelete}
            onClose={() => this.setState({userId: null, action: null})}
          />
        ) : (
          <Confirm
            isOpen={this.state.userId !== null}
            title={translate(`custom.message.disable.title`, {
              action:
                this.state.action === 'disable'
                  ? translate('custom.message.disable.disable')
                  : translate('custom.message.disable.activate'),
              smart_count: 1,
            })}
            content={translate('custom.message.disable.content', {
              action:
                this.state.action === 'disable'
                  ? translate('custom.message.disable.disable')
                  : translate('custom.message.disable.activate'),
              name: translate(`custom.action.users.name`, {
                smart_count: 1,
              }),
              smart_count: 1,
            })}
            confirm={translate('ra.message.yes')}
            cancel={translate('ra.message.no')}
            onConfirm={this.toggleActive(this.state.userId)}
            onClose={() => this.setState({userId: null, action: null})}
          />
        )}
        <CardContent />
      </Card>
    );
  }
}

const mapStateToProps = ({getUsersAcceptEFControlReducer: reducer}) => ({
  isUserSelect: window.location.href.includes('select-user') && 'selectUser',
  isClientAdminSelect:
    window.location.href.includes('client-admins') && 'selectClientAdmin',
  isEurofoursTechnicianSelect:
    window.location.href.includes('eurofours-technicians') &&
    'selectEurofoursTechnician',
  usersAccept: reducer.usersAccept,
  isLoading: reducer.loading,
  isCodeError: reducer.isCodeError,
});

export default compose(
  translate,
  connect(mapStateToProps, {
    getAcceptedControl,
    getClientAdmins,
    getEurofoursTechnicians,
    updateClientAdmin,
    deleteClientAdmin,
    connectAsUser,
    resetLoginError,
    showNotificationAction,
  }),
  withStyles(styles),
)(SelectUser);
