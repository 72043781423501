import StructureCreate from './create';
import StructureEdit from './edit';
import StructureShow from './show';
import StructureList from './list';

export default structures => {
  structures.create = StructureCreate;
  structures.edit = StructureEdit;
  structures.show = StructureShow;
  structures.list = StructureList;

  return structures;
};
