import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose, withState, withHandlers} from 'recompose';
import {connect} from 'react-redux';
import {reduxForm, Field} from 'redux-form';
import {translate, required, minLength, maxLength, email} from 'react-admin';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from '@material-ui/core';
import {SelectCountryCode} from '../Login';
import {createTechnician} from './store/action';
import {hasRoles} from '../../utils/auth';

const renderInput = ({
  meta: {touched, error} = {},
  input: {...inputProps},
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    required
  />
);

const mapStateToProps = state => ({
  createdUser: state.createTechnicianReducer.createdUser,
});

const fields = ['firstName', 'lastName', 'email', 'password', 'society'];

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  society: '',
  countryCode: '',
  temperatureUnitPreference: '',
  roles: hasRoles(['ROLE_SUPERADMIN'])
    ? ['ROLE_EUROFOURS_TECHNICIAN']
    : ['ROLE_CLIENT_ADMIN'],
};
const validatePassword = [required(), minLength(8), maxLength(64)];

const validateEmail = [required(), email()];
export const CreateTechnician = compose(
  connect(mapStateToProps, dispatch => ({
    createTechnician: (...args) => dispatch(createTechnician(...args)),
  })),
  translate,
  reduxForm({
    form: 'createTech',
    validate: ({password}, {translate}, values = {}) => {
      const errors = {};

      if (values.password) {
        errors.password = translate('ra.validation.required');
      } else if (values.password < 8) {
        errors.password = {
          message: 'ra.validation.minValue',
          args: {min: 8},
        };
      }
      return errors;
    },
  }),
  withState('state', 'setState', initialState),
  withRouter,
  withHandlers({
    updateState: ({setState, state}) => patch => setState({...state, ...patch}),
    resetState: ({setState}) => () => setState({}),
  }),
)(
  ({
    createTechnician,
    translate,
    updateState,
    state,
    history,
    handleSubmit,
  }) => {
    if (hasRoles(['ROLE_CLIENT_ADMIN'])) {
      window.location.replace('/#/not-granted');
    }
    return (
      <Card key="select-user-access">
        <div style={{display: 'flex'}}>
          <CardHeader
            title={translate('custom.components.createTechnician.title')}
            style={{flex: 1}}
          />
        </div>
        <CardContent>
          <form
            onSubmit={handleSubmit(() => {
              createTechnician(state, () => {
                history.push('/client-admins');
              });
            })}
            autoComplete="off">
            {fields.map(field => (
              <div>
                <Field
                  component={renderInput}
                  label={translate(`resources.users.fields.${field}`)}
                  onChange={event => updateState({[field]: event.target.value})}
                  value={state[field]}
                  name={field}
                  id="component-simple"
                  type={'password' === field ? 'password' : 'text'}
                  validate={
                    field === 'password'
                      ? validatePassword
                      : field === 'email'
                      ? validateEmail
                      : null
                  }
                />
              </div>
            ))}
            <SelectCountryCode
              language={state.countryCode}
              styles={{maxWidth: 300 + 'px', display: 'block'}}
              handleChange={event =>
                updateState({countryCode: event.target.value})
              }
            />
            <CardActions>
              <Button variant="raised" type="submit" color="primary">
                {translate('custom.auth.create_account')}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    );
  },
);
