import React from 'react';
import logo from '../src/assets/logo_eurofours.png';

export default function FirefoxPage() {
  return (
    <div
      style={{
        backgroundColor: '#212121',
        color: '#ccc',
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}>
      <img
        src={logo}
        alt="Logo"
        style={{marginTop: '25vh', marginBottom: '50px'}}
      />
      <p>
        This application is not supported by Firefox. Please, use another
        navigator.
      </p>
      <p>
        Cette application n'est pas compatible avec Firefox. Veuillez utiliser
        un autre navigateur.
      </p>
    </div>
  );
}
