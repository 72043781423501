import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {SHOW_NOTIFICATION} from 'ra-core';
import {
  USER_GET_ALL,
  USER_GET_ALL_SUCCESS,
  USER_GET_ONE_RESOURCE,
  USER_GET_ONE_SUCCESS,
  USER_ROLES_UPDATE_ONE,
} from '../components/User/store/action';
import {
  USER_SET_GAUTH_RESOURCE,
  USER_SET_GAUTH_SUCCESS,
  USER_SET_GAUTH_ERROR,
  USER_VERIFY_2FA,
  USER_VERIFY_2FA_SUCCESS,
  USER_VERIFY_2FA_ERROR,
  USER_SET_2FA,
  USER_SET_2FA_SUCCESS,
  USER_SET_2FA_ERROR,
} from '../components/AuthGoogle/store/action';
import {
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_ONE_PROFILE,
  USER_UPDATE_ONE_PROFILE_SUCCESS,
  USER_GET_PROFILE,
  USER_GET_PROFILE_SUCCESS,
  USER_LET_EUROFOUR_CONTROL_SUCCESS,
  USER_LET_EUROFOUR_CONTROL,
  USER_EXPORT_ACTIVITIES,
  USER_EXPORT_ACTIVITIES_SUCCESS,
} from '../components/Profile/store/action';
import {
  USER_VERIFY_TOKEN,
  USER_VERIFY_TOKEN_SUCCESS,
  USER_VERIFY_TOKEN_ERROR,
} from '../components/Activate/store/action';
import {
  EUROFOURS_USERS_GET_CLIENT_ADMINS,
  EUROFOURS_USERS_GET_CLIENT_ADMINS_SUCCESS,
  SUPERADMIN_GET_EUROFOURS_TECHNICIANS,
  SUPERADMIN_GET_EUROFOURS_TECHNICIANS_SUCCESS,
  USERS_GET_ACCEPTED_CONTROL,
  USERS_GET_ACCEPTED_CONTROL_SUCCESS,
  USERS_CONTROL_SELECTED,
  USERS_CONTROL_SELECTED_ERROR,
  USERS_CONTROL_SELECTED_SUCCESS,
  CLIENT_ADMIN_UPDATE,
  CLIENT_ADMIN_UPDATE_SUCCESS,
  CLIENT_ADMIN_DELETE_SUCCESS,
} from '../components/SelectUser/store/action';
import {getId, hasRoles} from '../utils/auth';
import {errorMessageHandler} from './helpers';
import {client} from '../utils/api-client';

function* handleUser(action) {
  const {type, payload} = action;
  switch (type) {
    case USER_EXPORT_ACTIVITIES: {
      try {
        const response = yield client(
          `users/${getId()}/export_machines_activities`,
        );
        yield put({
          type: USER_EXPORT_ACTIVITIES_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USER_GET_ONE_RESOURCE: {
      try {
        const response = yield client(`users/${payload}`);
        yield put({
          type: USER_GET_ONE_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USER_GET_ALL: {
      try {
        const response = yield client('users');
        yield put({
          type: USER_GET_ALL_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USER_VERIFY_2FA: {
      try {
        const response = yield client('two-factor-auth', {
          data: {code: payload},
        });
        yield put({
          type: USER_VERIFY_2FA_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put({
          type: USER_VERIFY_2FA_ERROR,
        });
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USER_SET_GAUTH_RESOURCE: {
      try {
        const response = yield client(`users/${getId()}/two-factor-auth`, {
          data: payload,
        });
        yield put({
          type: USER_SET_GAUTH_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put({
          type: USER_SET_GAUTH_ERROR,
        });
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USER_SET_2FA: {
      try {
        const response = yield client(
          `users/${getId()}/two-factor-auth/confirm`,
          {
            data: {
              code: payload,
            },
          },
        );
        yield put({
          type: USER_SET_2FA_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put({
          type: USER_SET_2FA_ERROR,
        });
        yield put(
          showNotificationAction('custom.notification.2fa_error', 'error'),
        );
      }
      break;
    }
    case USER_VERIFY_TOKEN: {
      try {
        yield client('token/verify', {
          data: {
            token: payload,
          },
        });
        yield put({
          type: USER_VERIFY_TOKEN_SUCCESS,
        });
        yield put(
          showNotificationAction('custom.auth.account_activated', 'info'),
        );
      } catch (e) {
        yield put({
          type: USER_VERIFY_TOKEN_ERROR,
        });
        yield put(showNotificationAction(errorMessageHandler(e), 'error'));
      }
      break;
    }
    case USER_UPDATE_PROFILE: {
      try {
        const response = yield client(`users/${getId()}`, {
          data: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            countryCode: payload.countryCode,
            email: payload.email,
            mobilePhone: payload.mobilePhone,
            society: payload.society,
            zipcode: payload.zipcode,
            street: payload.street,
            city: payload.city,
            country: payload.country,
            temperatureUnitPreference: payload.temperatureUnitPreference,
          },
        });
        yield put({
          type: USER_UPDATE_PROFILE_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USER_LET_EUROFOUR_CONTROL: {
      try {
        const response = yield client(`users/${getId()}`, {
          data: {
            isEuroFourControl: payload.checked,
          },
        });
        yield put({
          type: USER_LET_EUROFOUR_CONTROL_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USERS_CONTROL_SELECTED: {
      try {
        const response = yield client(`control/user/${payload.id}`, {
          data: payload,
          method: 'POST',
        });
        yield put({
          type: USERS_CONTROL_SELECTED_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put({
          type: USERS_CONTROL_SELECTED_ERROR,
        });
      }
      break;
    }
    case CLIENT_ADMIN_UPDATE: {
      try {
        const response = yield client(payload.id, {data: payload.body});
        yield put({
          type: CLIENT_ADMIN_UPDATE_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USER_GET_PROFILE: {
      try {
        const response = yield client('profile');
        yield put({
          type: USER_GET_PROFILE_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USERS_GET_ACCEPTED_CONTROL: {
      try {
        const response = yield client('users/controls');
        yield put({
          type: USERS_GET_ACCEPTED_CONTROL_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case EUROFOURS_USERS_GET_CLIENT_ADMINS: {
      try {
        const response = yield client('client-admins');
        yield put({
          type: EUROFOURS_USERS_GET_CLIENT_ADMINS_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case SUPERADMIN_GET_EUROFOURS_TECHNICIANS: {
      try {
        const response = yield client('eurofours-technicians');
        yield put({
          type: SUPERADMIN_GET_EUROFOURS_TECHNICIANS_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USER_ROLES_UPDATE_ONE: {
      try {
        yield client('users/rights', {
          data: {
            user: `/users/${payload.id}`,
            role: payload.name,
            active: payload.value,
          },
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case USER_UPDATE_ONE_PROFILE: {
      try {
        const {data, redirect, id} = payload;
        const response = yield client(`users/${id ? id : getId()}`, {
          data,
          method: 'PUT',
        });
        yield put({
          type: USER_UPDATE_ONE_PROFILE_SUCCESS,
          payload: response,
        });
        if (!!redirect) {
          window.location.replace('/#/profile');
          yield put({
            type: SHOW_NOTIFICATION,
            payload: {
              autoHideDuration: 5000,
              type: 'info',
              message: 'custom.notification.account_updated',
            },
          });
        } else {
          yield put({
            type: SHOW_NOTIFICATION,
            payload: {
              autoHideDuration: 5000,
              type: 'info',
              message: 'custom.notification.email_updated',
            },
          });
        }
      } catch (e) {}
      break;
    }
    case CLIENT_ADMIN_UPDATE_SUCCESS:
    case CLIENT_ADMIN_DELETE_SUCCESS: {
      try {
        yield put({
          type: hasRoles(['ROLE_EUROFOURS_TECHNICIAN'])
            ? EUROFOURS_USERS_GET_CLIENT_ADMINS
            : USER_GET_ALL,
        });
        if (
          hasRoles(['ROLE_SUPERADMIN']) &&
          CLIENT_ADMIN_DELETE_SUCCESS === type
        ) {
          yield put({
            type: SUPERADMIN_GET_EUROFOURS_TECHNICIANS,
          });
        }
      } catch (e) {}
      break;
    }
    default:
      break;
  }
}

export default function* watchUsersActions() {
  yield all([
    takeEvery(USER_GET_ONE_RESOURCE, handleUser),
    takeEvery(EUROFOURS_USERS_GET_CLIENT_ADMINS, handleUser),
    takeEvery(SUPERADMIN_GET_EUROFOURS_TECHNICIANS, handleUser),
    takeEvery(USER_VERIFY_2FA, handleUser),
    takeEvery(USER_SET_GAUTH_RESOURCE, handleUser),
    takeEvery(USER_SET_2FA, handleUser),
    takeEvery(USER_VERIFY_TOKEN, handleUser),
    takeEvery(USER_UPDATE_PROFILE, handleUser),
    takeEvery(CLIENT_ADMIN_UPDATE, handleUser),
    takeEvery(CLIENT_ADMIN_UPDATE_SUCCESS, handleUser),
    takeEvery(CLIENT_ADMIN_DELETE_SUCCESS, handleUser),
    takeEvery(USER_GET_PROFILE, handleUser),
    takeEvery(USER_GET_ALL, handleUser),
    takeEvery(USERS_GET_ACCEPTED_CONTROL, handleUser),
    takeEvery(USERS_CONTROL_SELECTED, handleUser),
    takeEvery(USER_ROLES_UPDATE_ONE, handleUser),
    takeEvery(USER_UPDATE_ONE_PROFILE, handleUser),
    takeEvery(USER_EXPORT_ACTIVITIES, handleUser),
  ]);
}
