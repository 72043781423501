import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = () => ({
  container: {
    background: '#ededed',
    fontSize: 1.8 + 'em',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    color: 'black',
    height: 60,
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const TopBarList = ({classes, children}) => {
  return <div className={classes.container}>{children}</div>;
};

TopBarList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(TopBarList);
