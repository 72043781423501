import UserCreate from './create';
import UserList from './list';
import UserShow from './show';
import UserEdit from './edit';

export default users => {
  users.create = UserCreate;
  users.list = UserList;
  users.show = UserShow;
  users.edit = UserEdit;

  return users;
};
