import React, {Component} from 'react';
import {Button} from 'react-admin';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {hasRoles} from '../../utils/auth';
import {Delete, Edit} from '@material-ui/icons';

const styles = {
  imageContainer: {
    padding: 1 + 'em',
  },
  image: {
    width: 100 + '%',
  },
  buttonsContainer: {
    display: 'flex',
    'justify-content': 'end',
  },
  detailContainer: {
    margin: '24px 0px 48px 0px',
  },
};

class ImagesListFactory extends Component {
  render() {
    const {classes, images, path, handleDelete} = this.props;
    return (
      <Grid container>
        {images &&
          images.map((image, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              className={classes.imageContainer}
              key={`image-${index}`}>
              <img
                src={`${process.env.REACT_APP_API_ENTRYPOINT}${path}${image.machineCode}`}
                className={classes.image}
                alt={image.machineCode}
              />
              {hasRoles(['ROLE_SUPERADMIN']) && (
                <div className={classes.buttonsContainer}>
                  <Button id={image.machineCode}>
                    <Link to={`/images-machines/${image.machineCode}`}>
                      <Edit />
                    </Link>
                  </Button>
                  <Button onClick={handleDelete} id={image.machineCode}>
                    <Delete />
                  </Button>
                </div>
              )}
              <>
                <div className={classes.detailContainer}>
                  <h4>Code :</h4>
                  <p>{image.machineCode}</p>
                </div>

                <div className={classes.detailContainer}>
                  <h4>Description :</h4>
                  <p>{image.machineDescription}</p>
                </div>
              </>
            </Grid>
          ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(ImagesListFactory);
