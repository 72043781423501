import * as actions from './action';

const initialState = {
  softwareUpdates: null,
  loading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.SOFTWARE_UPDATES_POST_RESOURCE:
      return {
        ...state,
        softwareUpdates: payload,
        loading: true,
      };
    case actions.SOFTWARE_UPDATES_POST_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.SOFTWARE_UPDATES_PUT_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
