import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import register from './registerServiceWorker';
import FirefoxPage from './FirefoxPage';

// Check if broswer is Firefox
if (typeof InstallTrigger !== 'undefined') {
  ReactDOM.render(<FirefoxPage />, document.getElementById('root'));
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
  register(); // @see https://gitlab.com/eurofours/etools/-/issues/426
}
