export const USER_UPDATE_PROFILE = 'USER_UPDATE_PROFILE';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_ONE_PROFILE = 'USER_UPDATE_ONE_PROFILE';
export const USER_UPDATE_ONE_PROFILE_SUCCESS =
  'USER_UPDATE_ONE_PROFILE_SUCCESS';
export const USER_GET_PROFILE = 'USER_GET_PROFILE';
export const USER_GET_PROFILE_SUCCESS = 'USER_GET_PROFILE_SUCCESS';
export const IMAGE_PROFILE_SEND_SUCCESS = 'IMAGE_PROFILE_SEND_SUCCESS';
export const USER_LET_EUROFOUR_CONTROL = 'USER_LET_EUROFOUR_CONTROL';
export const USER_LET_EUROFOUR_CONTROL_SUCCESS =
  'USER_LET_EUROFOUR_CONTROL_SUCCESS';
export const USER_EXPORT_ACTIVITIES = 'USER_EXPORT_ACTIVITIES';
export const USER_EXPORT_ACTIVITIES_SUCCESS = 'USER_EXPORT_ACTIVITIES_SUCCESS';

export const exportActivities = data => {
  return {
    type: USER_EXPORT_ACTIVITIES,
    payload: data,
  };
};

export const updateProfile = data => ({
  type: USER_UPDATE_PROFILE,
  payload: data,
});

export const updateOne = (data, redirect) => ({
  type: USER_UPDATE_ONE_PROFILE,
  payload: {
    data,
    redirect,
  },
});

export const activateEFControl = data => ({
  type: USER_LET_EUROFOUR_CONTROL,
  payload: data,
});

export const getProfile = () => ({
  type: USER_GET_PROFILE,
});
