export const USER_GET_ONE_RESOURCE = 'USER_GET_ONE_RESOURCE';
export const USER_GET_ONE_SUCCESS = 'USER_GET_ONE_SUCCESS';
export const USER_GET_ALL = 'USER_GET_ALL';
export const USER_GET_ALL_SUCCESS = 'USER_GET_ALL_SUCCESS';
export const USER_ROLES_UPDATE_ONE = 'USER_ROLES_UPDATE_ONE';

export const getUser = data => ({
  type: USER_GET_ONE_RESOURCE,
  payload: data,
});

export const getUsers = data => ({
  type: USER_GET_ALL,
  payload: data,
});

export const updateUserRoles = data => ({
  type: USER_ROLES_UPDATE_ONE,
  payload: data,
});
