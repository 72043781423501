import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {Link} from 'react-router-dom';
import SelectField from '@material-ui/core/Select';
import LockIcon from '@material-ui/icons/LockOutline';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  TextField,
} from '@material-ui/core';
import theme from '../../theme';
import {clientAdminCreate} from '../../action/registerAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';

import {
  Notification,
  translate,
  required,
  minLength,
  maxLength,
  email,
} from 'react-admin';
import CountryField from '../Common/Field/CountryField';
import TimezoneField from '../Common/Field/TimezoneField';

const styles = theme => ({
  main: {
    minHeight: '100vh',
    backgroundColor: '#' + 222,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing.unit * 2}px`,
    },
  },
  card: {
    maxWidth: '600px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    width: '100%',
  },
  actions: {
    padding: '1em',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing.unit * 2}px`,
    },
  },
  logo: {
    textAlign: 'center',
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  buttonDisabled: {
    position: 'relative',
    background: `${theme.palette.grey[300]}!important`,
  },
  loading: {
    position: 'absolute',
  },
  secondForm: {
    marginTop: `${theme.spacing.unit * 4}px`,
  },
});

const renderInput = ({
  meta: {touched, error} = {},
  input: {...inputProps},
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
    required
  />
);

const RenderSelectField = ({children, label, onChange, value}) => (
  <FormControl fullWidth>
    <InputLabel htmlFor="language">{label}</InputLabel>
    <SelectField value={value} onChange={event => onChange(event)}>
      {children}
    </SelectField>
  </FormControl>
);

const validatePassword = [required(), minLength(8), maxLength(64)];
const validateName = [required(), maxLength(100)];
const validateEmail = [required(), maxLength(200), email()];
const isRequired = [required()];

class AccountCreation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCode: 'EN',
      temperatureUnitPreference: 'C',
    };
  }

  handleChange = event => {
    this.setState({...this.state, countryCode: event.target.value});
  };
  handleChangeTemperatureUnit = event => {
    this.setState({
      ...this.state,
      temperatureUnitPreference: event.target.value,
    });
  };

  createAccount = ({
    email,
    password,
    firstName,
    lastName,
    society,
    zipcode,
    city,
    street,
    country,
  }) => {
    const {userAccountCreation} = this.props;
    userAccountCreation(
      {
        email,
        password,
        firstName,
        lastName,
        countryCode: this.state.countryCode,
        society,
        zipcode,
        city,
        street,
        country,
        temperatureUnitPreference: this.state.temperatureUnitPreference,
      },
      '/',
    );
  };

  render() {
    const {classes, handleSubmit, translate, isLoading} = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Grid container className={classes.main}>
          <Grid item xs={12} md={6} className={classes.logo}>
            <img src="login.png" alt={translate('custom.auth.login')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <form onSubmit={handleSubmit(this.createAccount)}>
                <div className={classes.form}>
                  <Grid container spacing={16}>
                    <Grid item xs={12}>
                      <div className={classes.input}>
                        <Field
                          name="email"
                          component={renderInput}
                          label={translate('custom.auth.email')}
                          type="email"
                          validate={validateEmail}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <Field
                          name="password"
                          component={renderInput}
                          label={translate('custom.auth.password')}
                          type="password"
                          validate={validatePassword}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <Field
                          name="password2"
                          component={renderInput}
                          label={translate('custom.creation.password_again')}
                          type="password"
                          validate={validatePassword}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={16} className={classes.secondForm}>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <Field
                          name="lastName"
                          component={renderInput}
                          label={translate('custom.creation.lastName')}
                          validate={validateName}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <Field
                          name="firstName"
                          component={renderInput}
                          label={translate('custom.creation.firstName')}
                          validate={validateName}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <Field
                          name="street"
                          component={renderInput}
                          label={translate('custom.creation.adress')}
                          validate={isRequired}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <Field
                          name="zipcode"
                          component={renderInput}
                          label={translate('custom.creation.zipcode')}
                          validate={isRequired}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <Field
                          name="city"
                          component={renderInput}
                          label={translate('custom.creation.city')}
                          validate={isRequired}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <CountryField
                          source="country"
                          validate={isRequired}
                          style={{marginTop: '0px'}}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <TimezoneField
                          source="timezone"
                          validate={isRequired}
                          style={{marginTop: '0px'}}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <Field
                          name="society"
                          component={renderInput}
                          label={translate('custom.creation.society')}
                          validate={isRequired}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.secondForm}>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <RenderSelectField
                          name="countryCode"
                          onChange={this.handleChange}
                          value={this.state.countryCode}
                          label={translate('custom.creation.languageLabel')}>
                          <MenuItem value={'FR'}>
                            {translate('custom.creation.language.french')}
                          </MenuItem>
                          <MenuItem value={'EN'}>
                            {translate('custom.creation.language.english')}
                          </MenuItem>
                        </RenderSelectField>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.input}>
                        <RenderSelectField
                          name="temperatureUnitPreference"
                          onChange={this.handleChangeTemperatureUnit}
                          value={this.state.temperatureUnitPreference}
                          label={translate(
                            'custom.creation.temperatureUnitPreference.label',
                          )}>
                          <MenuItem value={'C'}>
                            {translate(
                              'custom.creation.temperatureUnitPreference.celsius',
                            )}
                          </MenuItem>
                          <MenuItem value={'F'}>
                            {translate(
                              'custom.creation.temperatureUnitPreference.fahrenheit',
                            )}
                          </MenuItem>
                        </RenderSelectField>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="raised"
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                    classes={{
                      disabled: classes.buttonDisabled,
                    }}
                    fullWidth>
                    {isLoading && (
                      <CircularProgress
                        size={25}
                        thickness={2}
                        className={classes.loading}
                      />
                    )}
                    {translate('custom.auth.create_account')}
                  </Button>
                  <Button
                    variant="outlined"
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                    classes={{
                      disabled: classes.buttonDisabled,
                    }}
                    component={Link}
                    to="/login"
                    fullWidth>
                    {translate('custom.auth.sign_in')}
                  </Button>
                </CardActions>
              </form>
            </Card>
          </Grid>
          <Notification />
        </Grid>
      </MuiThemeProvider>
    );
  }
}

AccountCreation.propTypes = {
  ...propTypes,
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({isLoading: state.registerReducer.isLoading});

const enhance = compose(
  translate,
  reduxForm({
    form: 'createAccount',
    validate: ({password, password2}, {translate}) => {
      const errors = {};

      if (password !== password2) {
        errors.password = translate('custom.validation.samepassword');
        errors.password2 = translate('custom.validation.samepassword');
      }
      return errors;
    },
  }),
  connect(mapStateToProps, {userAccountCreation: clientAdminCreate}),
  withStyles(styles, {withTheme: true}),
);

export default enhance(AccountCreation);
