import React from 'react';
import qs from 'qs';
import parseHydraDocumentation from './components/Common/parseHydraDocumentation';
import {
  HydraAdmin,
  hydraClient,
  fetchHydra as baseFetchHydra,
} from '@api-platform/admin';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/core/styles';
import authProvider from './authProvider';
import {Route, Redirect} from 'react-router-dom';
import customRoutes from './customRoutes';
import LoginPage from './components/Login';
import Loading from './components/Loading';
import customSagas from './saga';
import customReducers from './reducer';
import overrideResources from './override';
import {getToken, hasRoles} from './utils/auth';
import theme from './theme';
import Layout from './components/Layout';
import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import frenchCustomTranslates from './translations/fr';
import englishCustomTranslates from './translations/en';
import Dashboard from './components/Home';
import './base.css';
import {transformJsonLdDocumentToReactAdminDocument} from '@api-platform/admin/lib/hydra/hydraClient';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
});
const token = getToken();

const fetchHeaders = {
  Authorization: `Bearer ${token}`,
};
const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
  });

let globalResources;
const setResources = resources => {
  globalResources = resources;
};

/**
 * SOURCE : REACT-ADMIN
 * @param {string} resource
 * @param {Object} data
 *
 * @returns {Promise}
 */
const convertHydraDataToReactAdminData = (resource, data = {}) => {
  resource = globalResources.find(({name}) => resource === name);
  if (undefined === resource) {
    return Promise.resolve(data);
  }

  const fieldData = {};
  resource.fields.forEach(({name, denormalizeData}) => {
    if (!(name in data) || undefined === denormalizeData) {
      return;
    }

    fieldData[name] = denormalizeData(data[name]);
  });

  const fieldDataKeys = Object.keys(fieldData);
  const fieldDataValues = Object.values(fieldData);

  return Promise.all(fieldDataValues).then(fieldData => {
    const object = {};
    for (let i = 0; i < fieldDataKeys.length; i++) {
      object[fieldDataKeys[i]] = fieldData[i];
    }

    return {...data, ...object};
  });
};

const dataProvider = ({entrypoint}) => {
  const hydraFetchApi = hydraClient(
    {
      entrypoint,
      resources: globalResources,
    },
    fetchHydra,
    'etools',
  );

  /** react-admin wants response to DELETE_MANY to be {data: []}
   * hydra fetchApi returns empty data in case of DELETE and DELETE_MANY so we need to
   * override this behaviour.
   */

  /**
   * We want to display the message contained in answer instead of just 'Bad request', so we need to override hydrafetchApi for case CREATE
   */

  return (type, resource, params) => {
    switch (type) {
      case 'GET_LIST':
      case 'GET_MANY_REFERENCE': {
        const {
          pagination: {page, perPage},
          sort: {field, order},
        } = params;
        // cas super particulier ici :
        // sur les structures, si l'appel vient de la vue liste et qu'il n'y a aucun filtre, on applique le filtre "lvl = 0" pour n'afficher que le premier niveau de structures et avoir la bonne pagination.
        let filter = params.filter;
        if (
          type === 'GET_LIST' &&
          resource === 'structures' &&
          filter.lvl0Only
        ) {
          delete filter.lvl0Only;
          delete filter.lvl;
          if (Object.values(filter).length === 0) filter = {lvl: 0};
        }
        const url = `${entrypoint}/${resource}?${qs.stringify({
          ...filter,
          order: {
            [field]: order,
          },
          page,
          itemsPerPage: perPage,
        })}`;
        const options = {
          headers: new Headers({
            Accept: 'application/ld+json',
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/ld+json',
          }),
        };
        return fetch(url, options)
          .then(async res => {
            if (400 === res.status) {
              const data = await res.json();
              throw new Error(data.detail);
            }
            return res.json();
          })
          .then(json => ({
            members: json['hydra:member'],
            total: json['hydra:totalItems'],
          }))
          .then(({members, total}) => {
            return {
              total,
              members: members.map(transformJsonLdDocumentToReactAdminDocument),
            };
          })
          .then(({members, total}) => {
            return Promise.all(
              members.map(data =>
                convertHydraDataToReactAdminData(resource, data),
              ),
            ).then(data => ({data, total}));
          });
      }
      case 'DELETE_MANY':
        return Promise.all(
          params.ids.map(id => hydraFetchApi('DELETE', resource, {id: id})),
        ).then(_ => ({data: params.ids}));

      case 'CREATE': {
        const url = `${entrypoint}/${resource}`;
        let options;
        if (resource === 'software_updates') {
          const formData = new FormData();
          formData.append('file', params.data.file.rawFile);
          formData.append('version', params.data.version);
          formData.append(
            'description',
            params.data.description ? params.data.description : '',
          );
          formData.append('beta', params.data.beta ? params.data.beta : false);
          options = {
            headers: new Headers({
              Authorization: `Bearer ${getToken()}`,
            }),
            method: 'POST',
            body: formData,
          };
        } else {
          options = {
            headers: new Headers({
              Accept: 'application/json',
              Authorization: `Bearer ${getToken()}`,
              'Content-Type': 'application/ld+json',
            }),
            method: 'POST',
            body: JSON.stringify(params.data),
          };
        }
        return fetch(url, options)
          .then(async res => {
            if (400 === res.status) {
              const data = await res.json();
              throw new Error(data.detail);
            }
            return res.json();
          })
          .then(json => {
            return {data: {...params.data, id: json.id}};
          });
      }

      default:
        return hydraFetchApi(type, resource, params);
    }
  };
};

const apiDocumentationParser = entrypoint => {
  return parseHydraDocumentation(entrypoint, {
    headers: new Headers(fetchHeaders),
  })
    .then(result => {
      const {api, status} = result;

      if (status === 401 || status === 403) {
        return result;
      }

      const {resources} = api;
      overrideResources(resources);
      setResources(resources);

      return result;
    })
    .catch(result => {
      const {api, status} = result;

      if (status === 401 || status === 403) {
        if (token && !hasRoles(['ROLE_NEED_2FA'])) {
          localStorage.removeItem('token');
          localStorage.removeItem('role');
        }

        return Promise.resolve({
          api,
          status,
          customRoutes: [
            <Route
              path="/"
              render={() => (
                <Redirect
                  to={hasRoles(['ROLE_NEED_2FA']) ? '/authenticate' : '/login'}
                />
              )}
            />,
          ],
        });
      }

      return Promise.reject(result);
    });
};

const messages = {
  fr: {...frenchMessages, ...frenchCustomTranslates},
  en: {...englishMessages, ...englishCustomTranslates},
};

const i18nProvider = local => {
  const language = localStorage.getItem('language');

  if (language && messages[language]) {
    local = language;
  }
  return messages[local];
};

export default () => (
  <JssProvider generateClassName={generateClassName}>
    <HydraAdmin
      i18nProvider={i18nProvider}
      appLayout={Layout}
      apiDocumentationParser={apiDocumentationParser}
      authProvider={authProvider}
      dashboard={Dashboard}
      entrypoint={process.env.REACT_APP_API_ENTRYPOINT}
      dataProvider={dataProvider}
      loginPage={LoginPage}
      loading={Loading}
      theme={theme}
      customSagas={customSagas}
      customReducers={customReducers}
      customRoutes={customRoutes}
    />
  </JssProvider>
);
