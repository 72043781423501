import React from 'react';
import {translate, AutocompleteInput} from 'react-admin';
import {compose} from 'recompose';
import countries from '../../../constants/countries';

const CountryField = ({source, validate, translate, style}) => {
  return (
    <AutocompleteInput
      source={source}
      label={translate('custom.creation.country')}
      choices={countries}
      optionText="label"
      optionValue="label"
      validate={validate}
      style={style}
    />
  );
};

export default compose(translate)(CountryField);
