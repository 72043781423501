export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const PASSWORD_RESET_CHILD_REQUEST = 'PASSWORD_RESET_CHILD_REQUEST';
export const PASSWORD_RESET_CHILD_SUCCESS = 'PASSWORD_RESET_CHILD_SUCCESS';
export const PASSWORD_RESET_CHILD_ERROR = 'PASSWORD_RESET_CHILD_ERROR';
export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR';

export const resetPassword = data => ({
  type: PASSWORD_RESET_REQUEST,
  payload: data,
});

export const resetChildPassword = data => ({
  type: PASSWORD_RESET_CHILD_REQUEST,
  payload: data,
});

export const changePassword = data => ({
  type: PASSWORD_CHANGE_REQUEST,
  payload: data,
});
