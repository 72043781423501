import React, {Fragment, useEffect, useState} from 'react';
import {translate, Link, linkToRecord} from 'react-admin';
import {Typography, Grid} from '@material-ui/core';
import {compose} from 'recompose';
import {client} from '../../utils/api-client';
import DateRenderer from '../Common/DateRenderer';

const ActivityRow = ({translate, ...props}) => {
  const {type, date, program, mode} = props.item;
  const dateObject = new Date(date.date || date);

  // edrive send preheat and cooking data at the same timestamp
  // need to substract 1 second to preheats in order to display them before cooking
  if (['PreheatEnd', 'PreheatStart'].includes(type)) {
    dateObject.setSeconds(dateObject.getSeconds() - 1);
  }

  const [programName, setProgramName] = useState(null);

  useEffect(() => {
    if (program) {
      const fetchProgram = async () => {
        const response = await client(`/programs/${program.split('/')[2]}`);
        setProgramName(response.name);
      };

      fetchProgram();
    }
  }, [program]);

  return (
    <Grid container wrap="nowrap">
      <Grid item xs={3}>
        <DateRenderer date={dateObject} />
      </Grid>
      <Grid item xs>
        <Typography style={{color: '#606060', fontWeight: 500}}>
          {translate(
            `custom.components.machine.usage_types.${type}${
              mode ? `.${mode}` : ''
            }`,
            {
              _: type,
            },
          )}
          {programName && (
            <Fragment>
              {' ('}
              <Link to={linkToRecord('/programs', program, 'show')}>
                {programName}
              </Link>
              {')'}
            </Fragment>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default compose(translate)(ActivityRow);
