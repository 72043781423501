import {isEmpty} from 'lodash';

export const defaultPhaseGroups = [
  {
    phaseGroupType: 'heat',
    phases: [
      {
        position: 0,
        values: [
          {
            criteria: 'temperature',
            value: 0,
          },
          {
            criteria: 'duration',
            value: 0,
          },
        ],
      },
    ],
    prePhaseValues: [
      {
        criteria: 'temperature',
        value: 0,
      },
    ],
  },
  {
    phaseGroupType: 'fan',
    phases: [
      {
        position: 0,
        values: [
          {
            criteria: 'speed',
            value: 100,
          },
          {
            criteria: 'start',
            value: 0,
          },
        ],
      },
    ],
  },
];

export const defaultPhaseGroupValueForAPhaseGroupType = phaseGroupType => ({
  phaseGroupType: phaseGroupType['@id'],
  prePhaseValues: phaseGroupType.prePhaseCriterias.map(criteria => ({
    value: criteria.configs.min || 0,
    criteria: criteria['@id'],
  })),
  phases: [
    {
      position: 0,
      values: phaseGroupType.phaseCriterias.map(criteria => ({
        value:
          'speed' === criteria.name && 'fan' === phaseGroupType.name
            ? 100
            : 'duration' === criteria.name && 'heat' === phaseGroupType.name
            ? 60
            : criteria.configs.min || 0,
        criteria: criteria['@id'],
      })),
    },
  ],
});

export const defaultPhaseGroupValues = phaseGroupTypes =>
  phaseGroupTypes.map(phaseGroupType =>
    defaultPhaseGroupValueForAPhaseGroupType(phaseGroupType),
  );

export const normalizePhaseGroups = (phaseGroups, phaseGroupTypes) => {
  const phaseGroupTypesPresentInPhaseGroups = phaseGroups.map(
    phaseGroup => phaseGroup.phaseGroupType,
  );
  return phaseGroupTypes.map(phaseGroupType => {
    const idx = phaseGroupTypesPresentInPhaseGroups.indexOf(
      phaseGroupType['@id'],
    );
    if (idx !== -1) {
      return phaseGroups[idx];
    } else {
      return defaultPhaseGroupValueForAPhaseGroupType(phaseGroupType);
    }
  });
};

export const formatPhaseGroupsValue = (phaseGroups, phaseGroupTypes) => {
  return phaseGroups.map((phaseGroup, phaseGroupIndex) => {
    // This does not rely on ordering anymore
    const phaseGroupType = phaseGroupTypes.find(
      pgt => pgt['@id'] === phaseGroup.phaseGroupType,
    );

    const phaseGroupData = {
      phaseGroupType: phaseGroupType['@id'],
    };

    if (!isEmpty(phaseGroup.phases)) {
      phaseGroupData.phases = phaseGroup.phases.map((phase, phaseIndex) => {
        const phaseData = {
          position: phaseIndex,
        };

        if (!isEmpty(phase.values)) {
          phaseData.values = phase.values.map((value, valueIndex) => {
            const criteria = phaseGroupType.phaseCriterias[valueIndex];

            return {
              value:
                'fan' === phaseGroupType.name &&
                'start' === criteria.name &&
                0 === phaseIndex
                  ? 0
                  : value.value,
              criteria: criteria['@id'],
            };
          });
        } else {
          phaseData.values = phaseGroupType.phaseCriterias.map(criteria => ({
            value:
              'speed' === criteria.name && 'fan' === phaseGroupType.name
                ? 100
                : criteria.configs.min || 0,
            criteria: criteria['@id'],
          }));
        }

        return phaseData;
      });
    } else {
      phaseGroupData.phases = [
        {
          position: 0,
          values: phaseGroupType.phaseCriterias.map(criteria => ({
            value:
              'speed' === criteria.name && 'fan' === phaseGroupType.name
                ? 100
                : criteria.configs.min || 0,
            criteria: criteria['@id'],
          })),
        },
      ];
    }

    if (!isEmpty(phaseGroup.prePhaseValues)) {
      phaseGroupData.prePhaseValues = phaseGroup.prePhaseValues
        .map((value, valueIndex) => {
          if (
            phaseGroupType.prePhaseCriterias.find(
              x => x['@id'] === value.criteria,
            )
          ) {
            return {
              value: value.value,
              criteria: value.criteria,
            };
          } else {
            return false;
          }
        })
        .filter(x => Boolean(x));
    } else {
      phaseGroupData.prePhaseValues = phaseGroupType.prePhaseCriterias.map(
        criteria => ({
          value: criteria.configs.min || 0,
          criteria: criteria['@id'],
        }),
      );
    }

    return phaseGroupData;
  });
};
