import * as actions from './action';

const initialState = {
  isVerifiedToken: false,
  loaded: false,
};

export default (state = initialState, {type}) => {
  switch (type) {
    case actions.USER_VERIFY_TOKEN:
      return initialState;
    case actions.USER_VERIFY_TOKEN_SUCCESS:
      return {
        isVerifiedToken: true,
        loaded: true,
      };
    case actions.USER_VERIFY_TOKEN_ERROR:
      return {
        isVerifiedToken: false,
        loaded: true,
      };
    default:
      return state;
  }
};
