import React from 'react';
import {translate, AutocompleteInput} from 'react-admin';
import {compose} from 'recompose';
import {timezones} from '../../../constants/timezones';

const TimezoneField = ({source, validate, translate, style}) => {
  return (
    <AutocompleteInput
      source={source}
      label={translate('custom.creation.timezone')}
      choices={timezones}
      optionText="label"
      optionValue="id"
      validate={validate}
      style={style}
    />
  );
};

export default compose(translate)(TimezoneField);
