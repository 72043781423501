import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {
  EXPORT_ONE_PROGRAM,
  EXPORT_ONE_PROGRAM_SUCCESS,
} from '../components/Common/Exporter/store/action';
import {getToken} from '../utils/auth';
import {client} from '../utils/api-client';
import {errorMessageHandler} from './helpers';

function* handleUpdate(action) {
  const {type, payload} = action;

  try {
    const contentHeaders = new Headers();
    contentHeaders.append('Accept', 'application/ld+json');
    contentHeaders.append('Content-Type', 'application/ld+json');
    if (getToken()) {
      contentHeaders.append('Authorization', `Bearer ${getToken()}`);
    }

    switch (type) {
      case EXPORT_ONE_PROGRAM: {
        const response = yield client(`${payload}/export`);
        yield put({
          type: EXPORT_ONE_PROGRAM_SUCCESS,
          payload: response,
        });
        break;
      }
      default:
        break;
    }
  } catch (e) {
    yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
  }
}

export default function* watchUsersActions() {
  yield all([takeEvery(EXPORT_ONE_PROGRAM, handleUpdate)]);
}
