import React, {Component, Fragment, Children, cloneElement} from 'react';
import PropTypes from 'prop-types';
import {Typography, withStyles, Button, Grid} from '@material-ui/core';
import {translate} from 'react-admin';
import {compose} from 'recompose';
import {ArrowDropDown} from '@material-ui/icons';

const styles = theme => ({
  button: {
    '& svg': {
      position: 'absolute',
      left: -1 + 'em',
    },
    textTransform: 'none',
    color: theme.palette.primary.main,
    padding: 0,
    minWidth: 0,
  },
});

class GridList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linePerPage: props.linePerPage || 10,
    };
  }

  render() {
    const {
      className,
      title,
      data,
      noDataDesc,
      children,
      translate,
      classes,
    } = this.props;
    const {linePerPage} = this.state;

    return (
      <Fragment>
        <Grid container direction="column" className={className} wrap="nowrap">
          {title && (
            <Grid item>
              <Typography paragraph variant="title">
                {translate(title).toUpperCase()}
              </Typography>
            </Grid>
          )}
          {data && data.length ? (
            <Fragment>
              {data && (
                <Fragment>
                  {data.slice(0, linePerPage).map((item, itemIndex) => (
                    <Grid item key={itemIndex}>
                      {Children.map(children, (child, childIndex) =>
                        cloneElement(child, {
                          key: childIndex,
                          item: item,
                          itemIndex: itemIndex,
                        }),
                      )}
                    </Grid>
                  ))}
                  {data.length > linePerPage && (
                    <Grid item>
                      <Typography>...</Typography>
                    </Grid>
                  )}
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Grid item>
              <Typography>
                {translate(noDataDesc, {
                  _: translate('custom.commons.no_data'),
                })}
              </Typography>
            </Grid>
          )}
        </Grid>
        {data && data.length > linePerPage && (
          <Button
            onClick={() => this.setState({linePerPage: linePerPage + 10})}
            className={classes.button}
            disableRipple>
            <ArrowDropDown />
            {translate('custom.components.show.see_more')}
          </Button>
        )}
      </Fragment>
    );
  }
}

GridList.propTypes = {
  title: PropTypes.string,
  noDataDesc: PropTypes.string,
  data: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(translate, withStyles(styles))(GridList);
