import React, {Component} from 'react';
import {SimpleForm, TextInput, SaveButton} from 'react-admin';
import {compose} from 'recompose';
import {Card, CardContent} from '@material-ui/core';
import {connect} from 'react-redux';
import {verifyUser2FA} from '../AuthGoogle/store/action';
import Title from '../Common/titleWithRedirect';

class AuthGoogle extends Component {
  handleSubmit = ({code}) => {
    this.props.verifyUser2FA(code);
  };

  render() {
    const {isVerified} = this.props;
    if (isVerified) {
      window.location.href = `/`;
    }
    return (
      <Card style={{marginTop: '3rem'}}>
        <CardContent>
          <Title redirect="/login" title="custom.components.gauth.2fa" />
        </CardContent>
        <SimpleForm
          save={this.handleSubmit}
          toolbar={
            <SaveButton
              label="Submit"
              submitOnEnter={true}
              style={{verticalAlign: 'center'}}
            />
          }>
          <TextInput source="code" />
        </SimpleForm>
      </Card>
    );
  }
}

const mapStateToProps = state => ({isVerified: state.authReducer.isVerified});

export default compose(connect(mapStateToProps, {verifyUser2FA}))(AuthGoogle);
