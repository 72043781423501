import React, {useEffect, useCallback, useState} from 'react';
import {isEmpty} from 'lodash';
import {translate} from 'react-admin';
import ParametersDisplay from '../../../Parametrage';
import {formatParameters} from '../../../Parametrage/ParametersFactory';
import {Grid, Typography} from '@material-ui/core';
import {Navigation} from '../../Navigation/formTabNavigation';
import {defaultPhaseGroups} from '../../Form/PhaseGroupFactory';
import {connect} from 'react-redux';
import {REDUX_FORM_NAME} from 'ra-core';
import compose from 'recompose/compose';
import {formValueSelector, change} from 'redux-form';
import ProgramPhasesInput from '../../PhaseGroups/ProgramPhasesInput';

const CreateParameters = props => {
  const {phaseGroups, name, category, change, isFahrenheit} = props;

  const [formValid, setFormValid] = useState(true);

  useEffect(() => {
    if (isEmpty(phaseGroups)) {
      change(REDUX_FORM_NAME, 'phaseGroups', defaultPhaseGroups);
    }
  }, [phaseGroups]);

  if (!category) {
    return <div></div>;
  }

  const onChange = useCallback(
    val => {
      change(REDUX_FORM_NAME, 'phaseGroups', val);
    },
    [change],
  );

  return (
    <>
      <Navigation
        description="custom.components.programs.create.fillsettings"
        back="/programs/create/1"
        forward="/programs/create/3"
        disabled={!category || isEmpty(phaseGroups) || !name || !formValid}
      />

      {!category ? (
        <Typography style={{textAlign: 'center'}}>
          {translate('category: validators.program.category.not_blank')}
        </Typography>
      ) : (
        <Grid container direction="column">
          <Grid item>
            {phaseGroups && phaseGroups.length ? (
              <ProgramPhasesInput
                phaseGroups={phaseGroups}
                label="Phases"
                onChange={onChange}
                setFormValid={setFormValid}
                isFahrenheit={isFahrenheit}
              />
            ) : null}
          </Grid>
          <Grid item>
            <ParametersDisplay
              isFahrenheit={isFahrenheit}
              {...formatParameters(phaseGroups, isFahrenheit)}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const selector = formValueSelector(REDUX_FORM_NAME);

const mapStateToProps = ({profileReducer, ...state}) => ({
  name: selector(state, 'name'),
  category: selector(state, 'category'),
  phaseGroups: selector(state, 'phaseGroups'),
  isFahrenheit:
    profileReducer.profile &&
    profileReducer.profile.temperatureUnitPreference === 'F',
});

export default compose(
  translate,
  connect(mapStateToProps, {change}),
)(CreateParameters);
