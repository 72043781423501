export const USER_VERIFY_2FA = 'USER_VERIFY_2FA';
export const USER_VERIFY_2FA_SUCCESS = 'USER_VERIFY_2FA_SUCCESS';
export const USER_VERIFY_2FA_ERROR = 'USER_VERIFY_2FA_ERROR';
export const USER_SET_GAUTH_RESOURCE = 'USER_SET_GAUTH_RESOURCE';
export const USER_SET_GAUTH_SUCCESS = 'USER_SET_GAUTH_SUCCESS';
export const USER_SET_GAUTH_ERROR = 'USER_SET_GAUTH_ERROR';
export const USER_SET_2FA = 'USER_SET_2FA';
export const USER_SET_2FA_SUCCESS = 'USER_SET_2FA_SUCCESS';
export const USER_SET_2FA_ERROR = 'USER_SET_2FA_ERROR';

export const verifyUser2FA = data => ({
  type: USER_VERIFY_2FA,
  payload: data,
});

export const setGAuth = data => ({
  type: USER_SET_GAUTH_RESOURCE,
  payload: data,
});

export const set2FA = data => ({
  type: USER_SET_2FA,
  payload: data,
});
