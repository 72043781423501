import forEach from 'lodash/forEach';
import structuresOverride from './components/Structure/override';
import programsOverride from './components/Program/override';
import tagsOverride from './components/Tag/override';
import machinesOverride from './components/Machine/override';
import usersOverride from './components/User/override';

const getRessource = (resources, resourceName) =>
  resources.find(r => resourceName === r.name);

const overrideDefined = {
  machines: machinesOverride,
  programs: programsOverride,
  structures: structuresOverride,
  tags: tagsOverride,
  users: usersOverride,
};

export default resources => {
  forEach(overrideDefined, (override, resourceName) => {
    override(getRessource(resources, resourceName));
  });
};
