import React from 'react';
import {ReferenceArrayInput} from 'react-admin';
import Label from '../Label';
import '../../assets/program-create.css';

const TagList = () => {
  return (
    <ReferenceArrayInput source="tags" reference="tags" perPage={100}>
      <Label />
    </ReferenceArrayInput>
  );
};

export default TagList;
