import React, {Component, useEffect, useState, useMemo} from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import {List} from '../Common/Admin';
import StructureActions from '../ActionsCards/StructureActions';
import Title from '../Common/Header';
import {hasRoles} from '../../utils/auth';
import {AddButton} from '../Buttons';
import {TopBarList, Breadcrumb} from '../TopBar';
import {withStyles} from '@material-ui/core/styles';
import {StructureFilters} from '../ActionsCards/CustomFilter';
import {
  Datagrid,
  BulkDeleteWithConfirmButton,
  DatagridBody,
  TextField,
} from 'react-admin';
import DatagridRow from './Datagrid/DatagridRow';
import {client} from '../../utils/api-client';

const PostBulkActionButtons = ({selectedIds}) => (
  <>
    {/* default bulk delete action */}
    {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_DELETE']) && (
      <BulkDeleteWithConfirmButton
        resource="structures"
        selectedIds={selectedIds}
      />
    )}
  </>
);

const MyDatagridBody = props => {
  return (
    <DatagridBody
      {...props}
      row={
        <DatagridRow selectedIds={props.selectedIds} filter={props.filter} />
      }
    />
  );
};

// styles custom pour le header de la datagrid.
const gridStyles = {
  datagrid: {
    '& th .MuiCheckbox-root': {
      visibility: 'hidden',
    },
    '& thead > tr > th:first-child': {
      width: '49px',
    },
    '& thead > tr > th:nth-child(2)': {
      width: '46%',
    },
    '& thead > tr > th:nth-child(3)': {
      width: '29%',
      textAlign: 'center',
    },
    '& thead > tr > th:nth-child(4)': {
      textAlign: 'center',
    },
  },
};

const MyDatagridContainer = props =>
  props.isLoading ? (
    <div>
      <CircularProgress size={24} />
    </div>
  ) : (
    <MyDatagrid {...props} />
  );

const MyDatagrid = compose(
  withRouter,
  withStyles(gridStyles),
)(props => {
  const [fullStructures, setFullStructures] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    perPage,
    page,
    currentSort,
    filterValues,
    selectedIds,
    isLoading,
  } = props;

  useEffect(() => {
    if (isLoading && isLoaded === true) {
      setFullStructures([]);
      setIsLoaded(false);
    }
  }, [isLoading]);

  useEffect(() => {
    const fetchStructures = async () => {
      const filters =
        Object.values(filterValues).length === 0 ? {lvl: 0} : filterValues;
      const params = {
        ...filters,
        [`order[${currentSort.field}]`]: currentSort.order,
        page,
        itemsPerPage: perPage,
      };

      const searchParams = new URLSearchParams(params);
      const queryString = searchParams.toString();

      const response = await client(`/structures?${queryString}`);
      setIsLoaded(true);
      setFullStructures(response['hydra:member']);
    };
    if (!isLoaded) {
      fetchStructures();
    }
  }, [perPage, page, currentSort, filterValues, isLoaded]);

  const fullData = useMemo(() => {
    if (!isLoaded) return {};
    return fullStructures.reduce((acc, structure) => {
      acc[structure['@id']] = structure;
      return acc;
    }, {});
  }, [fullStructures, isLoaded]);

  const {classes, ...rest} = props;

  return (
    <Datagrid
      {...rest}
      data={fullData}
      ids={isLoaded ? Object.keys(fullData) : props.ids}
      className={classes.datagrid}
      body={<MyDatagridBody selectedIds={selectedIds} filter={filterValues} />}>
      <TextField source="name" />
      <TextField source="users" />
      <TextField source="machines" />
    </Datagrid>
  );
});

class StructureList extends Component {
  render() {
    const {location, selectedIds} = this.props;

    if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_ACCESS'])) {
      window.location.replace('/#/not-granted');
    }

    return (
      <>
        <TopBarList>
          <Breadcrumb categoryName="custom.components.menu.structures" />
          <div>
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_DELETE']) && (
              <>
                {selectedIds.length ? (
                  <BulkDeleteWithConfirmButton
                    resource="structures"
                    selectedIds={selectedIds}
                  />
                ) : null}
              </>
            )}
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_CREATE']) && (
              <AddButton
                basePath="/structures"
                roles={['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_CREATE']}
              />
            )}
          </div>
        </TopBarList>
        <List
          filters={<StructureFilters />}
          filter={{lvl0Only: true}}
          title={
            <Title title="custom.components.menu.structures" {...this.props} />
          }
          actions={
            hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_ACCESS']) && (
              <StructureActions location={location} />
            )
          }
          bulkActionButtons={
            <PostBulkActionButtons
              resource="structures"
              selectedIds={selectedIds}
            />
          }
          {...this.props}>
          <MyDatagridContainer selectedIds={selectedIds} />
          {/*<StructureDatagrid />*/}
        </List>
      </>
    );
  }
}

const mapStateToProps = ({admin}) => {
  return {
    selectedIds: admin.resources.structures.list.selectedIds,
  };
};

export default connect(mapStateToProps, {})(StructureList);
