import {useMemo, useCallback, useEffect} from 'react';
import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import CriteriaInput from './CriteriaInput';
import {Steam, Duration} from '../../Buttons/SVG';
import CriteriaLine from './CriteriaLine';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {formatSteamPhase} from './utils';

export default function PhaseGroupSteam({phase, onChange, totalDuration}) {
  const limits = {
    start: {
      min: 0,
      max: totalDuration - 1,
    },
    volume: {
      min: 2,
      max: 10,
    },
  };

  const phases = useMemo(() => formatSteamPhase(phase), [phase]);

  const parse = phases => ({
    phaseGroupType: 'steam',
    phases: phases.map((p, index) => ({
      position: index,
      values: [
        {
          criteria: 'start',
          value: p.start * 60,
        },
        {
          criteria: 'volume',
          value: p.volume,
        },
      ],
    })),
  });

  useEffect(() => {
    if (!phases.length) return;
    const newPhases = phases.filter(p => p.start <= totalDuration - 1);
    if (newPhases.length === phases.length) return;
    const result = parse(newPhases);
    onChange(result);
  }, [phase, totalDuration, onChange]);

  const handleChange = useCallback(
    (index, attribute) => value => {
      const newPhases = [...phases];
      let newValue = parseInt(value, 10);
      newPhases[index][attribute] = newValue;

      const result = parse(newPhases);

      onChange(result);
    },
    [phases, onChange],
  );

  const addPhase = useCallback(
    index => event => {
      event.preventDefault();
      const newPhases = [...phases];
      newPhases.splice(index + 1, 0, {
        start: phases.length ? phases[index].start + 1 : 0,
        volume: 2,
      });
      const result = parse(newPhases);
      onChange(result);
    },
    [phases, onChange],
  );

  const removePhase = useCallback(
    index => event => {
      event.preventDefault();
      const newPhases = [...phases];
      newPhases.splice(index, 1);
      const result = parse(newPhases);
      onChange(result);
    },
    [phases, onChange],
  );

  return (
    <Grid container spacing={24} style={{marginBottom: 3 + 'em'}}>
      <Grid item>
        <Typography>
          <Steam isColored />
          <sup>{phases.length}</sup>
        </Typography>
      </Grid>
      <Grid item>
        {phases.length === 0 && totalDuration > 0 ? (
          <Button
            onClick={addPhase(0)}
            color="primary"
            variant={'contained'}
            aria-label="Add">
            <AddIcon />
          </Button>
        ) : null}
        {phases.map((p, index) => {
          const minStart = index === 0 ? 0 : phases[index - 1].start + 1;
          const maxStart =
            index === phases.length - 1
              ? limits.start.max
              : phases[index + 1].start - 1;
          return (
            <CriteriaLine
              isLast={index === phases.length - 1}
              onAdd={addPhase(index)}
              onRemove={removePhase(index)}
              notification={p.notification}
              withNotification={false}
              canAdd={
                index === phases.length - 1
                  ? p.start < totalDuration - 1
                  : phases[index + 1].start > p.start + 1
              }
              canDelete={true}>
              <CriteriaInput
                min={minStart}
                max={maxStart}
                unit="min"
                Icon={Duration}
                value={p.start}
                onChange={handleChange(index, 'start')}
              />
              <CriteriaInput
                min={limits.volume.min}
                max={limits.volume.max}
                unit="cl"
                Icon={Steam}
                value={p.volume}
                onChange={handleChange(index, 'volume')}
              />
            </CriteriaLine>
          );
        })}
      </Grid>
    </Grid>
  );
}
