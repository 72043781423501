import * as actions from './action';

const initialState = {
  userGallery: null,
  image: null,
  isLoaded: true,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.IMAGES_GET_GALLERY:
      return {
        ...state,
        isLoaded: false,
      };
    case actions.IMAGES_GET_GALLERY_SUCCESS:
      return {
        ...state,
        userGallery: payload,
        isLoaded: true,
      };
    case actions.IMAGES_DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        userGallery: payload,
        isLoaded: true,
      };
    case actions.IMAGE_ME_SEND_SUCCESS:
      return {
        ...state,
        userGallery: payload.gallery,
        image: payload.item,
        isLoaded: true,
      };
    case actions.IMAGES_RESET:
      return {
        ...state,
        image: null,
      };
    default:
      return state;
  }
};
