import React from 'react';
import {AssignButton} from '../Buttons';
import {hasRoles} from '../../utils/auth';
import CustomActions from './CustomActions';

export default props => {
  const buttons = [];
  if (hasRoles(['ROLE_PROGRAM_ACCESS', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton target="programs" assign roles={['ROLE_PROGRAM_ACCESS']} />,
    );
    buttons.push(
      <AssignButton target="programs" roles={['ROLE_PROGRAM_ACCESS']} />,
    );
  }
  return <CustomActions buttons={buttons} {...props} />;
};
