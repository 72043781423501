import React from 'react';
import {
  AssignButton,
  ImportButton,
  BooleanButton,
  ApproveButton,
  DisApproveButton,
} from '../Buttons';
import ExportButton from '../Common/Exporter';
import CustomActions from './CustomActions';
import {hasRoles} from '../../utils/auth';

export default props => {
  let buttons = [];
  if (hasRoles(['ROLE_MACHINE_INSTALL_PROGRAM', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton
        target="machines"
        assign
        roles={['ROLE_MACHINE_INSTALL_PROGRAM']}
      />,
    );
  }
  if (hasRoles(['ROLE_PROGRAM_IMPORT_EXPORT', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(<ImportButton roles={['ROLE_PROGRAM_IMPORT_EXPORT']} />);
  }
  if (hasRoles(['ROLE_TAG_ACCESS', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton target="tags" assign roles={['ROLE_TAG_ACCESS']} />,
    );
  }
  if (hasRoles(['ROLE_PROGRAM_APPROVE_ACTION', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <ApproveButton
        property="approved"
        switchOn
        roles={['ROLE_PROGRAM_APPROVE_ACTION']}
      />,
    );
  }
  if (hasRoles(['ROLE_MACHINE_INSTALL_PROGRAM', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton
        target="machines"
        roles={['ROLE_MACHINE_INSTALL_PROGRAM']}
      />,
    );
  }
  if (hasRoles(['ROLE_PROGRAM_IMPORT_EXPORT', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(<ExportButton roles={['ROLE_PROGRAM_IMPORT_EXPORT']} />);
  }
  if (hasRoles(['ROLE_TAG_ACCESS', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(<AssignButton target="tags" roles={['ROLE_TAG_ACCESS']} />);
  }
  if (hasRoles(['ROLE_PROGRAM_APPROVE_ACTION', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <DisApproveButton
        property="approved"
        roles={['ROLE_PROGRAM_APPROVE_ACTION']}
      />,
    );
  }
  return <CustomActions buttons={buttons} {...props} />;
};
