import React, {Component, Fragment} from 'react';
import {
  TextInput,
  FormTab,
  ReferenceInput,
  RadioButtonGroupInput,
  Toolbar,
  SaveButton as BaseSaveButton,
  required,
  minLength,
  maxLength,
  translate,
  TabbedForm,
  LongTextInput,
} from 'react-admin';
import {REDUX_FORM_NAME} from 'ra-core';
import {Grid, Typography, Button} from '@material-ui/core';
import {getFormValues, formValueSelector, change} from 'redux-form';
import {connect} from 'react-redux';
import {Navigation} from './Navigation/formTabNavigation';
import {compose} from 'recompose';
import Images from './Image';
import Title from '../Common/Header';
import {Create} from '../Common/Admin';
import CreateParameters from './view/create/createParameters';
import '../../assets/program-create.css';
import {hasRoles} from '../../utils/auth';
import {createTag, getTags} from '../Tag/store/action';
import TagList from '../Tag/TagList';
import {resetCreatedTag} from './store/action';

const SaveView = translate(({translate, ...props}) => {
  const canApprove = hasRoles([
    'ROLE_CLIENT_ADMIN',
    'ROLE_PROGRAM_APPROVE_ACTION',
  ]);

  return (
    <Grid container className="save-toolbar" spacing={16}>
      <Grid item xs={12}>
        {
          <Typography>
            {translate(`custom.components.programs.create.save_ask`)}
          </Typography>
        }
      </Grid>
      <SaveButton canApprove={canApprove} approve={false} {...props} />
      <SaveButton canApprove={canApprove} approve={true} {...props} />
    </Grid>
  );
});

const SaveButton = translate(
  ({translate, approve, canApprove, values, ...rest}) => (
    <Grid item xs={canApprove ? 6 : 12}>
      {((approve && canApprove) || !approve) && (
        <>
          <BaseSaveButton
            onMouseOverCapture={() => {
              values.approved = approve;
            }}
            fullWidth
            variant="flat"
            label={`ra.message.${approve ? 'yes' : 'no'}`}
            {...rest}
          />
          <Typography>
            {!approve && !canApprove
              ? translate(`custom.components.programs.create.cantApprove`)
              : translate(
                  `custom.components.programs.create.save_${
                    approve ? 'approve' : 'disapprove'
                  }`,
                )}
          </Typography>
        </>
      )}
    </Grid>
  ),
);

const CategoryRadioButtonGroupInput = ({
  setFilter,
  setPagination,
  setSort,
  ...props
}) => {
  return <RadioButtonGroupInput {...props} optionText="name" />;
};

const CenteredCategoryTabContent = props => {
  const styles = () => ({
    categoryList: {
      display: 'flex',
      maxWidth: '75%',
      margin: 'auto',
    },
  });
  const validateCategory = [required()];
  return (
    <Grid container alignItems="center" justify="center">
      <Grid item>
        <ReferenceInput
          {...props}
          source="category"
          reference="categories"
          className={styles.categoryList}
          validate={validateCategory}>
          <CategoryRadioButtonGroupInput />
        </ReferenceInput>
      </Grid>
    </Grid>
  );
};

const validateName = [required(), maxLength(40)];
const validateDescription = [maxLength(144)];

const CenteredInfoTabContent = translate(({translate, props, tagListKey}) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      spacing={40}>
      <Grid item>
        <TextInput
          source="name"
          label={translate('resources.programs.fields.name')}
          validate={validateName}
          {...props}
        />
      </Grid>
      <Grid item>
        <TextInput
          source="description"
          validate={validateDescription}
          {...props}
        />
      </Grid>
      <Grid item>
        <Images />
      </Grid>
      <Grid item>
        <TagList key={tagListKey} {...props} />
      </Grid>
    </Grid>
  );
});

class ProgramCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagNameValue: '',
      tagDescriptionValue: '',
      tagListKey: 0,
    };
  }

  handleClick = (tagNameValue, tagDescriptionValue) => async () => {
    await this.props.createTag({
      name: tagNameValue,
      description: tagDescriptionValue,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tags.length !== this.props.tags.length) {
      this.setState(prevState => ({
        tagListKey: prevState.tagListKey + 1,
      }));
    }
    if (this.props.tagCreated) {
      this.props.change(REDUX_FORM_NAME, 'tags', [
        ...this.props.values.tags,
        this.props.tagCreated,
      ]);
      this.props.resetCreatedTag();
    }
  }

  render() {
    const {tagListKey} = this.state;
    const {values, translate, tags, ...rest} = this.props;
    const {location, category, name} = rest;

    if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_CREATE'])) {
      window.location.replace('/#/not-granted');
    }

    const validateTagName = [minLength(1)];
    const validateTagDescription = [minLength(1), maxLength(255)];

    const currentTab =
      location.pathname.split('/').length > 3 &&
      location.pathname.split('/')[3];

    return (
      <Create
        actions={<Fragment />}
        title={
          <Title
            title="custom.components.programs.create.title"
            redirect="list"
            {...rest}
          />
        }
        {...rest}>
        <TabbedForm
          toolbar={
            location.pathname.split('/').length > 3 &&
            location.pathname.split('/')[3] === '3' ? (
              <Toolbar>
                <SaveView values={values} />
              </Toolbar>
            ) : (
              <Fragment />
            )
          }
          submitOnEnter={false}
          defaultValue={{tags: [], phaseGroups: []}}
          redirect="list">
          <FormTab label="custom.components.programs.create.choiceMachine">
            <Navigation
              description="custom.components.programs.create.choiceTypeMachine"
              forward="/programs/create/1"
              disabled={!category}
            />
            {!currentTab ? <CenteredCategoryTabContent /> : null}
          </FormTab>
          <FormTab label="custom.components.programs.create.identify">
            <Navigation
              description="custom.components.programs.create.name"
              back="/programs/create"
              forward="/programs/create/2"
              disabled={!name}
            />
            {currentTab === '1' ? (
              <CenteredInfoTabContent tagListKey={tagListKey} />
            ) : null}
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAG_CREATE']) && (
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                spacing={40}>
                <Grid item>
                  <Typography>
                    {translate('custom.components.tags.create.title')}
                  </Typography>
                  <TextInput
                    source="tagName"
                    label={translate('resources.tags.fields.name')}
                    validate={validateTagName}
                    onChange={e =>
                      this.setState({tagNameValue: e.target.value})
                    }
                  />
                  <LongTextInput
                    source="tagDescription"
                    label={translate('custom.commons.description')}
                    validate={validateTagDescription}
                    onChange={e =>
                      this.setState({tagDescriptionValue: e.target.value})
                    }
                  />
                  <Button
                    disabled={!this.state.tagNameValue}
                    color="primary"
                    variant="contained"
                    onClick={this.handleClick(
                      this.state.tagNameValue,
                      this.state.tagDescriptionValue,
                    )}>
                    {translate('custom.buttons.assign.tags.programs.label')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </FormTab>
          <FormTab label="custom.components.programs.create.settings">
            {category && currentTab === '2' && <CreateParameters />}
          </FormTab>
          <FormTab label="custom.components.programs.create.save">
            <Navigation
              description="custom.components.programs.create.save_desc"
              back="/programs/create/2"
            />
          </FormTab>
        </TabbedForm>
      </Create>
    );
  }
}

const selector = formValueSelector(REDUX_FORM_NAME);

const mapStateToProps = state => {
  const mapStateToPropsResult = {
    values: getFormValues(REDUX_FORM_NAME)(state),
    category: selector(state, 'category'),
    name: selector(state, 'name'),
    tags: state.tagReducer.tags,
    tagCreated: state.programReducer.tagCreated,
  };
  return mapStateToPropsResult;
};

export default compose(
  connect(mapStateToProps, {
    createTag,
    getTags,
    change,
    resetCreatedTag,
  }),
  translate,
)(ProgramCreate);
