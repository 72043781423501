import * as actions from './action';

const initialState = {
  isUpdated: null,
};

export default (state = initialState, {type}) => {
  switch (type) {
    case actions.IMAGE_SEND_SUCCESS:
      return {
        ...state,
        isUpdated: true,
      };
    case actions.IMAGE_SENDING:
    case actions.IMAGE_USER_SEND:
      return {
        ...state,
        isUpdated: 'sending',
      };
    case actions.IMAGE_INIT:
      return {
        ...state,
        isUpdated: 'ready',
      };
    case actions.IMAGE_RESET:
    case actions.IMAGE_USER_SEND_SUCCESS:
      return {
        ...state,
        isUpdated: null,
      };
    case actions.IMAGE_SEND_FAILURE:
    case actions.IMAGE_USER_SEND_FAILURE:
      return {
        ...state,
        isUpdated: false,
      };
    default:
      return state;
  }
};
