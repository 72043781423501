import authReducer from '../components/AuthGoogle/store/reducer';
import commonReducer from '../components/Common/store/reducer';
import createTechnicianReducer from '../components/CreateTechnician/store/reducer';
import exportReducer from '../components/Common/Exporter/store/reducer';
import getUsersAcceptEFControlReducer from '../components/SelectUser/store/reducer';
import historyReducer from '../components/History/store/reducer';
import imageReducer from '../components/ImageUploader/store/reducer';
import imagesEurofoursReducer from '../components/ImagesEurofours/store/reducer';
import imagesMachinesReducer from '../components/ImagesMachines/store/reducer';
import machineReducer from '../components/Machine/store/reducer';
import passwordReducer from '../components/Passwords/store/reducer';
import phaseGroupTypeReducer from '../components/PhaseGroupType/store/reducer';
import profileReducer from '../components/Profile/store/reducer';
import programReducer from '../components/Program/store/reducer';
import registerReducer from '../components/AccountCreation/store/reducer';
import softwareUpdatesReducer from '../components/SoftwareUpdates/store/reducer';
import structureReducer from '../components/Structure/store/reducer';
import tagReducer from '../components/Tag/store/reducer';
import tokenVerifyReducer from '../components/Activate/store/reducer';
import userGalleryReducer from '../components/UserGallery/store/reducer';
import userReducer from '../components/User/store/reducer';

export default {
  authReducer,
  commonReducer,
  createTechnicianReducer,
  exportReducer,
  getUsersAcceptEFControlReducer,
  historyReducer,
  imageReducer,
  imagesEurofoursReducer,
  imagesMachinesReducer,
  machineReducer,
  passwordReducer,
  phaseGroupTypeReducer,
  profileReducer,
  programReducer,
  registerReducer,
  softwareUpdatesReducer,
  structureReducer,
  tagReducer,
  tokenVerifyReducer,
  userGalleryReducer,
  userReducer,
};
