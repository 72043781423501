export const GET_NB_RESOURCES_TO_APPROVE_REQUEST =
  'GET_NB_RESOURCES_TO_APPROVE_REQUEST';
export const GET_NB_RESOURCES_TO_APPROVE_SUCCESS =
  'GET_NB_RESOURCES_TO_APPROVE_SUCCESS';
export const UPDATE_ONE = 'USER_UPDATE_ONE';
export const UPDATE_MANY_REQUEST = 'UPDATE_MANY_REQUEST';
export const USER_UPDATE_ONE_SUCCESS = 'USER_UPDATE_ONE_SUCCESS';
export const MACHINE_UPDATE_ONE_SUCCESS = 'MACHINE_UPDATE_ONE_SUCCESS';
export const STRUCTURE_UPDATE_ONE_SUCCESS = 'STRUCTURE_UPDATE_ONE_SUCCESS';

export const updateItem = data => ({
  type: UPDATE_ONE,
  payload: data,
});

export const updateMany = data => ({
  type: UPDATE_MANY_REQUEST,
  payload: data,
});

export const getNbResourcesToApprove = data => ({
  type: GET_NB_RESOURCES_TO_APPROVE_REQUEST,
  payload: data,
});
