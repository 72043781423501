import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {
  TAGS_GET_ALL_REQUEST,
  TAGS_GET_ALL_SUCCESS,
  TAGS_GET_COUNT_REQUEST,
  TAGS_GET_COUNT_REQUEST_SUCCESS,
  TAGS_UPDATE_ONE_RELATED_USERS_REQUEST,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  TAG_GET_ONE_RESOURCE,
  TAG_GET_ONE_SUCCESS,
} from '../components/Tag/store/action';
import {errorMessageHandler} from './helpers';
import {client} from '../utils/api-client';

function* handleTag(action) {
  const {type, payload} = action;
  switch (type) {
    case TAG_GET_ONE_RESOURCE: {
      try {
        const response = yield client(`tags/${payload}`);
        yield put({
          type: TAG_GET_ONE_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case TAGS_GET_ALL_REQUEST: {
      try {
        const response = yield client('tags');
        yield put({
          type: TAGS_GET_ALL_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case TAGS_GET_COUNT_REQUEST: {
      try {
        const response = yield client('tags');
        yield put({
          type: TAGS_GET_COUNT_REQUEST_SUCCESS,
          payload: response['hydra:totalItems'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case TAGS_UPDATE_ONE_RELATED_USERS_REQUEST: {
      try {
        yield client(payload.id, {
          data: {relatedUsers: payload.relatedUsers},
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case CREATE_TAG: {
      try {
        const response = yield client(`tags`, {data: payload, method: 'POST'});
        yield put({
          type: CREATE_TAG_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    default:
      break;
  }
}

export default function* watchTagsActions() {
  yield all([takeEvery(TAGS_GET_ALL_REQUEST, handleTag)]);
  yield all([takeEvery(TAGS_GET_COUNT_REQUEST, handleTag)]);
  yield all([takeEvery(TAGS_UPDATE_ONE_RELATED_USERS_REQUEST, handleTag)]);
  yield all([takeEvery(CREATE_TAG, handleTag)]);
  yield all([takeEvery(TAG_GET_ONE_RESOURCE, handleTag)]);
}
