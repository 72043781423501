import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  translate,
  Link,
  SimpleShowLayout,
  TextField,
  FunctionField,
} from 'react-admin';
import {
  Grid,
  withStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {Lock, LockOpen} from '@material-ui/icons';
import {connect} from 'react-redux';
import {linkToRecord} from 'ra-core';
import compose from 'recompose/compose';
import {resetChildPassword} from '../../Passwords/store/action';
import TabsUser from '../Tabs';
import Avatar from '../../Common/Avatar';
import '../../../assets/show.css';

const styles = theme => ({
  lockOpen: {
    fontSize: 2 + 'em',
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
  },
  floatRight: {
    float: 'right',
    marginRight: 1 + 'em',
  },
  contentRight: {
    position: 'relative',
    '& > .edit-info': {
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
  },
  topSpacing: {
    marginTop: 1 + 'rem',
  },
  resetPassword: {
    color: 'red',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

class UserShowView extends Component {
  handleResetPassword = () => {
    const {
      resetChildPasswordRequest,
      record: {email},
    } = this.props;

    resetChildPasswordRequest(email);
  };

  render() {
    const {
      classes,
      translate,
      resetChildPasswordRequest,
      loading,
      ...rest
    } = this.props;
    const {record, basePath} = rest;

    return (
      <div>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item xs={9} className="show-info">
                <Grid container direction="column">
                  <Grid item>
                    <Grid container className={classes.topSpacing}>
                      <Grid item xs={6}>
                        <SimpleShowLayout {...rest}>
                          <FunctionField
                            source="email"
                            render={user => (
                              <Fragment>
                                <span>{user.email}</span>
                                <br />
                                {loading ? (
                                  <CircularProgress />
                                ) : (
                                  <span
                                    className={classes.resetPassword}
                                    onClick={this.handleResetPassword}>
                                    {translate(
                                      'custom.components.users.resetPassword',
                                    )}
                                  </span>
                                )}
                              </Fragment>
                            )}
                          />
                          <FunctionField
                            source="lastName"
                            render={user =>
                              `${user.firstName} ${user.lastName}`
                            }
                          />
                          <TextField source="society" />
                          <FunctionField
                            source="description"
                            render={({description}) =>
                              !description
                                ? translate(
                                    'custom.components.users.noDescription',
                                  )
                                : description
                            }
                          />
                        </SimpleShowLayout>
                      </Grid>
                      <Grid item xs className={classes.contentRight}>
                        <div className={classes.floatRight}>
                          {record.googleAuthenticatorConfirmed ? (
                            <Lock
                              style={{
                                fontSize: 2 + 'em',
                                color: '#92C020',
                                verticalAlign: 'middle',
                              }}
                            />
                          ) : (
                            <LockOpen className={classes.lockOpen} />
                          )}
                        </div>
                        <SimpleShowLayout {...rest}>
                          <FunctionField
                            source="address"
                            render={({street, zipcode, city, country}) =>
                              !street && !zipcode && !city && !country ? (
                                translate('custom.components.users.noAddress')
                              ) : (
                                <Fragment>
                                  {street}
                                  <br />
                                  {zipcode}
                                  <br />
                                  {city}
                                  <br />
                                  {country}
                                </Fragment>
                              )
                            }
                          />
                          <FunctionField
                            source="phone"
                            render={({phone}) =>
                              !phone
                                ? translate('custom.components.users.noPhone')
                                : phone
                            }
                          />
                        </SimpleShowLayout>
                        <div className="edit-info">
                          <Link to={linkToRecord(basePath, record.id)}>
                            <Typography>
                              {translate('custom.commons.edit')}
                            </Typography>
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Avatar image={record.imageBase64} type="user" data={record} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TabsUser basePath={basePath} user={record} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

UserShowView.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  basePath: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({passwordReducer}) => ({
  loading: passwordReducer.loading,
});

export default compose(
  translate,
  connect(mapStateToProps, {resetChildPasswordRequest: resetChildPassword}),
  withStyles(styles),
)(UserShowView);
