import React from 'react';
import PropTypes from 'prop-types';
import {Grid, TableCell, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {translate} from 'react-admin';
import NbMachineView from '../../Structure/view/NbMachineView';
import {hasRoles} from '../../../utils/auth';
import {compose} from 'recompose';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  parents: {
    color: theme.palette.grey[500],
    fontSize: '11px',
  },
});

const sanitizeRestProps = ({
  cellClassName,
  field,
  formClassName,
  headerClassName,
  record,
  basePath,
  resource,
  translate,
  ...rest
}) => rest;

export const DatagridCell = ({
  field,
  record,
  basePath,
  resource,
  translate,
  relatedUsers,
  classes,
  withParents,
  ...rest
}) => {
  function formatStructures(structure, render) {
    return structure.parent
      ? `${formatStructures(
          structure.parent,
          `${structure.parent.name + ' > ' + render}`,
        )}`
      : render;
  }

  const depth = record.depth || 0;

  return (
    <div
      style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      {...sanitizeRestProps(rest)}>
      <Grid container style={{flexWrap: 'nowrap', cursor: 'default'}}>
        <Grid item xs={6} className="border-right" style={{display: 'flex'}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '4px',
              justifyContent: 'center',
            }}>
            {withParents && record.parent ? (
              <span className={classes.parents}>
                {formatStructures(record.parent, `${record.parent.name}`)}
              </span>
            ) : (
              ''
            )}
            {hasRoles(['ROLE_EUROFOURS_TECHNICIAN']) ? (
              <span style={withParents ? {} : {marginLeft: 30 * depth}}>
                {' '}
                {record.name}{' '}
              </span>
            ) : (
              <span style={withParents ? {} : {marginLeft: 30 * depth}}>
                <Link
                  to={`/structures/${encodeURIComponent(record['@id'])}/show`}>
                  {record.name}
                </Link>
              </span>
            )}
          </div>
        </Grid>
        {record.relatedUsers ? (
          <Grid
            item
            xs={4}
            className="border-right"
            style={{
              display: 'flex',
              padding: '4px',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <div className="v-center">
              <span>
                {record.relatedUsers.length > 1
                  ? `${record.relatedUsers.length} ${translate(
                      'resources.structures.fields.users',
                    )}`
                  : `${
                      record.relatedUsers.length
                        ? `${record.relatedUsers[0].firstName} ${record.relatedUsers[0].lastName}`
                        : `-`
                    }`}
              </span>
            </div>
          </Grid>
        ) : null}
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            padding: '4px',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <NbMachineView structure={record} withSubStructures={!withParents} />
        </Grid>
      </Grid>
    </div>
  );
};

DatagridCell.propTypes = {
  className: PropTypes.string,
  field: PropTypes.element,
  record: PropTypes.object,
  basePath: PropTypes.string,
  resource: PropTypes.string,
};

DatagridCell.displayName = 'DatagridCell';

export default compose(translate, withStyles(styles))(DatagridCell);
