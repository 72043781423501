import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getProfile} from '../Profile/store/action';
import {Link} from 'react-router-dom';
import {setGAuth, set2FA} from '../AuthGoogle/store/action';
import {Card, Checkbox, FormControlLabel, Grid} from '@material-ui/core';
import {Lock, LockOpen, Close} from '@material-ui/icons';
import {TextInput, SimpleForm, translate} from 'react-admin';
import {compose} from 'recompose';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import {TopBarList, Breadcrumb} from '../TopBar';
import Avatar from '../Common/Avatar';
import {updateOne} from '../Profile/store/action';
import Loading from '../Loading';
import {hasRoles} from '../../utils/auth';

import google_play from '../../assets/google_play.svg';
import app_store from '../../assets/app_store.svg';
import {displayDatePreferenceValues} from '../../constants/displayDatePreference';

const styles = ({spacing, palette}) => ({
  root: {
    margin: 0,
    padding: spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: spacing.unit,
    top: spacing.unit,
    color: palette.grey[500],
  },
  topSpacing: {
    marginTop: 1 + 'rem',
  },
  labelInfos: {
    fontSize: 1.2 + 'em',
    fontWeight: 'bold',
    display: 'block',
  },
  infosSeparator: {
    padding: 1 + 'em',
  },
  location: {
    display: 'block',
  },
  elementsRights: {
    paddingTop: 1 + 'em',
    paddingBottom: 1 + 'em',
  },
  titleUnderline: {
    borderBottom: 1 + 'px solid black',
    paddingTop: 1 + 'em',
    fontSize: 1.3 + 'em',
    marginBottom: 0.7 + 'em',
  },
  dialogIcons: {
    padding: 1 + 'em',
  },
});

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      notifications: [
        {
          main: 'custom.components.profile.notificationsEmail.main',
          items: [
            {
              title:
                'custom.components.profile.notificationsEmail.problems.title',
              enabled:
                'custom.components.profile.notificationsEmail.problems.enabledDescription',
              disabled:
                'custom.components.profile.notificationsEmail.problems.disabledDescription',
              value: 'notificationProblems',
            },
            {
              title:
                'custom.components.profile.notificationsEmail.programs.title',
              enabled:
                'custom.components.profile.notificationsEmail.programs.enabledDescription',
              disabled:
                'custom.components.profile.notificationsEmail.programs.disabledDescription',
              value: 'notificationPrograms',
            },
            {
              title: 'custom.components.profile.notificationsEmail.tags.title',
              enabled:
                'custom.components.profile.notificationsEmail.tags.enabledDescription',
              disabled:
                'custom.components.profile.notificationsEmail.tags.disabledDescription',
              value: 'notificationTags',
            },
            {
              title:
                'custom.components.profile.notificationsEmail.structures.title',
              enabled:
                'custom.components.profile.notificationsEmail.structures.enabledDescription',
              disabled:
                'custom.components.profile.notificationsEmail.structures.disabledDescription',
              value: 'notificationStructures',
            },
            {
              title:
                'custom.components.profile.notificationsEmail.machines.title',
              enabled:
                'custom.components.profile.notificationsEmail.machines.enabledDescription',
              disabled:
                'custom.components.profile.notificationsEmail.machines.disabledDescription',
              value: 'notificationMachines',
            },
          ],
        },
      ],
    };
  }

  componentDidMount() {
    this.props.getProfile();
  }

  toggleGAuth = () => {
    const {
      GAuth: {qrCodeUrl},
      profile: {googleAuthenticatorConfirmed},
    } = this.props;

    if (!googleAuthenticatorConfirmed) {
      this.setState({open: true});
    }

    if (qrCodeUrl) {
      return;
    }

    this.props.setGAuth({
      enabled: !googleAuthenticatorConfirmed,
    });
  };

  handleSave = ({GAuth}) => {
    this.props.set2FA(GAuth);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleChange = (event, checked) => {
    this.props.updateOne({[event.target.value]: checked}, '/profile');
  };

  render() {
    const {
      classes,
      GAuth: {qrCodeUrl},
      profile,
      translate,
      loading,
    } = this.props;

    if (!profile) {
      return <Loading />;
    }

    const {notifications, open} = this.state;
    const {
      googleAuthenticatorConfirmed,
      email,
      firstName,
      lastName,
      society,
      description,
      street,
      zipcode,
      city,
      country,
      phone,
      imageBase64,
      displayDatePreference,
    } = profile;

    return (
      <>
        <TopBarList>
          <Breadcrumb
            label={`${profile.firstName} ${profile.lastName}`}
            backLink="/#/"
          />
        </TopBarList>
        <Card square>
          <Grid container className={classes.topSpacing} alignItems="center">
            <Grid
              item
              xs={8}
              style={{backgroundColor: 'WhiteSmoke', borderRadius: 5 + 'px'}}>
              <Grid container>
                <Grid item xs={12} style={{position: 'relative'}}>
                  <span
                    style={{
                      position: 'absolute',
                      cursor: 'pointer',
                      right: 1 + 'em',
                      top: 1 + 'em',
                    }}
                    onClick={this.toggleGAuth}>
                    {googleAuthenticatorConfirmed ? (
                      <Lock
                        style={{
                          fontSize: 2 + 'em',
                          color: '#92C020',
                          verticalAlign: 'middle',
                        }}
                      />
                    ) : (
                      <LockOpen
                        style={{
                          fontSize: 2 + 'em',
                          color: '#EF4548',
                          verticalAlign: 'middle',
                        }}
                      />
                    )}
                  </span>
                  {!googleAuthenticatorConfirmed && (
                    <Dialog
                      open={open}
                      onClose={this.handleClose}
                      aria-labelledby="GAuth">
                      <DialogTitle id="GAuth" className={classes.root}>
                        <Fragment>
                          {translate('custom.components.gauth.2fa')}
                          <IconButton
                            aria-label="Close"
                            className={classes.closeButton}
                            onClick={this.handleClose}>
                            <Close />
                          </IconButton>
                        </Fragment>
                      </DialogTitle>
                      <DialogContent style={{textAlign: 'center'}}>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          qrCodeUrl && (
                            <Fragment>
                              <img src={qrCodeUrl} alt="QRCode" />
                              <Typography>
                                {translate('custom.components.profile.qrcode')}
                              </Typography>
                              <SimpleForm
                                style={{display: 'inline-flex'}}
                                save={this.handleSave}>
                                <TextInput
                                  label={translate(
                                    'custom.components.profile.qrcodefield',
                                  )}
                                  source="GAuth"
                                />
                              </SimpleForm>
                              <Typography>
                                {translate(
                                  'custom.components.profile.GAuthExplanation',
                                )}
                              </Typography>
                              <Typography>
                                {translate(
                                  'custom.components.profile.GAuthDownload',
                                )}
                              </Typography>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center">
                                <Grid item className={classes.dialogIcons}>
                                  <a
                                    rel="noopener noreferrer"
                                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                    target="_blank">
                                    <img
                                      src={google_play}
                                      alt="google_play"
                                      width="50"
                                      height="50"
                                    />
                                  </a>
                                </Grid>
                                <Grid item className={classes.dialogIcons}>
                                  <a
                                    rel="noopener noreferrer"
                                    href="https://apps.apple.com/fr/app/google-authenticator/id388497605"
                                    target="_blank">
                                    <img
                                      src={app_store}
                                      alt="app_store"
                                      width="50"
                                      height="50"
                                    />
                                  </a>
                                </Grid>
                              </Grid>
                            </Fragment>
                          )
                        )}
                      </DialogContent>
                    </Dialog>
                  )}
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Grid container>
                        <Grid item xs={12} className={classes.infosSeparator}>
                          <span className={classes.labelInfos}>
                            {translate('custom.auth.email')}
                          </span>
                          <span>{email}</span>
                          <br />
                          <Link to="/change-password">
                            {translate(
                              'custom.components.users.resetPassword',
                            ).toLowerCase()}
                          </Link>
                        </Grid>
                        <Grid item xs={12} className={classes.infosSeparator}>
                          <span className={classes.labelInfos}>
                            {translate('custom.commons.name')}
                          </span>
                          <span>
                            {firstName} {lastName}
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.infosSeparator}>
                          <span className={classes.labelInfos}>
                            {translate('custom.commons.society')}
                          </span>
                          <span>{society}</span>
                        </Grid>
                        <Grid item xs={12} className={classes.infosSeparator}>
                          <span className={classes.labelInfos}>
                            {translate('custom.commons.description')}
                          </span>
                          <span>
                            {description ||
                              translate(
                                'custom.components.users.noDescription',
                              )}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container>
                        <Grid item xs={12} className={classes.infosSeparator}>
                          <span className={classes.labelInfos}>
                            {translate('custom.commons.adress')}
                          </span>
                          {!street && !zipcode && !city && !country ? (
                            <span>
                              {translate('custom.components.users.noAddress')}
                            </span>
                          ) : (
                            <Fragment>
                              <span className={classes.location}>{street}</span>
                              <span className={classes.location}>
                                {zipcode}
                              </span>
                              <span className={classes.location}>{city}</span>
                              <span className={classes.location}>
                                {country}
                              </span>
                            </Fragment>
                          )}
                        </Grid>
                        <Grid item xs={12} className={classes.infosSeparator}>
                          <span className={classes.labelInfos}>
                            {translate('custom.commons.phone')}
                          </span>
                          <span>
                            {phone ||
                              translate('custom.components.users.noPhone')}
                          </span>
                        </Grid>
                        <Grid item xs={12} className={classes.infosSeparator}>
                          <span className={classes.labelInfos}>
                            {translate('custom.commons.displayDatePreference')}
                          </span>
                          <span>
                            {translate(
                              `custom.components.profile.displayDateMode.${displayDatePreferenceValues[displayDatePreference]}`,
                            )}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!hasRoles(['ROLE_EUROFOURS_TECHNICIAN']) && (
                    <Link
                      to="/profile/edit"
                      style={{
                        position: 'absolute',
                        cursor: 'pointer',
                        right: 1 + 'em',
                        bottom: 1 + 'em',
                      }}>
                      {translate('custom.commons.edit')}
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Avatar image={imageBase64} type="profile" />
            </Grid>
          </Grid>
          {!hasRoles(['ROLE_SUPERADMIN']) && (
            <Grid container className={classes.topSpacing}>
              {notifications &&
                notifications.map((notification, index) => (
                  <Fragment key={`notification-${index}`}>
                    <Grid item md={6} className={classes.titleUnderline}>
                      <span>{translate(notification.main)}</span>
                    </Grid>
                    <Grid item md={6}></Grid>
                    {notification.items &&
                      notification.items.map((child, index) => (
                        <Grid
                          item
                          xs={6}
                          className={classes.elementsRights}
                          key={`notification-${index}`}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={this.handleChange}
                                value={child.value}
                                checked={profile[child.value]}
                              />
                            }
                            label={
                              <div>
                                <span
                                  style={{
                                    fontSize: 1.3 + 'em',
                                    fontStyle: 'bold',
                                    display: 'block',
                                  }}>
                                  {translate(child.title)}
                                </span>
                                <span style={{color: 'gray', display: 'block'}}>
                                  {profile[child.value]
                                    ? translate(child.enabled)
                                    : translate(child.disabled)}
                                </span>
                              </div>
                            }
                          />
                        </Grid>
                      ))}
                  </Fragment>
                ))}
            </Grid>
          )}
        </Card>
      </>
    );
  }
}

const mapStateToProps = ({profileReducer, authReducer}) => ({
  profile: profileReducer.profile,
  GAuth: authReducer.GAuth,
  activated: authReducer.activated,
  loading: authReducer.loading,
});

export default compose(
  translate,
  connect(mapStateToProps, {
    getProfile,
    updateOne,
    setGAuth,
    set2FA,
  }),
  withStyles(styles),
)(Profile);
