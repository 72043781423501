import React, {Fragment} from 'react';
import {
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  email,
  maxLength,
  minLength,
  translate,
} from 'react-admin';
import Title from '../Common/Header';
import {Create} from '../Common/Admin';
import {compose, withHandlers, withState} from 'recompose';
import DelegateRightsSetup from './DelegateRightsSetup';
import {hasRoles} from '../../utils/auth';
import {CustomToolbar} from '../Common/Admin/CustomToolbar';
import CountryField from '../Common/Field/CountryField';
import TimezoneField from '../Common/Field/TimezoneField';

const isRequired = [required()];
const validateName = [required(), maxLength(255)];
const validateDescription = [maxLength(255)];
const validateEmail = [required(), maxLength(200), email()];
const validatePassword = [required(), minLength(8), maxLength(64)];

export default compose(
  translate,
  withState('roles', 'setRoles', ['ROLE_USER']),
  withHandlers({
    updateRoles: ({setRoles, roles}) => event => {
      let tmpRoles = roles;
      if (event.target.checked && !roles.includes(event.target.value)) {
        tmpRoles.push(event.target.value);
      } else if (!event.target.checked && roles.includes(event.target.value)) {
        tmpRoles.splice(tmpRoles.indexOf(event.target.value), 1);
      }
      setRoles(tmpRoles);
    },
  }),
)(({values, roles, setRoles, updateRoles, translate, ...rest}) => {
  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_SUPERADMIN'])) {
    window.location.replace('/#/not-granted');
  }
  if (
    hasRoles(['ROLE_CLIENT_ADMIN']) &&
    '/create-eurofours-admin' === rest.basePath
  ) {
    window.location.replace('/#/not-granted');
  }
  const redirect = hasRoles(['ROLE_CLIENT_ADMIN'])
    ? 'list'
    : '/eurofours-technicians';
  if (
    hasRoles(['ROLE_SUPERADMIN']) &&
    '/create-eurofours-admin' === rest.basePath
  ) {
    return (
      <Create
        title={
          <Title
            title={translate('custom.components.users.create.title')}
            redirect={redirect}
            {...rest}
          />
        }
        actions={<Fragment />}
        {...rest}>
        <SimpleForm
          toolbar={<CustomToolbar showDelete={false} props={rest} />}
          redirect={redirect}>
          <TextInput source="firstName" validate={validateName} />
          <TextInput source="lastName" validate={validateName} />
          <TextInput source="email" type="email" validate={validateEmail} />
          <TextInput
            source="password"
            type="password"
            validate={validatePassword}
          />
          <SelectInput
            source="countryCode"
            choices={[{id: 'FR'}, {id: 'EN'}]}
            optionText="id"
            validate={isRequired}
            style={{marginBottom: '2em'}}
          />
          <SelectInput
            source="temperatureUnitPreference"
            label={translate('custom.commons.temperatureUnitPreference.label')}
            choices={[
              {
                id: 'C',
                name: `${translate(
                  'custom.commons.temperatureUnitPreference.celsius',
                )} - C°`,
              },
              {
                id: 'F',
                name: `${translate(
                  'custom.commons.temperatureUnitPreference.fahrenheit',
                )} - F°`,
              },
            ]}
            validate={isRequired}
            style={{marginBottom: '2em'}}
          />
        </SimpleForm>
      </Create>
    );
  }
  return (
    <Create
      actions={<Fragment />}
      {...rest}
      title={
        <Title
          title={translate('custom.components.users.create.title')}
          redirect={redirect}
          {...rest}
        />
      }>
      <SimpleForm
        toolbar={<CustomToolbar showDelete={false} props={rest} />}
        redirect={redirect}>
        <TextInput source="firstName" validate={validateName} />
        <TextInput source="lastName" validate={validateName} />
        <TextInput source="email" type="email" validate={validateEmail} />
        <TextInput
          source="password"
          type="password"
          validate={validatePassword}
        />
        <TextInput source="society" validate={isRequired} />
        <TextInput
          source="description"
          multiline
          validate={validateDescription}
        />
        <TextInput
          source="phone"
          inputProps={{maxLength: 15}}
          validate={isRequired}
        />
        <TextInput source="street" validate={isRequired} />
        <TextInput source="city" validate={isRequired} />
        <TextInput
          source="zipcode"
          inputProps={{maxLength: 7}}
          validate={isRequired}
        />
        <CountryField
          source="country"
          validate={isRequired}
          style={{width: '256px'}}
        />
        <TimezoneField
          source="timezone"
          validate={isRequired}
          style={{width: '256px'}}
        />
        <SelectInput
          source="countryCode"
          choices={[{id: 'FR'}, {id: 'EN'}]}
          optionText="id"
          validate={isRequired}
          style={{marginBottom: '2em'}}
        />
        <SelectInput
          source="temperatureUnitPreference"
          label={translate('custom.commons.temperatureUnitPreference.label')}
          choices={[
            {
              id: 'C',
              name: `${translate(
                'custom.commons.temperatureUnitPreference.celsius',
              )} - C°`,
            },
            {
              id: 'F',
              name: `${translate(
                'custom.commons.temperatureUnitPreference.fahrenheit',
              )} - F°`,
            },
          ]}
          validate={isRequired}
          style={{marginBottom: '2em'}}
        />
        {hasRoles(['ROLE_CLIENT_ADMIN']) && <DelegateRightsSetup />}
      </SimpleForm>
    </Create>
  );
});
