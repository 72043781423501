export const HISTORY_GET_ALL_ONE_REQUEST = 'HISTORY_GET_ALL_ONE_REQUEST';
export const HISTORY_GET_ALL_ONE_SUCCESS = 'HISTORY_GET_ALL_ONE_SUCCESS';
export const HISTORY_GET_PROFILE_REQUEST = 'HISTORY_GET_PROFILE_REQUEST';
export const HISTORY_GET_PROFILE_SUCCESS = 'HISTORY_GET_PROFILE_SUCCESS';
export const HISTORY_GET_ALL_ONE_ACCESSPLUS_REQUEST =
  'HISTORY_GET_ALL_ONE_ACCESSPLUS_REQUEST';
export const HISTORY_GET_ALL_ONE_ACCESSPLUS_SUCCESS =
  'HISTORY_GET_ALL_ONE_ACCESSPLUS_SUCCESS';
export const HISTORY_GET_ACCESSPLUS_REQUEST = 'HISTORY_GET_ACCESSPLUS_REQUEST';
export const HISTORY_GET_ACCESSPLUS_SUCCESS = 'HISTORY_GET_ACCESSPLUS_SUCCESS';
export const HISTORY_GET_APPBAR_REQUEST = 'HISTORY_GET_APPBAR_REQUEST';
export const HISTORY_GET_APPBAR_SUCCESS = 'HISTORY_GET_APPBAR_SUCCESS';

export const getHistory = data => ({
  type: HISTORY_GET_ALL_ONE_REQUEST,
  payload: data,
});

export const getProfileHistory = data => ({
  type: HISTORY_GET_PROFILE_REQUEST,
  payload: data,
});

export const getAccessPlusHistory = data => ({
  type: HISTORY_GET_ACCESSPLUS_REQUEST,
  payload: data,
});

export const getAppBarHistory = data => ({
  type: HISTORY_GET_APPBAR_REQUEST,
  payload: data,
});
