import React, {useMemo} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {addField} from 'react-admin';
import {Labeled} from 'react-admin';
import {withStyles} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';

const styles = theme => ({
  input: {
    width: 250,
    background: 'white',
    borderRadius: 2,
    border: '8px solid white',
    boxShadow: '0 0 0 1px #c6c6c6',
    '& svg': {
      color: 'inherit',
    },
    '& [class^="Labeled-value-"]': {
      padding: 0,
    },
    '& .MuiInput-formControl': {
      width: '100%',
    },
  },
  inputNoFilter: {
    minWidth: 250,
    '& .MuiInput-formControl': {
      width: '100%',
    },
  },
});

const StructureInput = ({
  classes,
  input,
  meta: {touched, error},
  choices,
  filter,
  forbiddenIds = [],
  label,
}) => {
  const handleSelect = value => {
    input.onChange(value); // Mettre à jour la valeur sélectionnée
  };

  const structuresMap = useMemo(() => {
    const map = new Map();
    choices.forEach(structure => {
      map.set(structure['@id'], {...structure, substructures: []});
    });
    choices.forEach(structure => {
      if (structure.parent) {
        const parent = map.get(structure.parent);
        parent && parent.substructures.push(map.get(structure.id));
      }
    });
    return map;
  }, [choices]);

  const sortByName = (a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  };

  const rootStructures = useMemo(
    () =>
      [...structuresMap.values()]
        .map(s => ({...s, substructures: s.substructures.sort(sortByName)}))
        .filter(structure => !structure.parent)
        .sort(sortByName),
    [structuresMap],
  );

  const createMenuItems = (structures, level = 0, handleSelect) => {
    return structures.reduce((acc, structure) => {
      if (!forbiddenIds.includes(structure['@id'])) {
        // on n'affiche pas les ids interdits et leurs enfants
        acc.push(
          <MenuItem key={structure['@id']} value={structure['@id']}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: `${level * 10}px`,
              }}>
              {level > 0 ? <ChevronRight /> : ''}
              <span>{structure.name}</span>
            </div>
          </MenuItem>,
        );

        if (structure.substructures) {
          acc.push(
            ...createMenuItems(
              structure.substructures,
              level + 1,
              handleSelect,
            ),
          );
        }
      }

      return acc;
    }, []);
  };

  const currentStructure = useMemo(
    () => (input.value ? structuresMap.get(input.value) : null),
    [input, structuresMap],
  );

  const menuItems = useMemo(
    () => createMenuItems(rootStructures, 0, input.onChange),
    [rootStructures],
  );

  return (
    <Labeled
      label={label || 'Structure'}
      className={filter ? classes.input : classes.inputNoFilter}>
      <Select
        {...input}
        value={input.value}
        renderValue={value => (
          <span>{currentStructure ? currentStructure.name : ''}</span>
        )}
        onChange={event => handleSelect(event.target.value)}
        error={touched && !!error}
        displayEmpty
        disableUnderline={!!filter}>
        <MenuItem value={null}>&nbsp;</MenuItem>
        {menuItems}
      </Select>
    </Labeled>
  );
};

export default addField(withStyles(styles)(StructureInput)); // decorate with redux-form's <Field>
