import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f14343',
      light: '#fbc8c8',
    },
    secondary: {
      main: '#f14343',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    type: 'dark',
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
    body2: {
      color: 'inherit',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: '#303030',
      },
      colorSecondary: {
        backgroundColor: '#303030',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#303030',
        maxHeight: 40,
      },
    },
    MuiFormControlLabel: {
      disabled: {
        color: 'gray !important',
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: 'white',
        maxHeight: 200,
        left: 90,
      },
    },
    MuiFormHelperText: {
      root: {
        color: 'black',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiDrawer: {
      docked: {
        width: 54,
        transition: 'width 1s',
        overflowX: 'hidden',
        '@media (min-width:961px)': {
          '&': {
            width: 240,
          },
        },
        '@media (min-width:720px) and (max-width:960px)': {
          '&:hover': {
            width: 240,
          },
        },
      },
    },
    MuiCard: {
      root: {
        backgroundColor: 'white',
        padding: 1 + 'em',
      },
    },
    MuiTypography: {
      root: {
        color: 'inherit',
      },
      title: {
        color: 'inherit',
      },
      headline: {
        color: 'inherit',
      },
      body1: {
        color: 'inherit',
      },
      subheading: {
        color: 'inherit',
      },
    },
    MuiTable: {
      root: {
        borderRadius: 3,
        boxShadow: '0 0 0 1px #cecece',
        overflow: 'hidden',
        tableLayout: 'fixed',
      },
    },
    MuiTableBody: {
      root: {
        '& > tr > td:last-child': {
          borderRight: 'none',
        },
        '& > tr:last-child > td': {
          borderBottom: 'none',
        },
      },
    },
    MuiTableRow: {
      root: {
        background: '#ededed',
      },
      head: {
        background: '#303030',
      },
    },
    MuiToolbar: {
      regular: {
        padding: 0,
      },
    },
    MuiTableCell: {
      head: {
        color: 'white',
        fontWeight: 600,
      },
      body: {
        color: 'black',
        fontWeight: 500,
        borderBottom: '1px solid #bebebe',
        borderRight: '1px solid #cecece',
      },
    },
    MuiInputLabel: {
      root: {
        color: 'black',
      },
    },
    MuiInput: {
      root: {
        color: 'inherit',
      },
      underline: {
        borderBottom: '1px solid black',
      },
      input: {
        color: 'black',
      },
    },
    MuiButton: {
      root: {
        color: '#303030',
      },
    },
    MuiButtonBase: {
      root: {
        color: 'inherit',
      },
    },
    MuiTablePagination: {
      caption: {
        color: '#303030',
        fontWeight: 500,
      },
      selectIcon: {
        color: '#303030',
      },
      selectRoot: {
        color: '#303030',
        fontWeight: 500,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'white',
      },
      elevation1: {
        boxShadow: 'none',
      },
      elevation4: {
        boxShadow: 'none',
      },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        color: 'black',
      },
    },
    MuiMenuItem: {
      root: {
        color: 'black',
        '&:hover': {
          backgroundColor: '#f14343',
        },
        '&[aria-current=true]': {
          backgroundColor: '#f14343',
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        '&$checked + $bar': {
          backgroundColor: '#f14343 !important',
        },
      },
      bar: {
        backgroundColor: 'black',
      },
      icon: {
        color: 'black',
      },
      iconChecked: {
        color: '#f14343',
      },
    },
    MuiTabs: {
      flexContainer: {
        justifyContent: 'space-between',
      },
      indicator: {
        backgroundColor: '#f14343',
      },
    },
    MuiCheckbox: {
      root: {
        color: 'inherit',
      },
      disabled: {
        color: 'gray !important',
      },
    },
    MuiRadio: {
      root: {
        color: 'inherit',
      },
    },
    MuiDialogContentText: {
      root: {
        color: 'inherit',
      },
    },
    MuiSelect: {
      icon: {
        color: 'inherit',
      },
    },
    Layout: {
      content: {
        zIndex: 3,
      },
    },
  },
});

export default theme;
