import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Loading.css';
import theme from '../../theme';

export default () => (
  <MuiThemeProvider theme={theme}>
    <div className="Loading-wrapper">
      <CircularProgress size={80} className="Loading-progress" />
    </div>
  </MuiThemeProvider>
);
