export const SOFTWARE_UPDATES_POST_RESOURCE = 'SOFTWARE_UPDATES_POST_RESOURCE';
export const SOFTWARE_UPDATES_POST_RESOURCE_SUCCESS =
  'SOFTWARE_UPDATES_POST_RESOURCE_SUCCESS';
export const SOFTWARE_UPDATES_PUT_RESOURCE = 'SOFTWARE_UPDATES_PUT_RESOURCE';
export const SOFTWARE_UPDATES_PUT_RESOURCE_SUCCESS =
  'SOFTWARE_UPDATES_PUT_RESOURCE_SUCCESS';

export const postSoftwareUpdates = data => {
  return {
    type: SOFTWARE_UPDATES_POST_RESOURCE,
    payload: data,
  };
};

export const putSoftwareUpdates = data => {
  return {
    type: SOFTWARE_UPDATES_PUT_RESOURCE,
    payload: data,
  };
};
