import MachineCreate from './create';
import MachineShow from './show';
import MachineList from './list';
import MachineEdit from './edit';

export default machines => {
  machines.create = MachineCreate;
  machines.show = MachineShow;
  machines.list = MachineList;
  machines.edit = MachineEdit;

  return machines;
};
