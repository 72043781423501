export const TAGS_GET_ALL_REQUEST = 'TAGS_GET_ALL_REQUEST';
export const TAGS_GET_ALL_SUCCESS = 'TAGS_GET_ALL_SUCCESS';
export const TAGS_GET_COUNT_REQUEST = 'TAGS_GET_COUNT_REQUEST';
export const TAGS_GET_COUNT_REQUEST_SUCCESS = 'TAGS_GET_COUNT_REQUEST_SUCCESS';
export const TAGS_UPDATE_ONE_RELATED_USERS_REQUEST =
  'TAGS_UPDATE_ONE_RELATED_USERS_REQUEST';
export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const TAG_GET_ONE_RESOURCE = 'TAG_GET_ONE_RESOURCE';
export const TAG_GET_ONE_SUCCESS = 'TAG_GET_ONE_SUCCESS';

export const getTag = data => ({
  type: TAG_GET_ONE_RESOURCE,
  payload: data,
});

export const getTags = data => ({
  type: TAGS_GET_ALL_REQUEST,
  payload: data,
});

export const getCountTags = data => ({
  type: TAGS_GET_COUNT_REQUEST,
  payload: data,
});

export const updateTag = data => ({
  type: TAGS_UPDATE_ONE_RELATED_USERS_REQUEST,
  payload: data,
});

export const createTag = data => ({
  type: CREATE_TAG,
  payload: data,
});
