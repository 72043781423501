import * as actions from './action';

const initialState = {
  createdUser: null,
};

export default (state = initialState, {type}) => {
  switch (type) {
    case actions.CREATE_CLIENT_ADMIN_SUCCESS:
      return {
        ...state,
        createdUser: true,
      };
    case actions.CREATE_CLIENT_ADMIN_ERROR:
      return {
        ...state,
        createdUser: true,
      };
    default:
      return state;
  }
};
