import * as actions from './action';

const initialState = {
  structures: [],
  structure: null,
  loding: false,
  selectedStructures: [],
  filteredStructures: [],
  list: [],
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.STRUCTURE_GET_ALL_REQUEST:
      return {
        ...state,
        structures: initialState.structures,
        loading: true,
      };
    case actions.STRUCTURE_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        structures: payload,
      };
    case actions.SET_SELECTED_STRUCTURES:
      return {
        ...state,
        selectedStructures: payload,
      };
    case actions.GET_FILTERED_STRUCTURES:
      return {
        ...state,
        filteredStructures: payload,
      };
    case actions.STRUCTURE_GET_ONE_RESOURCE:
      return {
        ...state,
        structure: initialState.structure,
      };
    case actions.STRUCTURE_GET_ONE_SUCCESS:
      return {
        ...state,
        structure: payload,
      };
    default:
      return state;
  }
};
