import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {crudUpdate} from 'ra-core';
import get from 'lodash/get';
import unique from 'array-unique';

class AssignAction extends Component {
  componentDidMount() {
    const {crudUpdate, resources, assign} = this.props;

    const programMachines =
      (('programs' === this.props.resource &&
        'machines' === this.props.resourceToUpdate) ||
        ('machines' === this.props.resource &&
          'programs' === this.props.resourceToUpdate)) &&
      'programMachines';

    const programMachinesReverse =
      !!programMachines &&
      'programs' === this.props.resource &&
      'machines' === this.props.resourceToUpdate;

    const usersStructureReverse =
      'users' === this.props.resource &&
      'structures' === this.props.resourceToUpdate;

    const resource =
      (programMachinesReverse && 'machines') ||
      (usersStructureReverse && 'structures') ||
      this.props.resource;
    const resourceToUpdate =
      (programMachinesReverse && 'programs') ||
      (usersStructureReverse && 'users') ||
      this.props.resourceToUpdate;
    const selectedIds =
      ((programMachinesReverse || usersStructureReverse) &&
        this.props.idsToUpdate) ||
      this.props.selectedIds;
    const idsToUpdate =
      ((programMachinesReverse || usersStructureReverse) &&
        this.props.selectedIds) ||
      this.props.idsToUpdate;

    const data = resources[resource].data;

    const users = 'users' === resourceToUpdate && 'relatedUsers';
    // Retrieve data for each ids
    // Map replaces each ids by its data
    // Filter cleans the array off incorrect values
    // Reduce sets the index for each data with the resource id
    const selectedResources = selectedIds
      .map(id => get(data, id))
      .filter(r => typeof r !== 'undefined')
      .reduce((prev, next) => {
        prev[next.id] = next;
        return prev;
      }, {});

    selectedIds.forEach(resourceId => {
      if ('machines' === resource && 'structures' === resourceToUpdate) {
        crudUpdate(
          '/machines',
          resourceId,
          JSON.stringify(
            assign ? {structure: idsToUpdate[0]} : {structure: null},
          ),
          {},
          '/structures',
          'list',
        );
      } else {
        const ids = {};
        const currentIds = get(selectedResources, resourceId)[
          programMachines || users || resourceToUpdate
        ];

        if (assign) {
          ids[programMachines || users || resourceToUpdate] = currentIds.concat(
            !!programMachines
              ? idsToUpdate.map(idToUpdate => {
                  const item = {};
                  item[resource.substr(0, resource.length - 1)] = resourceId;
                  item[
                    resourceToUpdate.substr(0, resource.length - 1)
                  ] = idToUpdate;

                  return item;
                })
              : idsToUpdate,
          );
        } else {
          ids[programMachines || users || resourceToUpdate] = currentIds.filter(
            currentId =>
              !(!!programMachines
                ? unique(
                    idsToUpdate
                      .map(id => get(resources[resourceToUpdate].data, id))
                      .filter(r => typeof r !== 'undefined')
                      .reduce(
                        (prev, next) => prev.concat(next.programMachines),
                        [],
                      ),
                  )
                : idsToUpdate
              ).includes(currentId),
          );
        }

        crudUpdate(
          '/' + resource,
          resourceId,
          JSON.stringify(ids),
          {},
          '/' +
            (programMachinesReverse || usersStructureReverse
              ? resource
              : resourceToUpdate),
          'list',
        );
      }
    });

    selectedIds.length = 0;
    this.props.onExit();
  }

  render() {
    return null;
  }
}

AssignAction.propTypes = {
  selectedIds: PropTypes.array.isRequired,
  idsToUpdate: PropTypes.array.isRequired,
  resources: PropTypes.object.isRequired,
  resource: PropTypes.string,
  resourceToUpdate: PropTypes.string,
  assign: PropTypes.bool,
  onExit: PropTypes.func.isRequired,
  crudUpdate: PropTypes.func.isRequired,
};

AssignAction.defaultProps = {
  selectedIds: [],
  idsToUpdate: [],
};

const mapStateToProps = ({admin}) => ({
  resources: admin.resources,
});

export default connect(mapStateToProps, {crudUpdate})(AssignAction);
