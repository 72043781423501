import React, {Fragment} from 'react';
import {
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  LongTextInput,
  maxLength,
  regex,
} from 'react-admin';
import Title from '../Common/Header';
import {Create} from '../Common/Admin';
import {compose, withHandlers, withState} from 'recompose';
import {hasRoles} from '../../utils/auth';
import StructureInput from '../Input/StructureInput';

const validateName = [required(), maxLength(255)];
const validateDescription = [maxLength(255)];
const validatePin = [required(), regex(/^\d{4}$/, 'custom.validation.pin')];

export default compose(
  withState('roles', 'setRoles', []),
  withHandlers({
    updateRoles: ({setRoles, roles}) => event => {
      let tmpRoles = roles;
      if (event.target.checked && !roles.includes(event.target.value)) {
        tmpRoles.push(event.target.value);
      } else if (!event.target.checked && roles.includes(event.target.value)) {
        tmpRoles.splice(tmpRoles.indexOf(event.target.value), 1);
      }
      setRoles(tmpRoles);
    },
  }),
)(({values, roles, setRoles, updateRoles, ...rest}) => {
  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_MACHINE_CREATE'])) {
    window.location.replace('/#/not-granted');
  }
  return (
    <Create
      title={
        <Title
          title="custom.components.machines.create.title"
          redirect="list"
          {...rest}
        />
      }
      actions={<Fragment />}
      {...rest}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={validateName} />
        <LongTextInput source="description" validate={validateDescription} />
        <TextInput source="serialNumber" validate={required()} />
        <TextInput source="pin" validate={validatePin} />
        <BooleanInput source="active" />
        <BooleanInput source="approved" />
        <ReferenceInput
          source="structure"
          reference="structures"
          perPage={500}
          sort={{field: 'name', order: 'ASC'}}>
          <StructureInput />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Program machines"
          source="programs"
          reference="programs"
          allowEmpty>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput
          source="category"
          reference="categories"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="avatar" />
      </SimpleForm>
    </Create>
  );
});
