import React, {Component} from 'react';
import {compose} from 'recompose';
import {translate} from 'react-admin';
import {connect} from 'react-redux';
import {resetPassword} from '../store/action';
import {withStyles} from '@material-ui/core/styles';
import {Button, Card, Grid, Input, CircularProgress} from '@material-ui/core';

const styles = theme => ({
  card: {
    margin: '50px',
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    paddingTop: 1 + 'em',
    paddingBottom: 1 + 'em',
  },
  main: {
    minHeight: '100vh',
    backgroundColor: '#' + 222,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    textAlign: 'center',
  },
});

class RequestReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };
  }

  handleType = event => {
    this.setState({email: event.target.value});
  };

  handleResetPassword = () => {
    this.props.resetPassword(this.state.email);
  };

  render() {
    const {classes, translate, loading} = this.props;
    const {email} = this.state;
    return (
      <Card className={classes.card}>
        <h3>{translate('custom.components.passwords.reset')}</h3>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid item xs={12}>
            <div className={classes.input}>
              <Input
                placeholder={translate('custom.auth.email')}
                onChange={this.handleType}
                type="email"
                value={email}
                required
              />
            </div>
            <Button
              variant="raised"
              color="primary"
              className={classes.button}
              onClick={this.handleResetPassword}>
              {translate('custom.components.passwords.submitRequest')}
            </Button>
          </Grid>
        )}
      </Card>
    );
  }
}

const mapStateToProps = ({passwordReducer}) => ({
  loading: passwordReducer.loading,
});

export default compose(
  translate,
  connect(mapStateToProps, {resetPassword}),
  withStyles(styles),
)(RequestReset);
