import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Confirm, translate} from 'react-admin';
import {compose} from 'recompose';
import {updateMany} from './store/action';

class BooleanAction extends Component {
  handleDialogClose = () => {
    this.props.onExit();
  };

  handleConfirm = () => {
    const {
      selectedIds,
      updateMany,
      property,
      switchOn,
      resource,
      onExit,
    } = this.props;
    const update = {};

    update[property] = switchOn;

    updateMany({
      ids: selectedIds,
      body: update,
      resource: resource,
      property: property,
    });

    onExit();
  };

  render() {
    const {resource, translate, selectedIds, property, switchOn} = this.props;
    const action = (switchOn && 'on') || 'off';

    return (
      <Confirm
        isOpen={true}
        title={translate(
          `custom.message.update_property.${resource}.${property}.${action}`,
          {
            subject: translate(`custom.title.${resource}`, {
              smart_count: selectedIds.length,
            }),
          },
        )}
        content={translate(
          `custom.message.update_property.${resource}.${property}.content`,
          {
            _: translate('custom.message.update_property.confirm'),
          },
        )}
        confirm={translate('ra.message.yes')}
        cancel={translate('ra.message.no')}
        onConfirm={this.handleConfirm}
        onClose={this.handleDialogClose}
      />
    );
  }
}

BooleanAction.propTypes = {
  resource: PropTypes.string,
  translate: PropTypes.func.isRequired,
  updateMany: PropTypes.func.isRequired,
  selectedIds: PropTypes.array,
  switchOn: PropTypes.bool,
  onExit: PropTypes.func,
};

export default compose(
  translate,
  connect(undefined, {updateMany}),
)(BooleanAction);
