export const errorMessageHandler = e => {
  if (typeof e === 'string') {
    return e;
  }

  if (typeof e === 'undefined' || !e.message) {
    return 'create.error';
  }

  return e.message;
};
