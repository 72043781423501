import {all, put, takeEvery} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {SHOW_NOTIFICATION, showNotification} from 'react-admin';
import {
  PASSWORD_CHANGE_ERROR,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_RESET_CHILD_ERROR,
  PASSWORD_RESET_CHILD_REQUEST,
  PASSWORD_RESET_CHILD_SUCCESS,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
} from '../components/Passwords/store/action';
import {client} from '../utils/api-client';

function* handleStructure(action) {
  const {type, payload} = action;
  switch (type) {
    case PASSWORD_CHANGE_REQUEST: {
      try {
        yield client('change-password', {data: payload});
        yield put({
          type: PASSWORD_CHANGE_SUCCESS,
        });
        yield put(push('/'));
        yield put(
          showNotification(
            'custom.components.passwords.successChanged',
            'info',
          ),
        );
      } catch (e) {
        yield put({
          type: PASSWORD_CHANGE_ERROR,
        });
        yield put(
          showNotification('custom.components.passwords.errorChanged', 'error'),
        );
      }
      break;
    }
    case PASSWORD_RESET_REQUEST: {
      try {
        yield client('reset', {
          data: {
            email: payload,
          },
        });
        yield put({
          type: PASSWORD_RESET_SUCCESS,
        });
        yield put({
          type: SHOW_NOTIFICATION,
          payload: {
            autoHideDuration: 5000,
            type: 'info',
            message: 'custom.components.passwords.successReset',
          },
        });
      } catch (e) {
        yield put({
          type: PASSWORD_RESET_ERROR,
        });
        yield put(
          showNotification('custom.components.passwords.error', 'error'),
        );
      }
      break;
    }
    case PASSWORD_RESET_CHILD_REQUEST: {
      try {
        yield client('reset/child', {
          data: {
            email: payload,
          },
        });
        yield put({
          type: PASSWORD_RESET_CHILD_SUCCESS,
        });
        yield put(
          showNotification(
            'custom.components.users.resetPasswordSuccess',
            'info',
          ),
        );
      } catch (e) {
        yield put({
          type: PASSWORD_RESET_CHILD_ERROR,
        });
        yield put(
          showNotification(
            'custom.components.users.resetPasswordFailed',
            'error',
          ),
        );
      }
      break;
    }
    default:
      break;
  }
}

export default function* watchStructuresActions() {
  yield all([takeEvery(PASSWORD_CHANGE_REQUEST, handleStructure)]);
  yield all([takeEvery(PASSWORD_RESET_REQUEST, handleStructure)]);
  yield all([takeEvery(PASSWORD_RESET_CHILD_REQUEST, handleStructure)]);
}
