import * as actions from './action';

const initialState = {
  tagsCount: 0,
  tags: [],
  isLoaded: false,
  tag: null,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.TAGS_GET_ALL_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case actions.TAGS_GET_ALL_SUCCESS:
      return {
        ...state,
        tags: payload,
        isLoaded: true,
      };
    case actions.TAGS_GET_COUNT_REQUEST_SUCCESS:
      return {
        ...state,
        tagsCount: payload,
      };
    case actions.TAG_GET_ONE_RESOURCE:
      return {
        ...state,
        tag: initialState.tag,
      };
    case actions.TAG_GET_ONE_SUCCESS:
      return {
        ...state,
        tag: payload,
      };
    case actions.CREATE_TAG_SUCCESS:
      return {
        ...state,
        tags: payload,
        isLoaded: true,
      };
    default:
      return state;
  }
};
