import React from 'react';
import {translate} from 'react-admin';
import {
  HistoryConnectionAs,
  HistoryErrorWarningSuccessFormat,
  HistoryMachine,
  HistoryProgram,
  HistoryProgramMachine,
  HistoryProgramTag,
  HistoryStructure,
  HistoryStructureMachine,
  HistoryStructureUser,
  HistoryTag,
  HistoryTagProgram,
  HistoryUser,
} from './Formats';

export default translate(props => {
  switch (props.item.typeEvent) {
    case 'Connection as':
      return <HistoryConnectionAs {...props} />;
    case 'User creation':
    case 'User update':
    case 'User deletion':
      return <HistoryUser {...props} />;
    case 'Error':
    case 'Success':
    case 'Warning':
      return <HistoryErrorWarningSuccessFormat {...props} />;
    case 'Machine program installation':
    case 'Machine program uninstallation':
      return <HistoryProgram {...props} />;
    case 'Machine add to structure':
    case 'Machine remove from structure':
      return <HistoryStructure {...props} />;
    case 'Machine activation':
    case 'Machine deactivation':
    case 'Machine approval':
    case 'Machine disapproval':
    case 'Machine deletion':
      return <HistoryMachine {...props} />;
    case 'Program installation':
    case 'Program uninstallation':
    case 'Program import on machine':
    case 'Program update on machine':
      return <HistoryProgramMachine {...props} />;
    case 'Program import':
    case 'Program export':
    case 'Program update from import':
    case 'Program approval':
    case 'Program disapproval':
    case 'Program creation':
      return <HistoryProgram {...props} />;
    case 'Program tag added':
    case 'Program tag removed':
      return <HistoryProgramTag {...props} />;
    case 'Tag creation':
    case 'Tag update':
      return <HistoryTag {...props} />;
    case 'Tag added on program':
    case 'Tag removed from program':
      return <HistoryTagProgram {...props} />;
    case 'Structure add machine':
    case 'Structure remove machine':
      return <HistoryStructureMachine {...props} />;
    case 'Structure add user':
    case 'Structure remove user':
      return <HistoryStructureUser {...props} />;
    case 'Structure creation':
    case 'Structure update':
    case 'Structure deletion':
      return <HistoryStructureUser {...props} />;
    default:
      return <>error</>;
  }
});
