import * as actions from './action';

const initialState = {
  imagesMachines: null,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.IMAGES_GET_MACHINE_SUCCESS:
      return {
        ...state,
        imageMachine: payload,
      };
    case actions.IMAGES_GET_MACHINES_SUCCESS:
      return {
        ...state,
        imagesMachines: payload,
      };
    case actions.IMAGES_DELETE_MACHINES_SUCCESS:
      return {
        ...state,
        imagesMachines: state.imagesMachines.filter(
          image => image.machineCode !== payload.machineCode,
        ),
      };
    case actions.IMAGE_SEND_MACHINES_SUCCESS:
      return {
        ...state,
        imagesMachines: state.imagesMachines.length
          ? state.imagesMachines.concat([payload])
          : [payload],
      };
    case actions.IMAGES_UPDATE_MACHINES_SUCCESS:
      return {
        ...state,
        imagesMachines: payload,
      };
    default:
      return state;
  }
};
