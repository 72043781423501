import React, {Fragment, useState} from 'react';
import {translate} from 'react-admin';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@material-ui/core';
import {Delete, Warning} from '@material-ui/icons';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {deleteOneMachine} from '../store/action';

const styles = {
  icon: {
    fontSize: '40px',
    'margin-bottom': '24px',
  },
  contentDiv: {
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    'align-items': 'center',
  },
  contentWarning: {
    'font-weight': 'bold',
    color: '#f14343',
  },
};

const MachineBulkDeleteWithConfirmButton = props => {
  const rootLocation = '/machines';
  const {
    translate,
    deleteOneMachine,
    location,
    selectedIds = [],
    record = null,
  } = props;
  const [open, setOpen] = useState(false);

  if (record) {
    selectedIds.push(record.id);
  }

  if (selectedIds.length < 1) {
    return <Fragment></Fragment>;
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (deletePrograms = 0) => {
    selectedIds.forEach(iri => {
      deleteOneMachine(`${iri}?deletePrograms=${deletePrograms}`);
    });
    setOpen(false);

    // Current version of react admin cant handle it in a better way (machine list doesnt auto-update)
    setInterval(
      () =>
        location === rootLocation
          ? window.location.reload()
          : window.location.replace(`/#${rootLocation}`),
      500,
    );
  };

  return (
    <Fragment>
      <Button color="primary" onClick={() => handleClick()}>
        <Delete /> {translate('custom.buttons.deletebutton')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText
            style={styles.contentDiv}
            id="alert-dialog-description">
            <Warning style={styles.icon} />
            <Typography style={styles.contentWarning} variant="h2">
              {translate(`custom.components.machine.deleteModal.warning`, {
                smart_count: selectedIds.length,
              })}
            </Typography>
            <Typography variant="h3">
              {translate(`custom.components.machine.deleteModal.content`, {
                smart_count: selectedIds.length,
              })}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {translate(`custom.commons.cancel`)}
          </Button>
          <Button color="primary" onClick={() => handleDelete()}>
            {translate(`custom.components.machine.deleteModal.keep`)}
          </Button>
          <Button color="primary" onClick={() => handleDelete(1)} autoFocus>
            {translate(`custom.components.machine.deleteModal.destroy`)}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  machineReducer: state.machineReducer,
  location: state.router.location.pathname,
});

export default compose(
  translate,
  connect(mapStateToProps, {deleteOneMachine}),
)(MachineBulkDeleteWithConfirmButton);
