import registerClient from './register';
import getStructures from './structure';
import getPrograms from './program';
import getSoftwareUpdates from './softwareUpdates';
import getTags from './tag';
import getUsers from './user';
import getMachines from './machine';
import getHistory from './history';
import getPasswords from './password';
import commonSaga from './common';
import createTechnicianSaga from './createTechnician';
import exportSaga from './exporter';
import importSaga from './importer';
import getPhaseGroupTypes from './phase-group-type';
import imageUploadSaga from './imageUpload';
import imagesRetrieveDeleteSaga from './imagesRetrieveDelete';

export default [
  registerClient,
  getStructures,
  getPrograms,
  getSoftwareUpdates,
  getTags,
  getUsers,
  getMachines,
  getHistory,
  getPasswords,
  commonSaga,
  createTechnicianSaga,
  exportSaga,
  importSaga,
  getPhaseGroupTypes,
  imageUploadSaga,
  imagesRetrieveDeleteSaga,
];
