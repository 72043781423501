import TagList from './list';
import TagShow from './show';
import TagEdit from './edit';
import TagCreate from './create';

export default tags => {
  tags.list = TagList;
  tags.show = TagShow;
  tags.edit = TagEdit;
  tags.create = TagCreate;

  return tags;
};
