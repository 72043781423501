import React, {Component} from 'react';
import {Button} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {hasRoles} from '../../utils/auth';
import {Delete} from '@material-ui/icons';

const styles = {
  imageContainer: {
    padding: 1 + 'em',
  },
  image: {
    width: 100 + '%',
    borderRadius: 100 + '%',
  },
  deleteButton: {
    transform: 'translateY(-110%)',
  },
};

class ImagesListFactory extends Component {
  handleSelect = event => {
    this.props.handleSelect(event.target.attributes.alt.value);
  };

  render() {
    const {
      classes,
      images,
      path,
      handleDelete,
      selectable,
      isUserGallery,
    } = this.props;
    return (
      <Grid container>
        {images &&
          images.map((image, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              className={classes.imageContainer}
              key={`image-${index}`}>
              <img
                src={`${process.env.REACT_APP_API_ENTRYPOINT}${path}${image.avatar}`}
                className={classes.image}
                alt={image.avatar}
                onClick={selectable && this.handleSelect}
              />
              {isUserGallery ? (
                <>
                  {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_USER']) && (
                    <Button
                      onClick={handleDelete}
                      className={classes.deleteButton}
                      id={image.avatar}>
                      <Delete />
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {!selectable && hasRoles(['ROLE_SUPERADMIN']) && (
                    <Button
                      onClick={handleDelete}
                      className={classes.deleteButton}
                      id={image.avatar}>
                      <Delete />
                    </Button>
                  )}
                </>
              )}
            </Grid>
          ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(ImagesListFactory);
