import {isEmpty} from 'lodash';

export const formatParameters = (phaseGroups, isFahrenheit) => {
  if (isEmpty(phaseGroups)) {
    return {
      items: {},
      totalTime: 0,
      poi: [0],
      heatMax: 0,
      heatMin: 0,
    };
  }

  const items = {};
  let totalTime = 0,
    heatMin = undefined,
    heatMax = undefined;

  phaseGroups.forEach((phaseGroup, phaseGroupIndex) => {
    // make sure phaseGroup has been expanded from the string @id to an object.
    if (typeof phaseGroup !== 'object') {
      return;
    }

    const type = phaseGroup.phaseGroupType;

    items[type] = [];

    if (!isEmpty(phaseGroup.phases)) {
      phaseGroup.phases.forEach(phase => {
        if (phase.values) {
          const item = {};

          phase.values.forEach((value, valueIndex) => {
            const criteriaName = value.criteria;

            if ('heat' === type) {
              if ('temperature' === criteriaName) {
                if (undefined === heatMax || value.value > heatMax) {
                  heatMax = value.value;
                }

                if (undefined === heatMin || value.value < heatMin) {
                  heatMin = value.value;
                }
              }

              if ('duration' === criteriaName) {
                totalTime += value.value;
              }
            }

            item[criteriaName] = value.value;
          });

          items[type].push(item);
        }
      });
    }
  });

  return {
    items,
    totalTime,
    heatMax,
    heatMin,
  };
};

export const getPointsOfInterest = ({
  heat = [],
  steam = [],
  damper = [],
  fan = [],
}) => {
  let poi = [0];
  let addition = 0;
  heat.forEach(({duration}) => {
    addition += duration / 60;
    if (!poi.includes(addition)) {
      poi.push(addition);
    }
  });
  steam.forEach(({start}) => {
    start /= 60;
    if (!poi.includes(start)) {
      poi.push(start);
    }
  });
  damper.forEach(({start, duration}) => {
    start /= 60;
    if (!poi.includes(start)) {
      poi.push(start);
    }
  });
  fan.forEach(({start, starting}) => {
    start /= 60;
    if (!poi.includes(start)) {
      poi.push(start);
    }
  });
  poi.sort(function(a, b) {
    return a - b;
  });
  return poi;
};
