import React, {Component} from 'react';
import {
  BooleanField,
  TextField,
  Datagrid,
  FunctionField,
  translate,
  BulkDeleteWithConfirmButton,
} from 'react-admin';
import {Mail} from '@material-ui/icons';
import {Grid, Button} from '@material-ui/core';
import {NameField, NumberField} from '../Common/Field';
import {List} from '../Common/Admin';
import UsersActions from '../ActionsCards/UsersActions';
import Title from '../Common/Header';
import {connect} from 'react-redux';
import {UserFilters} from '../ActionsCards/CustomFilter';
import {hasRoles} from '../../utils/auth';
import {AddButton} from '../Buttons';
import {TopBarList, Breadcrumb} from '../TopBar';
import {connectAsUser, updateClientAdmin} from '../SelectUser/store/action';
import {compose} from 'recompose';
import {Confirm} from 'react-admin';

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      user: null,
    };
  }

  handleClick = user => () => {
    if (user.active) {
      const id = user['@id'].split('/')[2];
      this.props.connectAsUser({id});
    } else {
      this.props.showNotificationAction(
        'custom.auth.account_desactivated',
        'warning',
      );
    }
  };

  toggleActive = user => () => {
    const id = user['@id'].split('/')[2];
    this.props.updateClientAdmin(id, {active: !user.active});
  };

  sanitizeProps = ({updateClientAdmin, connectAsUser, ...rest}) => rest;

  render() {
    const {opened, user} = this.state;
    const {location, selectedIds, translate} = this.props;

    if (!hasRoles(['ROLE_SUPERADMIN', 'ROLE_CLIENT_ADMIN'])) {
      window.location.replace('/#/not-granted');
    }

    return (
      <>
        <TopBarList>
          <Breadcrumb categoryName="custom.components.menu.users" />
          <div>
            {hasRoles(['ROLE_CLIENT_ADMIN']) && (
              <>
                {selectedIds.length ? (
                  <BulkDeleteWithConfirmButton
                    resource="users"
                    selectedIds={selectedIds}
                  />
                ) : null}
              </>
            )}
            {hasRoles(['ROLE_CLIENT_ADMIN']) && (
              <AddButton basePath="/users" roles={['ROLE_CLIENT_ADMIN']} />
            )}
          </div>
        </TopBarList>
        <List
          title={
            <Title
              title="custom.components.menu.users"
              {...this.sanitizeProps(this.props)}
            />
          }
          actions={<UsersActions location={location} />}
          filters={<UserFilters location={location} />}
          {...this.sanitizeProps(this.props)}>
          <Datagrid
            rowStyle={record => ({
              background: selectedIds.includes(record.id) && '#fcd0d0',
            })}>
            <NameField source="lastName" />
            <FunctionField
              source="firstName"
              render={user => (
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <TextField source="firstName" record={user} />
                  </Grid>
                  <Grid item>
                    <a
                      href={`mailto:${user.email}`}
                      style={{color: 'inherit', display: 'flex'}}>
                      <Mail fontSize="inherit" viewBox="2 2 20 20" />
                    </a>
                  </Grid>
                </Grid>
              )}
            />
            <TextField source="society" />
            <NumberField source="nbStructures" />
            <BooleanField source="emailVerified" />
            <BooleanField source="googleAuthenticatorConfirmed" />
            <FunctionField
              render={user => {
                return (
                  <Button
                    onClick={() =>
                      this.setState({
                        user: user,
                        opened: true,
                      })
                    }
                    color="secondary">
                    {translate(
                      `custom.buttons.${user.active ? 'disable' : 'enable'}`,
                    )}
                  </Button>
                );
              }}
            />
          </Datagrid>
        </List>
        {opened && (
          <Confirm
            record={user}
            isOpen={opened}
            title={translate(
              `custom.buttons.action.boolean.users.title.${
                user.active ? 'off' : 'on'
              }`,
            )}
            content={translate(
              `custom.buttons.action.boolean.users.content.${
                user.active ? 'off' : 'on'
              }`,
            )}
            confirm={translate('ra.message.yes')}
            cancel={translate('ra.message.no')}
            onConfirm={this.toggleActive(user)}
            onClose={() =>
              this.setState({
                user: null,
                opened: false,
              })
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  selectedIds: state.admin.resources.users.list.selectedIds,
});

export default compose(
  translate,
  connect(mapStateToProps, {connectAsUser, updateClientAdmin}),
)(UserList);
