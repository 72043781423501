import * as actions from './action';
import * as authGoogleActions from '../../AuthGoogle/store/action';
import {CRUD_CREATE_SUCCESS} from 'react-admin';
import {downloadJSON} from '../../Common/Exporter/downloadJSON';

const initialState = {
  profile: null,
  homeInfos: null,
  control: null,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.USER_EXPORT_ACTIVITIES_SUCCESS:
      downloadJSON(payload, payload.name || 'activities');
      return state;
    case actions.USER_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: payload,
      };
    case actions.USER_LET_EUROFOUR_CONTROL_SUCCESS:
      return {
        ...state,
        control: payload,
      };
    case actions.USER_GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: payload.profile,
        homeInfos: payload.homeInfos,
      };
    case actions.USER_UPDATE_ONE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: payload,
      };
    case actions.IMAGE_PROFILE_SEND_SUCCESS:
      return {
        ...state,
        profile: payload,
      };
    case authGoogleActions.USER_SET_GAUTH_SUCCESS:
      return {
        ...state,
        profile: {...state.profile, googleAuthenticatorConfirmed: false},
      };
    case authGoogleActions.USER_SET_2FA_SUCCESS:
      return {
        ...state,
        profile: {...state.profile, googleAuthenticatorConfirmed: true},
      };
    case CRUD_CREATE_SUCCESS:
      if ('User' === payload.data['@type']) {
        const newUsersCount = state.homeInfos.usersCount + 1;
        return {
          ...state,
          homeInfos: {
            ...payload.homeInfos,
            usersCount: newUsersCount,
          },
        };
      }
      return state;
    default:
      return state;
  }
};
