import React from 'react';
import {translate} from 'react-admin';
import {compose} from 'recompose';
import {Card} from '@material-ui/core';
import {ReportProblem} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
  container: {
    minHeight: '70vh',
    paddingLeft: '1em',
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '1em',
  },
  errorMessage: {
    paddingLeft: '1em',
  },
});

const NotGranted = translate(({translate, classes}) => {
  return (
    <Card className={classes.container}>
      <div className={classes.content}>
        <ReportProblem />
        <p className={classes.errorMessage}>
          {translate('custom.commons.errors.not_granted')}
        </p>
      </div>
    </Card>
  );
});

export default compose(translate, withStyles(styles))(NotGranted);
