import React from 'react';
import {withStyles} from '@material-ui/core';
import {Create, translate} from 'react-admin';
import {styles} from './styles';
import {TopBarList, Breadcrumb} from '../../TopBar';

const CreateWithStyles = withStyles(styles)(({translate, classes, ...rest}) => {
  const {basePath, resource} = rest;
  return (
    <>
      <TopBarList>
        <Breadcrumb
          backLink={`/#${basePath}`}
          label={translate(`resources.${resource}.create`)}
        />
      </TopBarList>
      <Create className={classes.root} {...rest} />
    </>
  );
});

export default translate(CreateWithStyles);
