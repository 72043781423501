import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {Link} from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  TextField,
  Select,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/LockOutline';
import theme from '../../theme';
import {
  Notification,
  translate,
  userLogin,
  refreshView,
  changeLocale,
} from 'react-admin';

const styles = theme => ({
  main: {
    minHeight: '100vh',
    backgroundColor: '#' + 222,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    maxWidth: 300 + 'px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
  logo: {
    textAlign: 'center',
  },
  selectLanguage: {
    width: 100 + '%',
  },
});

const languagesList = ['FR', 'EN'];

export const SelectCountryCode = compose(
  translate,
  withStyles(styles),
)(({classes, styles, handleChange, language, translate}) => (
  <FormControl style={styles} className={classes.selectLanguage}>
    <InputLabel htmlFor="select-language">
      {translate('custom.auth.selectLanguage')}
    </InputLabel>
    <Select
      autoWidth
      onChange={handleChange}
      value={language}
      style={{width: '100%'}}
      inputProps={{
        name: 'select-language',
        id: 'select-language',
      }}>
      <MenuItem value="">
        <em>{translate('custom.auth.reset')}</em>
      </MenuItem>
      {languagesList.map(languageItem => (
        <MenuItem
          key={`language-${languageItem.toLowerCase()}`}
          value={languageItem.toLowerCase()}>
          {languageItem}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
));

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: {touched, error} = {},
  input: {...inputProps},
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: localStorage.getItem('language') || 'en',
    };
  }

  handleChange = event => {
    const {value} = event.target;

    this.setState({language: value});
    localStorage.setItem('language', value);
    this.props.changeLocale(value);
  };

  login = auth =>
    this.props.userLogin(
      auth,
      this.props.location.state ? this.props.location.state.nextPathname : '/',
    );

  render() {
    const {classes, handleSubmit, isLoading, translate} = this.props;
    const {language} = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <Grid container className={classes.main}>
          <Grid item xs={12} sm={6} className={classes.logo}>
            <img src="login.png" alt={translate('custom.auth.login')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <form onSubmit={handleSubmit(this.login)}>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      name="username"
                      component={renderInput}
                      label={translate('custom.auth.email')}
                      disabled={isLoading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="password"
                      component={renderInput}
                      label={translate('custom.auth.password')}
                      type="password"
                      disabled={isLoading}
                    />
                  </div>
                  <div className={classes.input}>
                    <SelectCountryCode
                      language={language}
                      handleChange={this.handleChange}
                    />
                  </div>
                </div>

                <CardActions className={classes.actions}>
                  <Button
                    variant="raised"
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                    className={classes.button}
                    fullWidth>
                    {translate('custom.auth.sign_in')}
                  </Button>
                </CardActions>

                <CardActions>
                  <Button
                    variant="raised"
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                    className={classes.button}
                    component={Link}
                    to="/create-account"
                    fullWidth>
                    {translate('custom.auth.create_account')}
                  </Button>
                </CardActions>

                <CardActions>
                  <Button
                    variant="raised"
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                    className={classes.button}
                    component={Link}
                    to="/reset"
                    fullWidth>
                    {translate('custom.auth.forgot_password')}
                  </Button>
                </CardActions>
              </form>
            </Card>
          </Grid>
          <Notification />
        </Grid>
      </MuiThemeProvider>
    );
  }
}

Login.propTypes = {
  ...propTypes,
  authProvider: PropTypes.func,
  classes: PropTypes.object,
  previousRoute: PropTypes.string,
  translate: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired,
};

const mapStateToProps = ({admin, registerReducer}) => ({
  isLoading: admin.loading > 0,
  user_created: registerReducer.user_created,
});

const enhance = compose(
  translate,
  reduxForm({
    form: 'signIn',
    validate: (values, props) => {
      const errors = {};
      const {translate} = props;
      if (!values.username) {
        errors.username = translate('ra.validation.required');
      }
      if (!values.password) {
        errors.password = translate('ra.validation.required');
      }
      return errors;
    },
  }),
  connect(mapStateToProps, {
    userLogin,
    refreshView,
    changeLocale,
  }),
  withStyles(styles),
);

export default enhance(Login);
