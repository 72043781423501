import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import {linkToRecord} from 'ra-core';
import './datagrid.css';
import DatagridCell from './DatagridCell';
import {getEmail, hasRoles} from '../../../utils/auth';

const sanitizeRestProps = ({
  basePath,
  children,
  classes,
  className,
  rowClick,
  id,
  isLoading,
  onToggleItem,
  push,
  record,
  resource,
  rowStyle,
  selected,
  selectedIds,
  style,
  styles,
  version,
  ...rest
}) => rest;

const DatagridRow = props => {
  const {
    basePath,
    classes,
    className,
    hasBulkActions,
    id,
    isLoading,
    onToggleItem,
    record,
    resource,
    rowClick,
    rowStyle,
    selected,
    selectedIds,
    style,
    styles,
    version,
    ...rest
  } = props;

  const [expanded, setExpanded] = useState(true);

  const toggleExpand = () => setExpanded(!expanded);

  const handleToggle = useCallback(() => {
    onToggleItem(id);
  }, [onToggleItem, id]);

  const handleClick = useCallback(
    event => {
      event.stopPropagation();
      if (!rowClick) return;
      if (rowClick === 'edit') {
        push(linkToRecord(basePath, id));
      }
      if (rowClick === 'show') {
        push(linkToRecord(basePath, id, 'show'));
      }
      if (typeof rowClick === 'function') {
        push(rowClick(id, basePath));
      }
    },
    [basePath, rowClick, id, push],
  );

  if (Object.values(record).length === 0) return null;
  const userEmail = getEmail();
  const isUser = hasRoles(['ROLE_USER']);

  const isRelatedToAuthenticatedUser =
    !isUser ||
    (Array.isArray(record.relatedUsers) &&
      record.relatedUsers.some(user => userEmail));

  if (!isRelatedToAuthenticatedUser) {
    return null;
  }

  const subStructures = record.children
    ? record.children
        .filter(c => c['@id'])
        .filter(
          c =>
            !isUser ||
            (Array.isArray(c.relatedUsers) &&
              c.relatedUsers.some(user => userEmail)),
        )
    : [];

  const withFilters = props.filter
    ? Object.keys(props.filter).length > 0
    : false;

  return withFilters ? (
    <TableRow
      className={className}
      key={id}
      style={style}
      onClick={handleClick}
      {...sanitizeRestProps(rest)}>
      <TableCell padding="none" colSpan={4}>
        <div
          className={className}
          key={id}
          style={{width: 100 + '%'}}
          {...sanitizeRestProps(rest)}>
          <div style={{display: 'flex'}}>
            {hasBulkActions && (
              <div className="border-right">
                <Checkbox
                  color="primary"
                  className={`select-item ${classes.checkbox}`}
                  checked={selected}
                  onClick={handleToggle}
                />
              </div>
            )}
            <DatagridCell
              className={classes.rowCell}
              style={{width: 100 + '%', display: 'flex'}}
              record={record}
              id={id}
              withParents
              {...{basePath, resource}}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow
      className={className}
      key={id}
      style={style}
      onClick={handleClick}
      {...sanitizeRestProps(rest)}>
      <TableCell padding="none" colSpan={4}>
        <ExpansionPanel
          expanded={expanded}
          className={className}
          key={id}
          style={{width: 100 + '%'}}
          onClick={handleClick}
          {...sanitizeRestProps(rest)}>
          <ExpansionPanelSummary
            expandIcon={
              subStructures.length ? (
                <ExpandMore onClick={toggleExpand} />
              ) : null
            }>
            {hasBulkActions && (
              <div className="border-right">
                <Checkbox
                  color="primary"
                  className={`select-item ${classes.checkbox}`}
                  checked={selected}
                  onClick={handleToggle}
                />
              </div>
            )}
            <DatagridCell
              className={classes.rowCell}
              style={{width: 100 + '%', display: 'flex'}}
              record={record}
              id={id}
              {...{basePath, resource}}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {subStructures.map((child, rowIndex) => {
              const childProps = {
                basePath,
                classes,
                hasBulkActions,
                id: child['@id'],
                key: child['@id'],
                onToggleItem,
                record: child,
                resource,
                rowClick,
                rowStyle,
                selected: selectedIds.includes(child['@id']),
                selectedIds,
                style: rowStyle ? rowStyle(child, rowIndex) : null,
                version,
              };
              return <DatagridRow {...childProps} />;
            })}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </TableCell>
    </TableRow>
  );
};

DatagridRow.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasBulkActions: PropTypes.bool.isRequired,
  id: PropTypes.any,
  onToggleItem: PropTypes.func,
  push: PropTypes.func,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  selected: PropTypes.bool,
  style: PropTypes.object,
  styles: PropTypes.object,
};

DatagridRow.defaultProps = {
  hasBulkActions: false,
  record: {},
  selected: false,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
DatagridRow.displayName = 'DatagridRow';

export default connect(null, {push})(DatagridRow);
