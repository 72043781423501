import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {
  CREATE_CLIENT_ADMIN,
  CREATE_CLIENT_ADMIN_ERROR,
  CREATE_CLIENT_ADMIN_SUCCESS,
} from '../components/CreateTechnician/store/action';
import {getToken} from '../utils/auth';
import {client} from '../utils/api-client';
import {errorMessageHandler} from './helpers';

function* handleUpdate(action) {
  const {type, payload, onSuccess} = action;

  try {
    const contentHeaders = new Headers();
    contentHeaders.append('Accept', 'application/ld+json');
    contentHeaders.append('Content-Type', 'application/ld+json');
    if (getToken()) {
      contentHeaders.append('Authorization', `Bearer ${getToken()}`);
    }

    switch (type) {
      case CREATE_CLIENT_ADMIN: {
        try {
          yield client('users', {data: payload});
          yield put({
            type: CREATE_CLIENT_ADMIN_SUCCESS,
          });
          if (onSuccess) onSuccess();
          break;
        } catch (e) {
          yield put({
            type: CREATE_CLIENT_ADMIN_ERROR,
          });
        }
        break;
      }
      default:
        break;
    }
  } catch (e) {
    yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
  }
}

export default function* watchUsersActions() {
  yield all([takeEvery(CREATE_CLIENT_ADMIN, handleUpdate)]);
}
