export const sortByPosition = (a, b) => {
  if (a.position > b.position) return 1;
  if (a.position < b.position) return -1;
  return 0;
};

export const sortByStart = (a, b) => {
  if (a.start > b.start) return 1;
  if (a.start < b.start) return -1;
  return 0;
};

export const toFahrenheit = val => Math.round(val * 1.8 + 32);
export const fromFahrenheit = val => Math.round((val - 32) / 1.8);

export const formatFanPhase = phase =>
  phase
    ? phase.phases.sort(sortByPosition).map(p => ({
        start: parseInt(
          p.values.find(c => c.criteria === 'start').value / 60,
          10,
        ),
        speed: p.values.find(c => c.criteria === 'speed').value,
      }))
    : [];

export const formatHeatPhases = (phase, farehneit = false) =>
  phase.phases
    ? phase.phases.sort(sortByPosition).map(p => ({
        temperature: farehneit
          ? toFahrenheit(p.values.find(c => c.criteria === 'temperature').value)
          : p.values.find(c => c.criteria === 'temperature').value,
        duration: parseInt(
          p.values.find(c => c.criteria === 'duration').value / 60,
          10,
        ),
        notification: p.values.find(c => c.criteria === 'notification')
          ? !!p.values.find(c => c.criteria === 'notification').value
          : false,
      }))
    : [];

export const formatSteamPhase = phase =>
  phase
    ? phase.phases.sort(sortByPosition).map(p => ({
        start: parseInt(
          p.values.find(c => c.criteria === 'start').value / 60,
          10,
        ),
        volume: p.values.find(c => c.criteria === 'volume').value,
      }))
    : [];

export const formatDamperPhase = phase =>
  phase
    ? phase.phases.sort(sortByPosition).map(p => ({
        start: p.values.find(c => c.criteria === 'start').value / 60,
        duration: p.values.find(c => c.criteria === 'duration').value / 60,
      }))
    : [];

export const getTotalDuration = phase => {
  if (!phase) return 0;
  const total = phase.phases.reduce((acc, phase) => {
    // Trouver l'objet associé à "duration" dans les valeurs de la phase
    const durationValue = phase.values.find(
      value => value.criteria === 'duration',
    );
    // Ajouter la valeur de "duration" à l'accumulateur si elle est définie
    if (durationValue) {
      return acc + durationValue.value;
    } else {
      return acc; // Ignorer si "duration" n'est pas trouvé
    }
  }, 0);
  return total / 60;
};

export const shouldDeletePhases = (phase, totalDuration) => {
  if (!phase) return false;

  return phase.phases.some(p => {
    const startCriteria = p.values.find(c => c.criteria === 'start');
    return startCriteria && startCriteria.value / 60 > totalDuration - 1;
  });
};
