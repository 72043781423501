import React from 'react';
import {MenuItemLink} from 'react-admin';
import {
  Book,
  Home,
  Image,
  LocalOffer,
  People,
  StoreMallDirectory,
  SystemUpdateAlt,
} from '@material-ui/icons';
import {MenuList} from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import {translate} from 'react-admin';
import {hasRoles} from './utils/auth';

const Menu = ({onMenuClick, translate}) => (
  <MenuList>
    <MenuItemLink
      to="/"
      primaryText={translate('custom.components.menu.home')}
      leftIcon={<Home />}
      onClick={onMenuClick}
    />
    {hasRoles(['ROLE_CLIENT_ADMIN']) && (
      <MenuItemLink
        to="/users"
        primaryText={translate('custom.components.menu.users')}
        leftIcon={<People />}
        onClick={onMenuClick}
      />
    )}
    {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_ACCESS']) && (
      <MenuItemLink
        to="/structures"
        primaryText={translate('custom.components.menu.structures')}
        leftIcon={<StoreMallDirectory />}
        onClick={onMenuClick}
      />
    )}
    {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAG_ACCESS']) && (
      <MenuItemLink
        to="/tags"
        primaryText={translate('custom.components.menu.tags')}
        leftIcon={<LocalOffer />}
        onClick={onMenuClick}
      />
    )}
    {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_ACCESS']) && (
      <MenuItemLink
        to="/programs"
        primaryText={translate('custom.components.menu.programs')}
        leftIcon={<Book />}
        onClick={onMenuClick}
      />
    )}
    {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_MACHINE_ACCESS']) && (
      <MenuItemLink
        to="/machines"
        primaryText={translate('custom.components.menu.machines')}
        leftIcon={
          <SvgIcon viewBox="-5 0 30 30">
            <path d="M18.37,0H2.09A2.1,2.1,0,0,0,0,2.09V28A2.1,2.1,0,0,0,2.09,30H18.37A2.1,2.1,0,0,0,20.46,28V2.09A2.1,2.1,0,0,0,18.37,0ZM12.66,19.7a2.09,2.09,0,0,1-2.09,2.09H6.34A2.1,2.1,0,0,1,4.25,19.7V6.37A2.1,2.1,0,0,1,6.34,4.28h4.23a2.09,2.09,0,0,1,2.09,2.09Zm5.07-2.59h-3V9.63h3Z" />
          </SvgIcon>
        }
        onClick={onMenuClick}
      />
    )}
  </MenuList>
);

export const MyEurofoursMenu = translate(({onMenuClick, translate}) => (
  <MenuList>
    <MenuItemLink
      to="/"
      primaryText={translate('custom.components.menu.home')}
      leftIcon={<Home />}
      onClick={onMenuClick}
    />
    {hasRoles(['ROLE_SUPERADMIN']) && (
      <MenuItemLink
        to="/eurofours-technicians"
        primaryText={translate('custom.components.menu.eurofoursTechnicians')}
        leftIcon={<People />}
        onClick={onMenuClick}
      />
    )}
    {hasRoles(['ROLE_SUPERADMIN', 'ROLE_EUROFOURS_TECHNICIAN']) && (
      <MenuItemLink
        to="/client-admins"
        primaryText={translate('custom.components.menu.clients')}
        leftIcon={<People />}
        onClick={onMenuClick}
      />
    )}
    {hasRoles(['ROLE_SUPERADMIN']) && (
      <>
        <MenuItemLink
          to="/images-eurofours"
          primaryText={translate('custom.components.appbar.programsImages')}
          leftIcon={<Image />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to="/images-machines"
          primaryText={translate('custom.components.appbar.machinesImages')}
          leftIcon={<Image />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to="/software-updates"
          primaryText={translate('custom.components.menu.softwareUpdates')}
          leftIcon={<SystemUpdateAlt />}
          onClick={onMenuClick}
        />
      </>
    )}
    {!hasRoles(['ROLE_SUPERADMIN', 'ROLE_EUROFOURS_TECHNICIAN']) && (
      <>
        <MenuItemLink
          to="/structures"
          primaryText={translate('custom.components.menu.structures')}
          leftIcon={<StoreMallDirectory />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to="/tags"
          primaryText={translate('custom.components.menu.tags')}
          leftIcon={<LocalOffer />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to="/programs"
          primaryText={translate('custom.components.menu.programs')}
          leftIcon={<Book />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to="/machines"
          primaryText={translate('custom.components.menu.machines')}
          leftIcon={
            <SvgIcon viewBox="-5 0 30 30">
              <path d="M18.37,0H2.09A2.1,2.1,0,0,0,0,2.09V28A2.1,2.1,0,0,0,2.09,30H18.37A2.1,2.1,0,0,0,20.46,28V2.09A2.1,2.1,0,0,0,18.37,0ZM12.66,19.7a2.09,2.09,0,0,1-2.09,2.09H6.34A2.1,2.1,0,0,1,4.25,19.7V6.37A2.1,2.1,0,0,1,6.34,4.28h4.23a2.09,2.09,0,0,1,2.09,2.09Zm5.07-2.59h-3V9.63h3Z" />
            </SvgIcon>
          }
          onClick={onMenuClick}
        />
      </>
    )}
  </MenuList>
));

export default translate(Menu);
