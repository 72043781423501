import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {
  IMAGES_GET_EUROFOURS,
  IMAGES_GET_EUROFOURS_SUCCESS,
  IMAGES_DELETE_EUROFOURS,
  IMAGES_DELETE_EUROFOURS_SUCCESS,
} from '../components/ImagesEurofours/store/action';
import {
  IMAGES_GET_MACHINES,
  IMAGES_GET_MACHINE,
  IMAGES_GET_MACHINE_SUCCESS,
  IMAGES_GET_MACHINES_SUCCESS,
  IMAGES_DELETE_MACHINES,
  IMAGES_DELETE_MACHINES_SUCCESS,
} from '../components/ImagesMachines/store/action';
import {
  IMAGES_DELETE_GALLERY,
  IMAGES_GET_GALLERY,
  IMAGES_GET_GALLERY_SUCCESS,
  IMAGES_DELETE_GALLERY_SUCCESS,
} from '../components/UserGallery/store/action';
import {client} from '../utils/api-client';

function* handleImage(action) {
  const {type, payload} = action;
  switch (type) {
    case IMAGES_GET_EUROFOURS: {
      try {
        const response = yield client('images/eurofours');
        yield put({
          type: IMAGES_GET_EUROFOURS_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(
          showNotificationAction('Error, we cannot retrieve images', 'warning'),
        );
      }
      break;
    }
    case IMAGES_DELETE_EUROFOURS: {
      try {
        const response = yield client('images/eurofours', {
          data: payload,
          method: 'DELETE',
        });

        yield put({
          type: IMAGES_DELETE_EUROFOURS_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(
          showNotificationAction('Error, we cannot retrieve images', 'warning'),
        );
      }
      break;
    }
    case IMAGES_GET_MACHINE: {
      try {
        const response = yield client(
          'images/machines/metadata/' + payload.machineCode,
        );
        yield put({
          type: IMAGES_GET_MACHINE_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(
          showNotificationAction(
            'Error, we cannot retrieve this image metadata',
            'warning',
          ),
        );
      }
      break;
    }
    case IMAGES_GET_MACHINES: {
      try {
        const response = yield client('images/machines');
        yield put({
          type: IMAGES_GET_MACHINES_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(
          showNotificationAction('Error, we cannot retrieve images', 'warning'),
        );
      }
      break;
    }
    case IMAGES_DELETE_MACHINES: {
      try {
        yield client('images/machines/' + payload.machineCode, {
          method: 'DELETE',
        });

        yield put({
          type: IMAGES_DELETE_MACHINES_SUCCESS,
          payload,
        });
      } catch (e) {
        yield put(
          showNotificationAction('Error, we cannot retrieve images', 'warning'),
        );
      }
      break;
    }
    case IMAGES_GET_GALLERY: {
      try {
        const response = yield client('images/me');
        yield put({
          type: IMAGES_GET_GALLERY_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(
          showNotificationAction('Error, we cannot retrieve images', 'warning'),
        );
      }
      break;
    }
    case IMAGES_DELETE_GALLERY: {
      try {
        const response = yield client('images/me', {
          data: payload,
          method: 'DELETE',
        });
        yield put({
          type: IMAGES_DELETE_GALLERY_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(
          showNotificationAction('Error, we cannot retrieve images', 'warning'),
        );
      }
      break;
    }
    default:
      break;
  }
}

export default function* watchImagesActions() {
  yield all([takeEvery(IMAGES_GET_EUROFOURS, handleImage)]);
  yield all([takeEvery(IMAGES_DELETE_EUROFOURS, handleImage)]);
  yield all([takeEvery(IMAGES_GET_MACHINE, handleImage)]);
  yield all([takeEvery(IMAGES_GET_MACHINES, handleImage)]);
  yield all([takeEvery(IMAGES_DELETE_MACHINES, handleImage)]);
  yield all([takeEvery(IMAGES_GET_GALLERY, handleImage)]);
  yield all([takeEvery(IMAGES_DELETE_GALLERY, handleImage)]);
}
