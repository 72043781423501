export const USER_CREATE = 'USER_CREATE';
export const USER_CREATE_LOADING = 'USER_CREATE_LOADING';
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';

export const CLIENT_ADMIN_CREATE = 'CLIENT_ADMIN_CREATE';
export const CLIENT_ADMIN_CREATE_LOADING = 'CLIENT_ADMIN_CREATE_LOADING';
export const CLIENT_ADMIN_CREATE_FAILURE = 'CLIENT_ADMIN_CREATE_FAILURE';
export const CLIENT_ADMIN_CREATE_SUCCESS = 'CLIENT_ADMIN_CREATE_SUCCESS';

export const userCreate = (payload, pathName) => ({
  type: USER_CREATE,
  payload,
  meta: {auth: true, pathName},
});

export const clientAdminCreate = payload => ({
  type: CLIENT_ADMIN_CREATE,
  payload,
});
