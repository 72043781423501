import React from 'react';
import Label from './label';
import {Typography} from '@material-ui/core';
import {translate} from 'react-admin';

export default translate(({translate, choices}) => (
  <div style={{paddingTop: 1 + 'em'}}>
    {choices.length > 0 ? (
      <div style={{textAlign: 'center'}}>
        {choices.map((tag, index) => (
          <Label key={index} tag={tag} />
        ))}
      </div>
    ) : (
      <Typography>{translate('custom.components.tags.none')}</Typography>
    )}
  </div>
));
