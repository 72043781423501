import * as actions from './action';
import {setToken} from '../../../utils/auth';

const initialState = {
  GAuth: {},
  isVerified: false,
  loading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.USER_VERIFY_2FA:
    case actions.USER_SET_GAUTH_RESOURCE:
    case actions.USER_SET_2FA:
      return {
        ...state,
        loading: true,
      };
    case actions.USER_VERIFY_2FA_ERROR:
    case actions.USER_SET_GAUTH_ERROR:
    case actions.USER_SET_2FA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.USER_VERIFY_2FA_SUCCESS:
      setToken(payload.token);
      return {
        ...state,
        isVerified: true,
        loading: false,
      };
    case actions.USER_SET_GAUTH_SUCCESS:
      return {
        ...state,
        GAuth: payload,
        loading: false,
      };
    case actions.USER_SET_2FA_SUCCESS:
      return {
        ...state,
        loading: false,
        GAuth: initialState.GAuth,
      };
    default:
      return state;
  }
};
