import React from 'react';
import {Grid} from '@material-ui/core';
import {CommonActions} from '../ActionsCards/CommonActions';
import TitleWithRedirect from './titleWithRedirect';

export default ({location, ...rest}) => {
  const {state} = location;

  if (state) {
    return (
      <Grid container>
        <Grid item xs>
          <TitleWithRedirect {...rest} {...state} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs>
        <TitleWithRedirect {...rest} />
      </Grid>
      <Grid item xs>
        <CommonActions {...rest} />
      </Grid>
    </Grid>
  );
};
