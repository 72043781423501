import ProgramCreate from './create';
import ProgramEdit from './edit';
import ProgramList from './list';
import ProgramShow from './show';

export default programs => {
  programs.create = ProgramCreate;
  programs.edit = ProgramEdit;
  programs.list = ProgramList;
  programs.show = ProgramShow;

  return programs;
};
