import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {formValueSelector, change} from 'redux-form';
import {compose} from 'recompose';
import classNames from 'classnames';
import {REDUX_FORM_NAME} from 'ra-core';

const styles = theme => ({
  labelCommon: {
    display: 'inline-block',
    lineHeight: '24px',
    position: 'relative',
    margin: '0 16px 8px 0',
    padding: '0 10px 0 20px',
    boxShadow: 'inset 0 0 0 1px ' + theme.palette.primary.main,
    borderRadius: '12px 4px 4px 12px',
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:after': {
      content: "''",
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      left: 8,
      float: 'left',
      width: 5,
      height: 5,
      borderRadius: '50%',
      background: '#fff',
      border: '1px solid' + theme.palette.primary.main,
    },
  },
  labelSelected: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
});

class Label extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  updateTags = value => this.props.change(REDUX_FORM_NAME, 'tags', value);

  handleSelect = () => {
    const {tags, tag} = this.props;
    const isSelected = tags.includes(tag['@id']);

    if (isSelected) {
      this.updateTags(tags.filter(t => t !== tag['@id']));
    } else {
      if (tags.length < 5) {
        this.updateTags(tags.concat([tag['@id']]));
      }
    }
  };

  handleHoverIn = () => {
    this.setState({isHovered: true});
  };

  handleHoverOut = () => {
    this.setState({isHovered: false});
  };

  render() {
    const {classes, tags, tag} = this.props;
    const {isHovered} = this.state;
    const isSelected = tags ? tags.includes(tag['@id']) : false;

    return (
      <div
        className={classNames(classes.labelCommon, {
          [classes.labelSelected]: isSelected || isHovered,
        })}
        onMouseOverCapture={this.handleHoverIn}
        onMouseOutCapture={this.handleHoverOut}
        onClick={this.handleSelect}>
        {tag.name}
      </div>
    );
  }
}

const selector = formValueSelector(REDUX_FORM_NAME);

const mapStateToProps = state => ({
  tags: selector(state, 'tags'),
});

export default compose(
  connect(mapStateToProps, {change}),
  withStyles(styles),
)(Label);
