import * as actions from '../../../action/registerAction';

const initialState = {
  user_created: false,
  isLoading: false,
};

export default (state = initialState, {type}) => {
  switch (type) {
    case actions.USER_CREATE_SUCCESS:
      return {
        ...state,
        user_created: true,
        isLoading: false,
      };
    case actions.USER_CREATE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.CLIENT_ADMIN_CREATE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.CLIENT_ADMIN_CREATE_SUCCESS:
      return {
        ...state,
        user_created: true,
        isLoading: false,
      };
    case actions.USER_CREATE_FAILURE:
      return {
        ...state,
        user_created: true,
        isLoading: false,
      };
    case actions.CLIENT_ADMIN_CREATE_FAILURE:
      return {
        ...state,
        user_created: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
