import React, {useEffect, useCallback} from 'react';
import {isEmpty} from 'lodash';
import {translate} from 'react-admin';
import ParametersDisplay from '../../../Parametrage';
import {formatParameters} from '../../../Parametrage/ParametersFactory';
import {Grid, Typography} from '@material-ui/core';
import {Navigation} from '../../Navigation/formTabNavigation';
import {defaultPhaseGroups} from '../../Form/PhaseGroupFactory';
import {connect} from 'react-redux';
import {linkToRecord, REDUX_FORM_NAME} from 'ra-core';
import {formValueSelector, change} from 'redux-form';
import {getProgram} from '../../store/action';
import ProgramPhasesInput from '../../PhaseGroups/ProgramPhasesInput';

const ProgramEditParameters = props => {
  const {phaseGroups, category, change, record, isFahrenheit} = props;

  useEffect(() => {
    if (isEmpty(phaseGroups)) {
      change(REDUX_FORM_NAME, 'phaseGroups', defaultPhaseGroups);
    }
  }, [phaseGroups]);

  if (!category) {
    return <div></div>;
  }

  const onChange = useCallback(
    val => {
      change(REDUX_FORM_NAME, 'phaseGroups', val);
    },
    [change],
  );

  return (
    <>
      <Navigation
        description="custom.components.programs.create.fillsettings"
        back={linkToRecord('/programs', record.id)}
      />

      {!category ? (
        <Typography style={{textAlign: 'center'}}>
          {translate('category: validators.program.category.not_blank')}
        </Typography>
      ) : (
        <Grid container direction="column">
          <Grid item>
            {record && (
              <ProgramPhasesInput
                phaseGroups={phaseGroups}
                source={phaseGroups}
                label="Phases"
                onChange={onChange}
                isFahrenheit={isFahrenheit}
              />
            )}
          </Grid>
          <Grid item>
            <ParametersDisplay
              isFahrenheit={isFahrenheit}
              {...formatParameters(phaseGroups, isFahrenheit)}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const selector = formValueSelector(REDUX_FORM_NAME);

const mapStateToProps = ({programReducer, profileReducer, ...state}) => {
  return {
    category: selector(state, 'category'),
    phaseGroups: selector(state, 'phaseGroups'),
    program: programReducer.program,
    isLoading: programReducer.loading,
    refresh: programReducer.refresh,
    isFahrenheit:
      profileReducer.profile &&
      profileReducer.profile.temperatureUnitPreference === 'F',
  };
};

export default connect(mapStateToProps, {
  change,
  getProgram,
})(ProgramEditParameters);
