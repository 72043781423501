import React, {useMemo} from 'react';
import {DeleteWithConfirmButton} from 'react-admin';
import {Show} from '../Common/Admin';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import UserShowView from './view/showView';
import Title from '../Common/Header';
import UsersActions from '../ActionsCards/UsersActions';
import {hasRoles} from '../../utils/auth';
import {TopBarList, Breadcrumb} from '../TopBar';
import {translate} from 'react-admin';

const UserShow = props => {
  if (!hasRoles(['ROLE_CLIENT_ADMIN'])) {
    window.location.replace('/#/not-granted');
  }

  const {usersData, ...rest} = props;
  const {id} = rest;
  const user = usersData[id];

  const userName = useMemo(
    () => (user ? `${user.firstName} ${user.lastName}` : ''),
    [user],
  );
  return (
    <>
      <TopBarList>
        <Breadcrumb
          categoryName="custom.components.menu.users"
          label={user ? userName : ' '}
        />
        {user && (
          <div>
            {hasRoles(['ROLE_CLIENT_ADMIN']) && (
              <DeleteWithConfirmButton
                record={{id: user['@id']}}
                resource="users"
                basePath="/users"
              />
            )}
          </div>
        )}
      </TopBarList>
      <Show
        title={<Title redirect="list" {...props} />}
        actions={<UsersActions displayTotal={false} />}
        {...rest}>
        <UserShowView />
      </Show>
    </>
  );
};

const mapStateToProps = ({admin}) => {
  return {
    usersData: admin.resources.users.data,
  };
};

export default compose(translate, connect(mapStateToProps))(UserShow);
