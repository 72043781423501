import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Card, CardHeader, CardContent, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';
import ImageUploader from '../ImageUploader/machines';
import {getImageMachine} from './store/action';
import {translate} from 'react-admin';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  cardBody: {
    padding: 2 + 'rem',
    '& a': {
      fontStyle: 'italic',
    },
  },
  card: {
    padding: 0,
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
  },
  cardHeader: {
    padding: '0.5em 1em 0.5em 2em',
    backgroundColor: '#ededed',
  },
});

class UpdateMachineImage extends Component {
  componentDidMount() {
    if (
      !this.props.imageMachine ||
      this.props.imageMachine.machineCode !==
        this.props.match.params.machineCode
    )
      this.props.getImageMachine({
        machineCode: this.props.match.params.machineCode,
      });
  }

  render() {
    const {theme, imageMachine, translate, classes} = this.props;
    const isRealMachine =
      imageMachine &&
      imageMachine.machineCode === this.props.match.params.machineCode;
    return isRealMachine ? (
      <Card className={classes.card}>
        <CardHeader
          title={
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                {translate('custom.title.image_update', {
                  code: imageMachine.machineCode,
                })}
              </Grid>
            </Grid>
          }
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardBody}>
          <div dir={theme.direction}>
            <ImageUploader
              update
              machineDescription={imageMachine.machineDescription}
              machineCode={imageMachine.machineCode}
            />
          </div>
        </CardContent>
      </Card>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = ({imagesMachinesReducer}) => ({
  imageMachine: imagesMachinesReducer.imageMachine,
});

export default compose(
  translate,
  connect(mapStateToProps, {getImageMachine}),
  withRouter,
  withStyles(styles, {withTheme: true}),
)(UpdateMachineImage);
