// CustomInputComponent.js
import React, {useMemo, useCallback, useEffect} from 'react';
import PhaseGroupHeat from './PhaseGroupHeat';
import PhaseGroupSteam from './PhaseGroupSteam';
import PhaseGroupDamper from './PhaseGroupDamper';
import PhaseGroupFan from './PhaseGroupFan';
import {getTotalDuration} from './utils';

const ProgramPhasesInput = props => {
  const {phaseGroups, label, onChange, isFahrenheit, setFormValid} = props;

  const formattedData = useMemo(() => {
    const heat = phaseGroups.find(p => p.phaseGroupType === 'heat');
    const fan = phaseGroups.find(p => p.phaseGroupType === 'fan');
    const steam = phaseGroups.find(p => p.phaseGroupType === 'steam');
    const damper = phaseGroups.find(p => p.phaseGroupType === 'damper');
    return {
      heat,
      fan,
      steam,
      damper,
    };
  }, [phaseGroups]);

  const totalDuration = useMemo(() => getTotalDuration(formattedData.heat), [
    formattedData,
  ]);

  useEffect(() => {
    if (setFormValid) setFormValid(totalDuration > 0);
  }, [setFormValid, totalDuration]);

  const onSteamChange = useCallback(
    val => {
      const result = [
        formattedData.heat,
        formattedData.fan,
        val,
        formattedData.damper,
      ].filter(p => p !== undefined);
      onChange(result);
    },
    [formattedData, onChange],
  );

  const onDamperChange = useCallback(
    val => {
      const result = [
        formattedData.heat,
        formattedData.fan,
        formattedData.steam,
        val,
      ].filter(p => p !== undefined);
      onChange(result);
    },
    [formattedData, onChange],
  );

  const onFanChange = useCallback(
    val => {
      const result = [
        formattedData.heat,
        val,
        formattedData.steam,
        formattedData.damper,
      ].filter(p => p !== undefined);
      onChange(result);
    },
    [formattedData, onChange],
  );

  const onHeatChange = useCallback(
    val => {
      const result = [
        val,
        formattedData.fan,
        formattedData.steam,
        formattedData.damper,
      ].filter(p => p !== undefined);
      onChange(result);
    },
    [formattedData, onChange],
  );

  return (
    <div>
      <h3>{label}</h3>
      <PhaseGroupHeat
        phaseGroups={phaseGroups}
        phase={formattedData.heat}
        onChange={onHeatChange}
        totalDuration={totalDuration}
        isFahrenheit={isFahrenheit}
      />
      <PhaseGroupSteam
        totalDuration={totalDuration}
        phase={formattedData.steam}
        onChange={onSteamChange}
      />
      <PhaseGroupDamper
        totalDuration={totalDuration}
        phase={formattedData.damper}
        onChange={onDamperChange}
      />
      <PhaseGroupFan
        totalDuration={totalDuration}
        phase={formattedData.fan}
        onChange={onFanChange}
      />
    </div>
  );
};

export default ProgramPhasesInput;
