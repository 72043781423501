import React, {Fragment} from 'react';
import {linkToRecord} from 'ra-core';
import {Navigation} from '../../Navigation/formTabNavigation';
import {TextInput, required, maxLength, ReferenceArrayInput} from 'react-admin';
import Images from '../../Image';
import Label from '../../../Label';
import {Grid} from '@material-ui/core';

const validateName = [required(), maxLength(40)];
const validateDescription = [maxLength(144)];

export default ({record, resource, basePath}) => (
  <Fragment>
    <Navigation
      description="custom.components.programs.create.name"
      forward={`${linkToRecord('/programs', record.id)}/1`}
    />
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      spacing={40}>
      <Grid item>
        <TextInput
          source="name"
          fullWidth
          validate={validateName}
          record={record}
        />
        <TextInput
          source="description"
          fullWidth
          validate={validateDescription}
          record={record}
        />
      </Grid>
      <Grid item>
        <Images />
      </Grid>
      <Grid item>
        <ReferenceArrayInput
          source="tags"
          reference="tags"
          perPage={100}
          record={record}
          resource={resource}
          basePath={basePath}>
          <Label />
        </ReferenceArrayInput>
      </Grid>
    </Grid>
  </Fragment>
);
