import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {translate} from 'react-admin';
import {withRouter} from 'react-router';

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  returnButton: {
    height: 23,
    width: 20,
    borderRadius: 3,
    marginRight: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #bebebe',
    fontSize: 0.5 + 'em',
    color: 'black',
    cursor: 'pointer',
  },
});

const Breadcrumb = ({
  classes,
  categoryName,
  label,
  history,
  translate,
  backLink,
}) => (
  <div className={classes.container}>
    {backLink ? (
      <a href={backLink} className={classes.returnButton}>
        {'<'}
      </a>
    ) : (
      <button onClick={() => history.goBack()} className={classes.returnButton}>
        {'<'}
      </button>
    )}
    <div>{label || translate(categoryName)}</div>
  </div>
);

Breadcrumb.propTypes = {
  categoryName: PropTypes.string,
  label: PropTypes.string,
};

export default compose(translate, withRouter, withStyles(styles))(Breadcrumb);
