import * as actions from './action';
import {CRUD_UPDATE_SUCCESS} from 'react-admin';
import {CREATE_TAG_SUCCESS} from '../../Tag/store/action';

const initialState = {
  loading: true,
  program: {},
  programCreated: false,
  refresh: false,
  tagCreated: null,
};

export default (state = initialState, {type, payload, meta}) => {
  switch (type) {
    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        tagCreated: payload['@id'],
      };
    case actions.RESET_PROGRAM:
      return initialState;
    case actions.RESET_CREATED_TAG:
      return {
        ...state,
        tagCreated: null,
      };
    case actions.CREATE_PROGRAM:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_PROGRAM_SUCCESS:
      return {
        ...state,
        program: payload,
        programCreated: true,
        loading: false,
      };
    case actions.PROGRAMS_GET_ONE_RESOURCE:
      return initialState;
    case actions.PROGRAMS_GET_ONE_SUCCESS:
      let program = payload;
      let phasesGroupReordered = program.phaseGroups.map(phaseGroup => {
        /**
         * 'fan' is currently '/phase_group_types/2'
         * /!\ if this nomenclature changes in future this will not work anymore
         * */
        if ('/phase_group_types/2' === phaseGroup.phaseGroupType) {
          phaseGroup.phases.forEach(phase => {
            return phase.values.reverse();
          });
          return phaseGroup;
        }
        return phaseGroup;
      });
      program.phaseGroups = phasesGroupReordered;
      return {
        ...state,
        program: program,
        loading: false,
      };
    case CRUD_UPDATE_SUCCESS:
      if ('programs' === meta.resource) {
        return {...state, refresh: true};
      }
      return state;
    default:
      return state;
  }
};
