import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {push} from 'react-router-redux';
import {
  SOFTWARE_UPDATES_POST_RESOURCE,
  SOFTWARE_UPDATES_POST_RESOURCE_SUCCESS,
  SOFTWARE_UPDATES_PUT_RESOURCE,
  SOFTWARE_UPDATES_PUT_RESOURCE_SUCCESS,
} from '../components/SoftwareUpdates/store/action';
import {SOFTWARE_UPDATES_PATH} from '../constants';
import {client} from '../utils/api-client';

function* handleSoftwareUpdates(action) {
  const {type, payload} = action;

  switch (type) {
    case SOFTWARE_UPDATES_POST_RESOURCE:
      {
        // TODO: Check that this part works (sending FormData via client)
        const body = JSON.stringify({
          version: payload.version,
          beta: payload.beta,
          description: payload.description,
        });
        const formData = new FormData();
        formData.append('data', body);
        formData.append('file', payload.file.rawFile);

        try {
          yield client('software_updates', {
            data: {
              formData,
            },
          });
          yield put(push(SOFTWARE_UPDATES_PATH));
          yield put({type: SOFTWARE_UPDATES_POST_RESOURCE_SUCCESS, payload});
        } catch (e) {
          yield put(showNotificationAction(e.statusText, 'warning'));
        }
      }
      break;
    case SOFTWARE_UPDATES_PUT_RESOURCE:
      try {
        yield client(payload.id, {
          data: {
            description: payload.description,
            revoked: payload.revoked,
          },
        });
        yield put({type: SOFTWARE_UPDATES_PUT_RESOURCE_SUCCESS, payload});
        yield put(
          showNotificationAction(
            'custom.components.software_updates.edit.success',
            'success',
          ),
        );
      } catch (e) {
        yield put(showNotificationAction(e.statusText, 'warning'));
      }

      break;
    default:
      break;
  }
}

export default function* watchSoftwareUpdatesActions() {
  yield all([
    takeEvery(SOFTWARE_UPDATES_POST_RESOURCE, handleSoftwareUpdates),
    takeEvery(SOFTWARE_UPDATES_PUT_RESOURCE, handleSoftwareUpdates),
  ]);
}
