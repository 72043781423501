import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import theme from '../../../theme';

const NotificationIcon = props => (
  <SvgIcon style={{fontSize: '18px'}} {...props}>
    <g fill="#363636" strokeWidth="0">
      <path d="M11.97 23.73c1.29 0 2.34-1.05 2.34-2.34H9.62c0 1.29 1.05 2.34 2.34 2.34zm7.58-7.03v-6.47c0-3.53-2.41-6.6-5.85-7.41v-.8c0-.97-.78-1.75-1.75-1.75s-1.75.78-1.75 1.75v.8a7.616 7.616 0 00-5.85 7.41v6.42l-2.34 2.34v1.17h19.9v-1.17l-2.36-2.3zm-2.33 1.17H6.7v-7.64a5.27 5.27 0 0110.54 0l-.02 7.64zM6.36 1.94L4.8.38A11.914 11.914 0 00.11 9.32h2.25a9.657 9.657 0 014-7.38zM21.61 9.26h2.28c-.16-3.53-1.88-6.8-4.69-8.94l-1.56 1.56a9.702 9.702 0 013.97 7.38z"></path>
    </g>
  </SvgIcon>
);

const NotificationIconChecked = props => (
  <SvgIcon style={{fontSize: '18px'}} {...props}>
    <g fill="red" strokeWidth="0">
      <path d="M11.97 23.73c1.29 0 2.34-1.05 2.34-2.34H9.62c0 1.29 1.05 2.34 2.34 2.34zm7.58-7.03v-6.47c0-3.53-2.41-6.6-5.85-7.41v-.8c0-.97-.78-1.75-1.75-1.75s-1.75.78-1.75 1.75v.8a7.616 7.616 0 00-5.85 7.41v6.42l-2.34 2.34v1.17h19.9v-1.17l-2.36-2.3zm-2.33 1.17H6.7v-7.64a5.27 5.27 0 0110.54 0l-.02 7.64zM6.36 1.94L4.8.38A11.914 11.914 0 00.11 9.32h2.25a9.657 9.657 0 014-7.38zM21.61 9.26h2.28c-.16-3.53-1.88-6.8-4.69-8.94l-1.56 1.56a9.702 9.702 0 013.97 7.38z"></path>
    </g>
  </SvgIcon>
);

const styles = () => ({
  notification: {
    borderRadius: '4px',
    border: '1px solid #ced4da',
    width: '41px',
    height: '41px',
    boxSizing: 'border-box',
    '&:hover': {
      borderColor: '#484747',
      color: '#484747',
      background: 'rgba(128,128,128,0.2)',
    },
  },
  notificationControl: {
    marginLeft: '0',
    marginRight: '4px',
    height: 'fit-content',
    '&.MuiFormControlLabel-disabled': {
      visibility: 'hidden',
    },
  },
  button: {
    borderRadius: '4px',
    width: '41px',
    height: '41px',
    minWidth: '0',
    boxShadow: 'none',
    '&.MuiButton-outlined.MuiButton-colorInherit': {
      border: '1px solid #808080!important',
      color: '#808080',
      '&:hover': {
        borderColor: '#484747',
        color: '#484747',
        background: 'rgba(128,128,128,0.2)',
      },
    },
    '&.MuiButton-outlinedPrimary': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    '&.MuiButton-colorInherit.MuiButton-contained': {
      background: '#808080',
      color: 'white',
      '&:hover': {
        background: '#484747',
      },
    },
    '&.MuiButton-disabled': {
      opacity: 0.3,
    },
  },
  buttons: {
    color: '#808080',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    marginLeft: '16px',
  },
});

const CriteriaLine = ({
  children,
  withNotification,
  notification,
  canAdd,
  canDelete,
  classes,
  onNotificationChange,
  onAdd,
  onRemove,
  isLast,
}) => {
  return (
    <>
      <div style={{display: 'flex', gap: '4px', flexDirection: 'row'}}>
        {children}
        <FormControlLabel
          className={classes.notificationControl}
          control={
            <Checkbox
              icon={<NotificationIcon />}
              checkedIcon={<NotificationIconChecked />}
              checked={notification}
              disabled={!withNotification}
              className={classes.notification}
              onChange={onNotificationChange}
            />
          }
          label=""
        />
        <div className={classes.buttons}>
          <Button
            onClick={onRemove}
            color="inherit"
            disabled={!canDelete}
            variant={isLast ? 'contained' : 'outlined'}
            aria-label="Remove"
            className={classNames(
              classes.button,
              isLast && 'button-last',
              !canAdd && 'button-disabled',
            )}>
            <CloseIcon />
          </Button>
          <Button
            onClick={onAdd}
            color="primary"
            disabled={!canAdd}
            variant={isLast ? 'contained' : 'outlined'}
            aria-label="Add"
            className={classNames(
              classes.button,
              isLast && 'button-last',
              !canAdd && 'button-disabled',
            )}>
            <AddIcon />
          </Button>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(CriteriaLine);
