import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {getId} from '../utils/auth';
import {
  IMAGE_SENDING,
  IMAGE_SEND_SUCCESS,
  IMAGE_SEND_FAILURE,
  IMAGE_EUROFOURS_SEND_REQUEST,
  IMAGE_MACHINES_SEND_REQUEST,
  IMAGE_MACHINES_UPDATE_REQUEST,
  IMAGE_ME_SEND_REQUEST,
  IMAGE_PROFILE_SEND_REQUEST,
  IMAGE_RESET,
} from '../components/ImageUploader/store/action';
import {IMAGE_ME_SEND_SUCCESS} from '../components/UserGallery/store/action';
import {IMAGE_SEND_EUROFOURS_SUCCESS} from '../components/ImagesEurofours/store/action';
import {IMAGE_SEND_MACHINES_SUCCESS} from '../components/ImagesMachines/store/action';
import {IMAGE_PROFILE_SEND_SUCCESS} from '../components/Profile/store/action';
import {client} from '../utils/api-client';

function* handleImage(action) {
  const {type, payload} = action;
  switch (type) {
    case IMAGE_EUROFOURS_SEND_REQUEST: {
      try {
        yield put({
          type: IMAGE_SENDING,
        });
        const response = yield client('images/eurofours', {
          data: {image: payload.image},
        });
        yield put({
          type: IMAGE_SEND_SUCCESS,
        });
        yield put({
          type: IMAGE_SEND_EUROFOURS_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put({
          type: IMAGE_SEND_FAILURE,
        });
        yield put(showNotificationAction(e, 'warning'));
      }
      break;
    }
    case IMAGE_MACHINES_SEND_REQUEST: {
      try {
        yield put({
          type: IMAGE_SENDING,
        });
        const response = yield client('images/machines', {
          data: {
            image: payload.image,
            machineCode: payload.machineCode,
            machineDescription: payload.machineDescription,
          },
        });
        yield put({
          type: IMAGE_SEND_SUCCESS,
        });
        yield put({
          type: IMAGE_SEND_MACHINES_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put({
          type: IMAGE_SEND_FAILURE,
        });
        yield put(showNotificationAction(e, 'warning'));
      }
      break;
    }
    case IMAGE_MACHINES_UPDATE_REQUEST: {
      try {
        yield put({
          type: IMAGE_SENDING,
        });
        const response = yield client(
          'images/machines/' + payload.machineCode,
          {
            data: {
              image: payload.image,
              machineCode: payload.machineCode,
              machineDescription: payload.machineDescription,
            },
            method: 'PUT',
          },
        );
        yield put({
          type: IMAGE_SEND_SUCCESS,
        });
        yield put({
          type: IMAGE_SEND_MACHINES_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put({
          type: IMAGE_SEND_FAILURE,
        });
        yield put(showNotificationAction(e, 'warning'));
      }
      break;
    }
    case IMAGE_PROFILE_SEND_REQUEST: {
      try {
        yield put({
          type: IMAGE_SENDING,
        });
        const response = yield client(`users/${getId()}`, {
          data: {
            imageBase64: payload.imageBase64,
          },
          method: 'PUT',
        });
        yield put({
          type: IMAGE_SEND_SUCCESS,
        });
        yield put({
          type: IMAGE_PROFILE_SEND_SUCCESS,
          payload: response,
        });
        yield put({
          type: IMAGE_RESET,
        });
      } catch (e) {
        yield put({
          type: IMAGE_SEND_FAILURE,
        });
        yield put(
          showNotificationAction('ra.notification.http_error', 'warning'),
        );
      }
      break;
    }
    case IMAGE_ME_SEND_REQUEST: {
      try {
        yield put({
          type: IMAGE_SENDING,
        });
        const response = yield client('image/update', {
          data: {image: payload.image},
        });
        yield put({
          type: IMAGE_ME_SEND_SUCCESS,
          payload: response,
        });
        yield put({
          type: IMAGE_SEND_SUCCESS,
        });
        yield put({
          type: IMAGE_RESET,
        });
      } catch (e) {
        yield put({
          type: IMAGE_SEND_FAILURE,
        });
        yield put(
          showNotificationAction('ra.notification.http_error', 'warning'),
        );
      }
      break;
    }
    default:
      break;
  }
}

export default function* watchImagesActions() {
  yield all([takeEvery(IMAGE_EUROFOURS_SEND_REQUEST, handleImage)]);
  yield all([takeEvery(IMAGE_MACHINES_SEND_REQUEST, handleImage)]);
  yield all([takeEvery(IMAGE_MACHINES_UPDATE_REQUEST, handleImage)]);
  yield all([takeEvery(IMAGE_PROFILE_SEND_REQUEST, handleImage)]);
  yield all([takeEvery(IMAGE_ME_SEND_REQUEST, handleImage)]);
}
