import React from 'react';
import {connect} from 'react-redux';
import {AssignButton, BooleanButton} from '../Buttons';
import CustomActions from './CustomActions';

const UsersActions = ({isActive, unassignButton, ...rest}) => (
  <CustomActions
    buttons={[
      <AssignButton target="structures" assign roles={['ROLE_TAG_ASSIGN']} />,
      <BooleanButton property="active" switchOn isActive={isActive} />,
      <AssignButton target="structures" single roles={['ROLE_TAG_ASSIGN']} />,
      <BooleanButton property="active" isActive={isActive} />,
    ]}
    {...rest}
  />
);

const mapStateToProps = ({structureReducer}, {data}) => {
  const selectedIds = data
    ? structureReducer.selectedStructures[data['@id']] || []
    : [];

  if (data) {
    return {
      unassignButton: (
        <AssignButton
          target="structures"
          roles={['ROLE_TAG_ASSIGN']}
          selectedIds={selectedIds}
          idsToUpdate={[data['@id']]}
          resourceToUpdate="users"
          resource="structures"
        />
      ),
    };
  }

  return {
    unassignButton: (
      <AssignButton target="structures" roles={['ROLE_TAG_ASSIGN']} />
    ),
  };
};

export default connect(mapStateToProps, {})(UsersActions);
