import React, {Fragment} from 'react';
import {
  SimpleForm,
  TextInput,
  LongTextInput,
  required,
  regex,
  maxLength,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import {Edit} from '../Common/Admin';
import Title from '../Common/Header';
import {hasRoles} from '../../utils/auth';
import StructureInput from '../Input/StructureInput';
import {CustomToolbar} from '../Common/Admin/CustomToolbar';

const validateName = [required(), maxLength(255)];
const validateDescription = [maxLength(255)];
const validatePin = [required(), regex(/^\d{4}$/, 'custom.validation.pin')];

export default props => {
  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_MACHINE_EDITOR'])) {
    window.location.replace('/#/not-granted');
  }

  return (
    <Edit
      title={<Title redirect="show" {...props} />}
      undoable={false}
      actions={<Fragment />}
      {...props}>
      <SimpleForm toolbar={<CustomToolbar />} redirect="show">
        <TextInput source="name" validate={validateName} />
        <LongTextInput source="description" validate={validateDescription} />
        {/* <TextInput source="serialNumber" validate={required()} /> */}
        <TextInput source="pin" validate={validatePin} />
        <ReferenceInput
          source="structure"
          reference="structures"
          perPage={500}
          sort={{field: 'name', order: 'ASC'}}>
          <StructureInput />
        </ReferenceInput>
        {/* <ReferenceInput
          source="category"
          reference="categories"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="avatar" /> */}
      </SimpleForm>
    </Edit>
  );
};
