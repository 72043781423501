import * as actions from './action';

const initialState = {
  phaseGroupTypes: [],
  loading: true,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.PHASE_GROUP_TYPE_GET_ALL_REQUEST:
      return initialState;
    case actions.PHASE_GROUP_TYPE_GET_ALL_REQUEST_SUCCESS:
      let phaseGroupTypes = payload.map(phaseGroup => {
        if ('fan' === phaseGroup.name) {
          phaseGroup.criterias.reverse();
          phaseGroup.phaseCriterias.reverse();

          return phaseGroup;
        }
        return phaseGroup;
      });
      return {
        phaseGroupTypes: phaseGroupTypes,
        loading: false,
      };
    default:
      return state;
  }
};
