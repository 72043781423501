const getButtonsList = location =>
  location && location.state && location.state.buttonsList
    ? location.state.buttonsList
    : false;

const getState = location =>
  location &&
  location.state &&
  (location.state.title.includes('Uninstall') || // uninstall a program from machine
    location.state.title.includes('Remove')) // remove a tag from a program (@see 366)
    ? 'only'
    : 'not';

const isProgramLocation = title => title.includes('program');

export const getIdsToUpdate = location => {
  let values = {};

  if (location && location.state && !isProgramLocation(location.state.title)) {
    values['approved'] = true;
  }

  if (getButtonsList(location)) {
    values[
      `programMachines.${
        isProgramLocation(location.state.title) ? 'programs' : 'machines'
      }[${getState(location)}]`
    ] = getButtonsList(location)[0].props.idsToUpdate.join(',');
  }

  return values;
};
