import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {
  GET_NB_RESOURCES_TO_APPROVE_REQUEST,
  GET_NB_RESOURCES_TO_APPROVE_SUCCESS,
  UPDATE_ONE,
  UPDATE_MANY_REQUEST,
  USER_UPDATE_ONE_SUCCESS,
  MACHINE_UPDATE_ONE_SUCCESS,
  STRUCTURE_UPDATE_ONE_SUCCESS,
} from '../components/Common/store/action';
import {
  FETCH_START,
  FETCH_END,
  REFRESH_VIEW,
  SET_LIST_SELECTED_IDS,
} from 'ra-core';
import {get, reduce} from 'lodash';
import {client} from '../utils/api-client';
import {errorMessageHandler} from './helpers';

function* handleUpdate(action) {
  const {type, payload} = action;

  try {
    switch (type) {
      case UPDATE_ONE: {
        const response = yield client(payload.id, {data: payload.body});
        if (payload.id.includes('users')) {
          yield put({
            type: USER_UPDATE_ONE_SUCCESS,
            payload: response,
          });
        } else if (payload.id.includes('machines')) {
          yield put({
            type: MACHINE_UPDATE_ONE_SUCCESS,
            payload: response,
          });
        } else if (payload.id.includes('structures')) {
          yield put({
            type: STRUCTURE_UPDATE_ONE_SUCCESS,
            payload: response,
          });
        }
        break;
      }
      case UPDATE_MANY_REQUEST: {
        yield put({
          type: FETCH_START,
        });
        for (let i = 0; i < payload.ids.length; i++) {
          yield client(payload.ids[i].substring(1), {
            data: payload.body,
            method: 'PUT',
          });
        }
        if ('approved' === payload.property) {
          yield put({
            type: GET_NB_RESOURCES_TO_APPROVE_REQUEST,
            payload: payload.resource,
          });
        }
        yield put({
          type: REFRESH_VIEW,
        });
        yield put({
          type: SET_LIST_SELECTED_IDS,
          payload: [],
          meta: {resource: payload.resource},
        });
        yield put({
          type: FETCH_END,
        });
        break;
      }
      case GET_NB_RESOURCES_TO_APPROVE_REQUEST: {
        const response = yield client(`${payload}?approved=false`);
        const isCorrect = reduce(
          response['hydra:search']['hydra:mapping'],
          (prev, next) => get(next, 'property') === 'approved' || prev,
          false,
        );

        yield put({
          type: GET_NB_RESOURCES_TO_APPROVE_SUCCESS,
          payload: isCorrect ? response['hydra:totalItems'] : 0,
        });
        break;
      }
      default:
        break;
    }
  } catch (e) {
    yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
  }
}

export default function* watchUsersActions() {
  yield all([takeEvery(GET_NB_RESOURCES_TO_APPROVE_REQUEST, handleUpdate)]);
  yield all([takeEvery(UPDATE_ONE, handleUpdate)]);
  yield all([takeEvery(UPDATE_MANY_REQUEST, handleUpdate)]);
}
