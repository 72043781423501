import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {translate} from 'react-admin';
import {compose} from 'recompose';
import ManageRights from '../ManageRights';
import StructureTable from './tableStructure';
import PartialHistory from '../History/Partial';

const styles = {
  tabsText: {
    textAlign: 'center',
    borderTopLeftRadius: 5 + 'px',
    borderTopRightRadius: 5 + 'px',
    cursor: 'pointer',
  },
  tabsNotSelected: {
    borderBottom: 1 + 'px solid gray',
  },
  tabsSelected: {
    borderTop: 1 + 'px solid gray',
    borderLeft: 1 + 'px solid gray',
    borderRight: 1 + 'px solid gray',
    color: '#f14343',
  },
};

class TabsUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
    };
  }

  handleChange = value => () => {
    this.setState({selected: value});
  };

  render() {
    const {basePath, classes, translate, user} = this.props;
    const {selected} = this.state;

    return (
      <Grid container style={{paddingTop: 3 + 'em'}}>
        <Grid
          item
          xs={4}
          className={[
            classes.tabsText,
            selected === 0 ? classes.tabsSelected : classes.tabsNotSelected,
          ].join(' ')}
          onClick={this.handleChange(0)}>
          <h3>{translate('custom.components.menu.structures')}</h3>
        </Grid>
        <Grid
          item
          xs={4}
          className={[
            classes.tabsText,
            selected === 1 ? classes.tabsSelected : classes.tabsNotSelected,
          ].join(' ')}
          onClick={this.handleChange(1)}>
          <h3>{translate('custom.components.appbar.activity')}</h3>
        </Grid>
        <Grid
          item
          xs={4}
          className={[
            classes.tabsText,
            selected === 2 ? classes.tabsSelected : classes.tabsNotSelected,
          ].join(' ')}
          onClick={this.handleChange(2)}>
          <h3>{translate('custom.components.manage_rights.title')}</h3>
        </Grid>
        <Grid item xs={12} style={{paddingTop: 1 + 'em'}}>
          {selected === 0 && <StructureTable basePath={basePath} user={user} />}
          {selected === 1 && <PartialHistory id={user.id} />}
          {selected === 2 && <ManageRights user={user} />}
        </Grid>
      </Grid>
    );
  }
}

export default compose(translate, withStyles(styles))(TabsUser);
