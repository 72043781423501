import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getProfile} from '../Profile/store/action';
import {Card, Grid} from '@material-ui/core';
import {
  TextInput,
  SimpleForm,
  translate,
  required,
  maxLength,
  email,
  SelectInput,
} from 'react-admin';
import {compose} from 'recompose';
import {withStyles} from '@material-ui/core/styles';
import {updateOne} from '../Profile/store/action';
import {TopBarList, Breadcrumb} from '../TopBar';
import {CustomToolbar} from '../Common/Admin/CustomToolbar';
import CountryField from '../Common/Field/CountryField';
import {displayDatePreferenceValues} from '../../constants/displayDatePreference';
import TimezoneField from '../Common/Field/TimezoneField';
import {hasRoles} from '../../utils/auth';

const styles = {
  inlineAdress: {
    paddingRight: 1 + 'em',
  },
  preferenceInput: {
    width: '100%',
  },
};

const isRequired = [required()];
const validateName = [required(), maxLength(100)];
const validateEmail = [required(), maxLength(200), email()];
const validateSociety = [required()];

class Profile extends Component {
  componentDidMount = () => this.props.getProfile();

  handleChange = event => this.props.updateOne(event, '/profile');

  render() {
    const {classes, profile, translate} = this.props;
    const emailDisabled = hasRoles(['ROLE_CLIENT_ADMIN']);

    return (
      profile && (
        <>
          <TopBarList>
            <Breadcrumb label={`${profile.firstName} ${profile.lastName}`} />
          </TopBarList>
          <Card square>
            <SimpleForm
              toolbar={<CustomToolbar showDelete={false} props={this.props} />}
              record={profile}
              save={this.handleChange}>
              <Grid container>
                <Grid item className={classes.inlineAdress}>
                  <TextInput
                    source="firstName"
                    label={translate('custom.creation.firstName')}
                    validate={validateName}
                  />
                </Grid>
                <Grid item className={classes.inlineAdress}>
                  <TextInput
                    source="lastName"
                    label={translate('custom.creation.lastName')}
                    validate={validateName}
                  />
                </Grid>
              </Grid>
              <TextInput
                source="email"
                label={translate('custom.auth.email')}
                type="email"
                validate={validateEmail}
                disabled={emailDisabled}
              />
              <TextInput
                source="society"
                label={translate('custom.commons.society')}
                validate={validateSociety}
              />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="description"
                    fullWidth
                    multiline
                    label={translate('custom.commons.description')}
                  />
                </Grid>
              </Grid>
              <TextInput
                source="phone"
                inputProps={{maxLength: 15}}
                label={translate('custom.commons.phone')}
              />
              <Grid container>
                <Grid item xs={4} className={classes.inlineAdress}>
                  <TextInput
                    source="street"
                    fullWidth
                    label={translate('custom.commons.adress')}
                    validate={isRequired}
                  />
                </Grid>
                <Grid item xs={3} className={classes.inlineAdress}>
                  <TextInput
                    source="city"
                    fullWidth
                    label={translate('custom.commons.city')}
                    validate={isRequired}
                  />
                </Grid>
                <Grid item xs={2} className={classes.inlineAdress}>
                  <TextInput
                    source="zipcode"
                    fullWidth
                    inputProps={{maxLength: 7}}
                    label={translate('custom.commons.zipcode')}
                    validate={isRequired}
                  />
                </Grid>
                <Grid item xs={3} className={classes.inlineAdress}>
                  <CountryField source="country" validate={isRequired} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4} className={classes.inlineAdress}>
                  <SelectInput
                    source="temperatureUnitPreference"
                    label={translate(
                      'custom.commons.temperatureUnitPreference.label',
                    )}
                    className={classes.preferenceInput}
                    choices={[
                      {
                        id: 'C',
                        name: `${translate(
                          'custom.commons.temperatureUnitPreference.celsius',
                        )} - C°`,
                      },
                      {
                        id: 'F',
                        name: `${translate(
                          'custom.commons.temperatureUnitPreference.fahrenheit',
                        )} - F°`,
                      },
                    ]}
                    validate={isRequired}
                    style={{marginBottom: '2em'}}
                  />
                </Grid>
                <Grid item xs={4} className={classes.inlineAdress}>
                  <SelectInput
                    source="displayDatePreference"
                    label={translate('custom.commons.displayDatePreference')}
                    className={classes.preferenceInput}
                    choices={displayDatePreferenceValues.map(
                      (value, index) => ({
                        id: index,
                        name: `${translate(
                          `custom.components.profile.displayDateMode.${value}`,
                        )}`,
                      }),
                    )}
                    validate={isRequired}
                    style={{marginBottom: '2em'}}
                  />
                </Grid>
                <Grid item xs={4} className={classes.inlineAdress}>
                  <TimezoneField
                    source="timezone"
                    validate={isRequired}
                    style={{width: '100%'}}
                  />
                </Grid>
              </Grid>
            </SimpleForm>
          </Card>
        </>
      )
    );
  }
}

const mapStateToProps = ({profileReducer}) => ({
  profile: profileReducer.profile,
});

export default compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps, {getProfile, updateOne}),
)(Profile);
