import React, {Component} from 'react';
import './imageUploader.scss';
import Cropper from 'react-cropper';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  Paper,
  Typography,
  TextField,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {
  sendImageMachines,
  updateImageMachines,
  resetSendImage,
} from './store/action';
import {connect} from 'react-redux';
import {reduxForm, Field} from 'redux-form';
import {compose} from 'recompose';
import {translate, required, minValue, number} from 'react-admin';

const validateMachineCode = [required()];

const styles = theme => ({
  multiline: {
    '& textarea': {
      height: '10em',
    },
  },
});

const renderInput = ({
  meta: {touched, error} = {},
  input: {...inputProps},
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
    required
  />
);

class MachinesUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: null,
      cropResult: null,
      machineCode: props.machineCode,
      machineDescription: props.machineDescription,
    };
  }

  uploadImage = ({machineCode, machineDescription}) => {
    if (this.props.update) {
      this.props.updateImageMachines({
        image: this.state.src
          ? this.cropper
              .getCroppedCanvas({
                minWidth: 256,
                minHeight: 256,
                maxWidth: 4096,
                maxHeight: 4096,
                fillColor: '#fff',
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high',
              })
              .toDataURL('image/jpeg', 1)
          : null,
        machineCode,
        machineDescription,
      });

      return;
    }

    if (
      typeof this.cropper.getCroppedCanvas() === 'undefined' ||
      this.cropper.getCroppedCanvas() === null
    ) {
      return;
    }

    this.setState({
      cropResult: this.cropper.getCroppedCanvas().toDataURL(),
    });

    this.props.sendImageMachines({
      image: this.cropper.getCroppedCanvas().toDataURL(),
      machineCode,
      machineDescription,
    });
  };

  onChange = e => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    this.props.resetSendImage();
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({src: reader.result});
    };
    reader.readAsDataURL(files[0]);
  };

  componentDidMount() {
    this.props.initialize({
      machineDescription: this.props.machineDescription,
      machineCode: this.props.machineCode,
    });
  }

  render() {
    const {classes, handleSubmit, isUpdated, translate, update} = this.props;
    return (
      <Card>
        <CardContent>
          <Typography gutterBottom variant="headline" component="h1">
            {translate('custom.components.image_uploader.label')}
          </Typography>
        </CardContent>
        <Grid container>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(this.uploadImage)}>
              <div
                style={{
                  width: 400 + 'px',
                  maxWidth: 400 + 'px',
                }}>
                {this.state.machineCode ? (
                  <img
                    src={`${process.env.REACT_APP_API_ENTRYPOINT}/images/machines/${this.state.machineCode}`}
                    alt={this.state.machineCode}
                  />
                ) : null}

                <Input type="file" onChange={this.onChange} />
                {this.state.src ? (
                  <div>
                    <Cropper
                      style={{height: 300, width: 300}}
                      aspectRatio={1}
                      preview=".img-preview"
                      guides={false}
                      zoomable={false}
                      src={this.state.src}
                      ref={cropper => {
                        this.cropper = cropper;
                      }}
                      responsive={true}
                    />
                    <div className="box">
                      <h1>
                        {translate(
                          'custom.components.image_uploader.previewLabel',
                        )}
                      </h1>
                      <div
                        className="img-preview"
                        style={{
                          width: 200,
                          float: 'left',
                          height: 200,
                          overflow: 'hidden',
                          border: 1 + 'px solid gray',
                        }}></div>
                    </div>
                  </div>
                ) : null}
                <Field
                  name="machineCode"
                  component={renderInput}
                  label={translate('custom.images.machine_code')}
                  type="text"
                  validate={validateMachineCode}
                  disabled={update}
                />
                <Field
                  name="machineDescription"
                  component={renderInput}
                  label={translate('custom.images.machine_description')}
                  type="textarea"
                  multiline
                  rows={4}
                  className={classes.multiline}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isUpdated === 'sending'}
                  style={{float: 'right', margin: '1em 0 1em'}}>
                  {translate('custom.components.image_uploader.submitLabel')}
                </Button>
              </div>
              {isUpdated !== null ? (
                <div style={{paddingTop: 150 + 'px'}}>
                  {isUpdated === true && (
                    <Paper
                      elevation={1}
                      style={{
                        backgroundColor: '#8cc97d',
                        paddingTop: 1 + 'em',
                        paddingBottom: 1 + 'em',
                        textAlign: 'center',
                      }}>
                      <Typography variant="headline" component="h3">
                        {translate('custom.images.upload.success')}
                      </Typography>
                    </Paper>
                  )}
                  {isUpdated === 'sending' && (
                    <Paper
                      elevation={1}
                      style={{
                        backgroundColor: '#a5d5ff',
                        paddingTop: 1 + 'em',
                        paddingBottom: 1 + 'em',
                        textAlign: 'center',
                      }}>
                      <Typography variant="headline" component="h3">
                        {translate('custom.images.upload.sending')}
                      </Typography>
                    </Paper>
                  )}
                  {isUpdated === false && (
                    <Paper
                      elevation={1}
                      style={{
                        backgroundColor: '#f8d7da',
                        paddingTop: 1 + 'em',
                        paddingBottom: 1 + 'em',
                        textAlign: 'center',
                      }}>
                      <Typography variant="headline" component="h3">
                        {translate('custom.images.upload.failure')}
                      </Typography>
                    </Paper>
                  )}
                </div>
              ) : null}
            </form>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

const mapStateToProps = ({imageReducer}) => ({
  isUpdated: imageReducer.isUpdated,
});

export default compose(
  translate,
  reduxForm({
    form: 'uploadImage',
  }),
  connect(mapStateToProps, {
    sendImageMachines,
    updateImageMachines,
    resetSendImage,
  }),
  withStyles(styles, {withTheme: true}),
)(MachinesUploader);
