import React from 'react';
import {DeleteWithConfirmButton} from 'react-admin';
import {Show} from '../Common/Admin';
import Title from '../Common/Header';
import ProgramShowView from './view/showView';
import ProgramActions from '../ActionsCards/ProgramActions';
import {hasRoles} from '../../utils/auth';
import {TopBarList, Breadcrumb} from '../TopBar';
import {connect} from 'react-redux';

const ProgramShow = props => {
  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_ACCESS'])) {
    window.location.replace('/#/not-granted');
  }
  const {programsData, ...rest} = props;
  const {id} = rest;
  const program = programsData[id];

  return (
    <>
      <TopBarList>
        <Breadcrumb
          categoryName="custom.components.menu.programs"
          label={program ? program.name : ' '}
        />
        {program && (
          <div>
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_DELETE']) && (
              <DeleteWithConfirmButton
                record={{id: program['@id']}}
                resource="programs"
                basePath="/programs"
              />
            )}
          </div>
        )}
      </TopBarList>
      <Show
        title={<Title redirect="list" {...props} />}
        actions={
          <ProgramActions
            customCount={
              program && Array.isArray(program.programMachines)
                ? program.programMachines.length
                : 0
            }
            displayTotal={false}
          />
        }
        {...rest}>
        <ProgramShowView />
      </Show>
    </>
  );
};

const mapStateToProps = ({admin}) => {
  return {
    programsData: admin.resources.programs.data,
  };
};

export default connect(mapStateToProps)(ProgramShow);
