export const IMAGES_GET_GALLERY = 'IMAGES_GET_GALLERY';
export const IMAGES_GET_GALLERY_SUCCESS = 'IMAGES_GET_GALLERY_SUCCESS';
export const IMAGE_ME_SEND_SUCCESS = 'IMAGE_ME_SEND_SUCCESS';
export const IMAGES_DELETE_GALLERY = 'IMAGES_DELETE_GALLERY';
export const IMAGES_DELETE_GALLERY_SUCCESS = 'IMAGES_DELETE_GALLERY_SUCCESS';
export const IMAGES_RESET = 'IMAGES_RESET';

export const getImagesGallery = data => ({
  type: IMAGES_GET_GALLERY,
  payload: data,
});

export const deleteImagesGallery = data => ({
  type: IMAGES_DELETE_GALLERY,
  payload: data,
});

export const resetImage = () => ({
  type: IMAGES_RESET,
});
