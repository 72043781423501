import React, {Component} from 'react';
import {linkToRecord} from 'ra-core';
import {
  SimpleShowLayout,
  TextField,
  ReferenceField,
  translate,
  Link,
} from 'react-admin';
import compose from 'recompose/compose';
import {Grid, Button, CircularProgress, Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import GridList from '../../Common/GridList';
import ResourceListItem from '../../Common/ResourceListItem';
import PartialHistory from '../../History/Partial';
import {getMachines} from '../../Machine/store/action';
import '../../../assets/show.css';
import {hasRoles} from '../../../utils/auth';

const MachineItem = ({item}) => (
  <Typography variant="body2" component="p">
    <Link to={linkToRecord('/machines', item['@id'], 'show')}>{item.name}</Link>
  </Typography>
);

class StructureShowView extends Component {
  componentDidMount() {
    const {
      getMachines,
      record: {id},
    } = this.props;

    getMachines('?structure=' + id);
  }

  componentDidUpdate = prevProps => {
    const {
      getMachines,
      record: {id},
    } = this.props;

    if (id === prevProps.record.id) {
      return;
    }

    getMachines('?structure=' + id);
  };

  sanitizeProps = ({getMachines, ...rest}) => rest;

  render() {
    const {
      translate,
      machineLoading,
      structureLoading,
      machines,
      ...rest
    } = this.props;

    const {
      record: {parent, id, relatedUsers},
      basePath,
    } = rest;

    return (
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Grid container>
            <Grid item xs={9} className="show-info">
              <Grid container direction="column">
                <Grid item>
                  <SimpleShowLayout {...this.sanitizeProps(rest)}>
                    <TextField source="name" />
                    {parent && (
                      <ReferenceField
                        source="parent"
                        reference="structures"
                        linkType="show">
                        <TextField source="name" />
                      </ReferenceField>
                    )}
                  </SimpleShowLayout>
                  {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_EDITOR']) && (
                    <div className="edit-info">
                      <Button component={Link} to={linkToRecord(basePath, id)}>
                        {translate('custom.commons.edit')}
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} className="bg-mall" />
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            className="tag-content"
            wrap="nowrap">
            <Grid item>
              <PartialHistory title id={id} />
            </Grid>
            {structureLoading ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item>
                <GridList
                  data={relatedUsers}
                  title="custom.components.menu.users"
                  noDataDesc="custom.components.users.none">
                  <ResourceListItem
                    source="relatedUsers"
                    reference="users"
                    fields={['firstName', 'lastName']}
                    {...this.sanitizeProps(rest)}
                  />
                </GridList>
              </Grid>
            )}
            {machineLoading ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item>
                <GridList
                  data={machines}
                  title="custom.components.menu.machines"
                  noDataDesc="custom.components.machine.none">
                  <MachineItem />
                </GridList>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({admin, machineReducer}) => ({
  machines: machineReducer.machines,
  machineLoading: machineReducer.loading,
  structureLoading: admin.loading > 0,
});

export default compose(
  translate,
  connect(mapStateToProps, {getMachines}),
)(StructureShowView);
