export const STRUCTURE_GET_ALL_REQUEST = 'STRUCTURE_GET_ALL_REQUEST';
export const STRUCTURE_GET_ALL_SUCCESS = 'STRUCTURE_GET_ALL_SUCCESS';
export const SET_SELECTED_STRUCTURES = 'SET_SELECTED_STRUCTURES';
export const GET_FILTERED_STRUCTURES = 'GET_FILTERED_STRUCTURES';
export const REMOVE_USER_FROM_STRUCTURE = 'REMOVE_USER_FROM_STRUCTURE';
export const STRUCTURE_GET_ONE_RESOURCE = 'STRUCTURE_GET_ONE_RESOURCE';
export const STRUCTURE_GET_ONE_SUCCESS = 'STRUCTURE_GET_ONE_SUCCESS';

export const getStructure = data => ({
  type: STRUCTURE_GET_ONE_RESOURCE,
  payload: data,
});

export const getStructures = data => ({
  type: STRUCTURE_GET_ALL_REQUEST,
  payload: data,
});

export const setSelectedStructures = data => ({
  type: SET_SELECTED_STRUCTURES,
  payload: data,
});

export const getFilteredStructures = data => ({
  type: GET_FILTERED_STRUCTURES,
  payload: data,
});

export const removeUserFromStructure = data => ({
  type: REMOVE_USER_FROM_STRUCTURE,
  payload: data,
});
