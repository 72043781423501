import {DELETE} from 'ra-core';
import {USER_UPDATE_ONE_PROFILE} from '../../Profile/store/action';

export const EUROFOURS_USERS_GET_CLIENT_ADMINS = 'ADMIN_GET_CLIENT_ADMINS';
export const EUROFOURS_USERS_GET_CLIENT_ADMINS_SUCCESS =
  'EUROFOURS_USERS_GET_CLIENT_ADMINS_SUCCESS';
export const SUPERADMIN_GET_EUROFOURS_TECHNICIANS =
  'SUPERADMIN_GET_EUROFOURS_TECHNICIANS';
export const SUPERADMIN_GET_EUROFOURS_TECHNICIANS_SUCCESS =
  'SUPERADMIN_GET_EUROFOURS_TECHNICIANS_SUCCESS';
export const USERS_GET_ACCEPTED_CONTROL = 'USERS_GET_ACCEPTED_CONTROL';
export const USERS_GET_ACCEPTED_CONTROL_SUCCESS =
  'USERS_GET_ACCEPTED_CONTROL_SUCCESS';
export const USERS_CONTROL_SELECTED_ERROR_RESET =
  'USERS_CONTROL_SELECTED_ERROR_RESET';
export const USERS_CONTROL_SELECTED = 'USERS_CONTROL_SELECTED';
export const USERS_CONTROL_SELECTED_ERROR = 'USERS_CONTROL_SELECTED_ERROR';
export const USERS_CONTROL_SELECTED_SUCCESS = 'USERS_CONTROL_SELECTED_SUCCESS';
export const CLIENT_ADMIN_UPDATE = 'CLIENT_ADMIN_UPDATE';
export const CLIENT_ADMIN_UPDATE_SUCCESS = 'CLIENT_ADMIN_UPDATE_SUCCESS';
export const CLIENT_ADMIN_DELETE = 'CLIENT_ADMIN_DELETE';
export const CLIENT_ADMIN_DELETE_SUCCESS = 'CLIENT_ADMIN_DELETE_SUCCESS';

export const getAcceptedControl = data => ({
  type: USERS_GET_ACCEPTED_CONTROL,
  payload: data,
});

export const getClientAdmins = () => ({
  type: EUROFOURS_USERS_GET_CLIENT_ADMINS,
});
export const getEurofoursTechnicians = () => ({
  type: SUPERADMIN_GET_EUROFOURS_TECHNICIANS,
});

export const connectAsUser = data => ({
  type: USERS_CONTROL_SELECTED,
  payload: data,
});

export const resetLoginError = () => ({
  type: USERS_CONTROL_SELECTED_ERROR_RESET,
});

export const updateClientAdmin = (id, data) => ({
  type: USER_UPDATE_ONE_PROFILE,
  payload: {
    id,
    data: data,
    redirect: '/',
  },
});

export const deleteClientAdmin = id => ({
  type: CLIENT_ADMIN_DELETE,
  payload: {
    id,
  },
  meta: {
    resource: 'users',
    fetch: DELETE,
    onSuccess: {
      notification: {
        body: 'ra.notification.deleted',
        level: 'info',
        messageArgs: {
          smart_count: 1,
        },
      },
    },
    onFailure: {
      notification: {
        body: 'ra.notification.http_error',
        level: 'warning',
      },
    },
  },
});
