import {useMemo, useCallback, useEffect, useState} from 'react';
import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import CriteriaInput from './CriteriaInput';
import {Duration, Damper} from '../../Buttons/SVG';
import CriteriaLine from './CriteriaLine';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {formatDamperPhase} from './utils';

export default function PhaseGroupDamper({phase, onChange, totalDuration}) {
  const [prevDuration, setPrevDuration] = useState(totalDuration);
  const phases = useMemo(() => formatDamperPhase(phase), [phase]);

  const parse = phases => ({
    phaseGroupType: 'damper',
    phases: phases.map((p, index) => ({
      position: index,
      values: [
        {
          criteria: 'start',
          value: p.start * 60,
        },
        {
          criteria: 'duration',
          value: p.duration * 60,
        },
      ],
    })),
  });

  const isEndDamper = useCallback(
    phase => {
      return phase.start + phase.duration === totalDuration;
    },
    [totalDuration],
  );

  // suppression des phases hors durée du programme SAUF LA DERNIERE
  useEffect(() => {
    if (!phases.length) return;
    if (prevDuration !== totalDuration) {
      let newPhases = [...phases];
      let endDamper;
      const endDamperIndex = newPhases.findIndex(
        p => p.start + p.duration === prevDuration,
      );
      if (endDamperIndex > -1) {
        endDamper = newPhases[endDamperIndex];
        endDamper.start = totalDuration - endDamper.duration;
        if (endDamper.start < 0) endDamper.start = 0;
        setPrevDuration(totalDuration);
      }
      setPrevDuration(totalDuration);
      //on supprime : les phases qui commencent pendant la phase "de fin", et les phases qui commencent après la durée totale du programme
      newPhases = newPhases.filter(
        (p, index) =>
          index === endDamperIndex ||
          (endDamper
            ? p.start + p.duration < endDamper.start - 1
            : p.start + p.duration <= totalDuration),
      );
      const result = parse(newPhases);
      onChange(result);
    }
  }, [phase, totalDuration, prevDuration, onChange]);

  const handleChange = useCallback(
    (index, attribute) => value => {
      const newPhases = [...phases];
      if (attribute === 'start' && isEndDamper(phases[index])) {
        let newStart = parseInt(value, 10);
        let newDuration = totalDuration - newStart;
        newPhases[index] = {start: newStart, duration: newDuration};
      } else {
        let newValue = parseInt(value, 10);
        newPhases[index][attribute] = newValue;
      }
      const result = parse(newPhases);
      onChange(result);
    },
    [phases, onChange, isEndDamper],
  );

  const addPhase = useCallback(
    index => event => {
      event.preventDefault();
      const newPhases = [...phases];
      const newPhase =
        phases.length === 0
          ? {start: totalDuration - 1, duration: 1}
          : {start: phases[index].start - 2, duration: 1};
      if (newPhase.start < 0) return;
      newPhases.splice(index, 0, newPhase);
      const result = parse(newPhases);
      onChange(result);
    },
    [phases, onChange, totalDuration],
  );

  const removePhase = useCallback(
    index => event => {
      event.preventDefault();
      const newPhases = [...phases];
      newPhases.splice(index, 1);
      const result = parse(newPhases);
      onChange(result);
    },
    [phases, onChange],
  );

  return (
    <Grid container spacing={24} style={{marginBottom: 3 + 'em'}}>
      <Grid item>
        <Typography>
          <Damper isColored />
          <sup>{phases.length}</sup>
        </Typography>
      </Grid>
      <Grid item>
        {phases.length === 0 && totalDuration > 0 ? (
          <Button
            onClick={addPhase(0)}
            color="primary"
            variant={'contained'}
            aria-label="Add">
            <AddIcon />
          </Button>
        ) : null}
        {phases.map((p, index) => {
          const canAdd =
            (index === 0 && p.start > 2) ||
            (index > 0 &&
              p.start >
                phases[index - 1].start + phases[index - 1].duration + 2);
          return (
            <CriteriaLine
              isLast={index === phases.length - 1}
              onAdd={addPhase(index)}
              onRemove={removePhase(index)}
              notification={p.notification}
              withNotification={false}
              canAdd={!!canAdd}
              canDelete={true}>
              <CriteriaInput
                unit="min"
                Icon={Duration}
                value={p.start}
                min={
                  index === 0
                    ? 0
                    : isEndDamper(p)
                    ? 0
                    : phases[index - 1].start + phases[index - 1].duration + 1
                }
                max={
                  index === phases.length - 1
                    ? totalDuration - p.duration
                    : phases[index + 1].start - p.duration - 1
                }
                onChange={handleChange(index, 'start')}
              />
              <CriteriaInput
                min={1}
                max={
                  index === phases.length - 1 || phases.length === 0
                    ? totalDuration - p.start
                    : phases[index + 1].start - p.start - 1
                }
                unit="min"
                Icon={Damper}
                value={p.duration}
                onChange={handleChange(index, 'duration')}
              />
            </CriteriaLine>
          );
        })}
      </Grid>
    </Grid>
  );
}
