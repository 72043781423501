import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {
  PHASE_GROUP_TYPE_GET_ALL_REQUEST,
  PHASE_GROUP_TYPE_GET_ALL_REQUEST_SUCCESS,
} from '../components/PhaseGroupType/store/action';
import {errorMessageHandler} from './helpers';
import {client} from '../utils/api-client';

function* handleMachine(action) {
  const {type, payload} = action;
  switch (type) {
    case PHASE_GROUP_TYPE_GET_ALL_REQUEST: {
      try {
        const response = yield client(`phase_group_types${payload}`);
        yield put({
          type: PHASE_GROUP_TYPE_GET_ALL_REQUEST_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    default:
      break;
  }
}

export default function* watchPhase_group_typeActions() {
  yield all([takeEvery(PHASE_GROUP_TYPE_GET_ALL_REQUEST, handleMachine)]);
}
