import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {IMPORT_ONE_PROGRAM} from '../components/Buttons/store/action';
import {errorMessageHandler} from './helpers';
import {client} from '../utils/api-client';
import {CRUD_GET_LIST} from 'ra-core';
import {GET_NB_RESOURCES_TO_APPROVE_REQUEST} from '../components/Common/store/action';

function* handleUpdate(action) {
  const {type, payload} = action;

  try {
    if (type !== IMPORT_ONE_PROGRAM) {
      return;
    }

    yield client('programs/import', {data: payload});
    yield put({
      type: CRUD_GET_LIST,
      payload: {
        pagination: {
          page: 1,
        },
        sort: {
          field: 'updatedAt',
          order: 'DESC',
        },
      },
      meta: {
        resource: 'programs',
        fetch: 'GET_LIST',
      },
    });
    yield put({
      type: GET_NB_RESOURCES_TO_APPROVE_REQUEST,
      payload: 'programs',
    });
  } catch (e) {
    yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
  }
}

export default function* watchUsersActions() {
  yield all([takeEvery(IMPORT_ONE_PROGRAM, handleUpdate)]);
}
