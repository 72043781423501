import {Children, cloneElement} from 'react';
import {Grid} from '@material-ui/core';
import React from 'react';

const ButtonsRow = ({children}) => (
  <Grid item xs={12}>
    <Grid container>
      {Children.map(children, (button, index) => (
        <Grid
          item
          key={index}
          xs={index < Children.toArray(children).length - 1 ? 3 : true}>
          {button}
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export const ButtonsList = ({buttons, resource, selectedIds}) => {
  const buttonsList =
    resource && selectedIds
      ? buttons.map((button, i) =>
          cloneElement(button, {
            key: i,
            selectedIds: button.props.selectedIds || selectedIds,
            resource: button.props.resource || resource,
            location: !button.props.idsToUpdate && {
              state: {
                redirect: `/${resource}`,
                buttonsList: buttons.map((button2, j) =>
                  cloneElement(button2, {
                    key: j,
                    idsToUpdate: selectedIds,
                    resourceToUpdate: resource,
                    isActive: button === button2,
                  }),
                ),
              },
            },
          }),
        )
      : buttons;

  const rowButtons1 = buttonsList.slice(0, buttonsList.length / 2);
  const rowButtons2 = buttonsList.slice(buttonsList.length / 2);

  return (
    <Grid container>
      <ButtonsRow>{rowButtons1}</ButtonsRow>
      <ButtonsRow>{rowButtons2}</ButtonsRow>
    </Grid>
  );
};
