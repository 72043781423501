import React, {Component} from 'react';
import {Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import {
  displayDatePreferenceValues,
  displayPreferenceMachine,
  displayPreferenceUser,
} from '../../constants/displayDatePreference';

class DateRenderer extends Component {
  getDateString() {
    const {
      date,
      machineTimezone,
      displayDatePreference,
      timezoneFromProps,
      userTimezone,
      locale,
    } = this.props;
    const storedLocale = localStorage.getItem('language');
    const localeFormat = storedLocale ? storedLocale : locale;
    const timezone = timezoneFromProps ? timezoneFromProps : machineTimezone;
    let usedTimezone = 'Etc/UTC';
    const displayPreferenceValue =
      displayDatePreferenceValues[displayDatePreference];

    if (displayPreferenceValue === displayPreferenceUser && userTimezone) {
      usedTimezone = userTimezone;
    }

    if (displayPreferenceValue === displayPreferenceMachine && timezone) {
      usedTimezone = timezone;
    }

    return `${date.toLocaleString(localeFormat, {timeZone: usedTimezone})} (${
      usedTimezone.split('/')[1]
    })`;
  }

  render() {
    return (
      <Typography style={{fontWeight: 600}}>{this.getDateString()}</Typography>
    );
  }
}

const mapStateToProps = ({
  machineReducer,
  profileReducer,
  programReducer,
  i18n,
}) => ({
  program:
    Object.keys(programReducer.program).length > 0
      ? programReducer.program
      : null,
  machineTimezone: machineReducer.machine && machineReducer.machine.timezone,
  displayDatePreference: profileReducer.profile
    ? profileReducer.profile.displayDatePreference
    : 0,
  userTimezone: profileReducer.profile ? profileReducer.profile.timezone : null,
  locale: i18n.locale,
});

export default connect(mapStateToProps)(DateRenderer);
