import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Tab,
  Tabs,
} from '@material-ui/core';
import ImagesListFactory from '../Images/list';
import {getImagesEurofours, deleteImagesEurofours} from './store/action';
import ImageUploader from '../ImageUploader/eurofours';
import {translate, Confirm} from 'react-admin';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  tabs: {
    backgroundColor: '#303030',
  },
  cardBody: {
    padding: 2 + 'rem',
    '& a': {
      fontStyle: 'italic',
    },
  },
  card: {
    padding: 0,
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
  },
  cardHeader: {
    padding: '0.5em 1em 0.5em 2em',
    backgroundColor: '#ededed',
  },
});

class ImagesEurofours extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 'manage',
      opened: false,
      imageId: null,
    };
  }

  componentDidMount() {
    this.props.getImagesEurofours();
  }

  handleSelect = image => {
    this.props.handleSelect(`images/${image}`);
  };

  handleChange = (event, value) => {
    this.setState({value});
  };

  retrieveButton = item => {
    if ('BUTTON' === item.tagName) {
      return item;
    }
    return this.retrieveButton(item.parentNode);
  };

  askConfirmation = event => {
    this.setState({
      imageId: this.retrieveButton(event.target).attributes.id.value,
      opened: true,
    });
  };

  handleDelete = () => {
    this.props.deleteImagesEurofours({image: this.state.imageId});
    this.setState({opened: false});
  };

  handleClose = () => {
    this.setState({opened: false, imageId: null});
  };

  render() {
    const {imagesEurofours, theme, translate, selectable, classes} = this.props;
    const {value, opened} = this.state;

    return (
      <Card className={classes.card}>
        <CardHeader
          title={
            <Grid container justify="space-between" alignItems="center">
              <Grid item>{translate('custom.title.images_programs')}</Grid>
            </Grid>
          }
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardBody}>
          {selectable ? (
            <ImagesListFactory
              handleDelete={this.handleDelete}
              images={imagesEurofours}
              handleSelect={this.handleSelect}
              selectable={selectable}
              path={'/images/'}
            />
          ) : (
            <>
              <Tabs
                className={classes.tabs}
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                fullWidth>
                <Tab
                  value={'manage'}
                  label={translate('custom.images.manage')}
                />
                <Tab value={'add'} label={translate('custom.images.add')} />
              </Tabs>
              {value === 'manage' && (
                <div dir={theme.direction}>
                  <ImagesListFactory
                    handleDelete={this.askConfirmation}
                    images={imagesEurofours}
                    handleSelect={this.handleSelect}
                    selectable={selectable}
                    path={'/images/'}
                  />
                </div>
              )}
              {value === 'add' && (
                <div dir={theme.direction}>
                  <ImageUploader />
                </div>
              )}
              {opened && (
                <Confirm
                  isOpen={opened}
                  title={translate('custom.images.delete.title')}
                  content={translate('custom.images.delete.content')}
                  confirm={translate('ra.message.yes')}
                  cancel={translate('ra.message.no')}
                  onConfirm={this.handleDelete}
                  onClose={this.handleClose}
                />
              )}
            </>
          )}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = ({imagesEurofoursReducer}) => ({
  imagesEurofours: imagesEurofoursReducer.imagesEurofours,
});

export default compose(
  translate,
  connect(mapStateToProps, {getImagesEurofours, deleteImagesEurofours}),
  withStyles(styles, {withTheme: true}),
)(ImagesEurofours);
