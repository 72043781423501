import React, {Fragment} from 'react';
import {Button, withStyles} from '@material-ui/core';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import {KeyboardArrowLeft} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import compose from 'recompose/compose';
import {translate, resolveRedirectTo} from 'react-admin';

const styles = {
  button: {
    color: 'black',
    border: '1px solid WhiteSmoke',
    padding: 0,
    minWidth: 'auto',
    minHeight: 'auto',
    marginRight: '1em',
    marginLeft: '-1.3em',
  },
};

const TitleWithRedirect = ({
  translate,
  width,
  title: baseTitle,
  record = {},
  basePath,
  redirect = '/',
  classes,
}) => {
  const title =
    record.name || (record.firstName && record.lastName)
      ? record.name || `${record.firstName} ${record.lastName}`
      : translate(baseTitle, {_: baseTitle});

  if (isWidthDown('xs', width)) {
    return title;
  }

  return (
    <Fragment>
      <Button
        component={Link}
        to={resolveRedirectTo(redirect, basePath, record.id, record)}
        variant="flat"
        size="small"
        className={classes.button}>
        <KeyboardArrowLeft
          style={{
            backgroundColor: '#f1f1f1',
            borderRadius: 2,
            boxShadow: '0 0 0 1px #d7d7d7',
          }}
        />
      </Button>
      {title}
    </Fragment>
  );
};

const enhance = compose(withWidth(), withStyles(styles), translate);

export default enhance(TitleWithRedirect);
