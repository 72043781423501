import React, {Component, Fragment} from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  withStyles,
  Button,
  Box,
} from '@material-ui/core';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {getProfile} from '../Profile/store/action';
import Gear from '../Gear';
import logo from '../../assets/logo_etools.svg';
import {Link} from 'react-router-dom';
import SelectLanguage from '../SelectLanguage';
import {getToken, hasRoles} from '../../utils/auth';
import {translate, setSidebarVisibility} from 'react-admin';
import {Dehaze} from '@material-ui/icons';

const styles = theme => ({
  appBar: {
    flexGrow: 1,
    zIndex: 1201,
  },
  etoolsLogo: {
    width: '60%',
    padding: '0 1em 0 0.6em',
  },
  profileName: {
    fontSize: 1.1 + 'em',
    fontWeight: 700,
    textAlign: 'center',
    letterSpacing: 0.5,
  },
  role: {
    fontSize: 0.7 + 'em',
    fontWeight: 200,
    textAlign: 'center',
    letterSpacing: 0.5,
  },
  padding: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 24,
    },
  },
  stopImpersonaButton: {
    color: '#f14343',
  },
  navbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0px',
  },
  navbarRightSide: {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
  },
  navbarNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 2vw',
    borderRight: '2px solid #666',
  },
  selectLanguage: {
    borderRight: '2px solid #666',
    padding: '0px 1vw',
  },
  gearContainer: {
    padding: '0px 1vw',
  },
  sidebarToggleContainer: {
    borderLeft: '2px solid #666',
    '@media (min-width:599px)': {
      display: 'none',
    },
  },
  sidebarToggle: {
    minWidth: 0,
    width: 100 + '%',
    color: 'white',
    '@media (min-width:599px)': {
      display: 'none',
    },
  },
});

class SimpleAppBar extends Component {
  componentDidMount() {
    if (!getToken() || hasRoles(['ROLE_NEED_2FA'])) {
      return;
    }
    this.props.getProfile();
  }

  handleStopImpersona = () => {
    const token = localStorage.getItem('masterToken');
    const role = localStorage.getItem('masterRole');
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.removeItem('masterToken');
    localStorage.removeItem('masterRole');
    window.location.replace('/');
  };

  render() {
    const {
      classes,
      profile,
      translate,
      setSidebarVisibility,
      sidebarOpen,
    } = this.props;

    const isImpersona = localStorage.getItem('masterToken');

    const renderRole = role => {
      switch (role) {
        case 'ROLE_SUPERADMIN':
          return 'custom.components.appbar.roles.superAdmin';
        case 'ROLE_EUROFOURS_TECHNICIAN':
          return 'custom.components.appbar.roles.eurofoursTechnician';
        case 'ROLE_CLIENT_ADMIN':
          return 'custom.components.appbar.roles.clientAdmin';
        default:
          return 'custom.components.appbar.roles.user';
      }
    };

    return (
      <div className={classes.appBar}>
        <AppBar position="fixed" color="default">
          <Toolbar disableGutters classes={{root: classes.padding}}>
            <div className={classes.navbar}>
              <Link to="/">
                <img src={logo} alt="eTools" className={classes.etoolsLogo} />
              </Link>
              <div className={classes.navbarRightSide}>
                {isImpersona && (
                  <Button
                    variant="contained"
                    className={classes.stopImpersonaButton}
                    onClick={this.handleStopImpersona}>
                    {translate('custom.buttons.stopImpersona')}
                  </Button>
                )}
                {profile && (
                  <>
                    <div className={classes.navbarNameContainer}>
                      {profile.firstName && profile.lastName && (
                        <Typography className={classes.profileName}>
                          {`${profile.firstName} ${profile.lastName}`}
                        </Typography>
                      )}
                      {profile.roles && (
                        <Typography className={classes.role}>
                          {translate(renderRole(profile && profile.roles[0]))}
                        </Typography>
                      )}
                    </div>
                    <div className={classes.selectLanguage}>
                      <SelectLanguage />
                    </div>
                  </>
                )}
                <div className={classes.gearContainer}>
                  <Gear />
                </div>
                <div className={classes.sidebarToggleContainer}>
                  <Button
                    onClick={() => setSidebarVisibility(!sidebarOpen)}
                    className={classes.sidebarToggle}>
                    <Dehaze />
                  </Button>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = ({profileReducer, admin}) => {
  return {
    sidebarOpen: admin.ui.sidebarOpen,
    profile: profileReducer.profile,
    locale: localStorage.getItem('language'),
  };
};

export default compose(
  connect(mapStateToProps, {getProfile, setSidebarVisibility}),
  withStyles(styles),
  translate,
)(SimpleAppBar);
