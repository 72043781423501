import * as actions from './action';
import {setToken} from '../../../utils/auth';

const initialState = {
  usersAccept: [],
  loading: false,
  isCodeError: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.CLIENT_ADMIN_UPDATE:
    case actions.CLIENT_ADMIN_DELETE:
      return {
        ...state,
        loading: true,
      };
    case actions.USERS_GET_ACCEPTED_CONTROL:
    case actions.EUROFOURS_USERS_GET_CLIENT_ADMINS:
      return {
        ...state,
        loading: true,
        usersAccept: initialState.usersAccept,
      };
    case actions.SUPERADMIN_GET_EUROFOURS_TECHNICIANS:
      return {
        ...state,
        loading: true,
        usersAccept: initialState.usersAccept,
      };
    case actions.USERS_GET_ACCEPTED_CONTROL_SUCCESS:
    case actions.EUROFOURS_USERS_GET_CLIENT_ADMINS_SUCCESS:
      return {
        ...state,
        loading: initialState.loading,
        usersAccept: payload,
      };
    case actions.SUPERADMIN_GET_EUROFOURS_TECHNICIANS_SUCCESS:
      return {
        ...state,
        loading: initialState.loading,
        usersAccept: payload,
      };
    case actions.USERS_CONTROL_SELECTED_ERROR:
      return {
        ...state,
        isCodeError: true,
      };
    case actions.USERS_CONTROL_SELECTED_ERROR_RESET:
      return {
        ...state,
        isCodeError: false,
      };
    case actions.USERS_CONTROL_SELECTED_SUCCESS:
      setToken(payload.token);
      window.location.replace('/');
      break;
    default:
      return state;
  }
};
