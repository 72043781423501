import React from 'react';
import {TextField, linkToRecord} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import lightblue from '@material-ui/core/colors/lightBlue';

const styles = theme => ({
  red: {
    color: theme.palette.primary.main,
  },
  blue: {
    color: lightblue['400'],
  },
});

export default withStyles(styles)(
  ({classes, pageTo = 'show', source = 'name', record = {}, basePath}) => (
    <Link to={linkToRecord(basePath, record.id, pageTo)}>
      <TextField
        source={source}
        record={record}
        className={classNames({
          [classes.blue]: false === record.approved,
          [classes.red]: true === record.approved,
        })}
        style={{marginLeft: record.depth && 30 * record.depth}}
      />
    </Link>
  ),
);
