import React from 'react';
import {
  Link,
  translate,
  SimpleShowLayout,
  TextField,
  FunctionField,
} from 'react-admin';
import {linkToRecord} from 'ra-core';
import {Grid, Button} from '@material-ui/core';
import GridList from '../../Common/GridList';
import ResourceListItem from '../../Common/ResourceListItem';
import PartialHistory from '../../History/Partial';
import '../../../assets/show.css';
import {hasRoles} from '../../../utils/auth';

export default translate(({translate, ...rest}) => {
  const {
    record: {programs, id, description},
    basePath,
  } = rest;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Grid container>
          <Grid item xs={9} className="show-info">
            <Grid container direction="column">
              <Grid item>
                <SimpleShowLayout {...rest}>
                  <TextField source="name" />
                  <FunctionField
                    source="description"
                    render={() =>
                      !description
                        ? translate('custom.components.tags.noDescription')
                        : description
                    }
                  />
                </SimpleShowLayout>
                {hasRoles(['ROLE_TAG_EDITOR', 'ROLE_CLIENT_ADMIN']) && (
                  <div className="edit-info">
                    <Button component={Link} to={linkToRecord(basePath, id)}>
                      {translate('custom.commons.edit')}
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} className="bg-tag" />
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          className="tag-content"
          wrap="nowrap">
          <Grid item>
            <PartialHistory title id={id} />
          </Grid>
          <Grid item>
            <GridList
              data={programs}
              title="custom.components.menu.programs"
              noDataDesc="custom.components.programs.none">
              <ResourceListItem source="programs" {...rest} />
            </GridList>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
