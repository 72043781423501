module.exports = {
  'Machine program installation': 'Installation du programme',
  'Machine program uninstallation': 'Désinstallation du programme',
  'Machine add to structure': 'Ajout à la structure',
  'Machine remove from structure': 'Retrait de la structure',
  'Machine activation': 'Activation de la machine',
  'Machine deactivation': 'Désactivation de la machine',
  'Machine approval': 'Approbation de la machine',
  'Machine disapproval': 'Désapprobation de la machine',
  'Machine deletion': 'Suppression de la machine',
  'Program installation': 'Installation du programme',
  'Program uninstallation': 'Désinstallation du programme',
  'Program import': 'Import du programme',
  'Program import on machine': 'Import du programme',
  'Program export': 'Export du programme',
  'Program update from import': 'Modification du programme',
  'Program update on machine': 'Modification du programme',
  'Program tag added': "Ajout de l'étiquette",
  'Program tag removed': "Retrait de l'étiquette",
  'Program approval': 'Approbation du programme',
  'Program disapproval': 'Désapprobation du programme',
  'Program creation': 'Création du programme',
  'Tag creation': "Création de l'étiquette",
  'Tag update': "Modification de l'étiquette",
  'Tag added on program': "Ajout de l'étiquete",
  'Tag removed from program': "Retrait de l'étiquette",
  'Structure add machine': 'Ajout de la machine',
  'Structure remove machine': 'Retrait de la machine',
  'Structure creation': 'Création de la structure',
  'Structure add user': "Ajout de l'utilisateur",
  'Structure remove user': "Retrait de l'utilisateur",
  'Structure update': 'Modification de la structure',
  'Structure deletion': 'Suppression de la structure',
  'User creation': "Création de l'utilisateur",
  'User deletion': "Suppression de l'utilisateur",
  'User update': "Modification de l'utilisateur",
  'Connection as': "s'est connecté en tant que",
  'on program': 'sur le programme',
  'from program': 'du programme',
  'on machine': 'sur la machine',
  'from machine': 'de la machine',
  'on structure': 'sur la structure',
  'from structure': 'de la structure',
  Unauthorized: 'Email ou mot de passe inconnus',
  'category: validators.program.category.not_blank':
    'Veuillez sélectionner un type de machine',
  'Failed to fetch': 'Erreur serveur',
  'Not Found': 'Page introuvable.',
  more_count: '...et %count% de plus',
  custom: {
    action: {
      machines: {
        name: 'machine |||| machines',
        selected: 'sélectionnée |||| sélectionnées',
        more_actions: 'Pour plus d’actions, cochez une machine dans la liste.',
        connect: 'Connecter',
        approve: 'Approuver',
        disapprove: 'Désapprouver',
      },
      programs: {
        name: 'programme |||| programmes',
        selected: 'sélectionné |||| sélectionnés',
        more_actions: 'Pour plus d’actions, cochez un programme dans la liste.',
      },
      tags: {
        name: 'étiquette |||| étiquettes',
        selected: 'sélectionnée |||| sélectionnées',
        more_actions:
          'Pour plus d’actions, cochez une étiquette dans la liste.',
      },
      structures: {
        name: 'structure |||| structures',
        selected: 'sélectionnée |||| sélectionnées',
        more_actions:
          'Pour plus d’actions, cochez une structure dans la liste.',
      },
      users: {
        name: 'utilisateur |||| utilisateurs',
        selected: 'sélectionné |||| sélectionnés',
        more_actions:
          'Pour plus d’actions, cochez un utilisateur dans la liste.',
      },
      inTotal: 'au total',
    },
    auth: {
      create_account: 'Créer un compte',
      create_account_error: 'Erreur lors de la création de compte',
      disconnect: 'Se déconnecter',
      email: 'Email',
      password: 'Mot de passe',
      login: 'Connexion Eurofours',
      sign_in: 'Se connecter',
      enter_security_code:
        'Entrez le code de sécurité pour vous connecter à cet utilisateur',
      validate: 'Valider',
      errorCode:
        'Le code de sécurité saisi est invalide, veuillez réessayer avec un code valide',
      forgot_password: 'Mot de passe oublié',
      selectLanguage: 'Choisir la langue',
      reset: 'Réinitialiser',
      user_created:
        "Votre compte a été créé, pensez à l'activer dans vos mails",
      account_activated: 'Votre compte a été activé avec succès',
      account_desactivated: "Le compte de l'utilisateur n'est pas activé",
    },
    buttons: {
      add: 'Créer',
      deletebutton: 'Supprimer',
      disable: 'Désactiver',
      enable: 'Activer',
      exportbutton: 'Exporter',
      tags: 'Étiquette',
      importbutton: 'Importer',
      stopImpersona: 'Arrêter la connexion',
      assign: {
        tags: {
          programs: {
            label: 'Ajouter',
            description: 'à un programme',
          },
        },
        machines: {
          structures: {
            label: 'Ajouter',
            description: 'à une structure',
          },
          programs: {
            label: 'Installer',
            description: 'un programme',
          },
        },
        programs: {
          tags: {
            label: 'Ajouter',
            description: 'une étiquette',
          },
          machines: {
            label: 'Installer',
            description: 'sur une machine',
          },
        },
        structures: {
          machines: {
            label: 'Ajouter',
            description: 'une machine',
          },
          users: {
            label: 'Ajouter',
            description: 'un utilisateur',
          },
        },
        users: {
          structures: {
            label: 'Ajouter',
            description: 'à une structure',
          },
        },
      },
      unassign: {
        tags: {
          programs: {
            label: 'Retirer',
            description: "d'un programme",
          },
        },
        machines: {
          structures: {
            label: 'Retirer',
            description: "d'une structure",
          },
          programs: {
            label: 'Désinstaller',
            description: 'un programme',
          },
        },
        programs: {
          tags: {
            label: 'Retirer',
            description: 'une étiquette',
          },
          machines: {
            label: 'Désinstaller',
            description: "d'une machine",
          },
        },
        structure: {
          machines: {
            label: 'Retirer',
            description: 'une machine',
          },
          users: {
            label: 'Retirer',
            description: 'un utilisateur',
          },
        },
        structures: {
          machines: {
            label: 'Retirer',
            description: 'une machine',
          },
          users: {
            label: 'Retirer',
            description: 'un utilisateur',
          },
        },
        users: {
          structures: {
            label: 'Retirer',
            description: "d'une structure",
          },
          structure: {
            label: 'Retirer',
            description: "d'une structure",
          },
        },
        user: {
          structures: {
            label: 'Retirer',
            description: "d'une structure",
          },
        },
      },
      action: {
        classic: {
          programs: 'le programme',
          tags: "l'étiquette",
          machines: 'la machine',
          users: "l'utilisateur",
        },
        boolean: {
          programs: {
            on: 'Approuver',
            off: 'Désapprouver',
          },
          machines: {
            on: 'Activer',
            off: 'Désactiver',
          },
          users: {
            title: {on: 'Activer utilisateur', off: 'Désactiver utilisateur'},
            content: {
              on: 'Êtes-vous sûr(e) de vouloir activer cet utilisateur ?',
              off: 'Êtes-vous sûr(e) de vouloir désactiver cet utilisateur ?',
            },
            on: 'Activer',
            off: 'Désactiver',
          },
        },
        import: 'depuis...',
        export: 'vers...',
      },
    },
    boolean: {
      enabled: 'activé',
      disabled: 'desactivé',
      enabling: 'Activation',
      disabling: 'Désactivation',
    },
    coming_soon: 'Bientôt disponible !',
    creation: {
      email: 'Email',
      firstName: 'Prénom',
      language: {
        french: 'Français',
        english: 'Anglais',
      },
      temperatureUnitPreference: {
        label: 'Unité de température',
        celsius: 'Celsius',
        fahrenheit: 'Fahrenheit',
      },
      society: 'Société',
      city: 'Ville',
      country: 'Pays',
      timezone: 'Fuseau horaire',
      adress: 'Adresse',
      zipcode: 'Code postal',
      languageLabel: 'Langue',
      lastName: 'Nom',
      password_again: 'Retapez votre mot de passe',
      countAllocatedMachine: 'Nombre de machines',
    },
    commons: {
      cancel: 'Annuler',
      unknown: 'inconnu',
      here: 'ici',
      name: 'Nom',
      description: 'Description',
      adress: 'Adresse',
      phone: 'Téléphone',
      zipcode: 'Code postal',
      displayDatePreference: "Préférence d'affichage des dates",
      temperatureUnitPreference: {
        label: 'Unité de température',
        celsius: 'Celsius',
        fahrenheit: 'Fahrenheit',
      },
      city: 'Ville',
      country: 'Pays',
      society: 'Société',
      assign: 'Ajouter',
      unassign: 'Retirer',
      category: 'Catégorie',
      parent: 'Parent',
      userInCharge: 'Utilisateur en charge',
      show: 'Afficher',
      edit: 'Modifier',
      delete: 'Supprimer image',
      errors: {
        not_granted:
          "Désolé, vous n'avez pas les droits nécessaires pour effectuer cette action.",
      },
      tryagain: 'Veuillez réessayer plus tard',
      nbPrograms: 'Attribué',
      nbMachines: 'Nombre machines',
      createdat: 'Créé le',
      updatedat: 'Mis à jour le',
      authorizedUsers: 'Utilisateurs autorisés',
      history: 'Activité',
      noHistory: "Pas d'activité",
      charLeft: 'caractères restants',
      fullHistory: "Voir l'historique complet",
      no_data: 'Aucune données trouvées',
      defaults: 'Défauts',
      stats: 'Statistiques',
    },
    components: {
      appbar: {
        activity: 'Activité',
        profile: 'Profil',
        programsImages: 'Images programmes',
        machinesImages: 'Images machines',
        manageImages: 'Vos images',
        access: "Droits d'accès",
        roles: {
          superAdmin: 'Super Admin',
          eurofoursTechnician: 'Technicien Eurofours',
          clientAdmin: 'Administrateur',
          user: 'Utilisateur',
        },
      },
      createTechnician: {
        title: "Création d'un nouveau technicien",
      },
      gauth: {
        '2fa': 'Identification à deux facteurs',
      },
      filter: {
        reset: 'Réinitialiser filtres',
        to_approve: {
          programs:
            "%{smart_count} programme en attente d'approbation |||| %{smart_count} programmes en attente d'approbation",
          machines:
            "%{smart_count} machine en attente d'approbation |||| %{smart_count} machines en attente d'approbation",
        },
      },
      home: {
        welcome: 'Bienvenue',
        securityCode: 'Code de sécurité',
        resume: {
          title: 'Résumé du compte',
          programsApproved: 'programme approuvé |||| programmes approuvés',
          machines: 'machine connectée |||| machines connectées',
          machinesToBeApproved:
            "machine en attente d'approbation |||| machines en attente d'approbation",
          structureWithMachines: 'structure équipée |||| structures équipées',
          usersCount: 'utilisateur |||| utilisateurs',
        },
        basics: {
          title: 'Les basiques',
          firstBloc: {
            whatIsThis:
              'eTools est un service de gestion à distance permettant à l’utilisateur d’avoir un contrôle direct sur son parc machine. eTools est un outil de mesure, d’analyse et d’aide à la décision pour optimiser la production d’un ou plusieurs points de vente.',
            userCan: 'Via son interface, l’utilisateur peut :',
            managePrgMach: 'Gérer les programmes de ses machines.',
            organise:
              'Organiser ses machines par structure : secteur, pays, point de vente ou tout autre critère de son choix.',
            manageUsers:
              'Gérer ses utilisateurs délégués et leur attribuer la responsabilité d’une ou plusieurs structures.',
            see:
              'Visualiser l’activité et l’état de ses machines en temps réel.',
            alerts: 'Recevoir des alertes en cas de défaillance.',
            extractDatas:
              'Extraire les données d’utilisation du parc machine à des fins d’analyse.',
            safety:
              'Tout cela dans un environnement utilisant les dernières normes de sécurité et de protection de données.',
          },
          secondBloc: {
            programs:
              'Faites parler votre créativité en mettant au point vos meilleurs programmes ! Un battement de cil et les voilà installés sur les machines que vous voulez. Une seule limite : votre imagination !',
            tags:
              'Croyez-nous, elles deviendront vite vos meilleures amies. Elles vous permettent de qualifier vos programmes pour le retrouver plus facilement ou de les regrouper par thématique.',
            machines:
              'Vos machines ont été conçues par Eurofours® ? Ca tombe très bien, vous aller pouvoir les intégrer dans eTools. Pour les autres on ne peut rien faire. Installez vos programmes, optimisez votre production en visualisant l’historique d’utilisation ou consultez les éventuels défauts de fonctionnement.',
            structures:
              'Elles représentent les différents sites de production ou de vente dont vous disposez. Organisez-les à votre image et attribuez-leur toute la performance des équipements Eurofours®.',
            users:
              'Depuis que vous avez fait le choix de matériel Eurofours® votre production explose et avec elle le nombre de structures ? Déléguez vos tâches en créant de nouveaux utilisateurs et personnalisez les droits et fonctionnalités de chacun.',
          },
        },
      },
      image_uploader: {
        label: 'Selectionnez une image',
        previewLabel: 'Aperçu',
        buttonLabel: "Valider l'aperçu",
        submitLabel: "Enregistrer l'image",
        sending: "Envoi en cours de l'image, patientez",
        success: "L'image a été envoyée avec succès",
        error: 'Une erreur est survenue, réessayez plus tard',
        images_eurofours: 'Phototèque eTools',
        images_upload: 'Charger nouvelle photo',
        images_bibliotheque: 'Ma phototèque',
        sizeLabel:
          "L'image téléchargée est trop lourde, veuillez recommencer l'opération avec une image inférieure à 5 Mo",
      },
      machine: {
        type: 'Type',
        serial: 'N° série',
        version: 'Version logicielle',
        timezone: 'Fuseau horaire',
        format: 'Format',
        none: 'Pas de machines pour cette structure',
        history: 'Historique',
        usage: 'Utilisation',
        noUsage: "Pas d'utilisation",
        deleted: 'Machine supprimée',
        deleteModal: {
          warning:
            'Vous êtes sur le point de supprimer une machine ! |||| Vous êtes sur le point de supprimer %{smart_count} machines !',
          content:
            'Souhaitez-vous conserver les programmes sur la machine ? |||| Souhaitez-vous conserver les programmes des machines ?',
          keep: 'Conserver les programmes',
          destroy: 'Tout Supprimer',
        },
        usage_types: {
          PowerOn: 'Allumage',
          PreHeatingStart: 'Préchauffage',
          WashEnd: {
            ShortWash: 'Arrêt nettoyage court',
            LongWash: 'Arrêt nettoyage long',
            StopOdor: 'Arrêt nettoyage stop odeur',
          },
          WashStart: {
            ShortWash: 'Nettoyage court',
            LongWash: 'Nettoyage long',
            StopOdor: 'Lavage stop odeur',
          },
          PowerOff: 'Arrêt',
          AutoPowerOn: 'Allumage automatique',
          AutoPowerOff: 'Arrêt automatique',
          PreheatStart: 'Début du préchauffage',
          PreheatEnd: 'Fin du préchauffage',
          DiagnosticStart: 'Début du diagnostic',
          DiagnosticEnd: 'Fin du diagnostic',
          SleepStart: 'Démarrage de la veille',
          SleepEnd: 'Arrêt de la veille',
          AutoSleepStart: 'Démarrage automatique de la veille',
          ProgramStart: 'Démarrage cuisson',
          ProgramEnd: 'Arrêt cuisson',
          ManualStart: 'Démarrage cuisson manuelle',
          ManualEnd: 'Arrêt cuisson manuelle',
        },
      },
      manage_rights: {
        title: 'Gestion des droits',
      },
      menu: {
        structures: 'Structures',
        machineType: 'Type de machine',
        machines: 'Machines',
        programs: 'Programmes',
        users: 'Utilisateurs',
        tags: 'Étiquettes',
        home: 'Accueil',
        eurofoursTechnicians: 'Techniciens Eurofours',
        clients: 'Clients',
        softwareUpdates: 'Mises à jour logiciel',
      },
      passwords: {
        label: 'Mot de passe',
        change: 'Modifier le mot de passe',
        lastPassword: 'Votre ancien mot de passe',
        newPassword: 'Votre nouveau mot de passe',
        newPassword2: 'Retapez votre nouveau mot de passe',
        mustMatch: 'Erreur, les nouveaux mots de passes sont différents',
        successChanged: 'Votre mot de passe a été changé avec succès',
        errorChanged:
          "Votre mot de passe n'a pas pu être changé, veuillez réessayer",
        resetInfo:
          'Validation de votre token de réinitialisation de mot de passe en cours',
        resetError:
          'Votre token de réinitialisation de mot de passe est invalide',
        submit: 'Valider le nouveau mot de passe',
        submitRequest: 'Demander une réinitialisation du mot de passe',
        successReset:
          'Un mail vous a été envoyé pour mettre à jour votre mot de passe',
        reset: 'Demander une réinitialisation du mot de passe',
        badPassword: 'Mot de passe invalide',
        error: "Erreur, votre mot de passe n'a pas pu être changé",
      },
      profile: {
        title: 'Profil',
        showProfileButton: 'Afficher votre profil',
        editProfileButton: 'Editer votre profil',
        gauthis: 'Google authenticator est',
        onaccount: 'sur votre compte',
        itsrightmoment: "C'est le bon moment pour",
        activate: "l'activer",
        qrcodefield: 'Renseignez le code obtenu ici',
        qrcode:
          'Veuillez scanner le QR code afin de récupérer la clef de sécurité. Celle-ci vous sera demandée à chaque nouvelle connexion.',
        disable: 'Vous pouvez le désactiver en cliquant',

        GAuthExplanation:
          "L'identification à deux facteurs est fournie par Google Authenticator.",
        GAuthDownload: 'Vous pouvez le télécharger sur',
        displayDateMode: {
          UTC: "Toujours utiliser l'heure UTC",
          MachineTimezone: 'Utiliser le fuseau horaire de la machine',
          UserTimezone: 'Toujours utiliser mon fuseau horaire',
        },
        history: {
          view: 'Voir votre historique de compte',
          backProfile: 'Retour à votre profil',
          title: 'Historique de votre profil',
          accessPlus: {
            title: 'Historique Assistance+',
            connectionAs:
              "%{eurofours_user} s'est connecté(e) en tant que %{client_user}",
          },
        },
        structures: {
          noResults: "Aucune structure n'est associée à l'utilisateur",
        },
        unfoundables: {
          firstname: 'Prénom introuvable',
          lastname: 'Nom introuvable',
        },
        notificationsEmail: {
          main: 'Notifications par email',
          problems: {
            title: 'Défauts',
            enabledDescription:
              "Vous serez notifié lorsqu'un défaut apparaît sur une de vos machines.",
            disabledDescription:
              "Vous ne serez pas noitifé lorsqu'un défaut apparaîtra sur une de vos machines.",
          },
          programs: {
            title: 'Programmes',
            enabledDescription:
              "Vous serez notifié lorsqu'une action est détectée sur un de vos programmes (création, modification, suppression, export...).",
            disabledDescription:
              'Vous ne serez pas notifié lorsque des actions surviennent sur un de vos programmes.',
          },
          machines: {
            title: 'Machines',
            enabledDescription:
              "Vous serez notifié lorsqu'une action est détectée sur une de vos machines (création, modification, suppression, approbation...).",
            disabledDescription:
              'Vous ne serez pas notifié lorsque des actions surviennent sur une de vos machines.',
          },
          structures: {
            title: 'Structures',
            enabledDescription:
              "Vous serez notifié lorsqu'une action est détectée sur une de vos structures (création, modification, suppression, ajout utilisateur...).",
            disabledDescription:
              'Vous ne serez pas notifié lorsque des actions surviennent sur une de vos structures.',
          },
          tags: {
            title: 'Etiquettes',
            enabledDescription:
              "Vous serez notifié lorsqu'une action est détectée sur une de vos étiquettes (création, modification, suppression, ajout programme...).",
            disabledDescription:
              'Vous ne serez pas notifié lorsque des actions surviennent sur une de vos étiquettes.',
          },
        },
        assistance: {
          main: 'Assistance+',
          enabled: {
            title: 'Activé',
            description:
              'Vous partagez votre accès avec le Service Client Eurofours',
          },
          disabled: {
            title: 'Désactivé',
            description:
              'Vous ne partagez pas votre accès avec le Service Client Eurofours',
          },
        },
      },
      programs: {
        assigned: 'programmes assignés',
        none: 'Aucun programmes',
        create: {
          title: 'Créer programme',
          choiceMachine: 'Choix de la machine',
          choiceTypeMachine:
            'Pour quel type de machine voulez-vous créer un nouveau programme ?',
          identify: 'Identification',
          name: 'Donnez un nom à votre programme',
          settings: 'Paramétrage',
          fillsettings:
            'Renseignez les différents paramètres de votre programme',
          save: 'Enregistrement',
          save_desc:
            'Félicitations ! Votre programme a bien été créé. Il est en attente d’approbation.',
          save_ask: "Voulez-vous l'approuver ?",
          save_approve:
            'Un programme approuvé est accessible par tous les délégués.',
          save_disapprove:
            'Un programme en attente n’est accessible qu’aux délégués autorisés. Vous pourrez l’approuver ultérieurement.',
          cantApprove: "Vous n'avez pas les droits pour approuver un programme",
        },
      },
      selectUser: {
        title: 'Sélectionnez un utilisateur pour accéder à son compte',
      },
      selectEurofoursTechnician: {
        title: 'Sélectionnez un technicien Eurofours pour accéder à son compte',
      },
      selectClientAdmin: {
        title:
          'Sélectionnez un administrateur client pour accéder à son compte',
      },
      structures: {
        assigned: 'structures assignées',
        none: 'Aucune structure',
        create: {
          title: 'Créer une structure',
        },
        edit: {
          title: 'Éditer la structure',
        },
      },
      software_updates: {
        create: {
          title: "Création d'une nouvelle mise à jour logiciel",
          semverRegexValidation: 'Le numéro de version doit être valide',
          filePlaceholder:
            'Cliquer pour sélectionner votre fichier, ou le déposer',
        },
        edit: {
          title: "Edition d'une nouvelle mise à jour logiciel",
          success: 'Mise à jour sauvegardée',
        },
      },
      tags: {
        assigned: 'étiquettes assignées',
        none: 'Aucune étiquette',
        noDescription: 'Aucune description renseignée',
        create: {
          title: 'Créer une étiquette',
        },
      },
      users: {
        none: 'Aucun utilisateur',
        resetPassword: 'Réinitialiser le mot de passe',
        resetPasswordSuccess: 'Le mot de passe a été réinitialisé avec succès',
        resetPasswordFailed: "Le mot de passe n'a pas pu être réinitialisé",
        noPhone: 'Aucun numéro renseigné',
        noDescription: 'Aucune description renseignée',
        noAddress: 'Aucune adresse renseignée',
        googleAuthenticatorConfirmed: 'Google authenticator activé',
        emailVerified: 'Email vérifié',
        create: {
          title: 'Ajouter un délégué',
        },
      },
      history: {
        assignedto: {
          machine: 'intallé sur',
          default: 'ajouté(e) à',
        },
        unassignedfrom: {
          machine: 'désinstallé de',
          default: 'enlevé(e) de',
        },
        user: {
          type: 'Utilisateur',
          create: "Création de l'utilisateur",
          delete: "Suppression de l'utilisateur %{name}",
          update: {
            default: "Mise à jour de l'utilisateur",
            imageBase64: "Modification de l'image de l'utilisateur",
            email: "Modifications de l'email de l'utilisateur",
            emailVerified: "Vérification de l'email de l'utilisateur",
            password: "Modifications du mot de passe de l'utilisateur",
            firstName: "Modifications du prénom de l'utilisateur",
            lastName: "Modifications du nom de l'utilisateur",
            address: "Modifications de l'adresse de l'utilisateur",
            mobilePhone: "Modifications du mobile de l'utilisateur",
            fixedPhone: "Modifications du fixe de l'utilisateur",
            roles: "Modifications des droits de l'utilisateur",
            googleAuthenticatorSecret:
              "Configuration de GAuth pour l'utilisateur",
            googleAuthenticatorConfirmed:
              "%{isActive} de GAuth pour l'utilisateur",
          },
        },
        machine: {
          type: 'Machine',
          create: 'Ajout de la machine',
          delete: 'Suppression de la machine %{name}',
          update: {
            default: 'Mise à jour de la machine',
            avatar: "Modification de l'image de la machine",
            name: 'Modification du nom de la machine',
            description: 'Modification de la description de la machine',
            pin: 'Modification du code PIN de la machine',
            active: '%{isActive} de la machine',
          },
        },
        program: {
          type: 'Programme',
          create: 'Création du programme',
          delete: 'Suppression du programme %{name}',
          update: {
            default: "Mise à jour de l'identité du programme",
            avatar: "Modification de l'image du programme",
            phase_groups: 'Modification des paramètres du programme',
            approved: '%{isActive} du programme',
          },
        },
        tag: {
          type: 'Étiquette',
          create: "Création de l'étiquette",
          delete: "Suppression de l'étiquette %{name}",
          update: {
            default: "Mise à jour de l'étiquette",
            name: "Modification du nom de l'étiquette",
            description: "Modification de la description de l'étiquette",
          },
        },
        structure: {
          type: 'Structure',
          create: 'Création de la structure',
          delete: 'Suppression de la structure %{name}',
          update: {
            default: 'Mise à jour de la structure',
            name: 'Modification du nom de la structure',
          },
        },
        error: 'Une erreur est survenue',
        warning: 'Un avertissement est survenu',
        success: 'Une opération a été exécutée avec succès',
      },
      roles: {
        create: 'Créer',
        edit: 'Modifier',
        delete: 'Supprimer',
        importExport: 'Importer et exporter',
        programs: {
          main: "L'utilisateur peut voir les programmes approuvés",
          create: "L'utilisateur peut créer un nouveau programme",
          edit: "L'utilisateur peut modifier un programme existant",
          delete: "L'utilisateur peut supprimer définitivement un programme",
          importExport:
            "L'utilisateur peut importer et exporter des programmes",
          accessUnapproved: {
            title: 'Accès aux programmes non approuvés',
            description:
              "L'utilisateur peut accéder à tous les programmes de ses structures, y compris non approuvés",
          },
          approve: {
            title: 'Approbation des programmes',
            description:
              "L'utilisateur peut approuver et désapprouver un programme",
          },
        },
        machines: {
          main:
            "L'utilisateur peut voir les machines approuvées liées à ses structures",
          create: "L'utilisateur peut créer une nouvelle machine (1)",
          create_help:
            'Autres droits requis pour Machines / Créer : Structures, Programmes / Créer',
          edit: "L'utilisateur peut modifier une machine existante",
          delete: "L'utilisateur peut supprimer définitivement une machine",
          accessWithoutStructure: {
            title: 'Accès aux machines sans structures',
            description: "L'utilisateur peut voir les machines sans structure",
          },
          installUninstall: {
            title: 'Installation et désinstallation de programmes',
            description:
              "L'utilisateur peut installer et désinstaller les programmes d’une machine",
          },
          approve: {
            title: 'Approbation de machines',
            description:
              "L'utilisateur peut approuver et désapprouver une machine",
          },
          activate: {
            title: 'Activation de machines',
            description: "L'utilisateur peut activer et désactiver une machine",
          },
        },
        tags: {
          main: "L'utilisateur peut voir les étiquettes liées à ses structures",
          create: "L'utilisateur peut créer une nouvelle étiquette",
          edit: "L'utilisateur peut modifier une étiquette existante",
          delete: "L'utilisateur peut supprimer définitivement une étiquette",
        },
        structures: {
          main: "L'utilisateur peut voir toutes ses structures",
          create: "L'utilisateur peut créer une nouvelle structure",
          edit: "L'utilisateur peut modifier une structure existante",
          delete: "L'utilisateur peut supprimer définitivement une structure",
          accessAllStructure: {
            title: 'Accès à toutes les structures',
            description:
              "L'utilisateur peut voir toutes les structures existantes",
          },
          addRemove: {
            title: 'Ajouter et retirer des machines',
            description:
              "L'utilisateur peut ajouter et retirer une machine d’une structure",
          },
        },
      },
      show: {
        see_more: 'Voir plus',
      },
    },
    errors: {
      E01: 'Défaillance de la sonde du four',
      E02: 'Défaillance du capteur de carte',
      E03: 'Sécurité du thermostat',
      E04: 'Défaillance d ela thermistance moteur',
      E05: "Défaillance de la date et l'heure",
      E06: 'Haute température',
      E07: 'Haute température, extinction forcée',
      E08: 'Panne électrique',
      not_resolved: 'Non résolu',
    },
    title: {
      tags: 'une étiquette |||| %{smart_count} étiquettes',
      machines: 'une machine |||| %{smart_count} machines',
      programs: 'un programme |||| %{smart_count} programmes',
      structures: 'une structure |||| %{smart_count} structures',
      users: 'un utilisateur |||| %{smart_count} utilisateurs',
      images_machines: 'Images machines',
      images_programs: 'Images programmes',
      image_update: 'Modifier Image machine %{code}',
      assign: {
        tags: {
          programs: 'Ajouter %{subject}',
        },
        machines: {
          structures: 'Ajouter %{subject}',
          programs: 'Installer sur %{subject}',
        },
        programs: {
          tags: 'Ajouter à %{subject}',
          machines: 'Installer %{subject}',
        },
        structures: {
          machines: 'Ajouter à %{subject}',
          users: 'Ajouter à %{subject}',
        },
        users: {
          structures: 'Ajouter %{subject}',
        },
      },
      unassign: {
        tags: {
          programs: 'Retirer %{subject}',
        },
        machines: {
          structures: 'Retirer %{subject}',
          programs: "Désinstaller de/d' %{subject}",
        },
        programs: {
          tags: "Retirer de/d' %{subject}",
          machines: 'Désinstaller %{subject}',
        },
        structures: {
          machines: "Retirer de/d' %{subject}",
          users: "Retirer de/d' %{subject}",
        },
        users: {
          structures: 'Retirer %{subject}',
        },
      },
    },
    images: {
      upload: {
        success: 'Image envoyée avec succès !',
        sending: "Envoi de l'image en cours",
        failure: "Une erreur est apparue durant l'envoi de l'image",
      },
      manage: 'Voir les images',
      add: 'Ajouter une image',
      delete: {
        title: 'Supprimer image',
        content: 'Êtes-vous sûr(e) de vouloir supprimer cette image ?',
      },
      machine_code: 'LogoId (ex: 0)',
      machine_description: 'Description de la machine',
    },
    input: {
      file: {},
      image: {},
      references: {},
    },
    message: {
      disable: {
        activate: 'Activer',
        disable: 'Désactiver',
        title: '%{action}',
        content:
          'Êtes-vous sûr(e) de vouloir %{action} cet élément ? |||| Êtes-vous sûr(e) de vouloir %{action} ces %{smart_count} éléments ?',
      },
      bulk_delete_title: {
        tags:
          'Supprimer une étiquette |||| Supprimer %{smart_count} étiquettes',
        machines:
          'Supprimer une machine |||| Supprimer %{smart_count} machines',
        programs:
          'Supprimer un programme |||| Supprimer %{smart_count} programmes',
        users:
          'Supprimer un utilisateur |||| Supprimer %{smart_count} utilisateurs',
        structures:
          'Supprimer une structure |||| Supprimer %{smart_count} structures',
      },
      update_property: {
        tags: {},
        machines: {
          approved: {
            on: 'Approuver %{subject}',
            off: 'Désapprouver %{subject}',
          },
          active: {
            on: 'Activer %{subject}',
            off: 'Désactiver %{subject}',
          },
        },
        programs: {
          approved: {
            on: 'Approuver %{subject}',
            off: 'Désapprouver %{subject}',
          },
        },
        users: {
          active: {
            on: 'Activer %{subject}',
            off: 'Désactiver %{subject}',
          },
        },
        structures: {},
        confirm: 'Confirmer ?',
      },
    },
    navigation: {},
    notification: {
      email_updated: 'Email mis à jour, veuillez vous reconnecter.',
      '2fa_error':
        "Erreur lors de l'activation de l'identification à deux facteurs.",
      account_updated: 'Le compte a été mis à jour',
    },
    page: {},
    validation: {
      samepassword: 'Erreur, les mots de passe sont différents',
      pin: 'Doit contenir 4 chiffres',
    },
  },
  resources: {
    program_machines: {
      fields: {
        position: 'Position',
        errors: 'Défauts',
      },
    },
    machines: {
      edit: 'Modifier une machine',
      create: 'Ajouter une machine',
      programs_installed_on: 'programme installé |||| programes installés',
      fields: {
        name: 'Nom',
        category: 'Catégorie',
        structure: 'Structure',
        serialNumber: 'N° de série',
        specifications: {
          capacity: 'Capacité',
          energy: 'Énergie',
        },
        format: 'Format',
        active: 'Activé',
        approved: 'Approbation',
        pin: 'Code PIN',
      },
    },
    structures: {
      edit: 'Modifier une structure',
      create: 'Créer une structure',
      fields: {
        name: 'Nom',
        machines: 'Machines',
        users: 'Utilisateurs',
        userInCharge: 'Propriétaire',
      },
    },
    programs: {
      edit: 'Modifier un programme',
      create: 'Créer un programme',
      name: 'programme',
      installed_on: 'machine liée |||| machines liées',
      fields: {
        name: 'Nom',
        category: 'Catégorie',
        createdAt: 'Créé le',
        updatedAt: 'Modifié le',
        nbMachines: 'Machines',
        tags: 'Étiquettes',
        machines: 'Machines',
        approved: 'Approbation',
        programMachines: 'Machines',
      },
      errors: {
        required: 'Cette valeur est requise',
        limits: 'Cette valeur doit être comprise entre %{min} et %{max}',
      },
      confirmDuration: {
        title: 'La durée du programme va être réduite !',
        content:
          "En modifiant cette valeur, la durée totale du programme sera réduite et d'autres phases seront supprimées. Êtes-vous sûr(e) de vouloir valider ce changement ?",
      },
    },
    users: {
      edit: 'Modifier un utilisateur',
      create: 'Créer un utilisateur',
      name: 'utilisateurs',
      fields: {
        email: 'Email',
        password: 'Mot de passe',
        lastName: 'Nom',
        firstName: 'Prénom',
        society: 'Société',
        tags: 'Étiquettes',
        nbStructures: 'Structures',
        googleAuthenticatorConfirmed: 'Google authenticator activé',
        emailVerified: 'Email vérifié',
        address: 'Adresse',
        phone: 'Téléphone',
        street: 'Rue',
        city: 'Ville',
        zipcode: 'Code postal',
        country: 'Pays',
        countryCode: 'Langue',
        temperatureUnitPreference: 'Unité de température',
      },
    },
    tags: {
      edit: 'Modifier une étiquette',
      create: 'Créer une étiquette',
      name: 'étiquettes',
      fields: {
        name: 'Nom',
        createdAt: 'Créé le',
        programs: 'Programmes',
      },
    },
    software_updates: {
      fields: {
        addRevoked: 'Révoqués',
        isAllowedBeta: 'Béta',
        version: 'Version',
        beta: 'Béta',
        revoked: 'Révoqué',
        description: 'Description',
        checksum: 'Checksum',
        file: 'Fichier',
        countDownloaded: 'Nombre de téléchargement',
      },
    },
  },
};
