import React, {useMemo, useCallback, useState} from 'react';
import {translate} from 'react-admin';
import {Grid, Typography} from '@material-ui/core';
import CriteriaInput from './CriteriaInput';
import {Heat, Prephase, Duration} from '../../Buttons/SVG';
import CriteriaLine from './CriteriaLine';
import {
  formatHeatPhases,
  fromFahrenheit,
  getTotalDuration,
  shouldDeletePhases,
  toFahrenheit,
} from './utils';

function PhaseGroupHeat({
  phaseGroups,
  phase,
  onChange,
  totalDuration,
  translate,
  isFahrenheit,
}) {
  const limits = {
    duration: {
      min: 0,
      max: 240,
    },
    temperature: {
      min: isFahrenheit ? toFahrenheit(0) : 0,
      max: isFahrenheit ? toFahrenheit(250) : 250,
    },
  };

  const [preHeatChange, setPreHeatChange] = useState(
    phase.prePhaseValues[0].value !== limits.temperature.min,
  );

  const decalage = isFahrenheit ? toFahrenheit(30) : 30;

  const phases = formatHeatPhases(phase, isFahrenheit);

  const preHeat = useMemo(() => {
    if (phase) {
      if (isFahrenheit) return toFahrenheit(phase.prePhaseValues[0].value);
      else return phase.prePhaseValues[0].value;
    }
    return 0;
  }, [phase, isFahrenheit]);

  const parse = (preHeat, phases) => ({
    phaseGroupType: 'heat',
    phases: phases.map((p, index) => {
      const data = [
        {
          criteria: 'temperature',
          value: isFahrenheit ? fromFahrenheit(p.temperature) : p.temperature,
        },
        {
          criteria: 'duration',
          value: p.duration * 60,
        },
      ];

      // Vérifier si l'index n'est pas le dernier du tableau
      if (index < phases.length - 1) {
        data.push({
          criteria: 'notification',
          value: p.notification ? 1 : 0,
        });
      }

      return {
        position: index,
        values: data,
      };
    }),
    prePhaseValues: [
      {
        criteria: 'temperature',
        value: isFahrenheit ? fromFahrenheit(preHeat) : preHeat,
      },
    ],
  });

  const handlePreheatChange = useCallback(
    value => {
      const {min, max} = limits.temperature;
      let newValue = parseInt(value, 10);

      if (!newValue) newValue = min;
      else if (newValue < min) newValue = min;
      else if (newValue > max) newValue = max;
      const result = parse(newValue, phases);
      setPreHeatChange(true);
      onChange(result);
    },
    [phases, onChange, setPreHeatChange],
  );

  const handleNotificationChange = useCallback(
    (index, attribute) => event => {
      const newPhases = [...phases];
      newPhases[index][attribute] = event.target.checked;
      const result = parse(preHeat, newPhases);

      onChange(result);
    },
    [phases, preHeat, onChange],
  );

  const handleChange = useCallback(
    (index, attribute) => value => {
      const newPhases = [...phases];
      let newPreheat = preHeat;
      let newValue = parseInt(value, 10);
      if (!newValue) newValue = 0;
      newPhases[index][attribute] = newValue;
      if (
        attribute === 'temperature' &&
        index === 0 &&
        preHeatChange === false
      ) {
        newPreheat =
          newValue + decalage < limits.temperature.max
            ? newValue + decalage
            : limits.temperature.max;
      }
      const result = parse(newPreheat, newPhases);

      onChange(result);
    },
    [phases, preHeat, preHeatChange, onChange],
  );

  const addPhase = useCallback(
    index => event => {
      event.preventDefault();
      const newPhases = [...phases];
      newPhases.splice(index + 1, 0, {
        temperature: limits.temperature.min,
        duration: 0,
      });
      const result = parse(preHeat, newPhases);
      onChange(result);
    },
    [phases, preHeat, onChange],
  );

  const removePhase = useCallback(
    index => event => {
      event.preventDefault();
      const newPhases = [...phases];
      newPhases.splice(index, 1);
      const result = parse(preHeat, newPhases);
      onChange(result);
    },
    [phases, preHeat, onChange],
  );

  const validPreheatChange = useCallback(() => {
    if (preHeat !== limits.temperature.min) setPreHeatChange(true);
  }, [preHeat, setPreHeatChange]);

  // on détecte si une phase risque d'être supprimée quand une durée de cuisson change
  const shouldDeleteOtherPhases = useCallback(
    index => value => {
      const newPhases = [...phases];
      let newValue = parseInt(value, 10);
      newPhases[index].duration = newValue;
      const newDuration = getTotalDuration(parse(0, newPhases));
      if (newDuration < totalDuration) {
        const fan = shouldDeletePhases(
          phaseGroups.find(p => p.phaseGroupType === 'fan'),
          newDuration,
        );
        const damper = shouldDeletePhases(
          phaseGroups.find(p => p.phaseGroupType === 'damper'),
          newDuration,
        );
        const steam = shouldDeletePhases(
          phaseGroups.find(p => p.phaseGroupType === 'steam'),
          newDuration,
        );
        return fan || damper || steam;
      }
      return false;
    },
    [phases],
  );

  return (
    <Grid container spacing={24} style={{marginBottom: 3 + 'em'}}>
      <Grid item>
        <Typography>
          <Heat isColored />
          <sup>{phases.length}</sup>
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <CriteriaInput
              min={limits.temperature.min}
              max={limits.temperature.max}
              unit={isFahrenheit ? '°F' : '°C'}
              Icon={Prephase}
              value={preHeat || limits.temperature.min}
              onChange={handlePreheatChange}
            />
          </Grid>
        </Grid>
        {phases.map((p, index) => {
          return (
            <CriteriaLine
              isLast={index === phases.length - 1}
              onAdd={addPhase(index)}
              onRemove={removePhase(index)}
              notification={p.notification}
              withNotification={index < phases.length - 1}
              onNotificationChange={handleNotificationChange(
                index,
                'notification',
              )}
              canAdd={phases.length < 50}
              canDelete={phases.length > 1}>
              <CriteriaInput
                min={limits.temperature.min}
                max={limits.temperature.max}
                unit={isFahrenheit ? '°F' : '°C'}
                Icon={Heat}
                value={p.temperature}
                onChange={handleChange(index, 'temperature')}
                onBlur={validPreheatChange}
              />
              <CriteriaInput
                min={limits.duration.min}
                max={limits.duration.max}
                unit="min"
                Icon={Duration}
                value={p.duration}
                onChange={handleChange(index, 'duration')}
                delayed
                confirmCondition={shouldDeleteOtherPhases(index)}
                confirmTitle={translate(
                  'resources.programs.confirmDuration.title',
                )}
                confirmContent={translate(
                  'resources.programs.confirmDuration.content',
                )}
              />
            </CriteriaLine>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default translate(PhaseGroupHeat);
