import jwt_decode from 'jwt-decode';

// use in-memory token to avoid localStorage I/O (Sylvain)
let token = localStorage.getItem('token');

export const getToken = () => {
  return token || null;
};

export const setToken = payload => {
  token = payload;
  localStorage.setItem('token', payload);
  localStorage.setItem('role', jwt_decode(payload).roles);
};

export const resetToken = () => {
  token = undefined;
  localStorage.removeItem('token');
  localStorage.removeItem('role');
};

export const getBaseRoles = ['ROLE_CLIENT_ADMIN', 'ROLE_EUROFOURS_TECHNICIAN'];

export const getId = () => {
  return token ? jwt_decode(token).id : null;
};

export const getEmail = () => {
  return token ? jwt_decode(token).username : null;
};

export const getRoles = () => {
  return token ? jwt_decode(token).roles : null;
};

export const hasRoles = values => {
  const roles = getRoles();

  if (!roles) {
    return false;
  }

  return values.some(value => roles.includes(value));
};
