import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel, LinearProgress} from '@material-ui/core';
import {translate} from 'react-admin';

class SwitchRights extends Component {
  constructor(props) {
    super(props);
    const {user, value} = props;
    this.state = {
      checked: user.roles.includes(value.role),
    };
  }

  componentDidUpdate(prevProps) {
    const {user, value} = this.props;
    if (prevProps.user.roles !== user.roles) {
      this.setState({checked: user.roles.includes(value)});
    }
  }

  handleChange = id => (event, checked) => {
    let {value} = event.target;
    const {updateUserRoles, user} = this.props;

    id = user['@id'].split('/');
    updateUserRoles({
      id: id[id.length - 1],
      name: value,
      value: checked,
    });

    if (checked) {
      user.roles.push(value);
    } else {
      user.roles = user.roles.filter(role => role !== value);
    }
    this.props.handleRoles({role: value, type: checked});

    this.setState({checked});
  };

  handleDisabled = () => {
    const {
      roles,
      value: {parents},
    } = this.props;
    var isDisabled = false;
    parents &&
      parents.map(parent => {
        if (!roles.includes(parent)) {
          isDisabled = true;
        }
        return true;
      });
    return isDisabled;
  };

  render() {
    const {checked} = this.state;
    const {user, translate, value} = this.props;
    if (user) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              onChange={this.handleChange(user['@id'])}
              value={value.role}
              disabled={this.handleDisabled()}
              checked={checked}
            />
          }
          label={
            <div>
              <span
                style={{
                  fontSize: 1.3 + 'em',
                  fontStyle: 'bold',
                  display: 'block',
                }}>
                {translate(value.name)}
              </span>
              <span style={{color: 'gray', display: 'block'}}>
                {translate(value.description)}
              </span>
            </div>
          }
        />
      );
    } else {
      return <LinearProgress />;
    }
  }
}

SwitchRights.propTypes = {
  user: PropTypes.object,
  value: PropTypes.object,
  updateUserRoles: PropTypes.func,
};

export default translate(SwitchRights);
