export const styles = theme => ({
  root: {
    '& > div': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      '& > div:first-child': {
        '& > div': {
          marginBottom: '1em',
        },
        '& > .title': {
          [theme.breakpoints.up('sm')]: {
            backgroundColor: '#ededed',
          },
        },
      },
      // Select the div containing actions and filters
      // TODO: find a better way to do it
      '& > div:nth-child(2)': {
        flexDirection: 'column-reverse',
        marginBottom: 16,
      },
    },
  },
});
