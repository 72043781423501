import React from 'react';
import {withStyles, Grid, Dialog, Typography} from '@material-ui/core';
import {translate} from 'react-admin';
import {compose} from 'recompose';
import ImageUploader from '../ImageUploader';
import {connect} from 'react-redux';
import {
  initSendImage,
  resetSendImage,
  sendProfileImage,
  sendUserImage,
} from '../ImageUploader/store/action';
import AvatarPlaceholder from '../../assets/placeholder150.svg';
import {getEmail, hasRoles} from '../../utils/auth';

const styles = theme => ({
  container: {
    width: 100 + '%',
    textAlign: 'center',
  },
  avatar: {
    borderRadius: 100 + '%',
    border: 1 + 'px solid whitesmoke',
    maxWidth: 100 + '%',
    maxHeight: 100 + '%',
  },
  edit: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textAlign: 'center',
    paddingTop: 0.5 + 'em',
  },
  delete: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textAlign: 'center',
    paddingTop: 0.5 + 'em',
  },
});

const Avatar = ({
  translate,
  classes,
  initSendImage,
  resetSendImage,
  sendProfileImage,
  sendUserImage,
  isUpdated,
  image,
  text = 'Avatar',
  type,
  data,
}) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <div className={classes.container}>
          <img
            src={
              image
                ? 'profile' === type || 'user' === type
                  ? image
                  : `${process.env.REACT_APP_API_ENTRYPOINT}/${image}`
                : `${AvatarPlaceholder}`
            }
            alt="avatar"
            className={classes.avatar}
            width="150"
            height="150"
          />
        </div>
      </Grid>
      {'programs' !== type && (
        <Grid item>
          <>
            <Typography
              className={classes.edit}
              onClick={() => initSendImage()}>
              {translate('custom.commons.edit')}
            </Typography>
            {data && hasRoles(['ROLE_CLIENT_ADMIN']) && image !== null && (
              <Typography
                className={classes.delete}
                onClick={() => sendUserImage(data.id, null)}>
                {translate('custom.commons.delete')}
              </Typography>
            )}
            {!data && image !== null && (
              <Typography
                className={classes.delete}
                onClick={() =>
                  sendProfileImage({
                    imageBase64: null,
                  })
                }>
                {translate('custom.commons.delete')}
              </Typography>
            )}
            <Dialog
              open={isUpdated === 'ready'}
              onClose={() => resetSendImage()}
              scroll="body">
              <ImageUploader type={type} data={data} />
            </Dialog>
          </>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({imageReducer}) => ({
  isUpdated: imageReducer.isUpdated,
});

export default compose(
  translate,
  connect(mapStateToProps, {
    initSendImage,
    resetSendImage,
    sendProfileImage,
    sendUserImage,
  }),
  withStyles(styles),
)(Avatar);
