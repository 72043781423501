import {all, put, takeEvery} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {showNotification} from 'react-admin';
import {
  USER_CREATE,
  USER_CREATE_LOADING,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILURE,
  CLIENT_ADMIN_CREATE,
  CLIENT_ADMIN_CREATE_LOADING,
  CLIENT_ADMIN_CREATE_SUCCESS,
  CLIENT_ADMIN_CREATE_FAILURE,
} from '../action/registerAction';
import {client} from '../utils/api-client';

function* handleRegistration(action) {
  const {type, payload} = action;
  switch (type) {
    case USER_CREATE: {
      try {
        const {
          email,
          password,
          firstName,
          lastName,
          countryCode,
          society,
          zipcode,
          street,
          city,
          country,
          temperatureUnitPreference,
        } = payload;

        yield put({type: USER_CREATE_LOADING});
        yield client('users', {
          data: {
            email,
            password,
            firstName,
            lastName,
            countryCode,
            society,
            zipcode,
            street,
            city,
            country,
            temperatureUnitPreference,
          },
        });

        yield put({
          type: USER_CREATE_SUCCESS,
          payload: payload,
        });
        yield put(push('/login'));
        yield put(showNotification('custom.auth.user_created', 'info'));
      } catch (e) {
        yield put({
          type: USER_CREATE_FAILURE,
          error: e,
          meta: {auth: true},
        });
      }
      break;
    }
    case CLIENT_ADMIN_CREATE: {
      try {
        const {
          email,
          password,
          firstName,
          lastName,
          countryCode,
          society,
          zipcode,
          street,
          city,
          country,
          temperatureUnitPreference,
        } = payload;

        yield put({type: CLIENT_ADMIN_CREATE_LOADING});
        yield client('register', {
          data: {
            email,
            password,
            firstName,
            lastName,
            countryCode,
            society,
            zipcode,
            street,
            city,
            country,
            temperatureUnitPreference,
          },
        });

        yield put({
          type: CLIENT_ADMIN_CREATE_SUCCESS,
          payload: payload,
        });
        yield put(push('/login'));
        yield put(showNotification('custom.auth.user_created', 'info'));
      } catch (e) {
        yield put({
          type: CLIENT_ADMIN_CREATE_FAILURE,
          error: e,
          meta: {auth: true},
        });
      }
      break;
    }
    default:
      break;
  }
}

function* handleErrorRegistration() {
  yield put(showNotification('custom.auth.create_account_error', 'warning'));
}

export default function* watchCreateActions() {
  yield all([
    takeEvery(USER_CREATE, handleRegistration),
    takeEvery(USER_CREATE_FAILURE, handleErrorRegistration),
    takeEvery(CLIENT_ADMIN_CREATE, handleRegistration),
    takeEvery(CLIENT_ADMIN_CREATE_FAILURE, handleErrorRegistration),
  ]);
}
