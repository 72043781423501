import React from 'react';
import {translate} from 'react-admin';
import {Link} from 'react-router-dom';
import {Button, Typography, Grid} from '@material-ui/core';
import {ArrowBack, ArrowForward} from '@material-ui/icons';

export const Navigation = translate(
  ({translate, back, forward, description, disabled = false}) => (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      style={{margin: '.5em'}}>
      <Grid item>
        {back && (
          <Button
            component={Link}
            to={{
              pathname: back,
              state: {skipFormReset: true},
            }}
            variant="flat"
            size="small"
            className="button-nav"
            style={{backgroundColor: '#f14343', color: 'white'}}>
            <ArrowBack />
          </Button>
        )}
      </Grid>

      {description && (
        <Grid item>
          <Typography
            style={{
              minHeight: '32px',
              padding: '7px 8px',
              fontStyle: 'italic',
            }}>
            {translate(description)}
          </Typography>
        </Grid>
      )}

      <Grid item>
        {forward && !disabled && (
          <Button
            component={Link}
            to={{
              pathname: forward,
              state: {skipFormReset: true},
            }}
            variant="flat"
            size="small"
            className="button-nav"
            style={{backgroundColor: '#f14343', color: 'white'}}>
            <ArrowForward />
          </Button>
        )}
      </Grid>
    </Grid>
  ),
);
