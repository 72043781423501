import React from 'react';
import {AssignButton} from '../Buttons';
import {hasRoles} from '../../utils/auth';
import CustomActions from './CustomActions';

export default props => {
  let buttons = [];
  if (hasRoles(['ROLE_STRUCTURE_ADD_MACHINE', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton
        target="machines"
        assign
        single
        roles={['ROLE_STRUCTURE_ADD_MACHINE']}
      />,
    );
  }
  if (hasRoles(['ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton target="users" assign roles={['ROLE_STRUCTURE_EDITOR']} />,
    );
  }
  if (hasRoles(['ROLE_STRUCTURE_ADD_MACHINE', 'ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton
        target="machines"
        single
        roles={['ROLE_STRUCTURE_ADD_MACHINE']}
      />,
    );
  }
  if (hasRoles(['ROLE_CLIENT_ADMIN'])) {
    buttons.push(
      <AssignButton target="users" single roles={['ROLE_STRUCTURE_EDITOR']} />,
    );
  }

  return <CustomActions buttons={buttons} {...props} />;
};
