export const IMAGES_GET_MACHINES = 'IMAGES_GET_MACHINES';
export const IMAGES_GET_MACHINE = 'IMAGES_GET_MACHINE';
export const IMAGES_GET_MACHINE_SUCCESS = 'IMAGES_GET_MACHINE_SUCCESS';
export const IMAGES_GET_MACHINES_SUCCESS = 'IMAGES_GET_MACHINES_SUCCESS';
export const IMAGES_DELETE_MACHINES = 'IMAGES_DELETE_MACHINES';
export const IMAGES_DELETE_MACHINES_SUCCESS = 'IMAGES_DELETE_MACHINES_SUCCESS';
export const IMAGES_UPDATE_MACHINES_SUCCESS = 'IMAGES_UPDATE_MACHINES_SUCCESS';
export const IMAGE_SEND_MACHINES_SUCCESS = 'IMAGE_SEND_MACHINES_SUCCESS';

export const getImagesMachines = data => ({
  type: IMAGES_GET_MACHINES,
  payload: data,
});

export const getImageMachine = data => ({
  type: IMAGES_GET_MACHINE,
  payload: data,
});

export const deleteImagesMachines = data => ({
  type: IMAGES_DELETE_MACHINES,
  payload: data,
});
