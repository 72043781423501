import React, {useEffect, useState} from 'react';
import {client} from '../../../utils/api-client';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose';
import Chip from '@material-ui/core/Chip';
import {translate} from 'react-admin';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

const styles = theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing.unit}px`,
    margin: `${theme.spacing.unit}px 0`,
    maxWidth: '200px',
  },
  chip: {
    height: '24px',
    '& > span': {
      padding: theme.spacing.unit,
    },
  },
  more: {
    background: 'none',
    border: `1px solid #616161`,
    '& > span': {
      color: '#616161',
    },
  },
  loading: {
    textAlign: 'center',
  },
});

const CustomMachineField = ({classes, translate, source, record = {}}) => {
  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!record) return;
    const programMachines = record.programMachines;
    const machinesToFetch = programMachines ? programMachines.slice(0, 3) : [];

    const fetchData = async () => {
      // On effectue les requêtes en parallèle pour chaque machine
      const promises = machinesToFetch.map(async id => {
        // Assurez-vous que la route et la méthode pour récupérer les détails de la machine sont corrects.
        // La fonction `client` doit être adaptée pour gérer correctement l'URL et renvoyer les données attendues.
        const {machine} = await client(`${id}`);
        return machine; // Supposons que l'API renvoie un objet avec la propriété `machine` contenant `name`.
      });

      // Attend toutes les promesses et met à jour l'état avec les noms des machines.
      Promise.all(promises).then(machines => {
        setMachines(machines);
        setIsLoading(false);
      });
    };

    if (machinesToFetch.length > 0) {
      fetchData();
    }

    fetchData();
  }, [record]);
  return isLoading ? (
    <div className={classes.loading}>
      <CircularProgress size={24} />
    </div>
  ) : !record.programMachines || record.programMachines.length === 0 ? (
    '-'
  ) : (
    <div className={classes.chips}>
      {machines.map(machine => (
        <Link to={`/machines/${encodeURIComponent(machine['@id'])}/show`}>
          <Chip
            clickable
            key={machine.name}
            label={machine.name}
            className={classes.chip}
          />
        </Link>
      ))}
      {record.programMachines.length > 3 && (
        <Chip
          className={classNames(classes.more, classes.chip)}
          label={translate('more_count').replace(
            '%count%',
            record.programMachines.length - 3,
          )}></Chip>
      )}
    </div>
  );
};

export default compose(translate, withStyles(styles))(CustomMachineField);
