import React, {Component, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  getHistory,
  getProfileHistory,
  getAccessPlusHistory,
} from './store/action';
import GridList from '../Common/GridList';
import {Grid, Typography, CircularProgress} from '@material-ui/core';
import HistoryItemContent from './ItemContent';
import {translate} from 'react-admin';
import {compose} from 'recompose';
import DateRenderer from '../Common/DateRenderer';
import {client} from '../../utils/api-client';

const HistoryItem = ({item}) => {
  const date = new Date(item.dateEvent.date || item.dateEvent);
  const [machineTimezone, setMachineTimezone] = useState(null);

  useEffect(() => {
    const fetchMachine = async () => {
      const result = await client(`/machines/${item.targetId}`);
      if (result && result.timezone) {
        setMachineTimezone(result.timezone);
      }
    };

    // set UTC default for program histories
    // @FIXME (hack fix to prevent timezone from machine reducer to be used if outside)
    if (item.typeEntity && item.typeEntity.includes('Program')) {
      setMachineTimezone('Etc/UTC');
    }

    if (item.targetId) {
      fetchMachine();
    }
  }, [item]);

  return (
    <Grid container wrap="nowrap">
      <Grid item xs={3}>
        <DateRenderer date={date} timezoneFromProps={machineTimezone} />
      </Grid>
      <Grid item xs>
        <Typography style={{color: '#606060', fontWeight: 500}}>
          <HistoryItemContent item={item} />
        </Typography>
      </Grid>
    </Grid>
  );
};

class PartialHistory extends Component {
  componentDidMount() {
    const {id, getHistory, getProfileHistory} = this.props;

    if (!id) {
      getProfileHistory();
      return;
    }

    getHistory({type: id.split('/')[1], id: id.split('/')[2]});
  }

  componentDidUpdate = prevProps => {
    const {id, getHistory, refresh} = this.props;

    if (!id || (id === prevProps.id && !refresh)) {
      return;
    }

    getHistory({type: id.split('/')[1], id: id.split('/')[2]});
  };

  render() {
    const {title, history, historyLoaded} = this.props;

    return !historyLoaded ? (
      <CircularProgress />
    ) : (
      <GridList
        data={history}
        title={title && 'custom.commons.history'}
        noDataDesc="custom.commons.noHistory">
        <HistoryItem />
      </GridList>
    );
  }
}

PartialHistory.propTypes = {
  id: PropTypes.string,
  title: PropTypes.bool,
  history: PropTypes.array.isRequired,
  historyLoaded: PropTypes.bool.isRequired,
};

const mapStatetoProps = ({historyReducer}) => ({
  history: historyReducer.history,
  historyLoaded: historyReducer.historyLoaded,
  refresh: historyReducer.refresh,
});

export default compose(
  translate,
  connect(mapStatetoProps, {
    getHistory,
    getProfileHistory,
    getAccessPlusHistory,
  }),
)(PartialHistory);
