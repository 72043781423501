import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {
  HISTORY_GET_ALL_ONE_REQUEST,
  HISTORY_GET_ALL_ONE_SUCCESS,
  HISTORY_GET_PROFILE_REQUEST,
  HISTORY_GET_PROFILE_SUCCESS,
  HISTORY_GET_ACCESSPLUS_REQUEST,
  HISTORY_GET_ACCESSPLUS_SUCCESS,
  HISTORY_GET_APPBAR_REQUEST,
  HISTORY_GET_APPBAR_SUCCESS,
  HISTORY_GET_ALL_ONE_ACCESSPLUS_REQUEST,
  HISTORY_GET_ALL_ONE_ACCESSPLUS_SUCCESS,
} from '../components/History/store/action';
import {errorMessageHandler} from './helpers';
import {client} from '../utils/api-client';

function* handleStructure(action) {
  const {type, payload} = action;
  switch (type) {
    case HISTORY_GET_ALL_ONE_REQUEST: {
      try {
        const response = yield client(
          `history/${payload.type}/${payload.id}/full`,
        );
        yield put({
          type: HISTORY_GET_ALL_ONE_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case HISTORY_GET_ALL_ONE_ACCESSPLUS_REQUEST: {
      try {
        const response = yield client(
          `history/${payload.type}/${payload.id}/full`,
        );
        yield put({
          type: HISTORY_GET_ALL_ONE_ACCESSPLUS_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case HISTORY_GET_PROFILE_REQUEST: {
      try {
        const response = yield client('profile/history');
        yield put({
          type: HISTORY_GET_PROFILE_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case HISTORY_GET_ACCESSPLUS_REQUEST: {
      try {
        const response = yield client('history/impersonalization');
        yield put({
          type: HISTORY_GET_ACCESSPLUS_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case HISTORY_GET_APPBAR_REQUEST: {
      yield put({
        type: HISTORY_GET_APPBAR_SUCCESS,
        payload: payload,
      });
      break;
    }
    default:
      break;
  }
}

export default function* watchStructuresActions() {
  yield all([takeEvery(HISTORY_GET_ALL_ONE_REQUEST, handleStructure)]);
  yield all([takeEvery(HISTORY_GET_PROFILE_REQUEST, handleStructure)]);
  yield all([takeEvery(HISTORY_GET_ACCESSPLUS_REQUEST, handleStructure)]);
  yield all([takeEvery(HISTORY_GET_APPBAR_REQUEST, handleStructure)]);
}
