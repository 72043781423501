import React, {Component, Fragment} from 'react';
import {Menu, MenuItem, Button} from '@material-ui/core';
import {connect} from 'react-redux';
import {changeLocale} from 'react-admin';
import './selectlanguage.css';

class SelectLanguage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      language: localStorage.getItem('language')
        ? localStorage.getItem('language')
        : 'en',
    };
  }

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = event => {
    if (!event.target.attributes.value) {
      this.setState({anchorEl: null});

      return;
    }

    const locale = event.target.attributes.value.value;

    this.setState({language: locale, anchorEl: null});
    localStorage.setItem('language', locale);
    this.props.changeLocale(locale);
  };

  render() {
    const {anchorEl, language} = this.state;
    return (
      <Fragment>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          color="inherit">
          {language && language.toUpperCase()}
        </Button>
        <Menu
          id="locale-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          <MenuItem value="en" onClick={this.handleClose}>
            EN
          </MenuItem>
          <MenuItem value="fr" onClick={this.handleClose}>
            FR
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

export default connect(undefined, {changeLocale})(SelectLanguage);
