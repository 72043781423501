import * as actions from './action';

const initialState = {
  userProfile: null,
  machine: null,
  structure: null,
  nbResourcesToApprove: 0,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.USER_UPDATE_ONE_SUCCESS:
      return {
        ...state,
        userProfile: payload,
      };
    case actions.MACHINE_UPDATE_ONE_SUCCESS:
      return {
        ...state,
        machine: payload,
      };
    case actions.STRUCTURE_UPDATE_ONE_SUCCESS:
      return {
        ...state,
        structure: payload,
      };
    case actions.GET_NB_RESOURCES_TO_APPROVE_SUCCESS:
      return {
        ...state,
        nbResourcesToApprove: payload,
      };
    default:
      return state;
  }
};
